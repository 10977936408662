import http from "./http-common";

class RequestService {

  getFilterConditions(filterId, token) {
    return http.get( 'getFilterConditions/' + filterId, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      }
    });
  }


    getAllUserCommentsByQueryId(queryId, token) {
      return http.get( 'commentsByQueryId/' + queryId, {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        }
      });
    }


    getAllUserCommentsByQueryIdForProcessor(queryId, token) {
      return http.get( 'commentsByQueryIdForProcessor/' + queryId, {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        }
      });
    }

    getAllUserCommentsByQueryIdForAdmin(queryId, token) {
      return http.get( 'commentsByQueryIdForAdmin/' + queryId, {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        }
      });
    }

  getCustomizationForQueryId(queryId, token) {
    return http.get( queryId + '/getUserReportCustomizationOptions', {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: {}
    });
  }

  saveCustomizations(token, data, queryId) {
    return http.post(queryId + '/saveUserReportCustomizationOptions', JSON.stringify(data), {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      }
    });
  }

  getFilteredMatchConditionCodes(postData, token) {
    return http.post('/getFilteredMatchConditions', JSON.stringify(postData),  {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      }
    });
  }

  getReport(queryId, token) {
    return http.get( queryId + '/getReport/', {
        headers: {
          "Authorization": "Bearer " + token
        },
        responseType: 'arraybuffer'    
      });
    }


  getReportForProcessor(queryId, token) {
    return http.get( queryId + '/getReportForProcessor/', {
        headers: {
          "Authorization": "Bearer " + token
        },
        responseType: 'arraybuffer'
      });
    }

    getReportForAdmin(queryId, token) {
      return http.get( queryId + '/getReportForAdmin/', {
          headers: {
            "Authorization": "Bearer " + token
          },
          responseType: 'arraybuffer'
        });
      }
    
    getSellerReportAsPDF(queryId, token) {
    return http.get( queryId + '/getSellerReportAsPDF/', {
        headers: {
          "Authorization": "Bearer " + token
        },
        responseType: 'arraybuffer'    
      });
    }

  getUserQuery(queryId, token) {
      var obj = {};
      obj.queryId = queryId;
      return http.post( '/getUserQuery', JSON.stringify(obj), {
          headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
          }
        });
    }

  getUserQueryForProcessor(queryId, token) {
      var obj = {};
      obj.queryId = queryId;
      return http.post( '/getUserQueryForProcessor', JSON.stringify(obj), {
          headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
          }
        });
    }

   compareReport(queryIds, token) {
    return http.post( '/getComparisonReport', queryIds, {
      headers: {
        "Authorization": "Bearer " + token
      },
      responseType: 'arraybuffer'    
    });

   }  

  saveUserQuery(queryArray, token) {
    return http.post("/saveUserQuery", JSON.stringify(queryArray), {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
    });
  }
  
  register(registerData) {
    return http.post('/register', registerData, {
      headers: {
        "Content-Type": "application/json"
      }
    })
  }

  enableShared(queryId, token) {
    return http.get('/enableSharedIndicatorOnQuery/' + queryId, {
      headers: {
        "Authorization": "Bearer " + token,
        'Content-type': 'application/json; charset=UTF-8',
      },
      data: {}
    })
  }

  disableShared(queryId, token) {
    return http.get('/disableSharedIndicatorOnQuery/' + queryId, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: {}
    })
  }

  enableSharedCustom(queryId, token) {
    return http.get('/enableSharedIndicatorOnCustomizedQuery/' + queryId, {
      headers: {
        "Authorization": "Bearer " + token,
        'Content-type': 'application/json; charset=UTF-8',
      },
      data: {}
    })
  }

  disableSharedCustom(queryId, token) {
    return http.get('/disableSharedIndicatorOnCustomizedQuery/' + queryId, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: {}
    })
  }

  getSharedLink(queryId, token) {
    return http.get('/getSharedReportLink/' + queryId, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: {}
    })
  }

  getViewScore(queryId, token) {
    return http.get(queryId + '/viewScore', {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: {}
    })
  }

    getViewScoreForProcessor(queryId, token) {
      return http.get(queryId + '/viewScoreForProcessor', {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        },
        data: {}
      })
    }

  getViewCredits(token) {
    return http.get('/viewCredits', {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: {}
    })
  }

  getUserRoles(token) {
    return http.get('/getUserRoles', {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: {}
    })
  }

  getCustomSharedReportLink(queryId, token) {
    return http.get('/getCustomSharedReportLink/' + queryId, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: {}
    })
  }

  getSharedReport(reportId) {
    return http.get('/getSharedReport/' + reportId, {
      responseType: 'arraybuffer'    
    });
  }

  getCustomSharedReport(reportId) {
    return http.get('/getCustomSharedReport/' + reportId, {
      responseType: 'arraybuffer'    
    });
  }


  getAllScoreFieldForUserQueryId(queryId, token) {
    var obj = {};
    obj.queryId = queryId;
    return http.post( '/getAllScoringFieldsForQueryId', JSON.stringify(obj), {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        }
      });

  }


  getAllScoreFieldForUserQueryIdForProcessor(queryId, token) {
    var obj = {};
    obj.queryId = queryId;
    return http.post( '/getAllScoringFieldsForQueryIdForProcessor', JSON.stringify(obj), {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        }
      });

  }

  resetLink(dataToPost) {
    return http.post('/getResetLink', dataToPost, {
      headers: {
        "Content-Type": "application/json"
      }
    })
  }

  changePassowrd(dataToPost, token) {
    return http.post('/changePassword', dataToPost, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      }
    })
  }


  assignByAdmin(dataToPost, token) {
    return http.post('/assignToProcessorForAdmin', dataToPost, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
      }
    })
  }

    assignToMeForProcessor(dataToPost, token) {
      return http.post('/assignToMeForProcessor', dataToPost, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        }
      })
    }



  unAssignFromProcessorForAdmin(dataToPost, token) {
    return http.post('/unAssignFromProcessorForAdmin', dataToPost, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
      }
    })
  }

  resetPasswordWithToken(dataToPost, token) {
    return http.post('/resetPasswordWithToken', dataToPost, {
        headers: {
          "Content-Type": "application/json"
      }
    })
  }

  updateUser(dataToPost, token) {
    return http.post('/updateUserName', dataToPost, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      }
    })
  }

  authenticate(loginData) {
    return http.post('/authenticate', loginData, {
      headers: {
        "Content-Type": "application/json"
      }
    })
  }

  activate(email, token) {
    return http.get('/' + email + '/' + token + '/activate/', {
      headers: {
        "Content-Type": "application/json"
      }
    })
  }

  paymentSuccess(paymentIntent, token) {
    return http.get('/payment/' + paymentIntent + '/status', {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      }
    })
  }

    markAsCompleted(jsonData, token) {
      return http.post('/markAsCompleted', jsonData, {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        }
      })
    }

  submitFeedback(jsonData, token) {
    return http.post('/submitRatingAndFeedback', jsonData, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      }
    })
  }

  provideClarification(jsonData, token) {
    return http.post('/provideClarification', jsonData, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      }
    })
  }

  needClarification(jsonData, token) {
    return http.post('/needClarification', jsonData, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      }
    })
  }

  sendBackRequest(jsonData, token) {
    return http.post('/sendBackRequest', jsonData, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      }
    })
  }

  deleteFile(sessionId, fileType, token) {
    return http.get('/deleteFile/' + sessionId + '/' + fileType, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      }
    })
  }

  calculateScore(dataToPost, token) {

    if(token != null) {
        return http.post( '/calculateScoreAndSave', dataToPost, {
            headers: {
              "Authorization": "Bearer " + token,
              "Content-Type": "application/json"
            }
        });
    } else {
        return http.post('/calculateScoreAndSave', dataToPost, {
          headers: {
            "Content-Type": "application/json"
          }
        });
    }
  }
  calculateScoreForProcessor(dataToPost, token) {

      if(token != null) {
          return http.post( '/calculateScoreAndSaveForProcessor', dataToPost, {
              headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
              }
          });
      } else {
          return http.post('/calculateScoreAndSave', dataToPost, {
            headers: {
              "Content-Type": "application/json"
            }
          });
      }
    }

  submitCalculateRequest(dataToPost, selectedFiles, token) {
        const formData = new FormData();
          selectedFiles.forEach(file => {
            formData.append('files', file);
        });

        // Append JSON data as a string
        formData.append('jsonData', dataToPost);

      return http.post( '/submitCalculateRequest', formData, {
        headers: {
          "Authorization": "Bearer " + token,
          'Content-Type': 'multipart/form-data'
        }
    });
  }

  submitBulkRequest(dataToPost, token) {
    return http.post( '/submitBulkRequest', dataToPost, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      }
    });
  }

  checkSubmitRequestImages(token, queryId) {
    return http.get( '/hasSubmitRequestImages/' + queryId, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      }
    });
  }

  checkFlipStatusForImages(token, queryId) {
    return http.get( '/checkFlipStatusForImages/' + queryId, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      }
    });
  }


  getSubmitRequestImages(token, queryId) {
    return http.get( '/getSubmitRequestImages/' + queryId, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      responseType: 'blob'
    });
  }

  getBulkSubmitRequestImages(token, queryId) {
    return http.get( '/downloadBulkFiles/' + queryId, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      responseType: 'blob'
    });
  }



  submitBulkRequestFile(bulkQueryId, file, token) {
    const formData = new FormData();
    formData.append('bulkQueryId', bulkQueryId);
    // Append JSON data as a string
    formData.append('file', file);

    return http.post( '/uploadBulkRequestFile', formData, {
    headers: {
      "Authorization": "Bearer " + token,
      'Content-Type': 'multipart/form-data'
    }
});
}
}



export default new RequestService();
