import React, { useState, useEffect } from 'react';
import 'react-tooltip/dist/react-tooltip.css'
import { useMediaQuery } from 'react-responsive';
import { selectCurrentUser, selectCurrentToken, userCredits, selectCurrentCredit } from '../authSlice';
import { useSelector } from 'react-redux';
import loadingImage from '../assets/images/loading.gif';
import ShowImages from './showImages';
import {Button, Box, Typography, Alert, Dialog, DialogContent, DialogActions, LinearProgress} from '@mui/material';
import ShowScore from './showScore';
import ShowForm from './showForm';
import RequestService from '../RequestService';
import { useDispatch } from 'react-redux';
import { clearSession, saveSession, selectSessionId } from '../imageSlice';
import { FaBackward, FaCalculator } from 'react-icons/fa';
import { Skeleton } from '@mui/material';
import ShowFormSkeleton from './ShowFormSkeleton';
import MaterialModalImageUpload from '../components/MaterialModalImageUpload';
import { confirmAlert } from 'react-confirm-alert';
import { ToastContainer, toast } from 'react-toastify';
import EditScore from './editScore';
import { setQueryId as setReportQueryId, selectQueryId, clearQueryId, setReportTitle } from '../reportSlice';
import { useNavigate } from 'react-router-dom';
import UploadService from "../UploadFileService";
import { clearRotation, selectRotation } from '../angleSlice';
import config from '../config';
import MarkdownViewerFromFile from '../components/MarkdownViewerFromFile';
import ScrollTopFab from '../components/ScrollToTopFab';
import VideoBar from '../components/VideoBar';
import usePageView from '../components/usePageView'; // Import the custom hook
import { Document, Page, pdfjs } from 'react-pdf';
import PDFViewerHeader from '../components/PDFViewerHeader';
import { Helmet } from 'react-helmet';

const getStatus = (label) => {

   if (label == 5) {
      return 'Excellent';
   }

   else if (label == 4) {
      return 'Good';
   }

   else if (label == 3) {
      return 'Average';
   }

   else if (label == 2) {
      return 'Unfavorable';
   } else {
      return 'Least Favorable'
   }

}


function GetScore() {
    const [roomParameters,setRoomParameters] = useState([]);
    const [showScore,setShowScore] = useState(false);
    const [scoreData,setScoreData] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [showFormData, setShowFormData] = useState(true);
    const [openHelp, setOpenHelp] = useState(false);
    const currentRotation = useSelector(selectRotation);
    const [showReport, setShowReport] = useState(false);
    const [score, setScore] = useState(0);
    const [overallRating, setOverallRating] = useState(0);
    const currentUser = useSelector(selectCurrentUser);
    const currentToken = useSelector(selectCurrentToken);
    const sessionId = useSelector(selectSessionId);
    const [showWizard, setShowWizard] = useState(sessionId == null ? true: false);
    const [loadingText, setLoadingText] = useState([]);
    const [showUploadImage, setShowUploadImage] = useState(false);
    const [showImageBox, setShowImageBox] = useState(true);
    const [loading, setLoading] = useState(false); 
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const [credits, setCredits] = useState([]);
    const dispatch = useDispatch();
    const [title, setTitle] = useState(null);
    const currentCredit = useSelector(selectCurrentCredit);
    const[queryId, setQueryId] = useState(null);
    const editQueryId = useSelector(selectQueryId);
    const [showEdit, setShowEdit] = useState(false);
    const [responseToLoad, setResponseToLoad] = useState([]);
    const navigate = useNavigate();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
   const [loadingReport, setLoadingReport] = useState(false);
   const [downloadQueryId, setDownloadQueryId] = useState(null);
   const [messageText, setMessageText] = useState([]);
   const [defaultScale, setDefaultScale] = useState(1);
   const [userRoles, setUserRoles] = useState([]);

  
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    };
  
    const downloadPdf = () => {
      // Check if pdfUrl is available and there is a valid queryId
      if (pdfUrl && downloadQueryId) {
        const reportId = downloadQueryId;
  
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `report_${reportId}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // Handle the case where pdfUrl is not available or there is no queryId
        console.error("Cannot download PDF. pdfUrl is not available or no queryId.");
      }
    };

     const navigateHome = () => {
       // 👇️ navigate to /
       navigate('/');
     };

   function reloadPage() {
      setShowScore(false);
      setScoreData([]);
      setScore(0)
      setOverallRating(0)
      setRoomParameters([]);
      setShowForm(false);
      dispatch(clearSession());
   }

   function gotoReports() {
      navigate('/myreports');
   }

   useEffect(() => {
      dispatch(clearRotation());
      setLoading(true);
      if (sessionId) {
         //If an image is already available, take straight to the showForm
         setShowWizard(false);
         setShowForm(true);
         
      } else {
         UploadService.getCurrentSessionId(currentToken).then((response) => {
            if (response.data != "NA") {
               const sessionId = response.data;
               dispatch(saveSession(sessionId));
            }
         });
         dispatch(clearQueryId());
      }
      fetch(config[process.env.NODE_ENV].backendUrl + '/getAllScoringFields', {
         method: 'GET',
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + currentToken
        },
      }).then((response) => response.json())
         .then((data) => {
            RequestService.getViewCredits(currentToken).then((response) => {
               if (response.status == 200) {
                  setCredits(response.data.creditsRemaining);   
                  dispatch(userCredits(response.data.creditsRemaining));
                  setRoomParameters(data.scoreFieldList.sort(sort_by('sequenceNumber', false, parseInt)));
                  setShowForm(true);
                  setLoading(false);
               }
            });

         }).catch((err) => {
            setLoadingText("An error occurred loading data. Please try again later");
         });
   }, [showForm]);

  useEffect(() => {
    // Fetch user roles and update state
    if (currentToken) {
      RequestService.getUserRoles(currentToken).then((response) => {
        if (response.status === 200) {
          setUserRoles(response.data.map(role => role.name)); // Extract role names
        }
      });
    }
  }, [currentToken]);

   const sort_by = (field, reverse, primer) => {

      const key = primer ?
        function(x) {
          return primer(x[field])
        } :
        function(x) {
          return x[field]
        };
    
      reverse = !reverse ? 1 : -1;
    
      return function(a, b) {
        a = key(a); 
        b = key(b); 
        return reverse * ((a > b) - (b > a));
      }
    }

   function handleSelection() {
      var selectedValue = document.getElementById("imageSelection").value;
      if (selectedValue == 'N') {
         //Image not available show form
         setShowWizard(false);
         setShowForm(true);
      } else {
         //Show the form to upload the image
         setShowUploadImage(true);
      }
   }
   
   function closeUpload() {
      setShowUploadImage(false);
      setShowWizard(false);
   }

   function skipImage() {
      setShowWizard(false);
   }

   function calculateScore(responseArray) {

       calcScoreAfterConfirm(responseArray);
   }

   function setTitleFromBox(title) {
      setTitle(title);
   }

   function loadReport(queryId) {
      setLoadingReport(true);
     //document.getElementById('reportFrame').srcdoc = `<div align="center"><img height="300px" width="300px" src="${loadingImage}" alt="Embedded Image in Iframe" /></div>`;
     RequestService.getReport(queryId, currentToken).then((response) => {
      if (response.status === 200) {
         // Check the Content-Type header
         const contentType = response.headers['content-type'];
         if (contentType === 'application/pdf') {
           // The response contains a PDF
           const blob = new Blob([response.data], { type: 'application/pdf' });
           const objectUrl = URL.createObjectURL(blob);
   
           setPdfUrl(objectUrl);
           setDownloadQueryId(queryId);
           setShowPdfViewer(true);
   
           setLoadingReport(false);
         } else {
           // Handle unexpected content type
           console.error('Unexpected Content-Type:', contentType);
           setMessageText('Error loading the report. Unexpected content type.');
           setLoadingReport(false);
         }
       } else if (response.status === 403) {
         setMessageText("Access is denied, token may have expired. Please logout and login again.");
         setLoadingReport(false);
       } else {
         setMessageText("Error loading the report. Please try again.");
         setLoadingReport(false);
       }
});
  }

  function showEditScreen() {
      //setShowReport(false);
      //setShowFormData(true);
      //setShowForm(!showForm);
      RequestService.getUserQuery(queryId, currentToken).then((response) => {
         if (response.status == 200) { 
           var queryString = response.data.queryString;
           var imageSessionId = response.data.imageSessionId;
           dispatch(saveSession(imageSessionId));
           var responseObject = JSON.parse(queryString);
           var editRoomParameters = [...roomParameters];
           let j = 0;
           editRoomParameters.map((roomParameter, index) => {
               for(let i = j; i < responseObject.query.length; i++) {
                  let obj = responseObject.query[i];
                  if (obj.objectCode == roomParameter.objectCode) {
                     if (!roomParameter.matchCondition) {
                        roomParameter.matchCondition = obj.matchCondition;
                        j = i+1;
                        break;
                     }
                     
                  }
              }
           });
           setRoomParameters(editRoomParameters);
           setShowForm(true);
           setTitle(response.data.title);
           dispatch(setReportTitle(response.data.title));
           dispatch(setReportQueryId(queryId));
           setShowEdit(true);
         } else if (response.status == 403) {
            toast.error("Access is denied, token may have expired please logout and login again");
         } else {
            toast.error("Unable to Load Saved Query");
         }
      });
       
  }

  const zoomIn = () => {
   setDefaultScale(defaultScale + 0.1);
 };

 const zoomOut = () => {
   if (defaultScale > 0.1) setDefaultScale(defaultScale - 0.1);
 };

const handleViewPrint = async () => {
try {
const pdfData = await fetch(pdfUrl).then((res) => res.blob());
const blobUrl = URL.createObjectURL(pdfData);

const newWindow = window.open('', '_blank');
if (newWindow) {
 newWindow.document.write(`<iframe width="100%" height="100%" src="${blobUrl}"></iframe>`);
 newWindow.document.title = 'Print Preview';

 // Instruct users to use the browser's print option (e.g., Ctrl+P or Command+P)
 newWindow.document.write('<p>Please use the browser\'s print option (e.g., Ctrl+P or Command+P) to print this document.</p>');
}
} catch (error) {
console.error('Error fetching PDF data:', error);
}
};

  function backToReports(score, scoreData, overallRating) {
      setScore(score);
      setScoreData(scoreData);
      setOverallRating(overallRating);
      setShowEdit(false);
      //calculateScore(responseArray);
      setShowReport(true);
      loadReport(queryId);
  }

  function openLearnMore() {
   setOpenHelp(true);
  }

  function handleCloseHelp() {
   setOpenHelp(false);
  }

   function calcScoreAfterConfirm(responseArray) {
     responseArray.imageSessionId = sessionId;
     responseArray.compassRotation = currentRotation;
     if(!userRoles.includes('Processor')) {

         RequestService.calculateScore(JSON.stringify(responseArray),currentToken).then((response) => {
                if (response.status == 200) {
                    setShowScore(true)
                    setShowFormData(false);
                    setShowReport(true);
                    setShowImageBox(false);
                    setScore(response.data.score);
                    setOverallRating(getStatus(response.data.ratingCode))
                    //setShowForm(false);
                    setScoreData(response.data.scoringDataSetList)
                    setTitle(response.data.title);
                    setQueryId(response.data.queryId);
                    dispatch(setReportQueryId(response.data.queryId));
                    dispatch(setReportTitle(response.data.title));
                    loadReport(response.data.queryId);
                    RequestService.getViewCredits(currentToken).then((response) => {
                     if (response.status == 200) {
                        setCredits(response.data.creditsRemaining);
                        dispatch(userCredits(response.data.creditsRemaining));
                     }
                  });
                }
              })
              .catch((err) => {
               if (currentCredit == 0) {
                  toast.error("Calculate - Vastuscore™ failed as there are not enough credits to process the request");
               }
                console.log(err);
         });
     } else {

        RequestService.calculateScoreForProcessor(JSON.stringify(responseArray),currentToken).then((response) => {
                if (response.status == 200) {
                   alert("Your report has been created for the selected user. This request has been assigned to you. Review the request under you all assigned requests in the processor screen and click the Mark As Complete button - if all looks good.")
                   navigateHome();
                }
              })
              .catch((err) => {
                  toast.error("Calculate - Vastuscore™ failed at this time. You might not have sufficient access, please reach out to the admin");
            });
        }
      }

      usePageView('Calculate - Vastuscore™ Page');

      return (
         
         <Box sx={{ width: '100%'}} alignContent={'center'} align={'center'} justifyContent={'center'}>
          <Helmet>
            {/* Additional event-specific tag */}
            <script>
              {`
                gtag('event', 'ads_conversion_SIGNUP_1', {
                  // <event_parameters>
                });
              `}
            </script>
          </Helmet>
             {showWizard ? '' : <VideoBar/>}
            <br></br>
            <Box sx={{ width: '100%', display: isMobile ? 'relative' : 'flex'}}  alignContent={'center'} align={'center'} justifyContent={'center'}>
         {showEdit ? <EditScore backToReports={backToReports} buttonName='View Report'/>: 
            <Box sx={{ width: '100%', display: isMobile ? 'relative' : 'flex'}} alignContent={'center'} align={'center'} justifyContent={'center'}>
            
            <ToastContainer position='top-center' theme='dark'/>
            {showUploadImage ? <MaterialModalImageUpload setOpenUpload={closeUpload} reloadImage={true} userToken={currentToken} editSessionId={sessionId} queryId={queryId}/>: ''}  
            {showWizard ? <Box sx={{ width: isMobile ? '99%': '50%', display: isMobile ? 'relative' : 'flex'}} align="center" mt="55px">
                  <fieldset>
                     <legend>Image</legend>
                  <table>
                  <tr>
                        <td colSpan={2}>

                        </td>
                     </tr>
                     <tr>
                     <td>
                     <Typography variant="body2">Do you have an image of the floor plan that you can upload ? 
                     </Typography>
                        </td>
                        <td>
                        <Typography variant="body1">
                        <select id="imageSelection" onChange={handleSelection}>
                           <option value="">Select One</option>
                           <option value="Y">Yes</option>
                           <option value="N">No</option>
                        </select>
                        </Typography>
                        </td>
                     </tr>
                     <tr>
                        <td colSpan={2}>

                        </td>
                     </tr>
                     <tr>
                        <td colSpan={2}>

                        </td>
                     </tr>
                     <tr>
                        <td colSpan={2}>
                        <Alert severity="info"><Typography variant='body2'>Highly Recommended: Having an image helps you with finding your Bhramastan (center point of the house) and all scores are derived based on that</Typography></Alert>
                        </td>
                     </tr>
                  </table>
                  <Button onClick={skipImage}>Skip</Button>
                  </fieldset>
                 
               </Box> 
               :
               <Box align="center" width={"99%"}>
                  <br></br>
                  {showReport ? <div><Button variant='contained' size='small' onClick={gotoReports}>My Reports</Button>&nbsp;<Button variant='contained' size='small' onClick={showEditScreen}>Edit</Button>&nbsp;{showPdfViewer ? <Button variant="contained" onClick={downloadPdf} size='small'>Download Report</Button>:''}</div>: ''}
                  {queryId && !showReport ?<Button variant='contained' size='small'>Show Report</Button>: ''}
                  
             <br></br>
             {!isMobile?<Alert severity="warning" className='centeredAlertContent'>
             <Typography variant='body2'>For a comprehensive understanding, review our important disclaimers and guidelines in the website footer. The Vastuscore™, rating, and report are provided for informational purposes.</Typography>
             </Alert>:''}
            <br></br>
            <Box sx={{ width: '100%', display: isMobile ? 'relative' : 'flex'}} align='left'>
               {showScore ? (  
                  <ShowScore score={score} overallRating={overallRating} scoreData={scoreData} reloadPage={reloadPage} title={title} editQueryId={queryId} showReport={showReport}/>): <Box sx={{ width: '33%'}}></Box>}   
               {!loading && showFormData ?  
                  <ShowForm calculateScore={calculateScore} roomParameters={roomParameters} editMode={queryId ? true: false} setShowScore={setShowScore} reloadPage={reloadPage} queryId={queryId} setTitle={setTitleFromBox} title={title}/> 
                  :''}   
                {showReport ?  <Box sx={{ width: '100%', marginLeft: isMobile ? '1px':'5px' }}><br></br>
                <Box>
     {loadingReport ? (
       // Render a loading indicator or message instead of the download button
       <Box width={"60%"} display={"flex"} alignContent={"center"} justifyContent={"center"} flexDirection="column" align="center">
       <LinearProgress/>
       <p>Loading Report</p>
       </Box>
     ) : (
        
       // Render the download button only when the report is loaded
       <Box display={"flex"} alignContent={"center"} justifyContent={"center"} flexDirection="column" align="center" >
       {showPdfViewer ? (
       <div>
       <PDFViewerHeader downloadPdf={downloadPdf} zoomIn={zoomIn} zoomOut={zoomOut} handleViewPrint={handleViewPrint} pageNumber={pageNumber} numPages={numPages}/>
     <div className='pdf-viewer-container'>
       <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} mobile={true}>
       
         {Array.from(new Array(numPages), (el, index) => (
           <div key={`page_container_${index + 1}`} className='pdf-page'>
             <Page
               key={`page_${index + 1}`}
               pageNumber={index + 1}
               renderTextLayer={false}
               scale={defaultScale}
               renderAnnotationLayer={false}
               className="pdf-page-content"
             />
           </div>
         ))}
       </Document>
     </div>
     </div>  
     ) : (
       <div className="loading-error-message">
          {messageText}
       </div>
     )}

       </Box>
     )}

   </Box>
                </Box>: ''}
               {currentUser && showImageBox ? <ShowImages editMode={false} queryId={queryId}/>: <Box sx={{ width: '33%'}}></Box>}   
                 
           </Box>
           </Box>}
           
           </Box>}
           </Box>  

           <Dialog
                  maxWidth={"md"}
                  open={openHelp}
                >
                  <DialogContent>
                        <MarkdownViewerFromFile mdFilePath="./help/credits_help.md" />
                    </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseHelp()}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>
         </Box>
         
      )
}

export default GetScore