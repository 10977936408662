import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Avatar, CircularProgress, Box, Typography } from '@mui/material';
import SupportAgentSharpIcon from '@mui/icons-material/SupportAgentSharp';
import RequestService from '../RequestService';
import { format } from 'date-fns';

const CommentSection = ({ queryId, currentToken, currentUser, isProcessor , isAdmin}) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadComments();
  }, []);

  const stringAvatar = (name) => {
    const initials = name
      .split(' ')
      .map((part) => part.charAt(0))
      .join('');

    return initials.toUpperCase();
  };

  function loadComments() {

    if(isProcessor != null && isProcessor) {
            RequestService.getAllUserCommentsByQueryIdForProcessor(queryId, currentToken)
              .then((commentsResponse) => {
                setComments(commentsResponse.data);
              })
              .catch((commentsError) => {
                console.error('Error fetching comments:', commentsError);
              })
              .finally(() => {
                setLoading(false);
              });
    } else if(isAdmin != null && isAdmin) {
            RequestService.getAllUserCommentsByQueryIdForAdmin(queryId, currentToken)
              .then((commentsResponse) => {
                setComments(commentsResponse.data);
              })
              .catch((commentsError) => {
                console.error('Error fetching comments:', commentsError);
              })
              .finally(() => {
                setLoading(false);
              });

    } else {
        RequestService.getAllUserCommentsByQueryId(queryId, currentToken)
          .then((commentsResponse) => {
            setComments(commentsResponse.data);
          })
          .catch((commentsError) => {
            console.error('Error fetching comments:', commentsError);
          })
          .finally(() => {
            setLoading(false);
          });
    }
  }

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', width: '100%' }}>
          <CircularProgress />
        </Box>
      ) : comments.length === 0 ? (
        <Typography variant="body2">No past comments.</Typography>
      ) : (
        <List>
          {comments.map((comment) => (
            <ListItem key={comment.commentId}>
              {comment.userType === 'Processor' ? (
                <Avatar>
                  <SupportAgentSharpIcon />
                </Avatar>
              ) : (
                <Avatar>{stringAvatar(currentUser)}</Avatar>
              )}
              <ListItemText
                primary={
                  <>
                    <Typography variant="body1" style={{ display: 'inline-block', textAlign: 'justify', textJustify: 'inter-word' }} sx={{ paddingLeft: (theme) => theme.spacing(1) }}>
                      {comment.commentText}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ paddingLeft: (theme) => theme.spacing(1) }}
                      color="textSecondary"
                    >
                      {format(new Date(comment.creationDate), 'MMMM dd, yyyy h:mm a')}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default CommentSection;
