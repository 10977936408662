import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogTitle, Grid, Slide, Button, TextField, Typography, CircularProgress, Box } from '@mui/material';
import { Alert } from '@mui/material';
import RequestService from '../RequestService';
import { selectCurrentToken } from '../authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { userCredits } from '../authSlice';
import SupportAgentSharpIcon from '@mui/icons-material/SupportAgentSharp';
import CommentSection from '../components/CommentSection';
import { selectCurrentUser} from '../authSlice';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NeedClarificationModal = ({ setNeedClarificationForm, queryId, loadAllReports }) => {
  const dispatch = useDispatch();
  const currentToken = useSelector(selectCurrentToken);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [clarificationText, setClarificationText] = useState('');
  const [timeSpentSoFar, setTimeSpentSoFar] = useState('');

  const [formVisible, setFormVisible] = useState(true);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    loadComments();
  }, []);

  function loadComments() {
    RequestService.getAllUserCommentsByQueryId(queryId, currentToken)
      .then((commentsResponse) => {
        setComments(commentsResponse.data);
      })
      .catch((commentsError) => {
        console.error('Error fetching comments:', commentsError);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleClose() {
    setNeedClarificationForm(false);
  }

  const handleNeedClarification = (event) => {
    setClarificationText(event.target.value);
  };

  const handleTimeSpentSoFar = (event) => {
    const inputValue = event.target.value;

    // Use regex to allow only whole numbers
    if (/^[0-9]*$/.test(inputValue)) {
      setTimeSpentSoFar(inputValue);
    } else {
        alert("Please enter numeric values only")
    }

  };

  function submitNeedClarification() {
    if (document.getElementById('clarificationText').value === '' || document.getElementById('timeSpentSoFar').value === '') {
      setMessage('Please enter your comments and time spent so far.');
      return;
    }

    const clarificationObject = {
      sendBackText: clarificationText,
      queryId: queryId,
      timeSpentSoFar: parseInt(timeSpentSoFar, 10) || 0
    };

    RequestService.needClarification(JSON.stringify(clarificationObject), currentToken)
      .then((response) => {
        if (response.status === 200) {
             setSuccessMessage('Your question has been submitted back to the customer. You can click the "Close Window" button to close this window.');
             setFormVisible(false);
             loadAllReports();
        }
      })
      .catch((err) => {
        setMessage('Unable to send your question at this time');
      });
  }

  return (
    <Dialog open={true} scroll={'paper'} onClose={handleClose} TransitionComponent={Transition} maxWidth="md" fullWidth align="center">
      <DialogActions>
        <Button onClick={handleClose}>Close Window X</Button>
      </DialogActions>

      {successMessage && successMessage !== '' ? (
        <div style={{ padding: '16px' }}>
          <Alert severity="success">{successMessage}</Alert>
        </div>
      ) : (
        ''
      )}

      {formVisible && (
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <DialogTitle id="scroll-dialog-title" align="center">
              Request Clarification Form
              <br />
            </DialogTitle>

            {message && message !== '' ? <Alert severity="error">{message}</Alert> : ''}


            <Typography>Enter your time spent so far in this request (in minutes) <span className="mandatory">*</span></Typography>
            <br />
                <TextField id="timeSpentSoFar" onChange= {handleTimeSpentSoFar} fullWidth multiline rows={1} variant="filled" />
                <br />
                <br />


            <Typography>Enter your question to the customer<span className="mandatory">*</span></Typography>
            <br />
            <TextField id="clarificationText" onChange={handleNeedClarification} fullWidth multiline rows={10} variant="filled" />
            <br />
            <br />
            <br />
            <br />
            <Button variant="contained" size="small" onClick={submitNeedClarification}>
              Send Question to Customer
            </Button>
            <br></br>
             <br></br>
                         <br></br>
                          <br></br>
          <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'left' }}>
            Comments on this request:
          </Typography>
              <br></br>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', width: '100%' }}>
                <CircularProgress />
              </Box>
            ) : (
              <CommentSection queryId={queryId} currentToken={currentToken} currentUser={currentUser} isProcessor={true} />
            )}
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export default NeedClarificationModal;
