import React from 'react';

class FilterDropDown extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: this.props.value};
      this.state.id = this.props.id
      this.state.options = this.props.options;  
      this.handleChange = this.handleChange.bind(this);
    }
  
    handleChange() {
      this.setState({value: document.getElementById(this.state.id).value});
    }
  
    render() {
      return (
            <select id={this.state.id} value={this.state.value} onChange={this.handleChange} name="showFilter">
              <option value="">--Select One--</option>  
              {
                this.state.options.map((optionCode) => {
                    return <option key={optionCode.filterConditionId} value={optionCode.valueCode}>{optionCode.valueDisplayName}</option>
                })
              }
            </select>
      );
    }
  }

  export default FilterDropDown