import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import {DialogActions, Button} from '@mui/material';
import Slide from '@mui/material/Slide';
import { selectCurrentToken } from '../authSlice';
import "./Modal.css";
import { useSelector } from 'react-redux';
import { Alert, Box} from '@mui/material';
import { useEffect } from 'react';
import Toggle from 'react-toggle';
import RequestService from '../RequestService';
import { useMediaQuery } from 'react-responsive';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function CustomizeReportOptions({queryId, loadAllReports, setShowOptions}) {
    const currentToken = useSelector(selectCurrentToken);
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const [loadOptions, setLoadOptions] = useState(false);
    const [includeOverallScoreSection, setIncludeOverallScoreSection] = useState(false);
    const [includeIndividualRatingSection, setIncludeIndividualRatingSection] = useState(false);
    const [includePostiveOnlyRating, setIncludePostiveOnlyRating] = useState(false);
    const [includeAllHighLights, setIncludeAllHighLights] = useState(false);
    const [includePostiveOnlyHighlights, setIncludePostiveOnlyHighlights] = useState(false);
    const [includeAllDescriptiveComments, setIncludeAllDescriptiveComments] = useState(false);
    const [includePositiveDescriptiveComments, setIncludePositiveDescriptiveComments] = useState(false);
    const [includeImage, setIncludeImage] = useState(false);
    const [message, setMessage] = useState([]);
    

    function handleChange() {
        setShowOptions(false);
    }

    function saveCustomization() {
        //Get all the selection and Save against the query id
        var dataToSave = {};
        dataToSave.includeOverallScoreSection = document.getElementById("includeOverallScoreSection").checked
        dataToSave.includeIndividualRatingSection = document.getElementById("includeIndividualRatingSection").checked;
        dataToSave.includePositiveOnlyIndividualRatings = document.getElementById("includePostiveOnlyRating").checked;
        dataToSave.includeAllHighlights = document.getElementById("includeAllHighLights").checked;
        dataToSave.includePositiveOnlyHighlights = document.getElementById("includePostiveOnlyHighlights").checked;
        dataToSave.includeAllDescriptiveComments = document.getElementById("includeAllDescriptiveComments").checked;
        dataToSave.includePostiveOnlyDescriptiveComments = document.getElementById("includePositiveDescriptiveComments").checked;
        dataToSave.includeImages = document.getElementById("includeImage").checked;
        RequestService.saveCustomizations(currentToken, dataToSave, queryId).then((response) => {
            if (response.status == 200) {
                setMessage("Customization Saved Successfully");
                setShowOptions(false);
            } else  {
                setMessage("An error occurred, unable to save report customizations. Please try again later.");
                setShowOptions(true);
            }
          })
          .catch((err) => {
            setMessage("An error occurred, unable to save report customizations. Please try again later.");
            setShowOptions(true);
        });   
    }

     
    useEffect(() => {
        RequestService.getCustomizationForQueryId(queryId, currentToken)
                .then((response) => {
                    if (response.status == 200) {
                        if(response.data) {
                            var jsonContent = JSON.parse(response.data.customizationJSON);
                            setIncludeOverallScoreSection(jsonContent.includeOverallScoreSection);
                            setIncludeIndividualRatingSection(jsonContent.includeIndividualRatingSection);
                            setIncludePostiveOnlyRating(jsonContent.includePositiveOnlyIndividualRatings);
                            setIncludeAllHighLights(jsonContent.includeAllHighlights);
                            setIncludePostiveOnlyHighlights(jsonContent.includePositiveOnlyHighlights);
                            setIncludeAllDescriptiveComments(jsonContent.includeAllDescriptiveComments);
                            setIncludePositiveDescriptiveComments(jsonContent.includePostiveOnlyDescriptiveComments);
                            setIncludeImage(jsonContent.includeImages);
                            setLoadOptions(true);
                        } else {
                            setLoadOptions(true);
                        }
                    }
            })
           

    }, [queryId]) 

    return (
        <Dialog
        fullScreen
        open={true}
        scroll={"paper"}
        onClose={handleChange}
        TransitionComponent={Transition}
        align="right"
      >
        <DialogActions>
          <Button onClick={handleChange}>Close Window X</Button>
        </DialogActions>
 
        <Box style={{width:isMobile ?"100%": "33%", display:"relative",  backgroundColor: 'rgb(250, 250, 252)'}} mb={5} align="center" m="auto"> 
        <fieldset>
        <legend>Customization Options</legend>   
            {message != '' ? <Alert severity='info'>{message}</Alert>: ''}
            {loadOptions ? <div>
            <table>
                <tr>
                    <td>
                        Include Overall Score section ?
                    </td>
                    <td>
                        <Toggle defaultChecked={includeOverallScoreSection} id="includeOverallScoreSection"/>
                    </td>
                </tr>
                <tr>    
                    <td>
                        Include All Items In Individual Rating section ?
                    </td>
                    <td>
                        <Toggle defaultChecked={includeIndividualRatingSection} id="includeIndividualRatingSection"/>
                    </td>
                </tr>
                <tr>    
                    <td>
                        Include Positive only Items in Individual Rating Section ?
                    </td>
                    <td>
                        <Toggle defaultChecked={includePostiveOnlyRating} id="includePostiveOnlyRating"/>
                    </td>
                </tr>
                <tr>    
                    <td>
                        Include All Items in Highlights Section ?
                    </td>
                    <td>
                        <Toggle defaultChecked={includeAllHighLights} id="includeAllHighLights"/>
                    </td>
                </tr>
                <tr>    
                    <td>
                        Include Postive only Items in Highlights Section ?
                    </td>
                    <td>
                        <Toggle defaultChecked={includePostiveOnlyHighlights} id="includePostiveOnlyHighlights"/>
                    </td>
                </tr>
                <tr>    
                    <td>
                        Include All Items in Descriptive Comments Section?
                    </td>
                    <td>
                        <Toggle defaultChecked={includeAllDescriptiveComments} id="includeAllDescriptiveComments"/>
                    </td>
                </tr>
                <tr>    
                    <td>
                        Include Positive Only Items in Descriptive Comments Section ?
                    </td>
                    <td>
                        <Toggle defaultChecked={includePositiveDescriptiveComments} id="includePositiveDescriptiveComments"/>
                    </td>
                </tr>
                <tr>    
                    <td>
                        Include All Images if Available ?
                    </td>
                    <td>
                        <Toggle defaultChecked={includeImage} id="includeImage"/>
                    </td>
                </tr>
            </table>
            <Button onClick={saveCustomization}>Save</Button></div>: ''}
            </fieldset>
        </Box>  
      </Dialog>

    )
}

export default CustomizeReportOptions;