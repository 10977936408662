import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DataTable, { createTheme, defaultThemes } from 'react-data-table-component';
import { Typography, Box, Button } from '@mui/material';
import config from '../config';
import { selectCurrentToken } from '../authSlice';
import { LinearProgress } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { Document, Page, pdfjs } from 'react-pdf';
import { Link } from 'react-router-dom';
import EditScore from './editScore';
import { setQueryId } from '../reportSlice';
import ReportActions from '../components/ReportActions';
import RequestService from '../RequestService';
import NeedClarificationModal from '../components/NeedClarificationModal';
import CommentModal from '../components/CommentModal';



createTheme('light', {
  rows: {
    fontSize: '15px',
  },
});

const customStyles = {
  header: {
    style: {
      minHeight: '56px',
    },
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      backgroundColor: '#1976d2',
      borderTopWidth: '1px',
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
      whiteSpace: 'pre-line',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
      whiteSpace: 'pre-line',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      color: defaultThemes.default.highlightOnHover.text,
      backgroundColor: '#97c1ec;',
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
      borderBottomColor: defaultThemes.default.background.default,
      outlineStyle: 'solid',
      outlineWidth: '1px',
      outlineColor: defaultThemes.default.background.default,
    },
  },
};

const Processor = () => {
  const token = useSelector(selectCurrentToken);
  const [users, setUsers] = useState([]);
  const [reports, setReports] = useState([]);
  const [pendingUsers, setPendingUsers] = useState(true);
  const [pendingReports, setPendingReports] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [displayUsersTable, setDisplayUsersTable] = useState(true);
  const [displayReportsTable, setDisplayReportsTable] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [downloadQueryId, setDownloadQueryId] = useState(null);
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [messageText, setMessageText] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [regularReports, setRegularReports] = useState([]);
  const [submittedRequests, setSubmittedRequests] = useState([]);
  const [pendingSubmittedRequests, setPendingSubmittedRequests] = useState(true); // Initialize with an appropriate default value
  const [displaySubmittedRequests, setDisplaySubmittedRequests] = useState(false); // Initialize with an appropriate default value
  const [needClarificationForm, setNeedClarificationForm] = useState(false);
  const [needClarificationQueryId, setNeedClarificationQueryId] = useState(null);
  const [showCommentsForm, setShowCommentsForm] = useState(false);
  const [commentsQueryId, setCommentsQueryId] = useState(null);



  const reportsColumns = [
    { name: 'Query ID', selector: row => row.queryId, sortable: true, width: '70px' },
    { name: 'Title', selector: row => row.title, sortable: true, width: '300px' },
    { name: 'Status', selector: row => row.statusName, sortable: true, width: '200px' },
    { name: 'Submitted Date', selector: row => row.submittedDate, sortable: true, width: '250px' },
    { name: 'Assigned Date', selector: row => row.assignedDate, sortable: true, width: '250px' },
    { name: 'Target Completion Date', selector: row => row.targetCompletionDate, sortable: true, width: '250px' },
{
  name: 'Actions',
  selector: row => (
    <Box display="flex" flexDirection="column">
      {/* Report Actions */}
      <Box alignItems="center">
        <ReportActions
          shareQueryId={row.sharedQueryId}
          queryId={row.queryId}
          viewFunction={fetchReport}
          editFunction={setShowEdit}
          reportName={row.title}
        />
      </Box>

      {/* Other action buttons on the second line */}
      <Box marginTop="5px">
        {/* Request Completed button */}
        {row.statusCode != null &&
          row.statusCode !== 'COMPLETED' &&
          row.statusCode !== 'AWAITING_INPUT' &&
          row.statusCode !== 'SUBMITTED' && (
            <Button
              onClick={() => handleRequestCompleted(row.queryId)}
              variant="contained"
              size="small"
              style={{ marginRight: '5px',  marginBottom:'10px' }}
            >
              Mark as Completed
            </Button>
        )}

        {/* Need Clarification button */}
        {row.statusCode === 'ASSIGNED' && (
          <Button
            variant="contained"
            size="small"
            onClick={() => handleNeedClarification(row.queryId)}
            style={{ marginRight: '5px', marginBottom:'10px' }}
          >
            Need Clarification
          </Button>
        )}

        {/* Assign to Me button */}
        {row.statusCode != null &&
          row.statusCode === 'SUBMITTED' && (
            <Button
              onClick={() => handleAssignToMe(row.queryId)}
              variant="contained"
              size="small"
              style={{ marginRight: '5px', marginBottom:'10px' }}
            >
              Assign to Me
            </Button>
        )}

      {/* Comments button on the third line */}
        <Button
          onClick={() => handleComments(row.queryId)}  // Adjust the function as needed
          variant="contained"
          size="small"
          style={{ marginRight: '5px', marginBottom:'10px' }}
        >
          Comments
        </Button>
      </Box>
    </Box>
  ),
  width: "500px",
  center: false
},


    { name: 'Completed Date', selector: row => row.completedDate, sortable: true, width: '250px' },
    { name: 'Total Time Spent (Mins)', selector: row => row.totalTimeSpendInMins, sortable: true, width: '150px' },
    { name: 'SLA Met', selector: row => row.slaMet, sortable: true, width: '150px', cell: (row) => (row.slaMet === false ? 'No' : row.slaMet ? 'Yes' : ''),},
  ];

  function loadAllReports() {
    setPendingReports(true);
    fetch(config[process.env.NODE_ENV].backendUrl + '/userQueriesForProcessor', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 403) {
          // Handle unauthorized access
          console.log('Access is denied, token may have expired.');
        }
      })
      .then((data) => {
        setPendingReports(false);
        setRegularReports(data); // Set regular reports
        setDisplaySubmittedRequests(false);

      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(() => {
    setDisplaySubmittedRequests(false);
    setDisplayReportsTable(true);
    loadAllReports();
  }, []);

  const handleLoadAllReports = () => {
    setDisplaySubmittedRequests(false);
    setDisplayReportsTable(true);
    loadAllReports();
  };

function backToReports() {
    setShowEdit(false);
}

  const handleAssignToMe = (queryId) => {

    RequestService.assignToMeForProcessor({ queryId: queryId}, token)
      .then((response) => {
        // Handle the response as needed
       alert('Request has been assigned to you.');
       loadAllReports();
      })
      .catch((error) => {
        // Handle errors
        alert('Error assigning request.');
      });

  };

  const handleRequestCompleted = (queryId) => {
    // Ask the user for the time spent in minutes
    const timeSpent = prompt('Enter time spent in minutes:');

    // Check if the user entered a valid number
    if (timeSpent !== null && !isNaN(timeSpent)) {
      // Call the function to send queryId and timeSpent
      sendQueryIdAndTimeSpent(queryId, parseInt(timeSpent, 10));
    } else {
      // Handle invalid input or cancellation
      console.log('Invalid input or user cancelled.');
    }
  };

  const sendQueryIdAndTimeSpent = (queryId, timeSpent) => {
    // Prepare the JSON data
    const jsonData = {
      queryId: queryId,
      timeSpent: timeSpent
    };

    // Call the markAsCompleted method
    RequestService.markAsCompleted(jsonData, token)
      .then((response) => {
        // Handle the response as needed
       alert('Query marked as completed successfully.');
       loadAllReports();
      })
      .catch((error) => {
        // Handle errors
        alert('Error marking query as completed.');
      });
  };

    const handleNeedClarification = (queryId) => {
        setNeedClarificationForm(!needClarificationForm);
        setNeedClarificationQueryId(queryId);

    };

    const handleComments = (queryId) => {
      setCommentsQueryId(queryId);
      setShowCommentsForm(!showCommentsForm);
    };
  const handleLoadAllImages = () => {
    setDisplayUsersTable(false);
    setDisplayReportsTable(false);
  };

    const handleViewReport = (queryId) => {
      fetchReport(queryId);
    };


    const loadSubmittedRequests = () => {
      setPendingSubmittedRequests(true);
      setPendingReports(false);
      fetch(config[process.env.NODE_ENV].backendUrl + '/allSubmittedUserQueriesForProcessor', {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: 'Bearer ' + token,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 403) {
            // Handle unauthorized access
            console.log('Access is denied, token may have expired.');
          }
        })
        .then((data) => {
          setPendingSubmittedRequests(false);
          setSubmittedRequests(data); // Set submitted requests
          setDisplaySubmittedRequests(true);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };


const fetchReport = (queryId) => {
  // Set loading state before making the request
  setLoadingReport(true);
  setShowPdfViewer(false);

  // Clear any previous error messages
  setMessageText('');

  // Fetch the PDF report using the "/{queryId}/getReportForAdmin/" endpoint
  fetch(`${config[process.env.NODE_ENV].backendUrl}/${queryId}/getReportForProcessor/`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        // Check the Content-Type header
        const contentType = response.headers.get('content-type');

        if (contentType === 'application/pdf') {
          // The response contains a PDF
          return response.blob();
        } else {
          // Handle unexpected content type
          console.error('Unexpected Content-Type:', contentType);
          throw new Error('Unexpected content type');
        }
      } else if (response.status === 403) {
        // Handle unauthorized access
        throw new Error('Access is denied, token may have expired.');
      } else {
        // Handle other error statuses
        throw new Error('Error loading the report. Please try again.');
      }
    })
    .then((blob) => {
      const objectUrl = URL.createObjectURL(blob);

      setPdfUrl(objectUrl);
      setDownloadQueryId(queryId);
      setShowPdfViewer(true);

      setLoadingReport(false);
    })
    .catch((err) => {
      // Handle errors and set appropriate messages
      if (err.message) {
        setMessageText(err.message);
      } else {
        setMessageText('An error occurred while loading the report.');
      }

      setLoadingReport(false);
    });
};


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const downloadPdf = () => {
    // Check if pdfUrl is available and there is a valid queryId
    if (pdfUrl) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = `vastu_report.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // Handle the case where pdfUrl is not available or there is no queryId
      console.error("Cannot download PDF. pdfUrl is not available or no queryId.");
    }
  };


    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  return  (
  <Box width="100%" style={{ display: 'flex', justifyContent: 'center' }}>
   { showEdit ? <EditScore backToReports={backToReports} buttonName='Back to List' isProcessorScreen={true}/> :
   <Box style={{ display: 'relative', width: '80%' }} alignContent="left" mt={10}>
      <Button variant="contained" color="primary" onClick={handleLoadAllReports} style={{ marginRight: '8px' }}>
        Requests Assigned To Me
      </Button>
                     <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={loadSubmittedRequests}
                                  style={{ marginRight: '8px' }}
                                >
                                  All Submitted Requests
                                </Button>

      <br />
      <br />
                        {needClarificationForm && (
                          <NeedClarificationModal setNeedClarificationForm={setNeedClarificationForm} queryId={needClarificationQueryId} loadAllReports={loadAllReports}/>
                        )}
                        {showCommentsForm && (
                            <CommentModal setShowCommentsForm={setShowCommentsForm} queryId={commentsQueryId} currentToken={token} isProcessor={true}/>
                          )}
      {displayReportsTable && (
        <div style={{ overflowX: 'auto' }}>

        <DataTable
          theme="light"
          title="Requests"
          columns={reportsColumns}
          data={displaySubmittedRequests ? submittedRequests : regularReports}
          pagination
          highlightOnHover
          responsive
          pointerOnHover
          progressPending={displaySubmittedRequests ? pendingSubmittedRequests : pendingReports}
          customStyles={customStyles}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
        />
        </div>

      )}
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%" }}>
         {loadingReport ? (
           // Render a loading indicator or message instead of the download button
           <Box width={"60%"} display={"flex"} alignContent={"center"} justifyContent={"center"} flexDirection="column" align="center">
           <LinearProgress/>
           <p>Loading Report</p>
           </Box>
         ) : (
           // Render the download button only when the report is loaded
           <Box display={"flex"} alignContent={"center"} justifyContent={"center"} flexDirection="column" align="center" >
           {showPdfViewer ? (
           <div className="pdf-viewer-container">
             <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} mobile={true}>
             <Button size="small" sx={{width: isMobile? "50%": "20%"}} alignContent="center" align="center" justifyContent="center"
             variant="contained"
             color="primary"
             onClick={downloadPdf}
             disabled={!showPdfViewer}
           >
             Download Report
           </Button>
               {Array.from(new Array(numPages), (el, index) => (
                 <div key={`page_container_${index + 1}`} className="pdf-page">
                   <Page
                     key={`page_${index + 1}`}
                     pageNumber={index + 1}
                     renderTextLayer={false}
                     scale={isMobile ? 0.75 : 1.5}
                     renderAnnotationLayer={false}
                     className="pdf-page-content"
                   />
                 </div>
               ))}
             </Document>
           </div>
         ) : (
           <div className="loading-error-message">
              {messageText}
           </div>
         )}

           </Box>
         )}
       </Box>


    </Box>
}
  </Box>
  );
};

export default Processor;
