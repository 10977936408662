import { createSlice } from "@reduxjs/toolkit";

export const imageSlice = createSlice({
    name: "image",
    initialState: { sessionId: null },
    reducers: {
        saveSession: (state, action) => {
            state.sessionId = action.payload;
        },
        clearSession: (state) => {
            state.sessionId = null
        }
    },
});
export const {saveSession, clearSession} = imageSlice.actions;
export const selectSessionId = (state) => state.image.sessionId
export default imageSlice.reducer;

