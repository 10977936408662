// CompassReading.js
import React from 'react';

const CompassReading = ({ value }) => {
  const getCompassReading = (number) => {

    // Make positive numbers 360 - number, remove negative sign for negative numbers
    const rotatedDegree = number > 0 ? 360 - number : (-number) > 360 ? (-number) - 360: -number;

    return {
      degree: rotatedDegree,
      direction: getDirection(rotatedDegree),
    };
  };

  const getDirection = (degree) => {
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    const index = Math.round(degree / 45) % 8;
    return directions[index];
  };

  const compassReading = getCompassReading(value);

  return `${compassReading.degree}° ${compassReading.direction}`;
};

export default CompassReading;
