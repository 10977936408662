import { Alert, AlertTitle, Button, Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator';
import {TextField, Box} from '@mui/material';
import config from '../config';
import usePageView from '../components/usePageView'; // Import the custom hook

export function ContactUs() {
    const[messageText, setMessageText] = useState([]);
    const [displayMessage, setDisplayMessage] = useState('Thank you for contacting us. We will review and get back to you in 2 business days!'); 
    const [showForm, setShowForm] = useState(true);
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    usePageView('Contact Us');

    function submitContactUs() {
        var messageText = [];
        var proceed = true;
        if (document.getElementById('fullName').value === '') {
            messageText.push('Full Name is Required');
            proceed = false;
        }  else {
            if (!validator.isLength(document.getElementById('fullName').value, {min:0, max: 200})) {
                messageText.push('Name is too long');
                proceed = false;
            }
        }

        if (document.getElementById('contactEmail').value === '') {
            messageText.push('Email Address is Required');
            proceed = false;
        } else {
            if (!validator.isEmail(document.getElementById('contactEmail').value)) {
                messageText.push('Email Address is not valid');
                proceed = false;
            }
        }

        if (document.getElementById('contactSubject').value === '') {
            messageText.push('Subject is Required');
            proceed = false;
        } else if (!validator.isLength(document.getElementById('contactSubject').value, {min:0, max: 200})) {
            messageText.push('Subject is too long');
            proceed = false;
        }

        if (document.getElementById('emailBody').value === '') {
            messageText.push('Email Body is Required');
            proceed = false;
        }  else if (!validator.isLength(document.getElementById('emailBody').value, {min:0, max: 8000})) {
            messageText.push('Email Content is too long');
            proceed = false;
        }

        if (proceed) {
            //post
            setMessageText([]);
            var obj = {};
            obj.fullName = document.getElementById('fullName').value;
            obj.email = document.getElementById('contactEmail').value;
            obj.subject = document.getElementById('contactSubject').value;
            obj.emailBody = document.getElementById('emailBody').value;
            fetch(config[process.env.NODE_ENV].backendUrl + '/sendEmail', {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            },
         })
         .then((response) => {
            toast.success('Your message was posted successfully!');
            setShowForm(false);
            document.getElementById('fullName').value = '';
            document.getElementById('contactEmail').value = '';
            document.getElementById('contactSubject').value = '';
            document.getElementById('emailBody').value = '';

         }).catch((err) => {
            toast.error('Attempt to send information failed');
            console.log(err.message);
         });
      
        } else {
            setMessageText(messageText);
        }

    }

    return (
    showForm? <div>   
    <div className='Scrollable-div'>
        <ToastContainer position='top-center' theme='dark'/>
        
        <fieldset className='shadow-md'>
            <legend>Contact Us</legend>
            {messageText.length > 0 && (
        <Alert severity='error'>
          <AlertTitle>Error</AlertTitle>  
          <ul>
            {messageText.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </Alert>
      )}


    {/* <Box
      component="form"
      noValidate
      autoComplete="off"
      width={"450px"}
      align="center"
    >
        <TextField
                required
                id='fullName' 
                label="Full Name"
                variant="filled"
                fullWidth
                size='small'
                margin="normal" 
                pt={2}
            />
        <TextField
                required
                id='contactEmail' 
                label="Email"
                variant="filled"
                fullWidth
                size='small'
                margin="normal" 
                pt={2}
            />
        <TextField
                id='contactSubject' 
                label="Subject"
                variant="filled"
                fullWidth
                size='small'
                margin="normal" 
                pt={2}
            />

        <TextField
          id="emailBody"
          required
          label="Email Body"
          multiline
          rows={8}
          variant="filled"
          fullWidth
          size='small'
          margin="normal" 
          pt={2}
        />
        <Button variant="contained" size="small" onClick={submitContactUs}  pt={2} align="center">Submit</Button>    

    </Box>     */}
         <table className='App-table'>
           
                <tr>
                    <td>
                        <label><Typography variant='body2'>Full Name:</Typography></label>
                    </td>
                    <td>    
                        <input type={'text'} name={'name'} id='fullName' size={40}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label><Typography variant='body2'>Email Address:</Typography></label>
                    </td>
                    <td>    
                        <input type={'text'} name={'emailAddress'} id='contactEmail' size={40}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label><Typography variant='body2'>Subject:</Typography></label>
                    </td>
                    <td>    
                        <input type={'text'} name={'contactSubject'} id='contactSubject' size={40}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label><Typography variant='body2'>Email Body:</Typography></label>
                    </td>
                    <td>    
                        <textarea name={'emailBody'} id='emailBody' rows="8" cols={isMobile ? "16" : "40"}/>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <Button variant="contained" size="small" onClick={submitContactUs}>Submit</Button>
                    </td>
                </tr>

            </table> 
        </fieldset>
    </div>
    </div>: <p className='App-p'>{displayMessage}</p>    
    
    )
}

export default ContactUs;