const config = {
    development: {
      backendUrl: 'http://localhost:8080',
    },
    staging: {
      backendUrl: 'https://vaastureport-service-e55qb4xaha-uc.a.run.app',
    },
    production: {
      backendUrl: 'https://vaastureport-service-e55qb4xaha-uc.a.run.app',
    }
  };
  
  export default config;