import './App.css';
import React from 'react';
import {Home} from './webpages/home';
import {VastuCalculatorPage} from './webpages/VastuCalculatorPage';
import {SouthFacingHomePage} from './webpages/SouthFacingHomePage';
import {WestFacingHomePage} from './webpages/WestFacingHomePage';
import Footer from './components/Footer';
import {MyReports} from './webpages/myreports';
import {MyCustomReports} from './webpages/mycustomreports';
import GetScore from './webpages/getscore';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './components/ProtectedRoutes.js';
import Navbar from './components/Navbar';
import { useEffect } from 'react';
import ContactUs from './webpages/contactus';
import LoginPage from './components/LoginPage';
import Verify from './webpages/Verify';
import Reset from './webpages/Reset'
import SharedReport from './webpages/SharedReport';
import ChangePassword from './webpages/ChangePassword';
import Agents from './webpages/agents';
import BuyerSeller from './webpages/buyerSeller';
import VastuReportAPIs from './webpages/VastuReportAPIs.js';
import Aboutus from './webpages/aboutus.js';
import Builders from './webpages/builders';
import ScrollTopFab from './components/ScrollToTopFab';
import StaticBar from "./components/StaticBar.js"
import FeedbackBar from './components/FeedbackBar';
import PrivacyPolicy from './webpages/privacyPolicy';
import TermsOfService from './webpages/termsOfService';
import AcceptableUsePolicy from './webpages/acceptableUsePolicy';
import CookiePolicy from './webpages/cookiePolicy';
import Disclaimer from './webpages/disclaimer';
import BuyCredits from './components/BuyCredits';
import ListOrders from './webpages/listorders';
import ListFeedback from './webpages/listfeedback';
import Administration from './webpages/administration';
import Processor from './webpages/processor';
import Success from './webpages/Success';
import MyProfile from './webpages/MyProfile';
import Faq from './webpages/faq';
import LogoutListener from './components/LogoutListener.js';
import SubmitRequest from './components/SubmitRequest.js';
import SubmitBulkRequest from './components/SubmitBulkRequest.js';
function App() {
  useEffect(() => {
    // Replace 'YOUR_MEASUREMENT_ID' with your actual GA4 Measurement ID
    const GA4_MEASUREMENT_ID = process.env.REACT_APP_TRACKING_ID || 'YOUR_DEFAULT_MEASUREMENT_ID';

    // Initialize GA4
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', GA4_MEASUREMENT_ID);

    // Track pageview
    gtag('event', 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: document.title,
    });
  }, []);


  return (
    
  <React.Fragment>
   <StaticBar />
   <LogoutListener/>
   <FeedbackBar />
   <Navbar/> 

   {/* This is required for proper alignment*/ }
   <div className='App-body'>
    <Routes>

      <Route path="/*" element={<ProtectedRoutes redirectPath="/login" />}>
        {/* Protected routes */}
        <Route path="myreports" element={<MyReports />} />
        <Route path="getscore" element={<GetScore editMode={false}/>} forceRefresh={true}></Route>
        <Route path='customReports' element={<MyCustomReports/>}></Route>
        <Route path='buycredits' element={<BuyCredits/>}></Route>
        <Route path='myorders' element={<ListOrders/>}></Route>
        <Route path='myfeedbacks' element={<ListFeedback/>}></Route>
        <Route path='ad-us-re' element={<Administration/>}></Route>
        <Route path='pr-oc-re' element={<Processor/>}></Route>
        <Route path='submitRequest' element={<SubmitRequest/>}></Route>
        <Route path='submitBulkRequest' element={<SubmitBulkRequest/>}></Route>
        <Route path='myprofile' element={<MyProfile/>}></Route>
        <Route path='changePassword' element={<ChangePassword/>}></Route>
      </Route>

      <Route path='paymentSuccess' element={<Success/>}></Route>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/home" element={<Home/>}></Route>
      <Route path='/login' element={<LoginPage/>}></Route>
      <Route path='/activate' element={<Verify/>}></Route>
      <Route path='/reset' element={<Reset/>}></Route>
      <Route path="/aboutus" element={<Aboutus/>}></Route>
      <Route path="/contactus" element={<ContactUs/>}></Route>
      <Route path='/getSharedReport' element={<SharedReport/>}></Route>
      <Route path='/buyerSeller' element={<BuyerSeller/>}></Route>
      <Route path='/VastuReportAPIs' element={<VastuReportAPIs/>}></Route>
      <Route path='/agents' element={<Agents/>}></Route>
      <Route path='/builders' element={<Builders/>}></Route>
      <Route path='/privacyPolicy' element={<PrivacyPolicy/>}></Route>
      <Route path='/termsOfService' element={<TermsOfService/>}></Route>
      <Route path='/acceptableUsePolicy' element={<AcceptableUsePolicy/>}></Route>
      <Route path='/cookiePolicy' element={<CookiePolicy/>}></Route>
      <Route path='/disclaimer' element={<Disclaimer/>}></Route>
      <Route path="/faq" element={<Faq/>}></Route>

      <Route path="/calculate-vastu" element={<VastuCalculatorPage/>}></Route>
      <Route path="/south-facing-house-vastu" element={<SouthFacingHomePage/>}></Route>
      <Route path="/west-facing-house-vastu" element={<WestFacingHomePage/>}></Route>

    </Routes>
    <ScrollTopFab smooth/>   
  </div>  
  <Footer/>
  </React.Fragment>
  )
}

export default App;
