import http from "./http-common";

class UploadFilesService {

  upload(file, sessionId, fileType, imageState, token, onUploadProgress) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("sessionId", sessionId);
    formData.append("fileType", fileType);
    formData.append("flipImage", imageState);

    return http.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": "Bearer " + token
      },
      onUploadProgress,
    });
  }

  getFiles(sessionId, token) {
    return http.get("/files/" + sessionId, {
        headers: {
          "Authorization": "Bearer " + token
        },
      });
  }

  getFile(fileId, token) {
    return http.get("/file/" + fileId, {
        headers: {
          "Authorization": "Bearer " + token
        },
      });
    }

  getCurrentSessionId(token) {
    return http.get("/currentImageSessionId", {
      headers: {
        "Authorization": "Bearer " + token
      },
    });
  }

}

export default new UploadFilesService();
