import React, { useEffect, useRef, useState } from 'react';
import { Button, TextField, Paper, Typography, List, ListItem, ListItemText, Alert, ListItemAvatar, Avatar, Box, Dialog, FormControl, InputLabel, Select, MenuItem, Grid, FormControlLabel, Checkbox, AlertTitle, Step, Stepper, StepLabel, StepContent, LinearProgress } from '@mui/material';
import usePageView from './usePageView';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCredit, selectCurrentToken, userCredits } from '../authSlice';
import { useNavigate } from 'react-router-dom';
import RequestService from '../RequestService';
import DragAndDropUpload from './DragAndDropUpload';
import http from '../http-common';
export function SubmitBulkRequest() {
    const [title, setTitle] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const [comment, setComment] = useState('');
    const currentCredit = useSelector(selectCurrentCredit);
    const currentToken = useSelector(selectCurrentToken);
    const navigate = useNavigate();
    const [flipStatus, setFlipStatus] = useState(false);
    const fileInputRef = useRef();
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Request Details', 'Upload Images', 'Add Comments'];
    const dispatch = useDispatch();
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadedFilesCount, setUploadedFilesCount] = useState(0);

    usePageView('Submit Request');
    useEffect(() => {
    }, [errorMessages, requestSubmitted]);


    const handleNext = () => {
        var errorArray = [];
        // Perform any validation or processing needed before moving to the next step  
        if (activeStep == 0) {    
            if (!title ||  title == '') {
                errorArray.push("Request Title is required to submit request");
                setErrorMessages(errorArray);
                return;  
            }

            if (title &&  title.length > 100) {
                errorArray.push("Request Title should be maximum of 100 chars");
                setErrorMessages(errorArray);
                return;  
            }
        }
        setErrorMessages([]);
        if (activeStep == 1) {
            if (selectedFiles && selectedFiles.length == 0) {
                errorArray.push("No Image files are added, images are required to submit request");
                setErrorMessages(errorArray);
                return;  
            }

            if (selectedFiles && selectedFiles.length > 20) {
                errorArray.push("Maximum of 20 images can be uploaded");
                setErrorMessages(errorArray);
                return;  
            }

        }

        if (activeStep == 2) {
            if (comment && comment.length > 200) {
                errorArray.push("Maximum of 200 Chars can be entered in comments");
                setErrorMessages(errorArray);
                return;  
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleFileInputChange = (event) => {
        handleFileChange(event); // Your existing file change handler
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleRemoveFile = (index) => {
        const newFiles = selectedFiles.filter((_, fileIndex) => index !== fileIndex);
        setSelectedFiles(newFiles);
    
        const newPreviewUrls = previewUrls.filter((_, urlIndex) => index !== urlIndex);
        setPreviewUrls(newPreviewUrls);
    };

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        const combinedFiles = [...selectedFiles, ...newFiles];
        var errorArray = [];
        // Limit the number of files to 6
        if (combinedFiles.length > 20) {
            errorArray.push("Maximum of 20 files are allowed");
            setErrorMessages(errorArray);
            return;
        }
    
        // Check for file size (5MB maximum)
        const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
        const areFilesTooLarge = combinedFiles.some(file => file.size > maxSize);
    
        if (areFilesTooLarge) {
            errorArray.push("Each file must be 5 MB or less.");
            setErrorMessages(errorArray);
            return;
        }

        const initialProgress = {};
        combinedFiles.forEach(file => {
            initialProgress[file.name] = 0;
        });
        setUploadProgress(initialProgress);
        setUploadedFilesCount(0); 
        setSelectedFiles(combinedFiles);

        // Generate previews
        const newPreviewUrls = combinedFiles.map(file => {
            if (file.type.startsWith('image/')) {
                return URL.createObjectURL(file);
            }
            return null; // For non-image files, you might want to handle differently
        });
        setPreviewUrls(newPreviewUrls);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        var errorArray = [];

        if (!title ||  title == '') {
            errorArray.push("Request Name is required to submit request");
            setErrorMessages(errorArray);
            return;  
        }

        if (selectedFiles && selectedFiles.length == 0) {
            errorArray.push("No Image files are added, images are required to submit request");
            setErrorMessages(errorArray);
            return;  
        }

        if (comment && comment.length > 200) {
            errorArray.push("Maximum of 200 Chars can be entered in comments");
            setErrorMessages(errorArray);
            return;  
        }

        // Here you can handle the submission of the title and files
        var requestArray = {};
        requestArray.title=title;
        requestArray.comments = comment;
        RequestService.submitBulkRequest(JSON.stringify(requestArray), currentToken).then((response) => {
            if (response.status === 200) {
                var bulkQueryId = response.data;
                const uploadPromises = selectedFiles.map(file => uploadFile(file, bulkQueryId));

                    // Use Promise.all to wait for all promises to resolve
                    Promise.all(uploadPromises).then(() => {
                        setRequestSubmitted(true);
                        // Additional code to execute after all files are uploaded
                    }).catch(error => {
                        // Handle any errors that occur during upload
                        console.error("An error occurred during file upload:", error);
                    });
            } else {
                setRequestSubmitted(false);
            }
        }).catch((err) => {
            errorArray.push("An error occurred while submitting request");
            setErrorMessages(errorArray);
         });
    };

    const uploadFile = async (file, bulkQueryId) => {
        setUploadedFilesCount(currentCount => currentCount + 1);
        var requestArray = {};
        requestArray.bulkQueryId = bulkQueryId;
        const formData = new FormData();
        formData.append('file', file.file);
        formData.append('bulkQueryId', bulkQueryId);
        try {
            await http.post('/uploadBulkRequestFile', formData, { // Using http instance from http-common
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Bearer " + currentToken
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [file.name]: progress,
                    }));
                }
            });
        } catch (error) {
            console.error('Error uploading file', error);
        }
    };

    const avatarStyle = {
        width: '100px', // Increase the width
        height: '100px', // Increase the height
        marginRight: '10px',
        borderRadius: '4px',
        cursor: 'pointer' 
    };

    function buyCredits() {
        navigate('/buycredits');
    }
  
    const progress = selectedFiles.length > 0 
        ? (uploadedFilesCount / selectedFiles.length) * 100
        : 0;

    return (
        <>
        { currentToken ? !requestSubmitted ?
        <Paper style={{ padding: '20px', maxWidth: '100vw', margin: '20px auto', width:isMobile ? "98vw": "42vw" }}>
            <Typography variant="h6" gutterBottom>
                Submit Score Calculation Request for Multiple properties
            </Typography>
            {progress && progress > 0 ? <div>
                <LinearProgress variant="determinate" value={progress} />
                <Typography variant="caption">{ progress && progress > 0 ? progress.toFixed(2): ''} {progress > 0 ? "% uploaded":""}</Typography>
            </div>: ''
            }
            {currentCredit < 2 ? <Box display={"flex"} alignContent={"center"} justifyContent={"center"} alignItems={"center"}>
                <Button size='small' onClick={buyCredits} variant='contained' color='warning'>Buy Credits</Button></Box>:''}
            <Box marginTop={2}>
            <fieldset>
                <legend>Provide details for Bulk score calculation request</legend>
                <br></br>
                {errorMessages.length > 0 && (
                    <Alert severity='error' width="100%" align="left">
                    <AlertTitle>Error</AlertTitle>
                    <ul>
                        {errorMessages.map((item, i) => {
                        return <li>{item}</li>;
                        })}
                    </ul>
                </Alert>      
                )}   
                 
                    <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>

                                {index == 0 ? <Grid container spacing={2} alignItems="center"><Grid item xs={12}>
                                <Typography variant="caption">
                                    (Enter a request title)
                                </Typography>    
                                <TextField 
                                    label="Request Title" 
                                    fullWidth={true} 
                                    variant="standard"
                                    margin="normal"
                                    defaultValue={title} 
                                    onChange={handleTitleChange} 
                                    size='small'
                                    required
                                /></Grid>
                                </Grid>: index == 1 ? <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom style={{ marginTop: '20px' }}>
                            Floor plans and Site plan/map
                        </Typography>
                        <Typography variant="caption">
                            (You can add upto 20 files)
                        </Typography>
                        <DragAndDropUpload setSelectedFiles={setSelectedFiles} uploadProgress={uploadProgress} setUploadedFilesCount={setUploadedFilesCount}/>
                    </Grid>    
                </Grid>:<Grid container spacing={2} alignItems="center"><Grid item xs={12}>
                            <TextField
                            label="Comment"
                            variant="filled"
                            fullWidth
                            margin="normal"
                            multiline
                            size='small'
                            rows={4}  // Adjust the number of rows as needed
                            value={comment}
                            onChange={handleCommentChange}
                        />
                                </Grid></Grid>
                                }
                
                    </StepContent>
                    </Step>
                    ))}
                  </Stepper>      
           
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button disabled={activeStep === steps.length - 1 && currentCredit < 2} onClick={activeStep === steps.length - 1 ? handleSubmit: handleNext}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>     
                
                <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md">
                    <img src={selectedImage} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                </Dialog>
                </fieldset>   
                </Box>
        </Paper>: <Box display={"flex"} alignContent={"center"} justifyContent={"center"} alignItems={"center"}><Typography variant='body1' padding={2}>Bulk Request Successfully Submitted.</Typography></Box>:
        <Box display={"flex"} alignContent={"center"} justifyContent={"center"} alignItems={"center"}>
            <Typography component={"div"}>Access Denied, Please login to access this page</Typography></Box>}
        </>
    );
};
export default SubmitBulkRequest;