// PhotoModal.js

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import PhotoCarousel from '../components/PhotoCarousel';
import PhotoCarouselStepper from '../components/PhotoCarouselStepper';
import image1 from '../assets/images/example_image_1_incorrect.png';
import image2 from '../assets/images/example_image_2_correct.png';
import image3 from '../assets/images/example_image_3_incorrect.png';
import image4 from '../assets/images/example_image_4_correct.png';
import image5 from '../assets/images/example_image_5_incorrect.png';
import image6 from '../assets/images/example_image_6_correct.png';
import { useMediaQuery } from 'react-responsive';

const closeButtonStyle = {
  position: 'absolute',
  top: '8px',
  right: '8px',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  fontSize: '24px',
  fontWeight: 'bold',
};

const titleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
};

const PhotoModal = ({ isOpen, closeModal }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });  
const imagesWithDescriptions = [
    { image: image1, description: "Incorrect: This cropping is incorrect as the image does not touch the the outermost wall as the boundary on left side. There is extra space and this is not acceptable." },
    { image: image2, description: "Correct:  This cropping is correct as the image is of rectangular shape with the outermost wall as the boundary on sides. Also, the front of the home/entrance faces the top side." },
    { image: image3, description: "Incorrect:  This orientation is incorrect as the front of the home/entrance does not face the top side of the screen (near the FLIP, UPLOAD and ROTATE buttons." },
    { image: image4, description: "Correct:  This orientation is correct as the front of the home/entrance faces the top side and the image is of rectangular shape with the outermost wall as the boundary on sides." },
    { image: image5, description: "Incorrect:  The Main entrance floor plan orientation is correct but the upper level floor plan orientation is incorrect as the front of the home does not face the top side." },
    { image: image6, description: "Correct:  Both the Main entrance floor plan and upper level floor plan orientation is correct as the front of the home in the both the plan face the top side." },
];

  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="lg">
      <DialogTitle style={titleStyle}>
        <button style={closeButtonStyle} onClick={closeModal}>
          X
        </button>
        <br/>
        <Typography variant='body2'>
          {isMobile ? "Click on the image to pause, these are examples of acceptable and unacceptable cropping of the floor plans" :
          "Please click through the photos on the right side to view examples of both acceptable and unacceptable cropping of the floor plans.To zoom-in further, click on the selected image."}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box className="modal-content">
          {!isMobile ? <PhotoCarousel images={imagesWithDescriptions} />: <PhotoCarouselStepper/>} 
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PhotoModal;
