import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../authSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import RequestService from '../RequestService';
import DataTable, { createTheme, defaultThemes } from 'react-data-table-component';
import loadingImage from '../assets/images/loading.gif';
import styled from 'styled-components';
import { FaQuestionCircle, FaTimes } from 'react-icons/fa';
import { setQueryId } from '../reportSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditScore from './editScore';
import Toggle from 'react-toggle'
import ShareToggleCustom from '../components/ShareToggleCustom';
import ReportActions from '../components/ReportActions';
import { useMediaQuery } from 'react-responsive';
import CustomizeReport from '../components/CustomizeReport';
import { Typography, Box, Collapse, IconButton, Alert, Button, Dialog, DialogContent, DialogActions, LinearProgress } from '@mui/material';
import ReportActionsCustom from '../components/ReportActionsCustom';
import config from '../config';
import MarkdownViewerFromFile from '../components/MarkdownViewerFromFile';
import ScrollTopFab from '../components/ScrollToTopFab';
import usePageView from '../components/usePageView'; // Import the custom hook
import { Document, Page, pdfjs } from 'react-pdf';
import DocumentViewer from '../components/DocumentViewer';
import CardListComponent from '../components/CardListComponent';

createTheme('light', {
    rows: {
        fontSize: '15px'
    }
});

const TextField = styled.input`
	height: 32px;
	width: 500px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;


const FilterComponent = ({ filterText, onFilter, onClear, open, setOpen, isMobile }) => (
    <>
    	<Box align='left' style={{width: isMobile ? "100%": "100%", maxWidth: isMobile ? 500 : 900}}>
        <Typography variant='body2'>The "Seller Reports" feature empowers you to customize your Detailed Vastu Reports, tailoring them to highlight the positive aspects of your property, ensuring you provide potential buyers with a personalized and compelling view.</Typography>  
        <TextField style={{ width: isMobile ? '70%': '30%' }}
    			id="search"
                name="search_filter"
    			type="text"
    			placeholder="Filter By Name"
    			aria-label="Search Input"
    			value={filterText}
    			onChange={onFilter}
    		/>
    		<Button onClick={onClear}>Clear</Button>
            <br></br>
            </Box> 
    	</>
    );

export function ListCustomReports({setShowEdit}) {
    usePageView('List Custom Reports');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
      const [loadingReport, setLoadingReport] = useState(false);
      const [downloadQueryId, setDownloadQueryId] = useState(null);
  
  
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    };
  
    const downloadPdf = () => {
      // Check if pdfUrl is available and there is a valid queryId
      if (pdfUrl && downloadQueryId) {
        const reportId = downloadQueryId;
  
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `report_${reportId}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // Handle the case where pdfUrl is not available or there is no queryId
        console.error("Cannot download PDF. pdfUrl is not available or no queryId.");
      }
    };
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const token = useSelector(selectCurrentToken);
    const [userQueries, setUserQueries] = useState([]);
    const [openHelp, setOpenHelp] = useState(false);
    const [pending, setPending] = useState(true);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [showReport, setShowReport] = useState(false);
    const [messageText, setMessageText] = useState([]);
	const [toggleCleared, setToggleCleared] = React.useState(false);
    const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [open, setOpen] = useState(false);
    
    const dispatch = useDispatch();
    const addObject = (newObj) => {
        const cloneSelectedRows = [...selectedRows];
          if (!cloneSelectedRows.some(obj => obj.queryId === newObj.queryId)) {
            cloneSelectedRows.push(newObj);
          }
          return cloneSelectedRows;
      };
        const removeObject = (newObj) => {
          const cloneSelectedRows = [...selectedRows];
          const index = cloneSelectedRows.findIndex(item => item.queryId === newObj.queryId);
          if (index !== -1) {
            cloneSelectedRows.splice(index, 1);
          }
          return cloneSelectedRows;
        };  
      
        function selectCard(report, add) {
          if (add) {
            setSelectedRows(addObject(report));
          } else {
            setSelectedRows(removeObject(report));
          }
        }    
      
    const columns = [
        { name: 'Title', selector: row => <Typography variant='body2'>{row.title}</Typography>, width:isMobile? "20vw":"35%", center: true},
        { name: 'Customize', selector: row => <CustomizeReport queryId={row.queryId} variant='body2'>{row.lastScore}</CustomizeReport>, width:isMobile? "15vw":"15%", center: true},
        { name: 'Share Report?', selector: row => <ShareToggleCustom shareQueryId={row.sharedCustomQueryId} queryId={row.queryId} loadAllReports={loadAllReports}/>, width:isMobile? "20vw":"22%", center: true},
        { name: 'Actions', selector: row => <ReportActionsCustom shareQueryId={row.sharedCustomQueryId} queryId={row.queryId} viewFunction={loadReport} editFunction={setShowEdit} reportName={row.title}/>, width:isMobile? "25vw":"20%", center: false}
      ];

      const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

    const filteredItems = userQueries.filter(
		item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()),
	);

    const contextActions = React.useMemo(() => {
		const handleCompare = () => {
			
				setToggleCleared(!toggleCleared);
                var queryIds = [];
                var obj = {};
                selectedRows.map((row, index) => {
                    queryIds.push(row.queryId);
                });
                obj.queryIds = queryIds;
                RequestService.compareReport(obj, token).then((response) => {
                    if (response.status == 200) {
                        setShowReport(true);
                        
                         var blob = new Blob([response.data], {
                             type: 'application/pdf'
                         })
                         var objectUrl = URL.createObjectURL(blob);
                         var iframe = document.getElementById('reportFrame');
                         iframe.removeAttribute('srcdoc');
                         iframe.src = objectUrl;
                         iframe.style.display = '';
                         iframe.hidden = false;
                     } else if (response.status == 403) {
                        setMessageText("Access is denied, token may have expired please logout and login again");
                    }
        
                 });
		};

        const handleEdit = () => {
            dispatch(setQueryId(selectedRows[0].queryId));
            setShowEdit(true);
        }

        const handleView = () => {
           loadReport(selectedRows[0].queryId);
        }

		return (
            selectedRows.length > 1 &&  selectedRows.length < 5 ?
            <span>
            
			<button key="compare" onClick={handleCompare} className="App-butt">
				Compare
			</button></span>: 
            selectedRows.length == 1 ?
            <span>
            
            <button key="compare" onClick={handleCompare} className="App-butt" disabled>
                Compare
            </button>
            </span>
            : <span>
            
            <button key="compare" onClick={handleCompare} className="App-butt" disabled>
				Compare
			</button>
            </span>
            
            
		);
	}, [userQueries, selectedRows, toggleCleared]);


      const customStyles = {
        	header: {
        		style: {
        			minHeight: '56px',
        		},
        	},
        	headRow: {
        		style: {
        			borderTopStyle: 'solid',
                    backgroundColor: "#1976d2",
        			borderTopWidth: '1px',
        			borderTopColor: defaultThemes.default.divider.default,
        		},
        	},
        	headCells: {
        		style: {
        			'&:not(:last-of-type)': {
        				borderRightStyle: 'solid',
        				borderRightWidth: '1px',
        				borderRightColor: defaultThemes.default.divider.default,
        			},
        		},
        	},
        	cells: {
        		style: {
        			'&:not(:last-of-type)': {
        				borderRightStyle: 'solid',
        				borderRightWidth: '1px',
        				borderRightColor: defaultThemes.default.divider.default,
        			},
        		},
        	},
            rows: {
                highlightOnHoverStyle: {
                    color: defaultThemes.default.highlightOnHover.text,
                    backgroundColor: "#97c1ec;",
                    transitionDuration: '0.15s',
                    transitionProperty: 'background-color',
                    borderBottomColor: defaultThemes.default.background.default,
                    outlineStyle: 'solid',
                    outlineWidth: '1px',
                    outlineColor: defaultThemes.default.background.default,
                },
            },
        };    
        
    useEffect(() => {
       loadAllReports();
    }, []);

    function handleOpenHelp() {
        setOpenHelp(false);
      }

      function openHelpContent() {
        setOpenHelp(true);
      } 
      
    function loadAllReports() {
        if (token) {
            //document.getElementById("reportFrame").srcdoc = '<div className="App-body"><h2 style="text-align:center">Select a title to see the PDF report</h2></div>';
        }

        fetch(config[process.env.NODE_ENV].backendUrl + '/userQueries', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status == 200) {
                return response.json();
            } else if (response.status == 403) {
                setMessageText("Access is denied, token may have expired please logout and login again");
            }

        })
            .then((data) => {
                if (data.length == 0) {
                    setMessageText('No Saved Reports found');
                }
                setPending(false);
                setUserQueries(data);
            }).catch((err) => {
                console.log(err.message);
            });
    }

    function loadReport(queryId) {
        setLoadingReport(true);
        RequestService.getSellerReportAsPDF(queryId, token).then((response) => {
            if (response.status === 200) {
                // Check the Content-Type header
                const contentType = response.headers['content-type'];
                if (contentType === 'application/pdf') {
                  // The response contains a PDF
                  const blob = new Blob([response.data], { type: 'application/pdf' });
                  const objectUrl = URL.createObjectURL(blob);
          
                  setPdfUrl(objectUrl);
                  setDownloadQueryId(queryId);
                  setShowPdfViewer(true);
          
                  setLoadingReport(false);
                } else {
                  // Handle unexpected content type
                  console.error('Unexpected Content-Type:', contentType);
                  setMessageText('Error loading the report. Unexpected content type.');
                  setLoadingReport(false);
                }
              } else if (response.status === 403) {
                setMessageText("Access is denied, token may have expired. Please logout and login again.");
                setLoadingReport(false);
              } else {
                setMessageText("Error loading the report. Please try again.");
                setLoadingReport(false);
              }
         });
    }

    const handleRowClicked = row => {
        loadReport(row.queryId);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

        const setInfoOpen = () => {
            if (open) {
                setOpen(!open);
            }
            
        }

		return (
            <Box style={{display: 'flex', width:"100%",}}>
            
            
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} open={open} setOpen={setInfoOpen} isMobile={isMobile} />
            <Typography variant='body'>&nbsp;<FaQuestionCircle onClick={openHelpContent}/></Typography>
            </Box>    
		);
	}, [filterText, resetPaginationToggle]);

    return (
       token ? (
            <Box style={{display: 'relative', width:"100%",}} alignContent="center">
              <Box>
                 <Collapse in={open}>
                  <Alert severity="info" style={{width: "100%"}} justify="left" action={
                     <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setOpen(false);
                     }}
                     >
                     x
                     </IconButton>
                  }> Each report can be shared, and you have the option to copy and distribute both shareable and embed links for every report.
                  </Alert>
                  </Collapse>
                  <Button size="small" variant="text"
                     disabled={open}
                     onClick={() => {
                    setOpen(true);
                     }}
                  >
                  Show Info
                </Button>
                </Box>
                    {!isMobile ? <DataTable theme="light" title="Seller Reports"
                        columns={columns} data={filteredItems}  
                        pagination
                        paginationPerPage={4}
                        paginationRowsPerPageOptions={[4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80, 84, 88, 92, 96, 100]} // Define the available options
                        highlightOnHover
                        noDataComponent="There are no reports to display"  
                        responsive 
                        pointerOnHover 
                        progressPending={pending}
                        onRowClicked={handleRowClicked}
                        customStyles={customStyles}
                        selectableRows
                        contextActions={contextActions}
			            onSelectedRowsChange={handleRowSelected}
			            clearSelectedRows={toggleCleared}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        paginationResetDefaultPage={resetPaginationToggle}
                        //persistTableHead
                        striped/>: <CardListComponent 
                        data={filteredItems} 
                        subHeaderComponentMemo={subHeaderComponentMemo} 
                        loadAllReports={loadAllReports} 
                        loadReport={loadReport} 
                        setShowEdit={setShowEdit} 
                        handleRowSelected={selectCard}
                        contextActions={contextActions}
                        userQueries={userQueries}
                        selectedRows={selectedRows}
                        toggleCleared={toggleCleared}
                        isCustom={true}
                      />}
                  <Dialog
                  maxWidth={"md"}
                  open={openHelp}
                >
                  <DialogContent>
                        <MarkdownViewerFromFile mdFilePath="./help/seller_reports_help.md" />
                    </DialogContent>
                  <DialogActions>
                    <Button onClick={handleOpenHelp}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>
       <DocumentViewer loadingReport={loadingReport} pdfUrl={pdfUrl} messageText={messageText} showPdfViewer={showPdfViewer} compareCount={0}/>     

            </Box>
        ): <div id="accessMessage">Access denied, user must be logged in to access the reports</div>
    )
}

export default ListCustomReports;