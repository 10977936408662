import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import PhotoModal from '../components/PhotoModal';
import usePageView from '../components/usePageView'; // Import the custom hook


import "./Modal.css";

import { v4 as uuidv4 } from 'uuid';
import UploadImage from '../UploadImage';
import { Typography, Button, Slide, Box, Alert, Collapse, IconButton, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import VideoBarUpload from './VideoBarUpload';
import UploadCropImage from './UploadCropImage';
import UploadImageNew from '../UploadImageNew';
import RequestService from '../RequestService';
import { CheckBox } from '@mui/icons-material';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const commonStyles = {
  bgcolor: 'background.paper',
  m: 1,
  border: 1,
  width: '5rem',
  height: '5rem',
};

const MaterialModalImageUpload = ({ userToken, setOpenUpload, reloadImage, editSessionId, editMode, queryId }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(isMobile ? false: true);
  const [hasRequestImages, setHasRequestImages] = useState(false);
  const [hasFlipImages, setHasFlipImages] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const openModal = () => {
      setIsModalOpen(true);
  };

  const closeModal = () => {
      setIsModalOpen(false);
  };
  const [sessionId, setSessionId] = useState(editMode ? editSessionId: uuidv4());

  const currentToken = userToken;

 useEffect(() => {
  RequestService.getUserRoles(currentToken).then((response) => {
    if (response.status === 200) {
      var roles = response.data.map(role => role.name);
      setUserRoles(roles); // Extract role names
      if((roles.includes('Processor') || roles.includes('Admin')) && queryId != null) {
        // Fetch the request images if they are available
        RequestService.checkSubmitRequestImages(currentToken, queryId).then((response) => {
          if (response.status === 200) {
            if (response.data == true) {
              RequestService.checkFlipStatusForImages(currentToken, queryId).then((response) => {
                if (response.status === 200) {
                    setHasFlipImages(response.data);
                    setHasRequestImages(true);
                }
              });
            }
          }
        });
      }
    }
  });

  }, [queryId, hasFlipImages, hasRequestImages, isModalOpen]);

function closeWindow() {
  setOpenUpload(false);
  if (editMode) {
    reloadImage(false);
  } else {
    reloadImage(true);
  }
}

const downloadZippedImages = () => {
  RequestService.getSubmitRequestImages(userToken, queryId).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'request_images.zip'); // Set the file name for download
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url); // Clean up
  })
  .catch(error => {
    console.error('There was a problem with the download:', error);
  });
}

usePageView('Image Upload');

return (
    <Dialog
    fullScreen
    open={true}
    scroll={"paper"}
    onClose={closeWindow}
    TransitionComponent={Transition}
    align="center">
    <DialogActions style={{ height: '25px' }}>
      <Button onClick={closeWindow}>Close Window X</Button>
    </DialogActions>
    <DialogTitle id="scroll-dialog-title" align='center' justifyContent="center">Add Home Floor Plans
    <Box style={{width:isMobile ? "100%":"100%"}} align="center">  
                  <Collapse in={open}>
                  <Alert severity='info' width="100%" justify="left" action={
                     <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setOpen(false);
                     }}
                     >
                     X
                     </IconButton>
                  }>
                    <b style={{ color: 'red' }}>Critical:</b> Crop the image to a square or rectangular shape with the outermost wall as the boundary.
  Flip the image if your floor plan is a mirror image of the actual floor plan.
  Rotate the image to align the front of the home/entrance to face the top side.
  This will help in finding the center of the house, which will be crucial in calculating the Vastu

  {/* Add margin only to the top to create a line gap */}
  <div style={{ marginTop: '5px' }}>
    <a href="#" onClick={openModal}>Click here to see examples</a>
  </div>
 
  {/* Render the modal */}
  <PhotoModal isOpen={isModalOpen} closeModal={closeModal} />
  <VideoBarUpload/>
                  </Alert>
                  </Collapse>
                  <Button size="small" variant="text"
                     disabled={open}
                     onClick={() => {
                    setOpen(true);
                     }}
                  >
                  Show Info
                </Button>
                </Box>      


    {/* <Typography variant="caption" align='center' mt={60}>Session: {sessionId}</Typography>   */}
    </DialogTitle>
    {hasRequestImages ? <div style={{ textAlign: 'center' }}><Button onClick={downloadZippedImages} variant='contained' size='small'>Download Images</Button></div>: ''}
    {hasFlipImages ?
    <div style={{ textAlign: 'center' }}>
      <FormControlLabel
        control={<Checkbox checked disabled/>}
        label="Flip requested"
      />
    </div>: ''}
    <Box style={{display: isMobile? 'relative':'flex', width:"100%",backgroundColor: 'rgb(248, 248, 252)'}} alignContent="center" justifyContent={"center"} align="center">
      <Box align="center" style={{width: isMobile ? "100%": "100%"}}>
          <Typography variant='h6' mb={2} mt={2}>Main entrance floor</Typography>
          {isMobile ? <UploadImage sessionId={sessionId} token={currentToken} imageId="F"/>: <UploadImage sessionId={sessionId} token={currentToken} imageId="F"/>}
      </Box> 
      <Box align="center" style={{width: isMobile ? "100%": "100%"}}>
          <Typography variant='h6' mb={2} mt={2}>Upper Floor</Typography>
          {isMobile ?<UploadImage sessionId={sessionId} token={currentToken} imageId="S"/>: <UploadImage sessionId={sessionId} token={currentToken} imageId="S"/>}
      </Box> 
      <Box align="center" style={{width: isMobile ? "100%": "100%"}}>
          <Typography variant='h6' mb={2} mt={2}>Basement/Other floor</Typography>
          {isMobile ?<UploadImage sessionId={sessionId} token={currentToken} imageId="T"/>: <UploadImage sessionId={sessionId} token={currentToken} imageId="T"/>}
      </Box>
    </Box>      
    </Dialog> 
  );
  }

  export default MaterialModalImageUpload