import useImage from './useImage'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useState } from 'react';

const CardImage = ({ filePath, id, displayName, sx, onClick }) => {
    const { loading, error, image } = useImage(filePath)
    const [sxWidth, setSxWidth] = useState(sx? sx: 300);
    if (error) return <p>Error Loading</p>

    return (
        <>
            {loading ? (
                <p>Loading</p>
            ) : (
                <Card sx={{ width: sxWidth }}>
      <CardActionArea>
        <CardMedia
          onClick={onClick}
          component="img"
          image={image}
          alt={displayName}
          id={id}
        />
        <CardContent>
          <Typography variant="body3" color="text.primary">
            {displayName}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
            )}
        </>
    )
}

export default CardImage