import React, { useState } from 'react';
import { useEffect } from 'react';
import RequestService from '../RequestService';
import { selectCurrentToken, selectCurrentCredit } from '../authSlice';
import { useSelector } from 'react-redux';
import { Typography, Box, Button } from '@mui/material';
import ModalFeedback from './ModalFeedback';
import { FaComments } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
const FeedbackBar = () => {   
const [credits, setCredits] = useState([]);
const currentCredit = useSelector(selectCurrentCredit);
const currentToken = useSelector(selectCurrentToken);
const [showFeedbackForm, setShowFeedbackForm] = useState(false);
const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
function toggleFeedback() {
    setShowFeedbackForm(!showFeedbackForm);
}
useEffect(() => {
    if (currentToken) {
        RequestService.getViewCredits(currentToken).then((response) => {
            if (response.status == 200) {
               setCredits(response.data.creditsRemaining);   
            }
         });
    }
}, [currentCredit]);      
 return (
    currentToken ?<Box  flexDirection="column" justifyContent={isMobile ? "bottom": "center"} textAlign="center" style={{ backgroundColor: 'lightgray', height: '55px', width: isMobile ? '150px' : '200px', position: 'fixed', top: "70vh", right: isMobile ? '-75px' : '-75px', display:'flex', zIndex: 100, transform: "rotate(270deg)" }}>
        {showFeedbackForm ? <ModalFeedback setShowFeedbackForm={setShowFeedbackForm}/>: ''} 
        <Button variant='text' onClick={toggleFeedback} size='small'><FaComments/>&nbsp; Feedback</Button>
        {isMobile ? '': <Typography variant='caption' color={"black"}>Get a Free Credit</Typography>}
    </Box>: ''
    );
    
  };
export default FeedbackBar