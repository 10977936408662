import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Typography, Paper, Card, CardActionArea, CardMedia, CardContent, CardActions, Button } from '@mui/material';
import plan from '../assets/images/plan.jpg';
import score from '../assets/images/score.png';
import report from '../assets/images/report.png';
import usePageView from '../components/usePageView'; // Import the custom hook

export function Disclaimer() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  usePageView('Disclaimer');

    return (
        <Box sx={{ width: '80%', backgroundColor: 'white'}} align='center' p={5}>

      <div class="legal-container-document">
<h1>Disclaimers for vastureport.com (owned by Swift Vision LLC)</h1>

<p>All the information on this website - vastureport.com (owned by Swift Vision LLC) - is published in good faith and for general information purpose only. vastureport.com (owned by Swift Vision LLC) does not make any warranties about the completeness, reliability and accuracy of this information.The Vastuscore™, reports and other information provided by vastureport.com (owned by Swift Vision LLC) should not be considered as a substitute for professional advice, diagnosis, or treatment. Vastu principles are based on cultural and traditional beliefs and may not be supported by scientific evidence. Moreover, any actions or decisions taken based on Vastu advice are solely at the individual's discretion and risk. vastureport.com (owned by Swift Vision LLC) will not be liable for any losses and/or damages in connection with the use of our website.It is important to use common sense and personal judgment in making decisions that are best for you and your surroundings.</p>
<p>From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.</p>

<p>Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.</p>

<h2>Consent</h2>

<p>By using our website, you hereby consent to our disclaimer and agree to its terms of service.</p>

<h2>Update</h2>

<p>Should we update, amend or make any changes to this document, those changes will be prominently posted here.</p>
      </div>
</Box>
    
    )
}

export default Disclaimer;