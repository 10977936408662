import { Alert, AlertTitle, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ImageMapper from 'react-img-mapper';
import { useMediaQuery } from 'react-responsive';
import vs from '../assets/images/padas_16.png';
import vs_rotated from '../assets/images/padas_16_rotated.png';
import deadend from '../assets/plot_street_focus/psf_dead_end.png'
import CardImage from './CardImage';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {Dialog, DialogActions, DialogTitle, Box, Slide} from '@mui/material';
import CardImageSelect from './CardImageSelect';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ModalPadasKitchen = ({ displayName, id, options, setOpenModal, setDropDownValue, selectedValue }) => {
  const [roads, setRoads] = useState([]);
  const [roadsRotated, setRoadsRotated] = useState([]);
  const [selectedRoad, setSelectedRoad] = useState([]);
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  const [reload, setReload] = useState(false);
  const [value, setValue] = useState(selectedValue);
  const [optionsList, setOptionsList] = useState(options);
  const [map, setMap] = useState([]);
  const [mapRotated, setMapRotated] = useState([]);
  const [deadEndImage, setDeadEndImage] = useState('../assets/plot_street_focus/psf_dead_end.png');
  const [deadEndArea, setDeadEndArea] = useState([]);
  const [selectedArray, setSelectedArray] = useState([]);
  const [selectedDisplayName, setSelectedDisplayName] = useState([]);
  const [showImages, setShowImages] = useState(false);
  const [showStraight, setShowStraight] = useState(false);
  const [coords, setCoords] = useState([{
    areas: [
      {  
      "coords": [
        250,22,
        362,22,
        360,133,
        250,133
      ]
     },
     {
      "coords": [
        363,22,
        475,22,
        477,133,
        363,133
      ]
     },
     {
      "coords": [
        477,22,
        591,22,
        590,133,
        477,133
      ]
     },
     
     {
      "coords": [
        480,137,
        590,137,
        590,250,
        480,250
      ]
     },
     {
      "coords": [
        480,250,
        590,250,
        590,360,
        480,360
      ]
     },
     {
      "coords": [
        480,360,
        590,360,
        590,477,
        480,476
      ]
     },
     {
      "coords": [
        480,480,
        590,480,
        590,590,
        480,590
      ]
     },
     {
      "coords": [
        365,480,
        480,480,
        480,590,
        365,590
      ]
     },
     {
      "coords": [
        250,480,
        365,480,
        365,590,
        250,590   
      ]
     },
     {
      "coords": [
        135,480,
        250,480,
        250,590,
        135,590
      ]
     },
     {
      "coords": [
        25,480,
        135,480,
        135,590,
        25,590
      ]
     },
     {
      "coords": [
        25,480,
        26,365,
        135,365,
        135,480
      ]
     },
     {
      "coords": [
        25,365,
        25,250,
        135,250,
        135,365
      ] 
     },
     {
      "coords": [
        25,250,
        25,135,
        135,135,
        135,250
      ]
     },
     {
      "coords": [
        25,135,
        25,25,
        135,25,
        135,135
      ]
     },
     {
      "coords": [
        135,133,
        135,22,
        248,22,
        248,133
      ]
     }
    ]
  }])

  const [coordsRoated, setCoordsRotated] = useState([{
    areas: [
      {
        "coords": [
          25,135,
          25,25,
          135,25,
          135,135
        ]
       },
       {
        "coords": [
          135,133,
          135,22,
          248,22,
          248,133
        ]
       },
      {  
      "coords": [
        250,22,
        362,22,
        360,133,
        250,133
      ]
     },
     {
      "coords": [
        363,22,
        475,22,
        477,133,
        363,133
      ]
     },
     {
      "coords": [
        477,22,
        591,22,
        590,133,
        477,133
      ]
     },
     
     {
      "coords": [
        480,137,
        590,137,
        590,250,
        480,250
      ]
     },
     {
      "coords": [
        480,250,
        590,250,
        590,360,
        480,360
      ]
     },
     {
      "coords": [
        480,360,
        590,360,
        590,477,
        480,476
      ]
     },
     {
      "coords": [
        480,480,
        590,480,
        590,590,
        480,590
      ]
     },
     {
      "coords": [
        365,480,
        480,480,
        480,590,
        365,590
      ]
     },
     {
      "coords": [
        250,480,
        365,480,
        365,590,
        250,590   
      ]
     },
     {
      "coords": [
        135,480,
        250,480,
        250,590,
        135,590
      ]
     },
     {
      "coords": [
        25,480,
        135,480,
        135,590,
        25,590
      ]
     },
     {
      "coords": [
        25,480,
        26,365,
        135,365,
        135,480
      ]
     },
     {
      "coords": [
        25,365,
        25,250,
        135,250,
        135,365
      ] 
     },
     {
      "coords": [
        25,250,
        25,135,
        135,135,
        135,250
      ]
     }
    ]
  }])

  useEffect(() => {
    //reload
    var newSelectedArray = selectedArray;
    if (Array.isArray(newSelectedArray) && newSelectedArray.length === 0) {
      if (value && value != undefined) {
        newSelectedArray = value.toString().split(',');
        setSelectedArray(newSelectedArray);
      }
    }

    var areasArray = [];
    var roadsArray = [];
    {options.map((item, i) => {
          var area = {};
          var road = {};
          area.id = i;
          area.title = item.matchingConditionDisplayName;
          area.name = item.matchingConditionCode;
          area.shape = "poly";
          area.fillColor = "#00ff194c";
          area.strokeColor = "black";
          area.coords = coords[0].areas[i].coords;         
          areasArray.push(area);  
          if (newSelectedArray && newSelectedArray != undefined && newSelectedArray != '') {
            if (newSelectedArray.includes(item.matchingConditionCode)) {
              area.preFillColor = true;
              //area.active = true;
              roadsArray.push(area);
            }
          }
    })}

    var areasArrayRoated = [];
    var roadsArrayRotated = [];
    {options.map((item, i) => {
          var area = {};
          var road = {};
          area.id = i;
          area.title = item.matchingConditionDisplayName;
          area.name = item.matchingConditionCode;
          area.shape = "poly";
          area.fillColor = "#00ff194c";
          area.strokeColor = "black";
          area.coords = coordsRoated[0].areas[i].coords;         
          areasArrayRoated.push(area);  
          if (newSelectedArray && newSelectedArray != undefined && newSelectedArray != '') {
            if (newSelectedArray.includes(item.matchingConditionCode)) {
              area.preFillColor = true;
              //area.active = true;
              roadsArrayRotated.push(area);
            }
          }
    })}
    var map = {}
    map.areas = areasArray;
    map.name = "my-map";
    setMap(map);
    setRoads(roadsArray);

    var mapRoated = {}
    mapRoated.areas = areasArrayRoated;
    mapRoated.name = "my-map-rotated";
    setMapRotated(mapRoated);
    setRoadsRotated(roadsArrayRotated);
  }, [reload]);
  
  
  function handleClick(area) {    
    setDropDownValue(id, area.name);
    setOpenModal(false);
  };

  function containsCode(roads, name) {
    for (var i = 0; i < roads.length; i++) {
      if (roads[i].name == name) {
        return true;
        break;
      }
    }
    return false;
  }
  function getIndex(roads, name) {
    for (var i = 0; i < roads.length; i++) {
      if (roads[i].name == name) {
        return i;
        break;
      }
    }
    return -1;
  }

  function handleDndClick() {
    for (let i = roads.length-1; i >= 0; i--) {
      roads.splice(i, 1);
    }
    roads.push(deadEndArea);
    setRoads(roads);
    setReload(!reload)
    setSelectedRoad(deadEndArea)
    setValue(deadEndArea.name);
    
  };

  function clearSelection() {
    for (let i = roads.length-1; i >= 0; i--) {
      roads.splice(i, 1);
    }
    setValue([]);
    setRoads(roads);
    setReload(!reload)
    setSelectedRoad([])
  }

  function removeItem(item) {
    if(roads.includes(item)) {
      const index = roads.indexOf(item);
      if (index > -1) { // only splice array when item is found
        roads.splice(index, 1); // 2nd parameter means remove one item only
        setSelectedRoad([]);
        var newArray = [];
        roads.map((item, i) => {
          newArray.push(item.name);
        });
        setSelectedArray(newArray);
        selectedValue = newArray.join(",");
        setValue(selectedValue);
        setReload(!reload)
      }
    }
  }

  function submitSelection() {
    var selection = "";
    if (roads.length == 0) {
      //you have not made any selection
    } else {
      for (let i=0; i < roads.length; i++) {
        if (i == 0) {
          selection = selection + roads[i].name;
        } else {
          selection = selection + "," + roads[i].name;
        }
      }
    }
    setDropDownValue(id, selection);
    setOpenModal(false);
  }

  function handleClose() {
    setOpenModal(false);
    if (!selectedRoad) {
      setSelectedRoad([]);
    } else {
      setSelectedRoad(deadEndArea);
    }
    
  }
  
  function handleSelect() {
    var selectedDirection = document.getElementById("frontRoad").value;
    if (selectedDirection) {    
     if(selectedDirection.length == 1) {
      setShowStraight(true);
     } else {
      setShowStraight(false);
     }
     setShowImages(true);
    }
  }
  
    return (
      <Dialog
        fullScreen
        open={true}
        scroll={"paper"}
        onClose={handleClose}
        TransitionComponent={Transition}
        align="center"
      >
        <DialogActions>
          <Button onClick={handleClose}>Close Window X</Button>
        </DialogActions>
        
        <DialogTitle id="scroll-dialog-title" align='center'>Select {displayName} Placement
        <br></br>
        </DialogTitle>
        
        {showImages ? <Box style={{display: isMobile ? 'relative': 'flex', width:"100%", align:'right'}} justify="right">
      {map ? <Card sx={{ width: "100%" }}>
      <CardActionArea>
      {showStraight ?   
      <ImageMapper
      src={vs}
      width={isMobile ? 285 : 450} 
      imgWidth={610}
      map={map}
      fillColor={"#00ff194c"}
      stayHighlighted={true}
      stayMultiHighlighted={true}
      onClick={handleClick}/> : <ImageMapper
      src={vs_rotated}
      width={isMobile ? 285 : 450} 
      imgWidth={610}
      map={mapRotated}
      fillColor={"#00ff194c"}
      stayHighlighted={true}
      stayMultiHighlighted={true}
      onClick={handleClick}/>}
      
        <CardContent>
          <Typography variant="body3" color="text.primary">
            Click on the section where your main door/entrace of the house falls. 
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>: ''}
      </Box>: <Box><Typography variant="body3" color="text.primary">
            Pick the direction of the road in front of the house: &nbsp;
          </Typography>
          <select id="frontRoad" onChange={handleSelect}>
            <option value="">Select one</option>
            <option value="N">North</option>
            <option value="NE">Northeast</option>
            <option value="E">East</option>
            <option value="SE">Southeast</option>
            <option value="S">South</option>
            <option value="SW">Southwest</option>
            <option value="W">West</option>
            <option value="NW">Northwest</option>
          </select>
          </Box>}
      </Dialog>
    );
  }


  export default ModalPadasKitchen


