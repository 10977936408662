import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../authSlice';
import config from '../config';
import { selectCurrentToken } from '../authSlice';

const UserSelectionModal = ({ onClose, handleAssignButtonClickComplete }) => {
  const token = useSelector(selectCurrentToken);
  const currentUser = useSelector(selectCurrentUser);
  const [selectedProcessor, setSelectedProcessor] = useState(null);
  const [processors, setProcessors] = useState([]);
  const [pendingProcessors, setPendingProcessors] = useState(true);

  const handleProcessorSelect = (event) => {
    const selectedProcessorId = event.target.value;
    const selectedProcessorObject = processors.find(
      (processor) => processor.id === parseInt(selectedProcessorId, 10)
    );
    console.log('Selected Processor Object:', selectedProcessorObject);
    setSelectedProcessor(selectedProcessorObject);
  };

  useEffect(() => {
    const loadAllProcessors = () => {
      setPendingProcessors(true);
      fetch(config[process.env.NODE_ENV].backendUrl + '/getAllProcessorsForAdmin', {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: 'Bearer ' + token,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 403) {
            // Handle unauthorized access
            console.log('Access is denied, token may have expired.');
          }
        })
        .then((data) => {
          setPendingProcessors(false);
          setProcessors(data || []); // Ensure data is an array or default to an empty array
        })
        .catch((err) => {
          console.log(err.message);
        });
    };

    loadAllProcessors(); // Call the function to load processors when the component mounts
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <Box sx={{ p: 2, pr: 3, pb: 5, position: 'relative' }}>
        <DialogTitle>
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            sx={{ position: 'absolute', top: 16, right: 16 }}
          >
            Close
          </Button>
          Select Processor
        </DialogTitle>
        <DialogContent>
          <label>
            Select the Processor to assign:
            <select
              value={selectedProcessor ? selectedProcessor.id : ''}
              onChange={handleProcessorSelect}
              sx={{ mr: 5 }}
            >
              <option value="" disabled>
                Choose a processor
              </option>
              {processors.map((processor) => (
                <option key={processor.id} value={processor.id}>
                  {processor.name}
                </option>
              ))}
            </select>
          </label>
          <Button   onClick={() => handleAssignButtonClickComplete(selectedProcessor ? selectedProcessor.id : null)} variant="contained" size="small" sx={{ ml: 2 }}>
            Assign to Selected Processor
          </Button>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default UserSelectionModal;
