import React, { useState, useEffect } from 'react';
import { selectCurrentUser, selectCurrentToken } from '../authSlice';
import { useSelector } from 'react-redux';
import ModalUpload from '../components/ModalImageUpload';
import MaterialModalImageUpload from '../components/MaterialModalImageUpload';
import UploadService from "../UploadFileService";
import compass from '../assets/images/blank.png';
import blocks from '../assets/images/blank.png';
import { FaCross, FaCrosshairs, FaEye, FaRedo, FaTimes, FaTrash, FaUpload } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip'
import { useDispatch } from 'react-redux';
import { clearSession, saveSession, selectSessionId } from '../imageSlice';
import ModalShowImage from '../components/ModalShowImage';
import ModalPieImage from '../components/ModalPieImage';
import { Alert, IconButton, Collapse, Box, Button, Typography, CardActionArea, Card, CardHeader, CardContent } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import RequestService from '../RequestService';
import { useToast } from 'react-toastify';

function ShowImages(editSessionId) {
   const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
   const [open, setOpen] = React.useState(false);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [imageValue, setImageValue] = useState('F')
    const currentUser = useSelector(selectCurrentUser);
    const currentToken = useSelector(selectCurrentToken);
    const [imageSessionId, setImageSessionId] = useState();
    const dispatch = useDispatch();
    const sessionId = useSelector(selectSessionId);
    const [allUrls, setAllUrls] = useState([]);
    const [floorTypes, setFloorTypes] = useState([]);
    const [showImageInModal, setShowImageInModal] = useState(false);
    const [infoMessage, setInfoMessage] = useState('Upload floor plan of the house. Having a floor plan will aid you in entering the directions accurately.');
    const [urlToShow, setUrlToShow] = useState([]);
    const [reloadPage, setReloadPage] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [hasFirstImage, setHasFirstImage] = useState(false);
    const [hasSecondImage, setHasSecondImage] = useState(false);
    const [hasThirdImage, setHasThirdImage] = useState(false);
    const [firstImage, setFirstImage] = useState([]);
    const [secondImage, setSecondImage] = useState([]);
    const [thirdImage, setThirdImage] = useState([]);
    const [imageAvailable, setImageAvailable] = useState(true);
    const [imageToLoad, setImageToLoad] = useState();

     useEffect(() => {
        var imageToLoad
        if (editSessionId.editMode) {
          setImageSessionId(editSessionId.sessionId);
          imageToLoad = editSessionId.sessionId;
        } else {
          setImageSessionId(sessionId);
          imageToLoad = sessionId;
        }
        setImageToLoad(imageToLoad);
        if (currentUser && editSessionId.sessionId && editSessionId.editMode) {
         loadImage(imageToLoad);
         setShowContent(true);
         
        } else if (currentUser) {
         if ((editSessionId.sessionId != null || editSessionId.sessionId == undefined) && editSessionId.editMode == false) {
            reloadImage(true);
         }

         setShowContent(true);
        }
    }, [imageToLoad, reloadPage]) 
    
    function rotateImage(imageId) {
        var image = document.getElementById(imageId);
        if (image.style.transform == "") {
         image.style.transform = 'rotate(45deg)';
        } else if (image.style.transform == "rotate(45deg)") {
         image.style.transform = 'rotate(90deg)';
        } else if (image.style.transform == 'rotate(90deg)') {
         image.style.transform = 'rotate(135deg)';
        } else if (image.style.transform == 'rotate(135deg)') {
         image.style.transform = 'rotate(180deg)';
        }else if (image.style.transform == 'rotate(180deg)') {
         image.style.transform = 'rotate(225deg)';
        } else if (image.style.transform == 'rotate(225deg)') {
         image.style.transform = 'rotate(270deg)';
        } else if (image.style.transform == 'rotate(270deg)') {
         image.style.transform = 'rotate(315deg)';
        }else if (image.style.transform == 'rotate(315deg)') {
         image.style.transform = '';
        }
      }

     function flipImage(tempImageId) {
        var inputImage = document.getElementById(tempImageId);
        // create a canvas that will present the output image
        const outputImage = document.createElement('canvas');
        // set it to the same size as the image
        outputImage.width = inputImage.naturalWidth;
        outputImage.height = inputImage.naturalHeight;
        // get the drawing context, needed to draw the new image
        const ctx = outputImage.getContext('2d');
        // scale the drawing context negatively to the left (our image is 400 pixels wide)
        // resulting change to context: 0 to 400 -> -400 to 0
        ctx.scale(-1, 1);
        // draw our image at position [-width, 0] on the canvas, we need
        // a negative offset because of the negative scale transform
        ctx.drawImage(inputImage, -outputImage.width, 0);
        var dataURL = outputImage.toDataURL();
        return dataURL;
     }
    
     
      function reloadImage(imageUploaded) {
         if (imageUploaded) {
            setImageSessionId(imageToLoad);
            UploadService.getCurrentSessionId(currentToken).then((response) => {
                if (response.data != "NA") {
                   setImageAvailable(true);
                    const sessionId = response.data;
                    setImageSessionId(sessionId); 
                    UploadService.getFiles(sessionId, currentToken)
                    .then((files) => {
                      var urls = [];
                      var imageTypes = [];
                       for(let i = 0; i < files.data.length; i++){
                          imageTypes.push(files.data[i].fileType);
                          UploadService.getFile(files.data[i].id, currentToken).then((file) => {
                             var targetDiv = document.querySelector('#' + files.data[i].fileType);
                             var url
                             if (files.data[i].flipImage) {
                                url = 'data:image/png;base64,' + file.data;
                                url = flipImage("tempImageId" + (i + 1));    
                             } else {
                                url = 'data:image/png;base64,' + file.data;
                             }
                             if (files.data[i].fileType == 'F') {
                              urls[0] = url;
                              setHasFirstImage(true);
                              setFirstImage(url);
                             }
                             else if (files.data[i].fileType == 'S') {
                              urls[1] = url;
                              setHasSecondImage(true);
                              setSecondImage(url);
                             }
                             else if (files.data[i].fileType == 'T') {
                              urls[2] = url;
                              setHasThirdImage(true);
                              setThirdImage(url);
                             }
                             return sessionId;
                          });
                       }    
                       setAllUrls(urls); 
                       setFloorTypes(imageTypes);
                       setInfoMessage('Click on the image to see the Floor plan with guiding compass. This will help enter the values manually.');
                       dispatch(saveSession(sessionId));        
                     }) 
                     .catch(() => {
                       this.setState({
                         progress: 0,
                         message: "Could not download the file!",
                         currentFile: undefined,
                       });
                     });
                } else {
                    dispatch(clearSession());
                    setImageAvailable(false);
                }
           });
        } else {
            setReloadPage(true);
        }
      }

      function loadImage(imageSessionId) {
         if (imageSessionId) {
            setImageSessionId(imageSessionId);
            UploadService.getFiles(imageSessionId, currentToken)
                    .then((files) => {
                     var urls = [];
                     var imageTypes = [];
                       for(let i = 0; i < files.data.length; i++){
                        setImageAvailable(true);
                        imageTypes.push(files.data[i].fileType);
                          UploadService.getFile(files.data[i].id, currentToken).then((file) => {

                             var url
                             if (files.data[i].flipImage) {
                                url = 'data:image/png;base64,' + file.data;
                                url = flipImage("tempImageId" + (i + 1));    
                             } else {
                                url = 'data:image/png;base64,' + file.data;
                             }
                             if (files.data[i].fileType == 'F') {
                              urls[0] = url;
                              setFirstImage(url);
                              setHasFirstImage(true);
                             }
                             else if (files.data[i].fileType == 'S') {
                              urls[1] = url;
                              setSecondImage(url);
                              setHasSecondImage(true);
                           }
                             else if (files.data[i].fileType == 'T') {
                              setThirdImage(url)
                              setHasThirdImage(true);
                              urls[2] = url;
                             }
                             return sessionId;
                          });
                       }
                       setAllUrls(urls);
                       setFloorTypes(imageTypes);
                       dispatch(saveSession(sessionId));        
                     }) 
                     .catch(() => {
                       this.setState({
                         progress: 0,
                         message: "Could not download the file!",
                         currentFile: undefined,
                       });
                     });
         } else {
            setImageAvailable(false);
            dispatch(clearSession())
         }
      }
      function showModalImage(imageId) {
         setImageValue(imageId);
         if (imageId == 'F') {
            setUrlToShow(firstImage);
         } else if (imageId == 'S') {
            setUrlToShow(secondImage);
         } else if (imageId == 'T') {
            setUrlToShow(thirdImage);
         }
         setShowImageInModal(true);
      }

      function deleteFile(fileType) {
         RequestService.deleteFile(imageSessionId, fileType, currentToken).then((response) => {
            if (response.status == 200) {

               if (fileType == 'F') {
                  setHasFirstImage(false);
               }
               if (fileType == 'S') {
                  setHasSecondImage(false);
               }
               if (fileType == 'T') {
                  setHasThirdImage(false);
               }
               setReloadPage(true);   
            }   
         });
      }

    return (
         <Box mr={1} ml={1} mb={1} width={isMobile ? "99%":"25%"}>
                {uploadOpen ? <MaterialModalImageUpload setOpenUpload={setUploadOpen} reloadImage={reloadImage} userToken={currentToken} editSessionId={imageSessionId} editMode={editSessionId.editMode} queryId={editSessionId.queryId}/>: ''}
                {showImageInModal ? <ModalPieImage url={allUrls} setShowImageInModal={setShowImageInModal} imageValue={imageValue} floorTypes={floorTypes}/>: ''}
                  <fieldset>
                  <legend>Floor Plan</legend>
                        <Button onClick={() => {setUploadOpen(true);}} size="small"> 
                           <FaUpload id="uploadImageModal" data-tooltip-content='click to upload floor plan'/>&nbsp; Add/Update
                        </Button>
                        <Tooltip anchorId='uploadImageModal'/>
                     <img id="tempImageId1" alt="compass" className="App-compass" hidden/>
                      <img id="tempImageId2" alt="compass" className="App-compass" hidden/>
                       <img id="tempImageId3" alt="compass" className="App-compass" hidden/>
                  
                  <Box px={2}
      sx={{
        width: '90%', // 30% of the screen width
        margin: 'auto', // Center the box if needed
        overflow: 'hidden', // Ensures the image does not overflow the box
      }}
    >
     {hasFirstImage ? <Card variant='outlined'>
      <CardContent>
      <Typography>Main Floor</Typography>   
      <img 
        src={firstImage}
        onClick={() => showModalImage('F')} 
        alt="First Floor"
        style={{ width: '90%', height: 'auto' }} // Scale the image to fit the box width
      /></CardContent>
      <CardActionArea><Button size="small" onClick={() => deleteFile('F')}><FaTrash/>Delete</Button>
      <Button size="small" onClick={() => showModalImage('F')}><FaEye/>View</Button></CardActionArea></Card>
      :''} <br></br>
       {hasSecondImage ? <Card variant='outlined'>
      <CardContent>
      <Typography>Upper Floor</Typography>   
      <img
        onClick={() => showModalImage('S')} 
        src={secondImage}
        alt="Upper Floor"
        style={{ width: '90%', height: 'auto' }} // Scale the image to fit the box width
      /></CardContent>
      <CardActionArea><Button size="small" onClick={() => deleteFile('S')}><FaTrash/>Delete</Button>
      <Button size="small" onClick={() => showModalImage('S')}><FaEye/>View</Button>
      </CardActionArea></Card>
      :''}<br></br>
       {hasThirdImage ? <Card variant='outlined'>
      <CardContent>
      <Typography>Basement/Other Floor</Typography>   
      <img 
        src={thirdImage}
        onClick={() => showModalImage('T')} 
        alt="Basement/Other Floor"
        style={{ width: '90%', height: 'auto' }} // Scale the image to fit the box width
      /></CardContent>
      <CardActionArea><Button size="small" onClick={() => deleteFile('T')}><FaTrash/>Delete</Button>
      <Button size="small" onClick={() => showModalImage('T')}><FaEye/>View</Button>
      </CardActionArea></Card>
      :''}
    </Box>
                 
                  {editSessionId.sessionId ? '' :
                  <Box align='center' mt={2}>
                  <Collapse in={open}>
                  <Alert severity="info" style={{width: '350px'}} justify="left" action={
                     <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setOpen(false);
                     }}
                     >
                     X
                     </IconButton>
                  }>{infoMessage} 
                  </Alert>
                  </Collapse>
                  <Button size="small" variant="text"
                     disabled={open}
                     onClick={() => {
                    setOpen(true);
                     }}
                  >
                  Show Info
                </Button>
                </Box>
                  }
                </fieldset>
               </Box>
    )
}

export default ShowImages