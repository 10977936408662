import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from 'jwt-decode';

export const authSlice = createSlice({
    name: "auth",
    initialState: { loggedIn: false, userName: null, userEmail: null, token: null, loginType: null, credits: null },
    reducers: {
        userLogin: (state, action) => {
            if (jwtDecode(action.payload.token).iss) {
              state.loginType = 'gmail';      
            } else {
              state.loginType = 'email';
            }
            state.loggedIn = action.payload.emailVerified;
            state.token = action.payload.token;
            state.userName = action.payload.name;
            state.userEmail = action.payload.email;
        },

        userLogout: (state) => {
            state.loggedIn = false
            state.token = null
            state.userName = null
            state.userEmail = null
            state.loginType = null
            state.credits = null
        },

        userCredits: (state, action) => {
          state.credits = action.payload;
        },

        userNameUpdate: (state, action) => {
          state.userName = action.payload
        }

    },
});
export const {userLogin, userLogout, userCredits, userNameUpdate} = authSlice.actions;
export const selectCurrentUser = (state) => state.auth.userName
export const selectCurrentToken = (state) => state.auth.token
export const selectCurrentEmail = (state) => state.auth.userEmail
export const selectCurrentCredit = (state) => state.auth.credits;
export const selectLoginType = (state) => state.auth.loginType
export default authSlice.reducer;

