import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../authSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import RequestService from '../RequestService';
import DataTable, { createTheme, defaultThemes } from 'react-data-table-component';
import styled from 'styled-components';
import { setQueryId } from '../reportSlice';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Typography, Box, Collapse, IconButton, Alert, Button } from '@mui/material';
import config from '../config';
import ModalFeedback from '../components/ModalFeedback';
import usePageView from '../components/usePageView'; // Import the custom hook

createTheme('light', {
    rows: {
        fontSize: '15px'
    }
});

const customStyles = {
    header: {
        style: {
            minHeight: '56px',
        },
    },
    headRow: {
        style: {
            borderTopStyle: 'solid',
            backgroundColor: "#1976d2",
            borderTopWidth: '1px',
            borderTopColor: defaultThemes.default.divider.default,
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
            },
        },
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
            },
        },
    },
    rows: {
        highlightOnHoverStyle: {
            color: defaultThemes.default.highlightOnHover.text,
            backgroundColor: "#97c1ec;",
            transitionDuration: '0.15s',
            transitionProperty: 'background-color',
            borderBottomColor: defaultThemes.default.background.default,
            outlineStyle: 'solid',
            outlineWidth: '1px',
            outlineColor: defaultThemes.default.background.default,
        },
    },
};    

export function ListFeedback() {
    usePageView('List Feedback');

    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const token = useSelector(selectCurrentToken);
    const [userOrders, setUserOrders] = useState([]);
    const [pending, setPending] = useState(true);
    const [messageText, setMessageText] = useState([]);
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    
    const columns = [
        { name: 'Ease of Use Rating', selector: row => <Typography variant='body2'>{row.easeOfUseRating}</Typography>, width:"25%", center: true},
        { name: 'Value Rating', selector: row => <Typography variant='body2'>{row.addsValueRating}</Typography>, width:"25%", center: true},
        { name: 'Overall Rating', selector: row => <Typography variant='body2'>{row.overallRating}</Typography>, width:"25%", center: true},
        { name: 'Feedback Comments', selector: row => <Typography variant='body2'>{row.feedback}</Typography>, width:"75%", center: false, wrap: true},
    ];
    useEffect(() => {
        loadAllOrders();
    }, []);

 
    function loadAllOrders() {

        fetch(config[process.env.NODE_ENV].backendUrl + '/getMyRatingAndFeedback', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status == 200) {
                return response.json();
            } else if (response.status == 403) {
                setMessageText("Access is denied, token may have expired please logout and login again");
            }

        })
            .then((data) => {
                if (data.length == 0) {
                    setMessageText('No Saved Reports found');
                }
                setPending(false);
                setUserOrders(data);
            }).catch((err) => {
                console.log(err.message);
            });
    }
    function toggleFeedback() {
        loadAllOrders();
        setShowFeedbackForm(!showFeedbackForm);
    }
 
    return (
       token ? (
        
        <Box style={{display: 'relative', width:"60%"}} alignContent="left" mt={10}>
            {showFeedbackForm ? <ModalFeedback setShowFeedbackForm={toggleFeedback}/>: ''}
            <Button onClick={toggleFeedback}>Add Feedback</Button>
                    <DataTable theme="light" title="My Feedbacks"
                        columns={columns} data={userOrders}
                        pagination   
                        highlightOnHover 
                        responsive 
                        pointerOnHover 
                        progressPending={pending}
                        customStyles={customStyles}
                        paginationResetDefaultPage={resetPaginationToggle}
                        persistTableHead
                        />
                     </Box>   
        ): <div id="accessMessage">Access denied, user must be logged in to access the reports</div>
    )
}

export default ListFeedback;