import { useState } from "react";
import { selectCurrentToken } from "../authSlice";
import { useSelector } from "react-redux";
import { FaTools } from "react-icons/fa";
import CustomizeReportOptions from "./CustomizeReportOptions";
export function CustomizeReport({queryId, loadAllReports}) {
    const [showOptions, setShowOptions] = useState(false);
    const currentToken = useSelector(selectCurrentToken);
    function getCustomizationOptions(event) {
        setShowOptions(true);
    }

    return (
        <div>
        {showOptions ? <CustomizeReportOptions queryId={queryId} loadAllReports={loadAllReports} setShowOptions={setShowOptions}/>: ''}
        <FaTools onClick={getCustomizationOptions}/>
        </div>
    )
}

export default CustomizeReport;