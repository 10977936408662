import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Typography, Button, Avatar, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { blue, green } from '@mui/material/colors';
import { format } from 'date-fns';
import RequestService from '../RequestService';
import { selectCurrentToken } from '../authSlice';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../authSlice';
import SupportAgentSharpIcon from '@mui/icons-material/SupportAgentSharp';
import CommentSection from '../components/CommentSection';

const CommentContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: (theme) => theme.spacing(2),
  marginRight: (theme) => theme.spacing(2), // Add margin to the right
});

const ProcessorAvatar = styled(Avatar)({
  backgroundColor: green[500],
  marginRight: (theme) => theme.spacing(1), // Adjust the margin as needed
});

const UserAvatar = styled(Avatar)({
  backgroundColor: '#FFFFFF', // Light blue color
  marginRight: (theme) => theme.spacing(1), // Adjust the margin as needed
  width: '36px', // Set the desired width
  height: '36px', // Set the desired height
});

const CommentListItemText = styled(ListItemText)({
  marginLeft: (theme) => theme.spacing(2), // Add margin to the left
  marginRight: (theme) => theme.spacing(2), // Add margin to the right
});

const useStyles = {
  commentContainer: CommentContainer,
  processorAvatar: ProcessorAvatar,
  userAvatar: UserAvatar,
  commentListItemText: CommentListItemText, // Add the new styled component
};

const CommentModal = ({ setShowCommentsForm, queryId, currentToken, isProcessor , isAdmin}) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state
  const currentUser = useSelector(selectCurrentUser);

  function onClose() {
    setShowCommentsForm(false);
  }

  useEffect(() => {
    loadComments();
  }, []);

  function loadComments() {
    setLoading(true); // Set loading to true before making the request

    if(isProcessor != null && isProcessor) {
        RequestService.getAllUserCommentsByQueryIdForProcessor(queryId, currentToken)
          .then((commentsResponse) => {
            setComments(commentsResponse.data);
          })
          .catch((commentsError) => {
            console.error('Error fetching comments:', commentsError);
          })
          .finally(() => {
            setLoading(false); // Set loading to false after the request completes
          });

     } else if(isAdmin != null && isAdmin) {
             RequestService.getAllUserCommentsByQueryIdForAdmin(queryId, currentToken)
               .then((commentsResponse) => {
                 setComments(commentsResponse.data);
               })
               .catch((commentsError) => {
                 console.error('Error fetching comments:', commentsError);
               })
               .finally(() => {
                 setLoading(false);
               });
    }  else {
        RequestService.getAllUserCommentsByQueryId(queryId, currentToken)
          .then((commentsResponse) => {
            setComments(commentsResponse.data);
          })
          .catch((commentsError) => {
            console.error('Error fetching comments:', commentsError);
          })
          .finally(() => {
            setLoading(false); // Set loading to false after the request completes
          });
      }
  }

  const WhiteAvatar = styled(Avatar)({
    backgroundColor: 'white',
    color: 'black',
    marginRight: (theme) => theme.spacing(1),
    width: '36px',
    height: '36px',
  });

  const stringAvatar = (name) => {
    const initials = name
      .split(' ')
      .map((part) => part.charAt(0))
      .join('');

    return initials.toUpperCase();
  };

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <Box sx={{ p: 2, pr: 3, pb: 3, position: 'relative' }}>
        <DialogTitle>
          <Button onClick={onClose} color="primary" variant="contained" sx={{ position: 'absolute', top: 16, right: 16 }}>
            Close
          </Button>
          Comments
          <Typography variant="body2" component="span" color="primary" style={{ fontSize: '14px' }}>
            &nbsp; - ordered descending by date
          </Typography>
        </DialogTitle>
        <DialogContent>
          <CommentSection queryId={queryId} currentToken={currentToken} currentUser={currentUser} isProcessor={isProcessor}/>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default CommentModal;
