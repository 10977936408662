import { Alert, AlertTitle, Button, Rating, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Typography from '@mui/material/Typography';
import {Dialog, DialogActions, DialogTitle, Box, Slide} from '@mui/material';
import RequestService from '../RequestService';
import { selectCurrentToken } from '../authSlice';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import {userCredits} from '../authSlice'
import { Table, TableBody, TableCell, TableRow } from '@mui/material';




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ModalFeedback = ({ setShowFeedbackForm }) => {
  const dispatch = useDispatch();
  const [easeOfUseRating, setEaseOfUseRating] = useState(null);
  const [addsValueRating, setAddsValueRating] = useState(null);
  const [overallRating, setOverallRating] = useState(null);

  const [reload, setReload] = useState(false);
  const [value, setValue] = useState(null);
  const [message, setMessage] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  const currentToken = useSelector(selectCurrentToken);
  const [feedbackText, setFeedbackText] = useState("");
  const [formVisible, setFormVisible] = useState(true); // New state variable

  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });

  function handleClose() {
    setShowFeedbackForm(false);
  }

  const handleFeedback = (event) => {
    setFeedbackText(event.target.value);
  };


  function submitFeedBack() {
    if (easeOfUseRating == null || addsValueRating == null || overallRating == null) {
      setMessage("Please provide ratings for Ease of Use, Value, and Overall Rating.");
      return;
    }
    if (document.getElementById("feedbackText").value === "") {
      setMessage("Please enter a comment");
      return;
    }

    const feedbackObject = {
      easeOfUseRating,
      addsValueRating,
      overallRating,
      feedback: feedbackText,
    };

    RequestService.submitFeedback(JSON.stringify(feedbackObject), currentToken)
      .then((response) => {
        if (response.status === 200) {
          const creditsAdded = response.data;
          RequestService.getViewCredits(currentToken).then((response) => {
            if (response.status === 200) {
              dispatch(userCredits(response.data.creditsRemaining));
              if (creditsAdded === 1) {
                setSuccessMessage(
                  'Thank you for your feedback! We have added 1 free credit to your account as a token of our appreciation. You can click the "Close Window" button to close this window.'
                );
              } else {
                setSuccessMessage('Thank you for your feedback! You can click the "Close Window" button to close this window.');
              }
              setFormVisible(false);
            }
          });
        }
      })
      .catch((err) => {
        toast.error("Unable to submit feedback at this time");
        console.log(err);
      });
  };


    return (
      <Dialog
        open={true}
        scroll={"paper"}
        onClose={handleClose}
        TransitionComponent={Transition}
        align="center"
      >
        <DialogActions>
          <Button onClick={handleClose}>Close Window X</Button>
        </DialogActions>

       {successMessage && successMessage != "" ?   <div style={{ padding: '16px' }}>
                                                     <Alert severity='success'>{successMessage}</Alert>
                                                   </div>: ''}


        {formVisible && (<Box width={isMobile ? "80vw": 500} height={550} style={{display:'relative'}} m={2}>
            <DialogTitle id="scroll-dialog-title" align='center'>Submit Feedback
            <br></br>
            </DialogTitle>

          <ToastContainer position='top-center' theme='dark'/>
           {message && message != "" ? <Alert severity='error'>{message}</Alert>: ''}
          <Typography>On a scale of 1 to 5 Rate your experience</Typography>
          <br></br>
<Box display="flex" width="100%">
   <Table>
              <TableBody>
                <TableRow>
                  <TableCell width="50%">
                    <Typography>Ease of Use:</Typography>
                  </TableCell>
                  <TableCell width="50%">
                    <Rating
                      name="easeOfUseRating"
                      defaultValue={0}
                      size="large"
                      value={easeOfUseRating}
                      onChange={(event, newValue) => setEaseOfUseRating(newValue)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%">
                    <Typography>Value :</Typography>
                  </TableCell>
                  <TableCell width="50%">
                    <Rating
                      name="valueRating"
                      defaultValue={0}
                      size="large"
                      value={addsValueRating}
                      onChange={(event, newValue) => setAddsValueRating(newValue)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%">
                    <Typography>Overall Rating:</Typography>
                  </TableCell>
                  <TableCell width="50%">
                    <Rating
                      name="overallRating"
                      defaultValue={0}
                      size="large"
                      value={overallRating}
                      onChange={(event, newValue) => setOverallRating(newValue)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
</Box>
          <br></br>
          <br></br>
          <Typography>Enter your comments <span className='mandatory'>*</span></Typography>

          <TextField
          id="feedbackText"
          onChange={handleFeedback}
          fullWidth
          multiline
          rows={4}
          variant="filled"
          ></TextField>
          <br></br>
          <br></br>
          <Button variant='contained' size='small' onClick={submitFeedBack}>Submit</Button>
        </Box>
         )}
      </Dialog>
    );
  }


  export default ModalFeedback


