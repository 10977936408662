import React from 'react';
import CardImage from './CardImage';
import Dialog from '@mui/material/Dialog';
import {DialogTitle, DialogActions, Button} from '@mui/material';
import { Box, Alert } from '@mui/material';
import Slide from '@mui/material/Slide';
import "./Modal.css";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
class MaterialModalImage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
      this.state.id = this.props.id;
      this.state.open = true;
      this.state.options = this.props.options;  
      this.handleChange = this.handleChange.bind(this);
      this.setOpenModal = this.props.setOpenModal;
      this.setDropDownValue = this.props.setDropDownValue;
      this.displayName = this.props.displayName;
      this.filterId = this.props.filterId;
    }
  
    handleChange(event) {
      this.setState({value: event.target.id});
      document.getElementById(this.state.id).value = event.target.id;
      //this.setDropDownValue(this.state.id, event.target.id);
      this.setOpenModal(false);
    }
  
    render() {
      return (
        <Dialog
        fullScreen
        open={true}
        scroll={"paper"}
        onClose={this.handleChange}
        TransitionComponent={Transition}
        align="center"
      >
        <DialogActions>
          <Button onClick={this.handleChange}>Close Window X</Button>
        </DialogActions>
        
        <DialogTitle id="scroll-dialog-title" align='center'>Select image best describes {this.displayName}

        </DialogTitle>
       {/*  <DialogContent dividers={'paper'}></DialogContent> */}
        <Box align="center" style={{display: 'relative', width:"100%"}}> 
        <Alert severity="info" style={{width: "500px"}} justify="center">The diagram shown below are for illustration purpose. Please select the closest shape matching the below diagrams</Alert>             
              {
                this.state.options.map((optionCode, index) => {
                    return <button className="imageButton" onClick={this.handleChange} key={optionCode.matchingConditionCode}>
                        <CardImage id={optionCode.matchingConditionCode} filePath={optionCode.imagePath} displayName={optionCode.matchingConditionDisplayName}></CardImage>
                      </button>
                })
              }
        </Box>
      </Dialog>
         
    );
    }
  }

  export default MaterialModalImage