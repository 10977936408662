import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useState } from 'react';
import './user.css';
import { FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLoginType } from '../authSlice';
import { selectCurrentToken } from '../authSlice';
import RequestService from '../RequestService';

export function UserActions({name, logOut, myorders, myFeedbacks, administration, processor}) {
    const navigate = useNavigate();
    const loginType = useSelector(selectLoginType);
    const [userRoles, setUserRoles] = useState([]);
    const currentToken = useSelector(selectCurrentToken);

      useEffect(() => {
        // Fetch user roles and update state
        if (currentToken) {
          RequestService.getUserRoles(currentToken).then((response) => {
            if (response.status === 200) {
              setUserRoles(response.data.map(role => role.name)); // Extract role names
            }
          });
        }
      }, [currentToken]);

    return (
      <div className='nav-links-plain'>
       <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-basic-button">
          <FaUserCircle/>{name}
        </Dropdown.Toggle>
  
        <Dropdown.Menu>
        {loginType == 'email' ? <Dropdown.Item onClick={() => navigate('./changePassword')}>Change Password</Dropdown.Item> : ''}
       <Dropdown.Item onClick={() => navigate('./myprofile')}>My Profile</Dropdown.Item>
          <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
          <Dropdown.Item onClick={myorders}>My Orders</Dropdown.Item>
          <Dropdown.Item onClick={myFeedbacks}>My Feedback</Dropdown.Item>
           {userRoles.includes('Admin') && (
                     <Dropdown.Item onClick={administration}>Administration</Dropdown.Item>
           )}
           {userRoles.includes('Processor') && (
                     <Dropdown.Item onClick={processor}>Processor</Dropdown.Item>
           )}
        </Dropdown.Menu>
      </Dropdown>
      </div>
    
    )
}

export default UserActions;