import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import RequestService from '../RequestService';
import usePageView from '../components/usePageView'; // Import the custom hook
import loadingImage from '../assets/images/loading.gif';
import { Typography, Box, Collapse, IconButton, Alert, Button, Dialog, DialogContent, DialogActions, LinearProgress } from '@mui/material';
import ScrollTopFab from '../components/ScrollToTopFab';
import { Document, Page, pdfjs } from 'react-pdf';
import { useMediaQuery } from 'react-responsive';
import DocumentViewer from '../components/DocumentViewer';
export function SharedReport() {
    usePageView('Shared Report');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingReport, setLoadingReport] = useState(false);
    const [downloadQueryId, setDownloadQueryId] = useState(null);
    const [messageText, setMessageText] = useState([]);
    const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    };
  
    const downloadPdf = () => {
      // Check if pdfUrl is available and there is a valid queryId
      if (pdfUrl && downloadQueryId) {
        const reportId = downloadQueryId;
  
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `report_${reportId}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // Handle the case where pdfUrl is not available or there is no queryId
        console.error("Cannot download PDF. pdfUrl is not available or no queryId.");
      }
    };
  
    let [searchParams, setSearchParams] = useSearchParams();
    const [message, setMessage] = useState([]);
    const params = [];

    for(let entry of searchParams.entries()) {
      params.push(entry);
    }
    useEffect(() => {
        var callParams = [];
        var messages = [];
        params.map((param, index) => {
            param.map((value, index) => {
                if (index == 1) {
                    callParams.push(value);
                }
             }); 
         });  
         setLoadingReport(true);
         //document.getElementById("reportFrame").srcdoc = `<div align="center"><img height="300px" width="300px" src="${loadingImage}" alt="Embedded Image in Iframe" /></div>`;
         RequestService.getSharedReport(callParams[0]).then((response) => {
            if (response.status === 200) {
                // Check the Content-Type header
                const contentType = response.headers['content-type'];
                if (contentType === 'application/pdf') {
                  // The response contains a PDF
                  const blob = new Blob([response.data], { type: 'application/pdf' });
                  const objectUrl = URL.createObjectURL(blob);
          
                  setPdfUrl(objectUrl);
                  setShowPdfViewer(true);
          
                  setLoadingReport(false);
                } else {
                  // Handle unexpected content type
                  console.error('Unexpected Content-Type:', contentType);
                  setMessageText('Error loading the report. Unexpected content type.');
                  setLoadingReport(false);
                }
              } else if (response.status === 403) {
                setMessageText("Access is denied, token may have expired. Please logout and login again.");
                setLoadingReport(false);
              } else {
                setMessageText("Error loading the report. Please try again.");
                setLoadingReport(false);
              }    
          })
          .catch((err) => {
            messages.push("Unable to find the report.");
            setMessage(messages);
        });

    }, [searchParams]);

    return (
        <div>
            <DocumentViewer loadingReport={loadingReport} pdfUrl={pdfUrl} messageText={messageText} showPdfViewer={showPdfViewer} compareCount={0}/>     
            {message ? <p className='App-p'>{message}</p> : ''}
        </div>
        

    
    )
}

export default SharedReport;