import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, Route, Routes } from 'react-router-dom';
import { Box, Typography, Paper, Card, CardActionArea, CardMedia, CardContent, CardActions, Button } from '@mui/material';
import floorplans from '../assets/images/floorplans.jpg';
import map from '../assets/images/maps.jpg';
import report from '../assets/images/report.png';
import usePageView from '../components/usePageView'; // Import the custom hook

export function Builders() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  usePageView('Builders');

    return (

        <Box sx={{ width: '80%', backgroundColor: 'white'}} align='center' p={5}>



        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>What is Vastu?</Typography>
        <Typography variant="body2" paragraph align='justify'>Vastu (also spelled as Vaastu) is an ancient Indian science of architecture and building design that involves the placement and orientation of buildings in harmony with the natural forces and energy fields of the universe. The word "Vaastu" is derived from the Sanskrit word "Vas" which means "to dwell" or "to live".According to Vaastu, every building or structure has a life force or energy field, and it is believed that by designing and constructing buildings in harmony with the natural energy flows, we can create a positive environment that enhances our well-being, health, and prosperity. The principles of Vaastu are based on the five elements of nature - earth, water, fire, air, and space - and aim to balance and harmonize these elements in the built environment.Vaastu also considers the placement and orientation of rooms, doors, windows, and other features of a building in relation to the cardinal directions and the movement of the sun and stars. It is believed that by aligning a building with the natural forces of the universe, we can create a space that is conducive to good health, happiness, and prosperity.
        </Typography>
        <Typography variant="body2" paragraph align='justify'> It's important to note that Vastu Shastra is not universally accepted or scientifically proven. The effectiveness of Vastu principles may vary from person to person, and personal beliefs and cultural backgrounds play a significant role in its adoption. When buying a property, it's advisable to consider factors like location, budget, functionality, and personal preferences alongside any Vastu considerations.
        </Typography>

        <br/>
        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>How does Vastu benefit a Builder Sales Agent?</Typography>

        <Typography variant="body2" paragraph align='justify'>
        By incorporating Vastu principles into their designs and recommendations, builder sales agents can help their clients to find properties that are more comfortable, harmonious, and beneficial. This can help to build trust and credibility with clients, and can lead to increased sales and referrals over time.Home buyers who are interested in Vastu principles are typically those who come from South Asian or Indian cultural backgrounds where Vastu is traditionally practiced. However, there is also a growing interest in Vastu among non-Indian home buyers who are interested in holistic and sustainable living. These buyers may be attracted to Vastu-compliant properties for their emphasis on natural light, ventilation, and energy flow, as well as their focus on the well-being of the occupants. Additionally, some home buyers may simply be interested in Vastu as a unique selling proposition that sets their property apart from others on the market.
        </Typography>
        <Typography variant="body2" paragraph align='justify'>When it comes to selling a property, Vastu can help a builder sales agent in several ways:
        </Typography>
        <Typography component="div" align='justify' variant="body2">
          <ul>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2">Faster Sales:</Typography>Homes with strong Vastu compliance tend to sell more swiftly, attracting a wider pool of potential buyers, leading to quicker sales.</li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Increased Home Value:</Typography>Studies show that homes with better Vastu compliance can often command higher prices, increasing your potential returns.</li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Distinct Property Appeal:</Typography>Vastu compliance adds a unique appeal to your property, making it stand out in the market.</li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Better Resale Value:</Typography>Properties with stronger Vastu compliance tend to maintain and potentially increase their resale value over time.This can be a significant advantage for both you and your clients, ensuring a wise investment in the long run.</li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Competitive Edge:</Typography>By incorporating Vastu principles into your sales pitches and recommendations, you can help your clients find properties that are more comfortable, harmonious, and beneficial, leading to increased customer satisfaction and loyalty over time.</li>
          </ul>
        </Typography>


        <br/>
        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>What are the required inputs from you?</Typography>
        <Typography component="div" align='justify' variant="body2">
          <ul>
            <li>Directional location, shape, and surroundings of the site or plot.</li>
            <li>Optional: Floor plan for determining room placement and orientation.</li>
            <li>Optional: Provide the precise degree of the front door or north point of your house/plot using a compass to enhance accuracy. Instructions will be provided.</li>
          </ul>
        </Typography>

        <br/>
        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>What we offer?</Typography>
        <Typography component="div" align='justify' variant="body2">
          <ul>
            <li>
              <Typography sx={{ fontWeight: 'bold' }} variant="body2">Leverage Vastu as a Selling Feature:</Typography>
              Our platform helps you obtain a Vastuscore™ and Rating for all plot and plan combinations, allowing you to assess the level of Vastu compliance and determine how to best incorporate it as a selling feature alongside other property attributes.
            </li>
            <li>
              <Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Get Vastuscore™ & Rating for all site/plan combinations:</Typography>
              With our tools, you can easily acquire Vastu scores and ratings for various site and plan combinations, enabling you to assist clients in selecting a plan with stronger Vastu compliance.
            </li>
            <li>
              <Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Find if you can Add Vastu Premium:</Typography>
              For properties that exhibit stronger Vastu compliance, you can command a competitive price by including a Vastu premium.
            </li>
            <li>
              <Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Help Clients Choose Plan:</Typography>
              Our tool not only allows you to evaluate Vastu scores and ratings for various plans but also helps you guide clients in selecting a plan with stronger Vastu compliance.
            </li>
            <li>
              <Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Increase Sales by Showcasing Positive Attributes of a property:</Typography>
              With our platform, you can easily highlight the positive attributes of a property, speeding up the sales process and attracting more potential buyers.
            </li>
            <li>
              <Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Share Detailed Vastu Reports via Shareable Links:</Typography>
              We offer detailed Vastu reports that can be shared with co-agents or potential buyers through shareable links.
            </li>
            <li>
              <Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Include in Ads and make your property stand out:</Typography>
              In addition to these, we also provide you with links to customized Vastu reports that can be included in your ads, giving you a distinct edge in the market.
            </li>
            <li>
              <Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Enhance Marketing Opportunities and Customer Satisfaction:</Typography>
              In summary, these features can help you sell your properties faster, attract more interested buyers, and make your real estate transactions a success.
            </li>
          </ul>
        </Typography>


        <br/>
        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>Would you prefer us to make this entire process very simple for you?</Typography>
       <Typography component="div" align='justify' variant="body2">
        Send us a note via our <Link to='/contactus'>Contact Us</Link> form and we will get in touch with you.
       </Typography>

    <br/>
    <br/>

        <Typography variant='h4' align='center' sx={{ width: '100%', maxWidth: '600px'}} p='10px' mb={5}>How It Works</Typography>
        <Paper elevation={0} sx={{display: isMobile ? 'relative':'flex', backgroundColor: 'white'}} style={{ width: '100%', justifyContent: 'center' }} p='10px'>
        <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 400, backgroundColor: 'white'}}>
        <CardActionArea>
        <CardMedia
          component="img"
          image={map}
          height={200}
          width={200}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          alt="Buyer/Seller"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Give us the neighborhood map.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            We will analyse the plot shapes and directions to give recommendation on lot premiums based on Vastu analysis
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{ width: '100%', justifyContent: 'center' }}>
       
      </CardActions>
      </Card>
      <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 400, backgroundColor: 'white' }}>
        <CardActionArea>
        <CardMedia
          component="img"
          image={floorplans}
          height={200}
          width={200}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          alt="Agents"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Give us the proposed floor plans
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Let our engine analyse the various aspects of the home based on floor plan in combination with plot details to get a Vastuscore™.
          </Typography>
        </CardContent>
      </CardActionArea>
        <CardActions style={{ width: '100%', justifyContent: 'center' }}>
        </CardActions>
        </Card>
        <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 400, backgroundColor: 'white' }}>
        <CardActionArea>
        <CardMedia
          component="img"
          image={report}
          height={200}
          width={200}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          alt="Builder"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Share Detailed Report
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Out reporting tool lets you shared the details report and create comparison report for the different homes.
          </Typography>
        </CardContent>
      </CardActionArea>
        <CardActions style={{ width: '100%', justifyContent: 'center' }}>
        
        </CardActions>
        </Card>
        </Paper>
    </Box>


    
    )
}

export default Builders;