import { Alert, AlertTitle, Button, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ImageMapper from 'react-img-mapper';
import { useMediaQuery } from 'react-responsive';
import vs from '../assets/images/focus.png';
import deadend from '../assets/plot_street_focus/psf_dead_end.png'
import CardImage from './CardImage';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {Dialog, DialogActions, DialogTitle, Box, Slide} from '@mui/material';
import CardImageSelect from './CardImageSelect';
import MarkdownViewerFromFile from './MarkdownViewerFromFile';
import ScrollTopFab from './ScrollToTopFab';
import { FaQuestionCircle } from 'react-icons/fa';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ModalPolarImage = ({ displayName, id, options, setOpenModal, setDropDownValue, selectedValue }) => {
  const [roads, setRoads] = useState([]);
  const [selectedRoad, setSelectedRoad] = useState([]);
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  const [reload, setReload] = useState(false);
  const [value, setValue] = useState(selectedValue);
  const [openHelp, setOpenHelp] = useState(false);
  const [optionsList, setOptionsList] = useState(options);
  const [map, setMap] = useState([]);
  const [deadEndImage, setDeadEndImage] = useState('../assets/plot_street_focus/psf_dead_end.png');
  const [deadEndArea, setDeadEndArea] = useState([]);
  const [selectedArray, setSelectedArray] = useState([]);
  const [selectedDisplayName, setSelectedDisplayName] = useState([]);
  const [coords, setCoords] = useState([{
    areas: [
      {  
      "coords": [
        285,65,
        327,65,
        327,155,
        285,155
      ]
     },
     {
      "coords": [
        367,65,
        410,65,
        410,155,
        369,155
      ]
     },
     {
      "coords": [
        492,74,
        523,104,
        458,168,
        426,137
      ]
     },
     
     {
      "coords": [
        533,181,
        533,223,
        442,226,
        442,181
      ]
     },
     {
      "coords": [
        440,271,
        534,271,
        534,316,
        441,316
      ]
     },
     {
      "coords": [
        533,362,
        535,402,
        442,404,
        442,362
      ]
     },
     {
      "coords": [
        422,444,
        450,414,
        516,479,
        487,509
      ]
     },
     {
      "coords": [
        366,425,
        410,425,
        410,514,
        366,514
      ]
     },
     {
      "coords": [
        324,423,
        284,423,
        284,515,
        324,515        
      ]
     },
     {
      "coords": [
        240,422,
        197,422,
        197,516,
        239,516
      ]
     },
     {
      "coords": [
        156,417,
        184,450,
        116,509,
        90,476
      ]
     },
     {
      "coords": [
        168,363,
        168,404,
        80,404,
        79,361
      ]
     },
     {
      "coords": [
        169,271,
        170,317,
        78,316,
        78,275
      ] 
     },
     {
      "coords": [
        168,181,
        170,225,
        79,224,
        79,182
      ]
     },
     {
      "coords": [
        148,170,
        83,107,
        110,78,
        177,142
      ]
     },
     {
      "coords": [
        196,63,
        238,62,
        239,158,
        197,158
      ]
     },
    ]
  }])

  useEffect(() => {
    //reload
    var newSelectedArray = selectedArray;
    if (Array.isArray(newSelectedArray) && newSelectedArray.length === 0) {
      if (value && value != undefined) {
        newSelectedArray = value.toString().split(',');
        setSelectedArray(newSelectedArray);
      }
    }

    var areasArray = [];
    var roadsArray = [];
    {options.map((item, i) => {
          var area = {};
          var road = {};
          area.id = i;
          area.title = item.matchingConditionDisplayName;
          area.name = item.matchingConditionCode;
          area.shape = "poly";
          area.fillColor = "#00ff194c";
          area.strokeColor = "black";

          
          if (i < 16) {
            area.coords = coords[0].areas[i].coords;
            areasArray.push(area);
          } else {
            setDeadEndArea(area);
          }     
          if (newSelectedArray && newSelectedArray != undefined && newSelectedArray != '') {
            if (newSelectedArray.includes(item.matchingConditionCode)) {
              area.preFillColor = true;
              //area.active = true;
              roadsArray.push(area);
            }
          }
    })}
    var map = {}
    map.areas = areasArray;
    map.name = "my-map";
    setMap(map);
    setRoads(roadsArray);
  }, [reload]);
  
  
  function handleClick(area) {
    
    if (roads.includes(deadEndArea)) {
      const index = roads.indexOf(deadEndArea);
      roads.splice(index, 1); 
    }

    if (roads.length == 1 && roads[0].name == 'psf_dead_end') {
      roads.splice(0, 1); 
    }

    if (!containsCode(roads, area.name)) {
      roads.push(area);
      setRoads(roads);
      setSelectedRoad(area);
    } 
  };

  function containsCode(roads, name) {
    for (var i = 0; i < roads.length; i++) {
      if (roads[i].name == name) {
        return true;
        break;
      }
    }
    return false;
  }
  function getIndex(roads, name) {
    for (var i = 0; i < roads.length; i++) {
      if (roads[i].name == name) {
        return i;
        break;
      }
    }
    return -1;
  }

  function handleDndClick() {
    for (let i = roads.length-1; i >= 0; i--) {
      roads.splice(i, 1);
    }
    roads.push(deadEndArea);
    setRoads(roads);
    setReload(!reload)
    setSelectedRoad(deadEndArea)
    setValue(deadEndArea.name);
    
  };

  function clearSelection() {
    for (let i = roads.length-1; i >= 0; i--) {
      roads.splice(i, 1);
    }
    setValue([]);
    setRoads(roads);
    setReload(!reload)
    setSelectedRoad([])
  }

  function removeItem(item) {
    if(roads.includes(item)) {
      const index = roads.indexOf(item);
      if (index > -1) { // only splice array when item is found
        roads.splice(index, 1); // 2nd parameter means remove one item only
        setSelectedRoad([]);
        var newArray = [];
        roads.map((item, i) => {
          newArray.push(item.name);
        });
        setSelectedArray(newArray);
        selectedValue = newArray.join(",");
        setValue(selectedValue);
        setReload(!reload)
      }
    }
  }

  function submitSelection() {
    var selection = "";
    if (roads.length == 0) {
      //you have not made any selection
    } else {
      for (let i=0; i < roads.length; i++) {
        if (i == 0) {
          selection = selection + roads[i].name;
        } else {
          selection = selection + "," + roads[i].name;
        }
      }
    }
    setDropDownValue(id, selection);
    setOpenModal(false);
  }

  function handleClose() {
    setOpenModal(false);
    if (!selectedRoad) {
      setSelectedRoad([]);
    } else {
      setSelectedRoad(deadEndArea);
    }
    
  }

  function openHelpPlotFocus() {
    setOpenHelp(true);
  }

  function handleCloseHelp() {
    setOpenHelp(false);
  }
  
    return (
      <Dialog
        fullScreen
        open={true}
        scroll={"paper"}
        onClose={handleClose}
        TransitionComponent={Transition}
        align="center"
      >
        <DialogActions>
          <Button onClick={handleClose}>Close Window X</Button>
        </DialogActions>
        
        <DialogTitle id="scroll-dialog-title" align='center'>Select {displayName}
       {/*  <Typography variant='subtitle1'>&nbsp;<FaQuestionCircle onClick={() => openHelpPlotFocus()}/></Typography> */}
        <br></br>
        <Button onClick={submitSelection} variant="contained" size='small'>Submit</Button>   
        <Button onClick={clearSelection}  size='small' disabled={roads.length == 0}>Clear All</Button>  
        </DialogTitle>
        
        <Box style={{display: isMobile ? 'relative': 'flex', width:"100%", align:'right'}} justify="right">
        <Card sx={{ width: isMobile ? "70%": "35%", maxWidth: "300", minWidth: "300"}}>
        <CardActionArea>
        <CardMedia
          onClick={handleDndClick}
          component="img"
          image={deadend}
          alt={options[16].matchingConditionDisplayName}
          id={options[16].matchingConditionCode}
        />
        <CardContent>
          <Typography variant="body3" color="text.primary">
            Select this image if your house is a Cul de Sac house or a dead end house.
          </Typography>
        </CardContent>
      </CardActionArea>
      </Card>  
      {map ? <Card sx={{ width: "70%" }}>
      <CardActionArea>
      {map && map.areas && map.areas.length > 0 ?   
      <ImageMapper
      src={vs}
      width={isMobile ? 285 : 450} 
      imgWidth={610}
      map={map}
      fillColor={"#00ff194c"}
      stayHighlighted={true}
      stayMultiHighlighted={true}
      onClick={handleClick}/> : ''}
      
        <CardContent>
          <Typography variant="body3" color="text.primary">
            Hover over the road and click to select. Select all the roads that hits the house from any direction. 
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>: ''}
      </Box>
      <br></br>
      <Box style={{display: 'relative', width:"100%", align:'center'}} justifyContent="center" alignItems="center">
      <Alert severity='info' align='center' justifyContent='center' alignItems="center">
        
        <AlertTitle>Selected Street Focus</AlertTitle>
        {roads.length > 0 && (
        
        <ul>
          {roads.map((item, i) => {
            return <li key={item.name} align='left'>{item.title}<Button size='small'
            onClick={() => removeItem(item)}>
            X
          </Button></li>;
          })}
        </ul>

        )}    

      </Alert>
      </Box>  
      <Dialog
                  maxWidth={"md"}
                  open={openHelp}
                >
                  <DialogContent>
                        <MarkdownViewerFromFile mdFilePath="./help/reports_help.md" />
                    </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseHelp()}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>
      </Dialog>
    );
}


  export default ModalPolarImage


