import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import RequestService from '../RequestService';
import validator from 'validator';
import {userLogin, userLogout, selectCurrentUser, selectCurrentToken, userCredits} from '../authSlice'
import { toast, ToastContainer } from 'react-toastify';
import { FaEye } from 'react-icons/fa';
import { selectCurrentEmail } from '../authSlice';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import usePageView from '../components/usePageView'; // Import the custom hook

export function ChangePassword() {
    const [registerMessage, setRegisterMessage] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let [callParams, setCallParams] = useState([]);
    const [message, setMessage] = useState([]);
    const currentToken = useSelector(selectCurrentToken);
    const navigate = useNavigate();
    const params = [];
    const userEmail = useSelector(selectCurrentEmail);
    usePageView('Change Password');

    for(let entry of searchParams.entries()) {
      params.push(entry);
    }

    function toggleField (elementId) {

        var x = document.getElementById(elementId);
        if (x) {
            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        }
        
    }

    function changePassword() {
        var newPassword = document.getElementById("newpassword").value;
        var userName = document.getElementById("userName").value;
        var oldPassword = document.getElementById("oldPassword").value;
        var messages = [];
        var proceed = true;
        if (validator.isEmpty(oldPassword)) {
            messages.push("Old password cannot be empty");
            proceed = false;
        }

        if (validator.isEmpty(userName)) {
            messages.push("Username cannot be empty");
            proceed = false;
        }

        if (validator.isEmpty(newPassword)) {
            messages.push("New password cannot be empty");
            proceed = false;
        }

        if (proceed) {
            RequestService.changePassowrd(JSON.stringify({
                oldPassword: oldPassword,
                email: userName,
                newPassword: newPassword
             }), currentToken).then((response) => {
                if (response.status == 200) {
                    toast.success("Password successfully changed");
                    toast.onChange(v => {
                        if(v.status === "removed"){
                         // run your callback
                         navigate('/login');
                        }
                      })
                    
                } else  {
                    messages.push("An error occurred, unable to change password at this time.");
                    setShowMessage(true);
                    setRegisterMessage(messages);
                }
              })
              .catch((err) => {
                console.log(err);
                messages.push("An error occurred, unable to change password at this time.");
                    setShowMessage(true);
                    setRegisterMessage(messages);
            });  
        } else {
            setRegisterMessage(messages);
        }
    }

    return (
        <div className='Scrollable-div'>
        <ToastContainer position='top-center' theme='dark' />    
        <fieldset>
        <legend>Reset Password</legend> 
        {registerMessage.length > 0 && (
        <div className="alert alert-secondary mt-2" role="alert">
          <ul>
            {registerMessage.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </div>
      )}   
        <table className='App-table'>
            <tr>
                <td>
                    <label>User Name:</label>
                </td>
                <td>
                    <input type="text" id="userName" name="userName" value={userEmail} readOnly disabled/>
                </td>
            </tr>
            <tr>
                <td>
                    <label>Old Password:</label>
                </td>
                <td>
                    <input type="password" id="oldPassword" name="oldPassword"/>
                    <FaEye id="showPassword" onClick={() => toggleField("oldPassword")}/>
                </td>
            </tr>
            <tr>
                <td>
                    <label>New Password:</label>
                </td>
                <td>
                    <input type="password" id="newpassword" name="newpassword"/>
                    <FaEye id="showPassword" onClick={() => toggleField("newpassword")}/>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <Button variant='contained' size='small' onClick={changePassword}>Change Password</Button>
                </td>
            </tr>
        </table>
         
        </fieldset>
        </div>
    
    )
}

export default ChangePassword;