import React from 'react';
import Image from './Image';
import CardImage from './CardImage';
import "./Modal.css";
class ModalImage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
      this.state.id = this.props.id
      this.state.options = this.props.options;  
      this.handleChange = this.handleChange.bind(this);
      this.setOpenModal = this.props.setOpenModal;
      this.setDropDownValue = this.props.setDropDownValue;
    }
  
    handleChange(event) {
      this.setState({value: event.target.id});
      document.getElementById(this.state.id).value = event.target.id;
      //this.setDropDownValue(this.state.id, event.target.id);
      this.setOpenModal(false);
    }
  
    render() {
      return (
        <div className="modalBackground">
        <div className="modalContainer">
          <div className="titleCloseBtn">
            <button
              onClick={() => {
                this.setOpenModal(false);
              }}
            >
              X
            </button>
          </div>
          <div className="title">
            <h1>Select image best fits your Plot shape</h1>
          </div>
          <div className='App-image'>              
              {
                this.state.options.map((optionCode) => {
                    return <div>
                      <button className="imageButton" onClick={this.handleChange} key={optionCode.matchingConditionCode}>
                        <CardImage id={optionCode.matchingConditionCode} filePath={optionCode.imagePath} displayName={optionCode.matchingConditionDisplayName}></CardImage>
                      </button>
                      </div>
                })
              }
            </div>
        </div>
      </div> 
    );
    }
  }

  export default ModalImage