// Faq.js
import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Grid, List, ListItem, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import faqData from './faqData';
import usePageView from '../components/usePageView'; // Import the custom hook

export function Faq() {
usePageView('FAQ');

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={10} md={8}>
        <div>
          <Typography variant="h5" gutterBottom>
            Frequently Asked Questions
          </Typography>
          <br />

          {faqData.map((sectionData, index) => (
            <Accordion key={index} style={{ marginBottom: '10px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`faq-content-${index}`}
                id={`faq-header-${index}`}
              >
                <Typography variant="h6">{sectionData.section}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {sectionData.faqs.map((faq, faqIndex) => (
                  <Accordion key={faqIndex} style={{ marginTop: '10px' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`faq-${index}-${faqIndex}-content`}
                      id={`faq-${index}-${faqIndex}-header`}
                    >
                      <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{`Q: ${faq.question}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{`A: ${faq.answer}`}</Typography>
                      {faq.additionalInfo && (
                        <List>
                          {faq.additionalInfo.map((info, infoIndex) => (
                            <ListItem key={infoIndex}>
                              <ListItemText>{`- ${info}`}</ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}

export default Faq;
