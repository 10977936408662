import React, { useEffect } from 'react';
import {userLogout} from '../authSlice'
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

const LogoutListener = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleTokenExpiry = () => {
      // Clear the token
      // Dispatch logout action
      dispatch(userLogout());
      // Redirect to login
      navigate('/login');
    };

    window.addEventListener('token-expired', handleTokenExpiry);

    return () => {
      window.removeEventListener('token-expired', handleTokenExpiry);
    };
  }, [dispatch]);

  return null; // This component does not render anything
};

export default LogoutListener;