import React, { useState } from 'react';
import { Box, Button, ButtonGroup, Card, CardActionArea, CardActions, CardContent, CardHeader, Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import GaugeChart from 'react-gauge-chart';
import { styled } from '@mui/material/styles';
import ShareToggle from './ShareToggle';
import ReportActions from './ReportActions';
import ShareToggleCustom from './ShareToggleCustom';
import ReportActionsCustom from './ReportActionsCustom';
import CustomizeReport from './CustomizeReport';

const CustomCardHeader = styled(CardHeader)({
    '& .MuiCardHeader-title': {
      fontSize: '16px', // Adjust the font size as needed
    },
  });

  const buttons = [
    <Button key="one">Comments</Button>,
    <Button key="two">Send Back</Button>,
  ];  
  
const ReportCard = ({ report, loadAllReports, loadReport, setShowEdit, handleRowSelected, selectedRows, isCustom, statusDisplay, handleAddViewComments, handleSendBack, handleProvideClarification }) => {
    const [selected, setSelected] = useState(selectedRows.some(obj => obj.queryId === report.queryId))
    function onChange(report, checked) {
        setSelected(checked)
        handleRowSelected(report, checked);
    }

    
    return (
    
  <Card variant="outlined" sx={{justifyContent:"center", alignContent:"center",  bgcolor: selected ? 'lightblue': 'while' }}>
    <CardContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection:"column" }}>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignContent:"center", alignItems: 'center'}}>
      <Typography variant='h6'>{report.title}</Typography> 
      <GaugeChart id="score-chart"  style={{ height: "150px" }}
                            nrOfLevels={25} 
                            percent={report.lastScore/100}
                            textColor="black"
                            formatTextValue = {value => "Vastuscore™"+ ' : ' + value}
                            colors={['#EA4228', '#F5CD19', '#5BE12C']}
                        />
                            
      </Box>
      {isCustom ?<CardActions>
         <CustomizeReport queryId={report.queryId} variant='body2'>{report.lastScore}</CustomizeReport>
        <ShareToggleCustom shareQueryId={report.sharedQueryId} queryId={report.queryId} loadAllReports={loadAllReports}/>
        <Checkbox color='default' onChange={(e) => onChange(report, e.target.checked)} checked={selected}/>
        <ReportActionsCustom shareQueryId={report.sharedQueryId} queryId={report.queryId} viewFunction={loadReport} editFunction={setShowEdit} reportName={report.title}/>
      </CardActions>:
      <CardActions>
        <Stack direction="column">
        <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
            
            <ShareToggle shareQueryId={report.sharedQueryId} queryId={report.queryId} loadAllReports={loadAllReports}/>
            <Tooltip title="Compare" placement="top" arrow>
                <Checkbox color='default' onChange={(e) => onChange(report, e.target.checked)} checked={selected}/>
            </Tooltip>
            <ReportActions shareQueryId={report.sharedQueryId} queryId={report.queryId} viewFunction={loadReport} editFunction={setShowEdit} reportName={report.title}/>
       </Box>    
        <Box>
            {statusDisplay(report, handleAddViewComments, handleSendBack, handleProvideClarification)}
        </Box>
        </Stack>
      </CardActions>}
    </CardContent>
  </Card>
);
}

export default ReportCard;