import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {AddressElement, PaymentElement} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../authSlice';
import { useMediaQuery } from 'react-responsive';
const CheckoutForm = ({subTotal, couponCode, discountAmount, discountedFinalAmount, productType, quantity, intent}) => {
  const stripe = useStripe();
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  const elements = useElements();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentToken = useSelector(selectCurrentToken);
  const addressOptions = {
    mode: 'billing',
    defaultValues: {
      name: ' ',
      address: {
        line1: '  ',
        line2: '  ',
        city: '  ',
        state: '  ',
        postal_code: '  ',
        country: '  ',
      },
    }
  };

  function goBack() {
    navigate('/');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:`${window.location.origin}/paymentSuccess?token=` + currentToken
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };


  return (
    <Box sx={{display:'flex', width: isMobile? "90%": "70%", flexDirection: isMobile ? 'column': 'row'}}>

    <Box sx={{width:isMobile?"90%": "30%", minWidth:300}} m="auto" ml={"auto"}>
    
    <Card sx={{color: 'white', background: 'linear-gradient(to right bottom, #1976d2, #52afff)'}} ml={10} mr={10} mt={10} mb={10} variant="outlined">
    <CardContent>
      <Box align="center">
      <Typography variant='h6'>Purchase Summary</Typography>
      <Typography variant='body2' align='center'>Item: {productType == 'SINGLE_CREDIT'? '1 Credit ($9.99)': '5 Credits ($39.95)'} </Typography>
      <Typography variant='body2' align='center'>Quantity: {quantity}</Typography>
      <br/>
      {couponCode !== null && couponCode !== "" && <Typography variant='body1' align='center'>Subtotal: ${subTotal.toFixed(2)}</Typography>}
      {couponCode !== null && couponCode !== "" && <Typography variant='body1' align='center'>Discount (applied coupon <span style={{ color: 'lightblue' }}>{couponCode}</span>): <span style={{ color: '#00ff00' }}>-${discountAmount.toFixed(2)}</span></Typography>}
      {couponCode !== null && couponCode !== "" && <br></br>}
      </Box>

      <Typography variant='caption' align='center' sx={{color:'lightgreen'}}>Total Amount: <Typography variant='h3'>${discountedFinalAmount.toFixed(2)}</Typography></Typography>
      <Button  onClick={goBack} size="small" variant='contained'>Cancel</Button>
    </CardContent>
    </Card>  
    </Box>
    <br></br>
    <Box sx={{width:isMobile?"50%": "60%", minWidth: 300}} m="auto" ml={"auto"}>
      <AddressElement options={addressOptions}/>
      <PaymentElement id="payment-element"/>
      <br></br>
      <Button disabled={isLoading || !stripe || !elements} id="submit" onClick={handleSubmit} variant='contained' size='small'>
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </Button>
      <br></br>
      {message && <div id="payment-message">{message}</div>}
     </Box>
    </Box>    
  );
};

export default CheckoutForm;