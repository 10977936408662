import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DataTable, { createTheme, defaultThemes } from 'react-data-table-component';
import { Typography, Box, Button } from '@mui/material';
import config from '../config';
import { selectCurrentToken } from '../authSlice';
import { LinearProgress } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { Document, Page, pdfjs } from 'react-pdf';
import RequestService from '../RequestService';
import UserSelectionModal from '../components/UserSelectionModal';
import CommentModal from '../components/CommentModal';

createTheme('light', {
  rows: {
    fontSize: '15px',
  },
});

const customStyles = {
  header: {
    style: {
      minHeight: '56px',
    },
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      backgroundColor: '#1976d2',
      borderTopWidth: '1px',
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
      whiteSpace: 'pre-line',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
      whiteSpace: 'pre-line',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      color: defaultThemes.default.highlightOnHover.text,
      backgroundColor: '#97c1ec;',
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
      borderBottomColor: defaultThemes.default.background.default,
      outlineStyle: 'solid',
      outlineWidth: '1px',
      outlineColor: defaultThemes.default.background.default,
    },
  },
};

const Administration = () => {
  const token = useSelector(selectCurrentToken);
  const [users, setUsers] = useState([]);
  const [reports, setReports] = useState([]);
  const [bulkReports, setBulkReports] = useState([]);
  const [pendingUsers, setPendingUsers] = useState(true);
  const [pendingReports, setPendingReports] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [displayUsersTable, setDisplayUsersTable] = useState(true);
  const [displayReportsTable, setDisplayReportsTable] = useState(false);
  const [displayBulkReportsTable, setDisplayBulkReportsTable] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [downloadQueryId, setDownloadQueryId] = useState(null);
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [messageText, setMessageText] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [selectedStatusCode, setSelectedStatusCode] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showUserSelectionModal, setShowUserSelectionModal] = useState(false);
  const [showCommentsForm, setShowCommentsForm] = useState(false);
  const [commentsQueryId, setCommentsQueryId] = useState(null);

  // Custom cell renderer
  const TruncatedTextCell = ({ text }) => {
    let truncatedText = text;

    return (
      <div className="react-data-table-cell-wrap">
        {truncatedText}
      </div>
    );
  };
  const usersColumns = [
    { name: 'ID', selector: row => row.id, sortable: true, width: '70px' },
    { name: 'Email', selector: row => row.email, sortable: true, width: '220px' },
    { name: 'Name', selector: row => row.name, sortable: true, width: '200px' },
    { name: 'Verified', selector: row => row.emailVerified, sortable: true, width: '100px' },
    { name: 'Type', selector: row => row.loginType, sortable: true, width: '100px' },
    { name: 'C Type', selector: row => row.customerType, sortable: true, width: '100px' },
    { name: 'Failed', selector: row => row.failedAttemptsCount, sortable: true, width: '100px' },
    { name: 'Creation Date', selector: row => row.creationDate, sortable: true, width: '250px' },
    { name: 'Last Modified', selector: row => row.lastModified, sortable: true, width: '250px' },
  ];

  const bulkReportsColumns = [
    { name: 'Bulk Query ID', selector: row => row.bulkQueryId, sortable: true, width: '13%' },
    { name: 'Title', selector: row => row.title, sortable: true, width: '20%' },
    { name: 'User ID', selector: row => row.userId, sortable: true, width: '12%' },
    { name: 'Comments', selector: row => row.comments, sortable: false, width: '25%', cell: row => <TruncatedTextCell text={row.comments} />},
    { name: 'Creation Date', selector: row => row.creationDate, sortable: true, width: '15%' },
    { name: 'Donwload Files', selector: row => <Button size='small' onClick={() => downloadZippedImages(row.bulkQueryId)}>Download Files</Button>, width:"15%", center: true}
  ];


  const reportsColumns = [
    { name: 'Query ID', selector: row => row.queryId, sortable: true, width: '70px' },
    { name: 'Title', selector: row => row.title, sortable: true, width: '300px' },
    { name: 'User ID', selector: row => row.userId, sortable: true, width: '100px' },
    { name: 'Last Score', selector: row => row.lastScore, sortable: true, width: '120px' },
    { name: 'Status', selector: row => row.statusName, sortable: true, width: '200px' },
    { name: 'Assigned to User', selector: row => row.assignedToUserId, sortable: true, width: '200px' },
    { name: 'Submitted Date', selector: row => row.submittedDate, sortable: true, width: '250px' },
    { name: 'Assigned Date', selector: row => row.assignedDate, sortable: true, width: '250px' },
    { name: 'Target Completion Date', selector: row => row.targetCompletionDate, sortable: true, width: '250px' },
    { name: 'Completed Date', selector: row => row.completedDate, sortable: true, width: '250px' },
    { name: 'Total Time Spent (Mins)', selector: row => row.totalTimeSpendInMins, sortable: true, width: '150px' },
    { name: 'SLA Met', selector: row => row.slaMet, sortable: true, width: '150px', cell: (row) => (row.slaMet === false ? 'No' : row.slaMet ? 'Yes' : ''),
},
    { name: 'Shared Query ID', selector: row => row.sharedQueryId, sortable: true, width: '320px' },
    { name: 'Shared Custom Query ID', selector: row => row.sharedCustomQueryId, sortable: true, width: '320px' },
    {
      name: 'View Report',
      cell: (row) => (
        <a
          href="#"
          onClick={() => handleViewReport(row.queryId)}
          style={{ color: 'blue', cursor: 'pointer' }}
        >
          View Report
        </a>
      ),
      button: true,
    },
       {
         name: 'Actions',
         selector: (row) => (
           <Box display="flex" alignItems="center">
             <Button
               onClick={() => handleAssignButtonClick(row.queryId)}
               variant="contained"
               size="small"
               style={{ marginRight: '5px', marginBottom: '10px' }}
             >
               Assign
             </Button>

             {row.assignedToUserId ? (
               <Button
                 onClick={() => handleUnassignButtonClick(row.queryId)}
                 variant="contained"
                 size="small"
                 style={{ marginRight: '5px', marginBottom: '10px' }}
               >
                 Unassign
               </Button>
             ) : null}

             <Button
               onClick={() => handleComments(row.queryId)}
               variant="contained"
               size="small"
               style={{ marginRight: '5px', marginBottom: '10px' }}
             >
               Comments
             </Button>
           </Box>
         ),
         width: '400px',
         center: false,
       }

  ];

useEffect(() => {
  if (displayUsersTable) {
    loadAllUsers();
  } else if (displayReportsTable) {
    loadAllReports();
  } else {
    loadBulkReports();
  }
}, [displayUsersTable, selectedStatusCode]);

    const handleComments = (queryId) => {
      setCommentsQueryId(queryId);
      setShowCommentsForm(!showCommentsForm);
    };

const handleAssignButtonClick = (queryId) => {
    // Fetch the list of users
    loadAllUsers();

    setDownloadQueryId(queryId);

    // Open the modal
    setShowUserSelectionModal(true);
  };

    const handleAssignButtonClickComplete = (processorId) => {
      if (processorId) {
        // Call the assignByAdmin endpoint with queryId and processorId
        RequestService.assignByAdmin({ queryId: downloadQueryId, processorId: processorId }, token)
          .then((response) => {
            // Handle the response as needed
            alert('Query assigned successfully.');
           setShowUserSelectionModal(false);

            loadAllReports();
          })
          .catch((error) => {
            // Handle errors
            alert('Error assigning query.');
          });

      } else {
        // Handle the case where no processor is selected
        console.error('No processor selected.');
      }
    };

  function loadAllUsers() {
    setPendingUsers(true);
    fetch(config[process.env.NODE_ENV].backendUrl + '/getAllUsersForAdmin', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 403) {
          // Handle unauthorized access
          console.log('Access is denied, token may have expired.');
        }
      })
      .then((data) => {
        setPendingUsers(false);
        setUsers(data || []); // Ensure data is an array or default to an empty array

      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const downloadZippedImages = (queryId) => {
    RequestService.getBulkSubmitRequestImages(token, queryId).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', queryId + '_request_images.zip'); // Set the file name for download
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up
    })
    .catch(error => {
      console.error('There was a problem with the download:', error);
    });
  }
  

  function loadBulkReports() {
    setPendingReports(true);
    const endpoint = `/getAllBulkReports`;
    fetch(config[process.env.NODE_ENV].backendUrl + endpoint, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: 'Bearer ' + token,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status === 403) {
        // Handle unauthorized access
        console.log('Access is denied, token may have expired.');
      }
    })
    .then((data) => {
      setPendingReports(false);
      setBulkReports(data);
    })
    .catch((err) => {
      console.log(err.message);
    });

  }

  function loadAllReports() {
    setPendingReports(true);

  const endpoint = selectedStatusCode
    ? `/userQueriesForAdminByStatusCode/${selectedStatusCode}`
    : '/userQueriesForAdmin';

      fetch(config[process.env.NODE_ENV].backendUrl + endpoint, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 403) {
          // Handle unauthorized access
          console.log('Access is denied, token may have expired.');
        }
      })
      .then((data) => {
        setPendingReports(false);
        setReports(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const handleLoadAllReports = () => {
    setDisplayUsersTable(false);
    setDisplayBulkReportsTable(false);
    setDisplayReportsTable(true);
  };

  const handleLoadAllImages = () => {
    setDisplayUsersTable(false);
    setDisplayBulkReportsTable(false);
    setDisplayReportsTable(false);
  };

  const handleLoadAllBulkReports = () => {
    setDisplayUsersTable(false);
    setDisplayBulkReportsTable(true);
    setDisplayReportsTable(false);
    loadBulkReports();
  };

    const handleUnassignButtonClick = (queryId) => {
      // Call the unassignByAdmin endpoint with queryId
      RequestService.unAssignFromProcessorForAdmin({ queryId }, token)
        .then((response) => {
          // Handle the response as needed
          alert('Request unassigned successfully.');
          loadAllReports();
        })
        .catch((error) => {
          // Handle errors
          alert('Error unassigning Request.');
        });
    };

  const handleLoadAllUsers = () => {
    setDisplayUsersTable(true);
    setDisplayReportsTable(false);
    setDisplayBulkReportsTable(false);
    loadAllUsers();
  };

    const handleViewReport = (queryId) => {
      fetchReport(queryId);
    };

const fetchReport = (queryId) => {
  // Set loading state before making the request
  setLoadingReport(true);
  setShowPdfViewer(false);

  // Clear any previous error messages
  setMessageText('');

  // Fetch the PDF report using the "/{queryId}/getReportForAdmin/" endpoint
  fetch(`${config[process.env.NODE_ENV].backendUrl}/${queryId}/getReportForAdmin/`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        // Check the Content-Type header
        const contentType = response.headers.get('content-type');

        if (contentType === 'application/pdf') {
          // The response contains a PDF
          return response.blob();
        } else {
          // Handle unexpected content type
          console.error('Unexpected Content-Type:', contentType);
          throw new Error('Unexpected content type');
        }
      } else if (response.status === 403) {
        // Handle unauthorized access
        throw new Error('Access is denied, token may have expired.');
      } else {
        // Handle other error statuses
        throw new Error('Error loading the report. Please try again.');
      }
    })
    .then((blob) => {
      const objectUrl = URL.createObjectURL(blob);

      setPdfUrl(objectUrl);
      setDownloadQueryId(queryId);
      setShowPdfViewer(true);

      setLoadingReport(false);
    })
    .catch((err) => {
      // Handle errors and set appropriate messages
      if (err.message) {
        setMessageText(err.message);
      } else {
        setMessageText('An error occurred while loading the report.');
      }

      setLoadingReport(false);
    });
};


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const downloadPdf = () => {
    // Check if pdfUrl is available and there is a valid queryId
    if (pdfUrl) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = `vastu_report.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // Handle the case where pdfUrl is not available or there is no queryId
      console.error("Cannot download PDF. pdfUrl is not available or no queryId.");
    }
  };

  const handleUserSelection = (userId) => {
    // Call the assignByAdmin endpoint with queryId and userId
    RequestService.assignByAdmin({ queryId: downloadQueryId, processorId: userId }, token)
      .then((response) => {
        // Handle the response as needed
        alert('Query assigned successfully.');
        loadAllReports();
      })
      .catch((error) => {
        // Handle errors
        alert('Error assigning query.');
      });

    // Close the modal
    setShowUserSelectionModal(false);
  };

    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  return (
    <Box style={{ display: 'relative', width: '80%' }} alignContent="left" mt={10}>
      <Button variant="contained" color="primary" onClick={handleLoadAllUsers} style={{ marginRight: '8px' }} size='small'>
        Load All Users
      </Button>
      <Button variant="contained" color="primary" onClick={handleLoadAllReports} style={{ marginRight: '8px' }} size='small'>
        Load All Reports
      </Button>
      <Button variant="contained" color="primary" onClick={handleLoadAllBulkReports} style={{ marginRight: '8px' }} size='small'>
        Load All Bulk Requests
      </Button>
      <br />
      <br />
      {displayReportsTable ?<select
        value={selectedStatusCode}
        onChange={(e) => setSelectedStatusCode(e.target.value)}
      >
        <option value="">All Statuses</option>
        <option value="ASSIGNED">ASSIGNED</option>
        <option value="AWAITING_INPUT">AWAITING_INPUT</option>
        <option value="COMPLETED">COMPLETED</option>
        <option value="SENT_BACK">SENT_BACK</option>
        <option value="SUBMITTED">SUBMITTED</option>
      </select>:''}
       {showCommentsForm && (
            <CommentModal setShowCommentsForm={setShowCommentsForm} queryId={commentsQueryId} currentToken={token} isProcessor={false} isAdmin={true}/>
          )}
      {showUserSelectionModal && (
        <UserSelectionModal
          handleAssignButtonClickComplete ={handleAssignButtonClickComplete}
          onClose={() => setShowUserSelectionModal(false)}
        />
      )}
      {displayUsersTable && (
        <div style={{ overflowX: 'auto' }}>

        <DataTable
          theme="light"
          title="All Users"
          columns={usersColumns}
          data={users}
          pagination
          highlightOnHover
          responsive
          pointerOnHover
          progressPending={pendingUsers}
          customStyles={customStyles}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
        />
        </div>

      )}
      {displayReportsTable && (
        <div style={{ overflowX: 'auto' }}>

        <DataTable
          theme="light"
          title="All Reports"
          columns={reportsColumns}
          data={reports}
          pagination
          highlightOnHover
          responsive
          pointerOnHover
          progressPending={pendingReports}
          customStyles={customStyles}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
        />
        </div>

      )}

      {displayBulkReportsTable && (
        <div style={{ overflowX: 'auto' }}>

        <DataTable
          theme="light"
          title="Bulk Requests"
          columns={bulkReportsColumns}
          data={bulkReports}
          pagination
          responsive
          pointerOnHover
          progressPending={pendingReports}
          customStyles={customStyles}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
        />
        </div>

      )}
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%" }}>
         {loadingReport ? (
           // Render a loading indicator or message instead of the download button
           <Box width={"60%"} display={"flex"} alignContent={"center"} justifyContent={"center"} flexDirection="column" align="center">
           <LinearProgress/>
           <p>Loading Report</p>
           </Box>
         ) : (
           // Render the download button only when the report is loaded
           <Box display={"flex"} alignContent={"center"} justifyContent={"center"} flexDirection="column" align="center" >
           {showPdfViewer ? (
           <div className="pdf-viewer-container">
             <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} mobile={true}>
             <Button size="small" sx={{width: isMobile? "50%": "20%"}} alignContent="center" align="center" justifyContent="center"
             variant="contained"
             color="primary"
             onClick={downloadPdf}
             disabled={!showPdfViewer}
           >
             Download Report
           </Button>
               {Array.from(new Array(numPages), (el, index) => (
                 <div key={`page_container_${index + 1}`} className="pdf-page">
                   <Page
                     key={`page_${index + 1}`}
                     pageNumber={index + 1}
                     renderTextLayer={false}
                     scale={isMobile ? 0.75 : 1.5}
                     renderAnnotationLayer={false}
                     className="pdf-page-content"
                   />
                 </div>
               ))}
             </Document>
           </div>
         ) : (
           <div className="loading-error-message">
              {messageText}
           </div>
         )}
           </Box>
         )}
       </Box>
    </Box>


  );
};

export default Administration;
