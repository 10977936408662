import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function VastuCalculatorPage() {

  const faqs = [
    {
      question: 'What is Vastu?',
      answer: 'Vastu, also known as Vastu Shastra, is an ancient Indian science that focuses on creating harmonious and balanced living environments. It involves principles and guidelines for designing spaces in a way that aligns with the natural forces and energies. Vastu emphasizes the arrangement of elements within a property to promote well-being, prosperity, and positive energy flow. The practice takes into account factors such as direction, layout, and the placement of rooms to create a balanced and auspicious living space.',
    },
    {
      question: 'Why do people look for homes that have stronger Vastu compliance?',
      answer: 'People look for homes with stronger Vastu compliance to ensure a harmonious and balanced living environment. A home with better Vastu alignment is believed to promote positive energies, which can contribute to improved health, prosperity, harmony, and success. By seeking properties with strong Vastu compliance, individuals aim to create a space that aligns with Vastu principles, fostering a positive and auspicious atmosphere for themselves and their families.',
    },
    {
      question: 'Why should I use VastuReport.com?',
      answer: 'Traditionally, evaluating Vastu requires reaching out to a Vastu expert, incurring costs and consuming time. If you want to compare multiple properties, it becomes more expensive and time-consuming. VastuReport.com changes this dynamic by providing instant Vastu scores, ratings, and detailed reports within seconds at a low cost. Our platform facilitates faster decision-making by offering:',
      additionalInfo: [
        'Instant Vastu scores, ratings, and detailed reports for swift decision-making.',
        'Accessibility to Vastu-enhanced living for individuals.',
        'Invaluable tools for builder sales agents and real estate agents to enhance service quality and cater to the growing demand for Vastu-compliant properties.',
        'Empower builders to create properties with stronger Vastu compliance, meeting the rising expectations for harmonious and balanced living spaces.',
      ]
    },
    {
      question: 'What evaluation do we do?',
      answer: 'We perform a thorough Vastu assessment of your property based on the details you provide. This includes a detailed report with a Vastuscore™ and rating, offering insights into the alignment and energies of each room or plot. The analysis focuses on aspects such as health, prosperity, harmony, and success in your home.',
    },
    {
      question: 'What does the Vastuscore™ represent?',
      answer: 'The Vastuscore™ is a numerical representation ranging from 0 to 100, gauging the level of Vastu compliance for any given property. A higher score indicates better adherence to Vastu principles.',
    },
    {
      question: 'How is the overall rating determined based on the Vastuscore™?',
      answer: 'The overall rating is determined according to the following table:\n\n\
      - Score 0-29: Least Favorable\n\
      - Score 30-49: Unfavorable\n\
      - Score 50-69: Average\n\
      - Score 70-89: Good\n\
      - Score 90 and above: Excellent\n\
      \nThis rating system helps you quickly assess the Vastu compliance level of a property.',
    },
    {
      question: 'Can I get a detailed report for my property?',
      answer: 'Yes, a Vastuscore™ calculation provides you a detailed Vastu report for your property, which includes the score, recommendations, and other relevant information.',
    }
    // ... (other FAQ items)
  ];


    const navigate = useNavigate();

  const calculateScore = () => {
      navigate('/myreports');
    }

  const [selectedValues, setSelectedValues] = useState({
    'Main Door': '',
    'Living Room/Hall': '',
    'Kitchen': '',
    'Master Bedroom': '',
    'Children\'s Bedroom': '',
    'Pooja': '',
    'Bathroom/Toilet': '',
    'Garage/Car Parking': '',
  });

  const handleSelectChange = (title, value) => {
    console.log('Handling select change:', title, value);

    setSelectedValues((prevValues) => ({
      ...prevValues,
      [title]: value,
    }));
  };

  useEffect(() => {
    console.log('Rendering YourComponent', selectedValues);
  }, [selectedValues]);

  const Dropdown = ({ title, options, selectedValue, onSelectChange }) => {
    const handleSelectChange = (event) => {
      onSelectChange(title, event.target.value);
    };

    return (
      <div style={{ marginBottom: '20px', marginRight: '20px', width: 'calc(35% - 10px)', display: 'inline-block' }}>
        <Typography variant="h3" sx={{ fontSize: '17px', fontWeight: 500, marginBottom: '12px' }}>
          {title}
        </Typography>
        <select
          style={{ width: '70%', fontSize: '16px' }}
          value={selectedValue || ''}
          onChange={handleSelectChange}
        >
          <option value="" disabled>Select an option</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>

      </div>
    );
  };

  return (
    <Box sx={{ width: '80%', margin: '30px auto', fontSize: '14px', textAlign: 'left'}}>
      <Helmet>
        <title>Vastu Calculator - Evaluate Your Home's Vastu Online</title>
        <meta name="description" content="Unlock the harmony of your home with our Vastu Calculator. Evaluate your home's Vastu Score and receive a detailed report with our advanced calculator." />
        <meta name="keywords" content="vastu calculator, online vastu check, check vastu online free, online vastu consultant free, vastu evaluator, home energy assessment, vastu analysis, home vastu test, vastu shastra score, vastu shastra calculator, vastu check online" />
        <link rel="canonical" href="https://vastureport.com/calculate-vastu" />
      </Helmet>
      <Typography variant="h1" sx={{ fontSize: '28px', fontWeight: 600, marginBottom: '16px' }}>
        Vastu Calculator - Evaluate Your Home's Vastu Score Online and Receive a Detailed Report
      </Typography>

    <Typography variant="body1" sx={{ marginBottom: '16px' }}>
      Unlock the harmony of your home with our Vastu Calculator. Evaluate your home's Vastu Score and receive a detailed report with our advanced calculator.
      Choose the options given below and find the overall Home <strong>Vastu compliance</strong>. The first calculation and detailed report are absolutely free! Discover the Vastu insights for your first home evaluation at no cost.
      Our our advanced <strong>Vastu Calculator</strong> goes beyond aesthetics, delving into the intricate energy patterns that shape your home.
    </Typography>

    <Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 500, marginTop:'40px' ,marginBottom: '12px' }}>
      Why <strong>Vastu Shastra</strong> Matters?
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: '16px' }}>
      <strong>Vastu Shastra</strong> is an ancient Indian architectural science that aligns living spaces with the natural forces to enhance positive energy flow.
      By understanding the balance of the five elements and directional influences, <strong>Vastu</strong> creates a harmonious environment that positively impacts your physical, mental, and spiritual well-being.
    </Typography>

    <Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 500, marginTop:'40px' ,marginBottom: '12px' }}>
      Why should you use a <strong>Vastu Shastra</strong> calculator?
    </Typography>
    <Typography>
    Welcome to our Vastu Calculator page, where we explore the intricate balance between interior design and the ancient principles of Vastu. When organizing your living spaces according to the principles of Vastu Shastra, it's essential to consider each room's orientation and purpose.
    <br></br><br></br>
    For instance, the living room, often the heart of the home, should ideally be situated in an area conducive to positive energy flow. Similarly, the dining room, where families gather to share meals and connect, benefits from a Vastu-compliant layout to enhance positive vibes during mealtimes.
    <br></br><br></br>
    Rooms like the study room and pooja room hold specific significance in Vastu Shastra, as they cater to focused activities and spiritual practices, respectively. By aligning these spaces with Vastu principles, you can create environments conducive to productivity and inner peace.
    <br></br><br></br>
    Our Vastu Calculator not only provides practical vastu tips but also empowers you to make informed decisions about your home design. Whether you're setting up a home office or creating a cozy family room, our platform offers invaluable insights to help you align your living spaces with the principles of Vastu.
    <br></br><br></br>
    By incorporating these principles into your interior design choices, you can transform your home into a sanctuary of positivity and well-being. Utilizing a Vastu score calculator can provide valuable insights into the overall Vastu compliance of your home.

    Ultimately, incorporating Vastu Shastra into your home design fosters positive energy flow and creates a harmonious atmosphere conducive to well-being and prosperity. Whether it's optimizing the layout of your living room or enhancing the tranquility of your pooja room, embracing Vastu principles can transform your home into a sanctuary of positivity and abundance.
 </Typography>

    <Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 500, marginTop:'50px',marginBottom: '12px' }}>
      How Our <strong>Vastu Calculator</strong> Works
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: '16px' }}>
      Our <strong>Vastu Calculator</strong> performs a meticulous evaluation, assessing key elements that influence the energy dynamics of your home:
    </Typography>

      <br></br>
      <Typography variant="h3" sx={{ fontSize: '22px', fontWeight: 500, marginLeft:'30px', marginBottom: '8px', borderBottom: '2px solid #1976D2', paddingBottom: '4px' }}>
        Room Placement Assessment
      </Typography>
      <ul>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Main Door:</strong> The entry point for energy; evaluate its location for optimal vibes.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Kitchen:</strong> The heart of the home; assess its position for overall well-being.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Living Room:</strong> The social hub; examine its layout for positive interactions.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Master Bedroom:</strong> Your sanctuary; analyze its arrangement for optimal energy flow.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Children's Bedroom:</strong> Nurture positivity; evaluate its setup for a harmonious living environment.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Guest Bedroom:</strong> Welcome positive vibes; assess the arrangement and energy flow.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Garage:</strong> Ensure positive energy flow; examine its placement.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Study Room:</strong> Foster learning; analyze its location for a conducive environment.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Pooja Room:</strong> Spiritual center; evaluate its placement for enhanced energy.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Dining Room:</strong> Balance and nourishment; assess its arrangement for a harmonious atmosphere.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" sx={{ marginBottom: '8px' }}>
            <strong>Toilet:</strong> Maintain energy harmony; examine its placement and design.
          </Typography>
        </li>
      </ul>
      <br></br>
        <Typography variant="h3" sx={{ fontSize: '22px', fontWeight: 500, marginLeft:'30px', marginBottom: '8px', borderBottom: '2px solid #1976D2', paddingBottom: '4px' }}>
         Plot Shape, Size and Direction Assessment
       </Typography>
       <Typography variant="body1" sx={{ marginBottom: '16px' ,marginLeft:'30px'}}>
         Consider the shape, size and direction of your plot to ensure alignment with Vastu principles for stability and positive energy.
       </Typography>
<br></br>
       <Typography variant="h3" sx={{ fontSize: '22px', fontWeight: 500, marginLeft:'30px', marginBottom: '8px', borderBottom: '2px solid #1976D2', paddingBottom: '4px' }}>
         Surroundings Examination
       </Typography>
       <Typography variant="body1" sx={{ marginBottom: '16px' ,marginLeft:'30px'}}>
         Assess the influence of natural elements and nearby water bodies on energy.
       </Typography>
<br></br>
       <Typography variant="h3" sx={{ fontSize: '22px', fontWeight: 500, marginLeft:'30px', marginBottom: '8px', borderBottom: '2px solid #1976D2', paddingBottom: '4px' }}>
         Streets Around the Plot Evaluation
       </Typography>
       <Typography variant="body1" sx={{ marginBottom: '16px' , marginLeft:'30px'}}>
         Evaluate the impact of roads surrounding your property on energy flow.
       </Typography>
<br></br>
<br></br>

    <Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 500, marginBottom: '12px' }}>
      <strong>Vastu Score Calculator</strong> - Assess Your Home's Positive Energy
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: '16px', align: 'middle' }}>
      This is a free basic version of our <strong>Vastu Calculator</strong>, providing a high-level understanding of your home's <strong>Vastu compliance</strong>. For a more accurate and detailed analysis, we highly recommend signing up! Our advanced <strong>Vastu Calculator</strong> offers additional options and criteria for a more thorough assessment, ensuring a harmonious living environment tailored to your preferences and needs.
      <br></br>
      <br></br>
      Sign up is free, and we offer one complimentary property analysis without the need for any credit card or payment information.
      <br></br>
      Also upon signing up and completing the evaluation, you will receive a comprehensive report with a <strong>Vastu Score</strong>, ratings, and detailed descriptions for each area.
      <br></br>
      <br></br>
      {Object.entries(selectedValues).map(([title, selectedValue]) => (
        <Dropdown
          key={title}
          title={title}
          options={['Not Applicable', 'East', 'West', 'North', 'South', 'North East', 'South East', 'North West', 'South West']}
          selectedValue={selectedValues[title]}
          onSelectChange={handleSelectChange}
        />
      ))}
      <br></br>
      <Link
        to="#"
        onClick={calculateScore}
        style={{
          fontSize: '18px',
          fontWeight: 600,
          display: 'inline-block',
          margin: '16px 0',
          padding: '10px 20px',
          color: '#FFFFFF',
          backgroundColor: '#1976D2',
          textDecoration: 'none',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(25, 118, 210, 0.2)',
        }}
      >
        Calculate Your <strong>Vastu Score</strong>
      </Link>
    </Typography>

    <Typography variant="body1" sx={{ marginBottom: '16px' }}>
      Uncover the secret to a harmonious home – where positive energies thrive, and well-being flourishes.
      Embrace the profound wisdom of <strong>Vastu Shastra</strong> and create a living space that resonates with positivity.
    </Typography>

    <br></br><br></br>
    <Typography variant="h5" sx={{ marginBottom: '16px' }}>
      <strong>Vastu Evaluation - Frequently asked Questions</strong>
    </Typography>
    <br></br>
     {faqs.map((faq, index) => (
            <Accordion key={index} sx={{ marginBottom: '16px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`faq-${index}-content`}
                id={`faq-${index}-header`}
              >
                <Typography variant="h3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
    </Box>
  );
};

export default VastuCalculatorPage;
