import React from 'react';
import "./Modal.css";
import { FaCopy, FaRedo } from 'react-icons/fa';
class ModalShowLink extends React.Component {

    constructor(props) {
      super(props);
      this.state = {url: this.props.url, message: this.props.message}
      this.setShowLinkInModal = this.props.setShowLinkInModal;
    }

    copyLink() {
        // Get the text field
        var copyText = document.getElementById("queryLink");
         // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.href);
      }

    render() {
      return (
        <div className="modalBackground-link">
        <div className="modalContainer-link">
          <div className='titleCloseBtn-link'>
            <button
              onClick={() => {
                this.setShowLinkInModal(false);
              }}
            >
              X
            </button>

          </div>
          <div className="title">
            <p>Generated Links</p>
          </div>
            {this.state.url ? <div className='App-profile'><a href={this.state.url} id="queryLink">Link</a><FaCopy onClick={this.copyLink}/></div>: ''}

            {this.state.message != '' ? <div className='alert alert-danger'>{this.state.message}</div>: ''}
        </div>
      </div>
    );
    }
  }

  export default ModalShowLink