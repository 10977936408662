import React, { Component } from "react";
import UploadService from "./UploadFileService";


export default class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
    this.sessionId = this.props.sessionId;
    this.fileType = this.props.fileType;
    this.currentToken = this.props.token;
    this.imageUploaded = false;
    this.state = {
        selectedFiles: undefined,
        currentFile: undefined,
        progress: 0,
        message: "", 
        fileInfos: [],
      };
  }


  selectFile(event) {
    this.setState({
      selectedFiles: event.target.files,
    });
  }

  upload() {
    let currentFile = this.state.selectedFiles[0];

    this.setState({
      progress: 0,
      currentFile: currentFile,
    });

    UploadService.upload(currentFile, this.sessionId, this.fileType, this.currentToken, (event) => {
      this.setState({
        progress: Math.round((100 * event.loaded) / event.total),
      });
    })
      .then((response) => {
        this.setState({
          message: response.data,
        });
        this.imageUploaded = true;
      })
      .catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
        });
      });

    this.setState({
      selectedFiles: undefined,
    });
  }


  render() {
    const {
        selectedFiles,
        currentFile,
        progress,
        message,
      } = this.state;
  
      return (
        <div>
          {currentFile && (
            <div className="progress">
              <div
                className="progress-bar progress-bar-info progress-bar-striped"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progress + "%" }}
              >
                {progress}%
              </div>
            </div>
          )}
  
          <label className="btn btn-default">
            <input type="file" accept="image/*" onChange={this.selectFile}/>
          </label>
  
          <button className="App-butt"
            disabled={!selectedFiles}
            onClick={this.upload}
          >
            Upload
          </button>
  
          <div className="alert alert-light" role="alert">
            {message}
          </div>
  
        </div>
      );
  }
}