import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, useMediaQuery } from '@mui/material';
import { PictureAsPdf, Print, ZoomIn, ZoomOut, Download } from '@mui/icons-material';

const PDFViewerHeader = ({ downloadPdf, zoomIn, zoomOut, handleViewPrint, pageNumber, numPages }) => {
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the max-width as needed

  const buttonStyle = { margin: isMobile ? '0 1px' : '0 8px' };

  return (
    <AppBar position="static" sx={{ background: 'linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);' }}>
      <Toolbar>
        <Button color="inherit" startIcon={<PictureAsPdf />} aria-label="menu">
        <Typography variant="body" style={{ flexGrow: 1 }}> PDF</Typography>
        </Button>

        <Typography variant="body" style={{ flexGrow: 1 }}>
          Total pages {numPages}
        </Typography>

        <Button color="inherit" onClick={zoomIn} style={buttonStyle}>
          <ZoomIn />
          {isMobile ? null : 'Zoom In'}
        </Button>

        <Button color="inherit" onClick={zoomOut} style={buttonStyle}>
          <ZoomOut />
          {isMobile ? null : 'Zoom Out'}
        </Button>

        {!isMobile && (
          <Button color="inherit" onClick={handleViewPrint} style={buttonStyle}>
            <Print />
            {'View and Print'}
          </Button>
        )}

        <Button color="inherit" onClick={downloadPdf} style={buttonStyle}>
          <Download />
          {isMobile ? null : 'Download - Vastuscore™ Report'}
        </Button>

      </Toolbar>
    </AppBar>
  );
};

export default PDFViewerHeader;
