import React from "react";
import {
  Box,
  Zoom,
  Fab,
} from "@mui/material";
//import { KeyboardArrowUp } from "@mui/icons-material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { FaArrowCircleUp, FaArrowUp } from "react-icons/fa";

const ScrollTopFab = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });
 
    const scrollToTop = React.useCallback(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
       //document.body.scrollTo({ top: 0, behavior: "smooth" });
     }, []);
 
   return (
       <Zoom in={trigger}>
         <Box
           role="presentation"
           sx={{
             position: "fixed",
             bottom: 40,
             right: "50vw",
             zIndex: 100,
           }}
         >
           <Fab
             onClick={scrollToTop}
             
             size="small"
             aria-label="scroll back to top"
           >
             <FaArrowUp />
           </Fab>
         </Box>
       </Zoom>
   )
 }

 export default ScrollTopFab;