import React, { useState } from 'react';
import { Box, Container, Grid, Pagination } from '@mui/material';
import ReportCard from './ReportCard';
import ShareToggle from './ShareToggle';
import ReportActions from './ReportActions';
import SwipeableViews from 'react-swipeable-views';

const CardListComponent = ({data, subHeaderComponentMemo, loadAllReports, loadReport, setShowEdit, handleRowSelected, contextActions, userQueries, selectedRows, toggleCleared, isCustom, statusDisplay, handleAddViewComments, handleSendBack, handleProvideClarification}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1;
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const filteredItems = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  

  return (
    <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
      <Container>       
      
                        
                        {subHeaderComponentMemo}
                        <SwipeableViews enableMouseEvents style={{ width: '100%' }}>

                          {filteredItems.map(report => (
                            <div key={report.queryId} style={{ width: '100%', padding: 10 }}>
                              <ReportCard report={report} loadAllReports={loadAllReports} loadReport={loadReport} setShowEdit={setShowEdit} handleRowSelected={handleRowSelected} selectedRows={selectedRows} isCustom={isCustom} statusDisplay={statusDisplay} handleAddViewComments={handleAddViewComments} handleSendBack={handleSendBack} handleProvideClarification={handleProvideClarification}/>
                            </div>
                          ))}
                        
                        </SwipeableViews>  
                        <Pagination
        count={pageCount}
        page={currentPage}
        onChange={(event, page) => setCurrentPage(page)}
        color="primary"
      />

                        {selectedRows.length > 0 ? <Box sx={{ bgcolor: '#cfe8fc', height: '5vh' }}><Box ml={5} mt={5} mb={5} mr={5}>{selectedRows.length} {' Items are seleced'} &nbsp; {contextActions}</Box></Box>: ''}
                      </Container>


    </Box>
  );
};

export default CardListComponent;