import axios from "axios";
import config from './config';

// Define the logout function
const logout = () => {
  window.dispatchEvent(new Event('token-expired'));
};

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: config[process.env.NODE_ENV].backendUrl,
  headers: {
    "Content-type": "application/json"
  }
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401 || error.response && error.response.status === 403) {
      // Token expired or unauthorized access
      logout();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;