import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../authSlice';

function ProtectedRoutes({ children, redirectPath = '/login' }) {
  const token = useSelector(selectCurrentToken);

  if (!token) {
    // Redirect to login with state containing the original URL
    return <Navigate to={redirectPath} state={{ from: window.location.pathname }} replace />;
  }

  return <Outlet />;
}

export default ProtectedRoutes;
