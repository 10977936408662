import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import image1 from '../assets/images/example_image_1_incorrect.png';
import image2 from '../assets/images/example_image_2_correct.png';
import image3 from '../assets/images/example_image_3_incorrect.png';
import image4 from '../assets/images/example_image_4_correct.png';
import image5 from '../assets/images/example_image_5_incorrect.png';
import image6 from '../assets/images/example_image_6_correct.png';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  { image: image1, description: "Incorrect: This cropping is incorrect as the image does not touch the the outermost wall as the boundary on left side. There is extra space and this is not acceptable." },
  { image: image2, description: "Correct:  This cropping is correct as the image is of rectangular shape with the outermost wall as the boundary on sides. Also, the front of the home/entrance faces the top side." },
  { image: image3, description: "Incorrect:  This orientation is incorrect as the front of the home/entrance does not face the top side of the screen (near the FLIP, UPLOAD and ROTATE buttons." },
  { image: image4, description: "Correct:  This orientation is correct as the front of the home/entrance faces the top side and the image is of rectangular shape with the outermost wall as the boundary on sides." },
  { image: image5, description: "Incorrect:  The Main entrance floor plan orientation is correct but the upper level floor plan orientation is incorrect as the front of the home does not face the top side." },
  { image: image6, description: "Correct:  Both the Main entrance floor plan and upper level floor plan orientation is correct as the front of the home in the both the plan face the top side." },
];

function PhotoCarouselStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;
  const [autoPlayActive, setAutoPlayActive] = React.useState(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleImageClick = () => {
    setAutoPlayActive(!autoPlayActive);  // Toggle the auto-play state
  };


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 350, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 5,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        autoplay={autoPlayActive} interval={2000}
      >

      {images.map((name, index) => (
          <div key={index}>
          <Box
              component="img"
              onClick={handleImageClick}
              sx={{
                height: 255,
                display: 'block',
                maxWidth: 255,
                overflow: 'hidden',
                width: '100%',
              }}
              src={name.image}
            />
             <Typography variant='body2'>{name.description}</Typography>  
        </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}

export default PhotoCarouselStepper;