import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import RequestService from '../RequestService';
import validator from 'validator';
import { toast, ToastContainer } from 'react-toastify';
import { FaEye } from 'react-icons/fa';
import { selectCurrentCredit, selectCurrentEmail, selectCurrentToken, selectCurrentUser, selectLoginType, userNameUpdate } from '../authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import usePageView from '../components/usePageView'; // Import the custom hook

export function MyProfile() {
    usePageView('My Profile');

    const [registerMessage, setRegisterMessage] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let [callParams, setCallParams] = useState([]);
    const [message, setMessage] = useState([]);
    const navigate = useNavigate();
    const params = [];
    const userEmail = useSelector(selectCurrentEmail);
    const currentUser = useSelector(selectCurrentUser);
    const currentToken = useSelector(selectCurrentToken);
    const credits = useSelector(selectCurrentCredit)
    const dispatch = useDispatch()
    const loginType = useSelector(selectLoginType);
    for(let entry of searchParams.entries()) {
      params.push(entry);
    }

    function updateUserName() {
        var newName = document.getElementById("updatedName").value;
        var messages = [];
        var proceed = true;
        if (validator.isEmpty(newName)) {
            messages.push("User Name cannot be empty");
            proceed = false;
        }

        if (proceed) {
            RequestService.updateUser(JSON.stringify({
                name: newName
             }), currentToken).then((response) => {
                if (response.status == 200) {
                    dispatch(userNameUpdate(newName))
                    toast.success("Name successfully changed");
                    toast.onChange(v => {
                        if(v.status === "removed"){
                         // run your callback
                         navigate('/login');
                        }
                      })
                    
                } else  {
                    messages.push("An error occurred, unable to update name at this time.");
                    setShowMessage(true);
                    setRegisterMessage(messages);
                }
              })
              .catch((err) => {
                console.log(err);
                messages.push("An error occurred, unable to update name at this time.");
                    setShowMessage(true);
                    setRegisterMessage(messages);
            });  
        } else {
            setRegisterMessage(messages);
        }
    }

    return (
        <div className='Scrollable-div'>
        <ToastContainer position='top-center' theme='dark' />    
        <fieldset>
        <legend>My Profile</legend> 
        {registerMessage.length > 0 && (
        <div className="alert alert-secondary mt-2" role="alert">
          <ul>
            {registerMessage.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </div>
      )}   
        <table className='App-table'>
            <tr>
                <td>
                <Typography variant='body2'>Email:</Typography>
                </td>
                <td>
                    <Typography variant='body2'>{userEmail}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                <Typography variant='body2'>Name:</Typography>
                </td>
                <td>
                    {loginType == 'email' ? 
                    <input type="text" id="updatedName" name="updatedName" defaultValue={currentUser}/>
                    : <Typography variant='body2'>{currentUser}</Typography>}
                </td>
            </tr>
            <tr>
                <td>
                <Typography variant='body2'>Balance Credit(s):</Typography>
                </td>
                <td>
                    <Typography variant='body2'>{credits}</Typography>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                {loginType == 'email' ? 
                    <Button variant='contained' size="small" onClick={updateUserName}>Update</Button>
                    : ''}
                </td>
            </tr>
            
        </table>
         
        </fieldset>
        </div>
    
    )
}

export default MyProfile;