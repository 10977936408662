import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { useState, useEffect } from 'react';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, FormControl, FormControlLabel, Radio, RadioGroup, Typography ,Dialog, DialogContent, DialogActions, Alert, Collapse, IconButton, Modal} from '@mui/material';
import { FaMinus, FaPlus, FaQuestionCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../authSlice';
import singleImage from '../assets/images/score.png'
import fiveImage from '../assets/images/five.png'
import config from '../config';
import MarkdownViewerFromFile from '../components/MarkdownViewerFromFile';
import ScrollTopFab from '../components/ScrollToTopFab';
import { useMediaQuery } from 'react-responsive';
import usePageView from '../components/usePageView'; // Import the custom hook

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function BuyCredits() {
 usePageView('Buy Credits');

  const [clientSecret, setClientSecret] = useState("");
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  const [intent, setIntent] = useState("");
  const [value, setValue] = useState(0);
  const [showQuantity, setShowQuantity] = useState(false);
  const [disableBuy, setDisableBuy] = useState(true);
  const [amount, setAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [couponCode, setCouponCode] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountedFinalAmount, setDiscountedFinalAmount] = useState(0);
  const [productType, setProductType] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const currentToken = useSelector(selectCurrentToken);
  const [openHelp, setOpenHelp] = useState(false);
  const [open, setOpen] = useState(isMobile ? false : true);
  const [alertMessage, setAlertMessage] = useState([]);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
  }, []);

  function openLearnMore() {
   setOpenHelp(true);
  }

  function handleCloseHelp() {
   setOpenHelp(false);
  }

  function createIntent(productType, qty, currencyCode, couponCode) {
    var json = {};
    json.productType = productType;
    json.quantity = qty;
    json.currencyCode = currencyCode;
    json.couponCode = couponCode;
    fetch(config[process.env.NODE_ENV].backendUrl + "/payment/paymentIntent", {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + currentToken},
      body: JSON.stringify(json)
    })
    .then((res) => {
        if (!res.ok) {
            // If the response status is not in the range 200-299, parse the error JSON
            return res.json().then(error => {
                throw error; // Throw the error object
            });
        }
        return res.json();
    })
    .then((data) => {
        // Handle successful response
        setData(data);
    })
    .catch((error) => {
        if(error.errorCode == "invalid_coupon_code") {
            document.getElementById('couponCode').value = '';
            setAlertMessage(error.errorMessage + "Your current coupon code has been cleared, please check out to proceed without a coupon code or enter a new code and try again.");
            setOpenAlertMessage(true);
        }
    });
  }

  function setData(data) {
    setClientSecret(data.paymentIntent.client_secret);
    setIntent(data.paymentIntent.id);
    setSubTotal(data.totalAmount);
    setCouponCode(data.couponCode);
    setDiscountAmount(data.discountAmount);
    setDiscountedFinalAmount(data.discountedFinalAmount);
  }

  function handleChange(value) {
    var currentValue = value;
    if (currentValue > 0) {
      setShowQuantity(true);
    }
    setValue(currentValue);
    setDisableBuy(false);
  };

  const isSelected = (cardId) => {
    return cardId === value;
  };

  const handleCloseAlertMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertMessage([]);
    setOpenAlertMessage(false);
  };

  function add() {
    var qty = document.getElementById('quantity').value;
    document.getElementById('quantity').value = (parseInt(qty) + 1);
  }

  function subtract() {
    var qty = document.getElementById('quantity').value;
    if (qty > 1) {
      document.getElementById('quantity').value = (parseInt(qty) - 1);
    } else {
      document.getElementById('quantity').value = 1;
    }
    
  }

  function buyCredits() {
    var qty = document.getElementById('quantity').value;
    var couponCode = document.getElementById('couponCode').value;

    var productType;
    var itemAmount;
    if (value == 1) {
        productType = "SINGLE_CREDIT";
        itemAmount = 9.99
    } else {
        productType = "FIVE_CREDIT";
        itemAmount = 39.95;
    }
    var currencyCode = "usd";
    setAmount((qty*itemAmount).toFixed(2));
    setProductType(productType);
    setQuantity(qty);
    createIntent(productType, qty, currencyCode, couponCode);
    
  }

 
  const appearance = {
    theme: 'flat',
    business: {
      name: "Swift Vision LLC"
    },
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Arial, Helvetica, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
      // See all possible variables below
    }
  };
  const options = {
    clientSecret,
    appearance
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box width={"100%"} alignContent="center" justifyContent={"center"} align="center">
      <Dialog
        open={openAlertMessage}
        onClose={handleCloseAlertMessage}
      >
      
        <DialogContent>
          <Typography sx={{ mt: 2 }}>
              {alertMessage}
          </Typography>
        </DialogContent>
        <DialogActions><Button onClick={handleCloseAlertMessage}>Close</Button></DialogActions>
      </Dialog>
   
    {currentToken ? <Box m="auto" sx={{display: clientSecret ? 'flex': 'relative', width: clientSecret ?  "100%": isMobile ? "90%": "50%"}} alignContent="center" justifyContent={"center"} align="center">
      {!clientSecret && (
          <Box width={"100%"} alignContent="center" justifyContent={"center"} align="center">
          <br/>
          <Typography variant={isMobile?'subtitle2':'h6'}>Vastuscore™ Report - Click on a package, Adjust Quantity, and Checkout When Ready <FaQuestionCircle onClick={openLearnMore}/></Typography>
          <br></br>
          <Collapse in={open}>
                  <Alert severity='info' width="100%" justify="left" action={
                     <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setOpen(false);
                     }}
                     >
                     X
                     </IconButton>
                  }>To acquire credits, please choose from the packages listed below. After selecting a package, specify the desired quantity at the bottom of the screen and proceed to the checkout process.
                  </Alert>
                  </Collapse>
                  <Button size="small" variant="text"
                     disabled={open}
                     onClick={() => {
                    setOpen(true);
                     }}
                  >
                  Show Info
                </Button>
          <br></br>
            <Card key={1} m="auto" mt="10" sx={{
            display: isMobile ? "relative":"flex",  
            color: isSelected(1) || isSelected(0) ?'white':'gray',
            boxShadow: isSelected(1) ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)':'none', 
            background: isSelected(1) ?'linear-gradient(110.1deg, rgb(34, 126, 34) 2.9%, rgb(168, 251, 60) 90.3%)': isSelected(0) ?'linear-gradient(to right bottom, #1976d2, #52afff)':'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);',
          }}>
            
          <CardActionArea onClick={() => handleChange(1)} sx={{display: isMobile ? "relative":"flex"}}>
            <CardMedia
        component="img"
        sx={{ width: 175, height: 200, padding:"25px" }}
        image={singleImage}
        alt="Single Credit"
      />

              <CardContent>
              <Box alignContent="left" align="left"><Typography variant='h6'>Single Credit Package</Typography>
                <ul style={{ lineHeight: '1.7',fontSize: '0.85rem'}}>
                  <li>Purchase a credit with no expiration.</li>
                  <li>Get a Vastuscore™ report for one property instantly with a single credit using our DIY option.</li>
                  <li>Download the Vastuscore™ Report for one property.</li>
                  <li>Opt for our service to handle the work for you, requiring two credits (adjust the quantity to two).</li>
                  <li>Enjoy unlimited access to Vastuscore™ reports with the purchased credits.</li>
                  <li>One-time fee, no recurring charges.</li>
                </ul></Box>
                <Typography variant="h6"> 1 Credit <span style={{ textDecoration: 'line-through' }}>( $19.99 )</span> <span>(Introductory Offer: $9.99)</span> </Typography>
                {/* <CardActions>                  
                <FormControlLabel value="1" disableTypography="true" control={<Radio size='small'/>} label={<Typography variant="h6"> 1 Credit <span style={{ textDecoration: 'line-through' }}>( $19.99 )</span> <span style={{ color: '#FFFFFF' }}>(Introductory Offer: $9.99)</span> </Typography>}/>
                </CardActions> */}
                </CardContent>
              </CardActionArea> 
            </Card>
            <br></br><br></br>
            <Card key={5} m="auto" mt="10" sx={{
            display: isMobile ? "relative":"flex",  
            color: isSelected(5) || isSelected(0) ?'white':'gray',
            boxShadow: isSelected(5) ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)':'none',  
            background: isSelected(5) ?'linear-gradient(110.1deg, rgb(34, 126, 34) 2.9%, rgb(168, 251, 60) 90.3%)': isSelected(0) ?'linear-gradient(to right bottom, #1976d2, #52afff)':'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);',
          }}>
            <CardActionArea onClick={() => handleChange(5)} sx={{display: isMobile ? "relative":"flex"}}>
            <CardMedia
        component="img"
        sx={{ width: 175, height: 200, padding:"25px",objectFit: "cover" }}
        image={fiveImage}
        alt="Five Credit"
      />

              <CardContent>
              <Box alignContent="left" align="left"><Typography variant='h6'>Five Credits Package</Typography>
                <ul style={{ lineHeight: '1.7',fontSize: '0.85rem' }}>
                    <li>Purchase a bundle of Five Credits with no expiration.</li>
                    <li>Take advantage of a generous 20% bulk discount, paired with our exclusive introductory offer.</li>
                    <li>Generate Vastuscore™ Report for up to 5 properties.</li>
                    <li>Download the Vastuscore™ Report for up to 5 properties.</li>
                    <li>Experience unlimited access to Vastuscore™ reports generated through these credits.</li>
                    <li>One-time fee. No recurring charges.</li>
                </ul>
              </Box>
                <Typography variant="h6"> 5 Credits <span style={{ textDecoration: 'line-through' }}>( $79.96 )</span> <span >(Introductory Offer: $39.95)</span> </Typography>
                {/* <CardActions>
                <FormControlLabel value="5" disableTypography="true" control={<Radio size='small'/>} label={<Typography variant="h6"> 5 Credits <span style={{ textDecoration: 'line-through' }}>( $79.96 )</span> <span style={{ color: '#FFFFFF' }}>(Introductory Offer: $39.95)</span> </Typography>}/>
                </CardActions> */}
                </CardContent>
              </CardActionArea> 
            </Card>

                
       {showQuantity ?  (

        <div>
          <br></br>
        <strong>Adjust Quantity:</strong><Button size='small' onClick={subtract} id="backward"><FaMinus/></Button>
        <input type="text" readOnly size="2" align="center" id="quantity" defaultValue={1} textAlign="center" name="angleInput"/>
        <Button size='small' onClick={add} id="forward"><FaPlus/></Button></div>) : ''
        }
        <br></br>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontWeight: 'bold' }}>Apply a promo code:&nbsp;&nbsp;</span>
          <input type="text" id="couponCode" placeholder="Enter promo code" style={{ border: '2px solid #3498db', borderRadius: '4px', padding: '4px' }} />
          <Button size="small" sx={{ width: isMobile ? "50%" : "20%", marginLeft: '10px' }} onClick={buyCredits} disabled={disableBuy} variant="contained" color='primary'>Checkout</Button>
        </div>

          <br></br>
          </Box>
        )
      }
      
      {clientSecret && (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          px: 2, 
          width:"100%",// 
          mb:2,
          mt:isMobile ? "2vh": "15vh"
        }}>
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm  subTotal={subTotal} couponCode={couponCode} discountAmount={discountAmount} discountedFinalAmount={discountedFinalAmount} productType={productType} quantity={quantity} intent={intent}/>
        </Elements>
        </Box>
      )}

                 <Dialog
                        maxWidth={"md"}
                        open={openHelp}
                      >
                        <DialogContent>
                              <MarkdownViewerFromFile mdFilePath="./help/credits_help.md" />
                          </DialogContent>
                        <DialogActions>
                          <Button onClick={() => handleCloseHelp()}>Close</Button>
                        </DialogActions>
                        <ScrollTopFab/>
                    </Dialog>
    </Box>: <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        px: 2, 
        height: '80vh' // 100% of the viewport height
      }}><Typography>Access denied, Please login to access the buy credits page</Typography></Box>}
    </Box>
  );
};
