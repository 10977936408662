import React from 'react';
import UploadFile from '../UploadFile';
import Image from './Image';
import "./Modal.css";
import { v4 as uuidv4 } from 'uuid';
import UploadImages from '../UploadImages';
import { Typography } from '@mui/material';
class ModalUpload extends React.Component {
    constructor(props) {
      super(props);
      this.state = {sessionId: uuidv4(), floorCount: 1}
      this.currentToken = this.props.userToken;
      this.handleChange = this.handleChange.bind(this);
      this.setOpenUpload = this.props.setOpenUpload;
      this.reloadImage = this.props.reloadImage;
    }
  
    handleChange = (event) => {
        if (event.target.value > 1) {
          document.getElementById("secondFloor").removeAttribute('hidden');
        }

        if (event.target.value > 2) {
          document.getElementById("thirdFloor").removeAttribute('hidden');
        }
        
    }

    getTotalFloors = () => {
      return this.state.floorCount;
    }
  
    render() {
      return (
        <div className="modalBackground">
        <div className="modalContainer">
          <div className="titleCloseBtn">
            <button
              onClick={() => {
                this.setOpenUpload(false);
                this.reloadImage(this.state.sessionId, true);
              }}
            >
              X
            </button>
          </div>
          <fieldset>
            <legend>Add floor plan</legend>
            <div className='Scorllable-div'>
              <Typography variant='body2' mb={2} mt={2}>Upload up to 3 Image files. The size of the image should not exceed 10 MB.</Typography>

                  <div id="firstFloor"><UploadImages sessionId={this.state.sessionId} token={this.currentToken}/></div>

            </div>
          </fieldset>
          <Typography variant="caption" align='center' mt={60}>Session: {this.state.sessionId}</Typography>      
        </div>
      </div> 
    );
    }
  }

  export default ModalUpload