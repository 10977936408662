import { createSlice } from "@reduxjs/toolkit";

export const angleSlice = createSlice({
    name: "angle",
    initialState: { rotation: null },
    reducers: {
        setRotation: (state, action) => {
            state.rotation = action.payload;
        },

        clearRotation: (state) => {
            state.rotation = null;
        }
    },
});
export const {setRotation, clearRotation} = angleSlice.actions;
export const selectRotation = (state) => state.angle.rotation
export default angleSlice.reducer;