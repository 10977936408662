// faqData.js
const faqData = [
{
  section: 'Account and Profile',
  faqs: [
    {
      question: 'How do I update my account information?',
      answer: 'If you registered manually not via Google Login, click on your name in the top right corner, and select "My Profile" from the menu. Then, modify your information and click "Update". If you used Google Sign-In, your account information is managed by Google, and changes must be made through your Google account.',
    },
    {
      question: 'What should I do if I forget my password?',
      answer: 'If you registered manually, on the login page, click on "Forgot Password" and follow the instructions to reset your password. You will receive a password reset link via email. This option is only applicable for manual registration. If you signed in with Google, password management is handled by Google, and you cannot reset it through our platform.',
    },
    {
      question: 'Can I change the password associated with my account?',
      answer: 'If you registered manually, you can change your password by clicking on your name in the top right corner, selecting "My Profile" from the menu, and then choosing "Change Password." Enter your old and new passwords to complete the process. This option is not available if you used Google Sign-In, as password management is handled by Google.',
    },
    {
      question: 'Can I change the email associated with my account?',
      answer: 'No. Currently, email changes are not supported directly.',
    },
    {
      question: 'Is there a way to delete my account?',
      answer: 'To delete your account, contact our support team with a request for account deletion. Ensure all outstanding matters, such as credit balances, are resolved before proceeding. This process is applicable for both manual registration and Google Sign-In users.',
    }
  ],
},
{
  section: 'Credits and Payments',
  faqs: [
    {
      question: 'What are Vastu Report Credits?',
      answer: 'Vastu Report Credits are a unit of value used in our platform. Users can purchase credits by paying money.',
    },
    {
      question: 'How to purchase credits?',
      answer: 'To purchase credits, click on the "Buy Credits" link in the top menu. Choose the desired credit package, proceed to checkout, and complete the payment process.',
    },
    {
      question: 'What are Free Sign-Up Credits?',
      answer: 'As part of our introductory offer, we\'re pleased to provide you with 1 complimentary credit upon signing up. This free credit is instantly added to your account upon registration. This initial credit bestows upon you the privilege of accessing a comprehensive Vastu report for a single property. In the event that you seek Vastu scores and reports for multiple properties, the procurement of additional credits will be necessary.',
    },
    {
      question: 'How to check your available credit balance',
      answer: 'After logging in, you will see your remaining credit balance displayed prominently in the upper-left corner of the screen. Additionally, you can also find this information on the "My Profile" page within your account settings.',
    },
    {
      question: 'How many credits are consumed with each "Calculate - Vastuscore™" action?',
      answer: 'Each "Calculate - Vastuscore™" action consumes one credit. This action not only yields a Vastuscore™ but also generates a comprehensive property report for the same credit. Credits are also deducted when performing multiple "Calculate - Vastuscore™" actions on a specific property due to subsequent edits. Example: Begin by uploading a floor plan, specifying room placements, and conducting the initial score calculation. Suppose you identify a room orientation error and need to rectify it. Modifying the room placement doesn\'t trigger an automatic score recalculation. To obtain an updated score, you must initiate the "Calculate - Vastuscore™" action again within the edit interface. It\'s important to note that every "Calculate Score" action, even for the same property, incurs a credit cost. "Calculate Score" action will cost you one credit, even if it\'s on the same property. Credits will not be utilized during the following actions: System errors, Property name changes, Sharing Reports, Report customizations, Report downloads.',
    },
    {
      question: 'Is the payment process secure?',
      answer: 'Yes, the payment process is secure. We utilize a trusted third-party payment service to ensure the safety of your transactions. Your payment information is encrypted and handled with care by the third-party payment provider, which follows industry-standard security protocols.',
    },
    {
      question: 'Can I get a refund for unused credits?',
      answer: 'No. Refunds for unused credits are not provided.',
    },
    {
      question: 'Are there discounts or promotions for credit purchases?',
      answer: 'We occasionally offer promotions and discounts for credit purchases. Keep an eye on our announcements for special offers.',
    },
    {
      question: 'What should I do if my credits do not reflect after a payment?',
      answer: 'If your credits do not reflect after a payment, please wait for a short period as there might be a processing delay. If the issue persists, try clicking the refresh button in the My Orders page. If the problem still persists, contact our support team via the "Contact Us" menu link at the top, providing details about your transaction. We will investigate and assist you promptly.',
    },
    {
      question: 'Encountered issues with payments?',
      answer: 'If you encounter any issues during the payment process, ensure that your payment details are entered correctly. If the problem persists, contact our support team via the "Contact Us" menu link at the top, providing details about the issue. We will assist you in resolving payment-related issues.',
    },
    ]
    },
  {
    section: 'Vastu Evaluation',
    faqs: [
    {
      question: 'What is Vastu?',
      answer: 'Vastu, also known as Vastu Shastra, is an ancient Indian science that focuses on creating harmonious and balanced living environments. It involves principles and guidelines for designing spaces in a way that aligns with the natural forces and energies. Vastu emphasizes the arrangement of elements within a property to promote well-being, prosperity, and positive energy flow. The practice takes into account factors such as direction, layout, and the placement of rooms to create a balanced and auspicious living space.',
    },
    {
      question: 'Why do people look for homes that have stronger Vastu compliance?',
      answer: 'People look for homes with stronger Vastu compliance to ensure a harmonious and balanced living environment. A home with better Vastu alignment is believed to promote positive energies, which can contribute to improved health, prosperity, harmony, and success. By seeking properties with strong Vastu compliance, individuals aim to create a space that aligns with Vastu principles, fostering a positive and auspicious atmosphere for themselves and their families.',
    },
    {
      question: 'Why should I use VastuReport.com?',
      answer: 'Traditionally, evaluating Vastu requires reaching out to a Vastu expert, incurring costs and consuming time. If you want to compare multiple properties, it becomes more expensive and time-consuming. VastuReport.com changes this dynamic by providing instant Vastu scores, ratings, and detailed reports within seconds at a low cost. Our platform facilitates faster decision-making by offering:',
      additionalInfo: [
        'Instant Vastu scores, ratings, and detailed reports for swift decision-making.',
        'Accessibility to Vastu-enhanced living for individuals.',
        'Invaluable tools for builder sales agents and real estate agents to enhance service quality and cater to the growing demand for Vastu-compliant properties.',
        'Empower builders to create properties with stronger Vastu compliance, meeting the rising expectations for harmonious and balanced living spaces.',
      ]
    },
    {
      question: 'What evaluation do we do?',
      answer: 'We perform a thorough Vastu assessment of your property based on the details you provide. This includes a detailed report with a Vastuscore™ and rating, offering insights into the alignment and energies of each room or plot. The analysis focuses on aspects such as health, prosperity, harmony, and success in your home.',
    },
    {
      question: 'What does the Vastuscore™ represent?',
      answer: 'The Vastuscore™ is a numerical representation ranging from 0 to 100, gauging the level of Vastu compliance for any given property. A higher score indicates better adherence to Vastu principles.',
    },
    {
      question: 'How is the overall rating determined based on the Vastuscore™?',
      answer: 'The overall rating is determined according to the following table:\n\n\
      - Score 0-29: Least Favorable\n\
      - Score 30-49: Unfavorable\n\
      - Score 50-69: Average\n\
      - Score 70-89: Good\n\
      - Score 90 and above: Excellent\n\
      \nThis rating system helps you quickly assess the Vastu compliance level of a property.',
    },
    {
      question: 'Can I get a detailed report for my property?',
      answer: 'Yes, a Vastuscore™ calculation provides you a detailed Vastu report for your property, which includes the score, recommendations, and other relevant information.',
    },
    ],
  },

{
    section: 'Reports',
    faqs: [
    {
      question: 'How can I search for my Vastu reports by name?',
      answer: 'Navigate to the "Reports" page and locate the "Filter By Name" textbox. Click inside the textbox, enter the name of the property you wish to search, and allow a few seconds for the reports display table to refresh with the filtered results. To view all properties again, simply remove the text entered in the "Filter By Name" textbox or click the "Clear" button and wait for all the properties to reappear in the "Reports" display table.',
    },
    {
      question: 'What steps are involved in viewing a property\'s detailed Vastu report?',
      answer: 'To access a property\'s detailed Vastu report, navigate to the "Reports" page by clicking the "Reports" link in the top menu. The page will display properties for which you have previously calculated the Vastuscore™. Each property with a calculated Vastuscore™ will be listed as a row in the table. Identify the property, locate the "Actions" column, and click on the "View Report" icon, typically the first icon listed. The detailed Vastu report will load below the Reports table. To download the report as a PDF, click the "Download" button in the top menu of the PDF frame.',
    },
    {
      question: 'How can I compare Vastu scores for multiple properties?',
      answer: 'To compare Vastu scores for multiple properties, go to the "Reports" page by clicking the "Reports" link in the top menu. Select properties using checkboxes, click the "Compare" button above the Reports table, and view the comparison report. To download the comparison report as a PDF, click the "Download" button in the top menu of the PDF frame.',
    },
    {
      question: 'How do I turn on public sharing for a property\'s detailed Vastu report?',
      answer: 'To enable public sharing for a property\'s detailed Vastu report, navigate to the "Reports" page and ensure the "sharing" option is turned on in the "Share Report" column for the specific property. Click on the "Copy Shareable Link" icon, typically the third icon listed in the Actions column. The publicly shareable URL for your property\'s detailed Vastu report will be copied to your clipboard.',
    },
    {
      question: 'What\'s the process for obtaining an HTML embed code to share a detailed Vastu report on a different site?',
      answer: 'To get an HTML embed code for sharing a detailed Vastu report, go to the "Reports" page, ensure sharing is enabled for the specific property, and click on the "Copy Embed Link" icon, typically the fourth icon listed in the Actions column. The HTML code to embed your property\'s detailed Vastu report will be copied to your clipboard.',
    },
    {
      question: 'How can I turn off public sharing for a property\'s detailed Vastu report?',
      answer: 'To disable public sharing for a property\'s detailed Vastu report, go to the "Reports" page, locate the property, and click the sharing button in the Share Report column to turn off the sharing option. Once disabled, the property\'s detailed Vastu report will no longer be publicly accessible via the previously shared URL.',
    },
    {
      question: 'How do I edit a property\'s specification to recalculate the Vastu score?',
      answer: 'To edit a property\'s specification and recalculate the Vastuscore™, navigate to the "Reports" page, identify the property, locate the "Actions" column, and click on the "Edit Property" icon, typically the second icon listed. After making the necessary changes to the property\'s specification, click the "Score" button again in the edit screen to recalculate the Vastuscore™ based on the updated information.',
    },
    ],
  },
  {
    section: 'Seller reports',
    faqs: [
    {
      question: 'What are Seller Reports?',
      answer: 'Seller Reports empower you to customize Detailed Vastu Reports for your properties, emphasizing positive aspects to present a personalized view to potential buyers.',
    },
    {
      question: 'How can I search for Seller Reports by property name?',
      answer: 'Navigate to the "Seller Reports" page, locate the "Filter By Name" textbox, enter the property name, and wait for the filtered results. To view all properties, remove the text or click "Clear."',
    },
    {
      question: 'How do I customize a Seller Report?',
      answer: 'Visit the "Seller Reports" page, find the property, click "Customize," and toggle options for Overall Score, Individual Rating, Highlights, Descriptive Comments, and Images. Click "Save" to apply changes.',
    },
    {
      question: 'How can I view a property\'s customized Vastu Report?',
      answer: 'Navigate to the "Seller Reports" page, find the property, and click the "View Report" icon in the "Actions" column. The customized Vastu Report will appear below the table. To download as PDF, click "Download" in the top menu.',
    },
    {
      question: 'How do I share a customized report and get a publicly accessible URL?',
      answer: 'On the "Seller Reports" page, find the property, ensure sharing is on, click "Copy Shareable Link," and paste the URL where needed. This enables effective sharing of insights with others.',
    },
    {
      question: 'How can I get an HTML Embed Code for embedding the report in a different site?',
      answer: 'On the "Seller Reports" page, find the property, ensure sharing is on, click "Copy Embed Link," and paste the HTML code into your website\'s HTML or desired location.',
    },
    {
      question: 'How do I turn off public sharing for a property\'s customized Vastu Report?',
      answer: 'Visit the "Seller Reports" page, find the property, click the sharing button in the "Share Report" column to disable public sharing. The report will no longer be accessible via the shared URL.',
    },
    ],
  },
  {
    section: 'Technical Support',
    faqs: [
      {
        question: 'How do I contact technical support?',
        answer: 'To contact technical support, click on "Contact Us" in the top menu bar. Fill out the contact form with your details and describe the nature of your technical issue. Our support team will respond to your inquiry promptly.',
      },
      {
        question: 'What information should I provide when reporting an issue?',
        answer: 'When reporting an issue, provide details such as your account information, the property in question, and a detailed description of the problem. Screenshots may also be helpful.',
      },
      {
        question: 'How quickly can I expect a response from support?',
        answer: 'Our support team aims to respond to inquiries promptly within 1-2 business days. Response times may vary, but we strive to address urgent matters as quickly as possible.',
      },
      {
        question: 'I have not received the activation link yet after registration. What should I do?',
        answer: 'Typically, you should receive the link to activate your account immediately. In the rare instance that you do not get it right away, please allow up to an hour for the email to arrive. If you still have not received the email with the activation link after this period, kindly reach out to us through the Contact Us form. Our support team will assist you in resolving the issue promptly.',
      },
      {
        question: 'I have requested a password reset, but I have not received the link yet. What should I do?',
        answer: 'Typically, you should receive the link to reset your password in your email immediately. In the rare instance that you do not get it right away, please allow up to an hour for the email to arrive. If you still have not received the reset password link after this period, kindly reach out to us through the Contact Us form. Our support team will assist you in resolving the issue promptly.',
      },
    ],
  },
  {
    section: 'Privacy and Security',
    faqs: [
      {
        question: 'How is my data protected?',
        answer: 'We prioritize the security of your data. We employ encryption measures, access controls, and other security protocols to safeguard your information. Refer to our Privacy Policy for more details.',
      },
      {
        question: 'What information is collected during the evaluation process?',
        answer: 'During the evaluation process, we collect data related to room placements, orientations, and other Vastu-related details. This includes floor plan images, direction information, plot direction shape information, and other relevant details. Its important to note that no personal data is collected, apart from email, name, and a few basic details. Our data handling practices adhere to the guidelines outlined in our Privacy Policy.',
      },
      {
        question: 'Will my information on floor plans, etc., be shared?',
        answer: 'No, your information related to floor plans, room placements, and other Vastu-related details will not be explicitly shared by us without your consent. However, if you have chosen to enable public sharing on your reports, anyone with the link can view the information on that specific report. It is crucial to note that the decision to share your information publicly is entirely up to you, and you have control over this feature in your report settings.',
      },
      {
        question: 'Are there measures in place to prevent unauthorized access?',
        answer: 'Yes, we implement measures to prevent unauthorized access to your account and property information.',
      },
    ],
  },
];

export default faqData;
