import React, { useState, useEffect } from 'react';
import 'react-tooltip/dist/react-tooltip.css'
import { selectCurrentUser, selectCurrentToken, userCredits, selectCurrentCredit } from '../authSlice';
import { useSelector } from 'react-redux';
import ShowImages from './showImages';
import ShowScore from './showScore';
import ShowForm from './showForm';
import loadingImage from '../assets/images/loading.gif';
import { useDispatch } from 'react-redux';
import { clearSession, saveSession, selectSessionId } from '../imageSlice';
import { selectQueryId, setQueryId, setReportTitle } from '../reportSlice';
import { FaAddressBook, FaBackward, FaCalculator, FaHistory } from 'react-icons/fa';
import RequestService from '../RequestService';
import { ToastContainer, toast } from 'react-toastify';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, LinearProgress, Typography } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { selectRotation, setRotation } from '../angleSlice';
import MarkdownViewerFromFile from '../components/MarkdownViewerFromFile';
import ScrollTopFab from '../components/ScrollToTopFab';
import VideoBar from '../components/VideoBar';
import usePageView from '../components/usePageView'; // Import the custom hook
import { Document, Page, pdfjs } from 'react-pdf';
import PDFViewerHeader from '../components/PDFViewerHeader';

//import 'react-confirm-alert/src/react-confirm-alert.css';
const getStatus = (label) => {

   if (label == 5) {
      return 'Excellent';
   }

   else if (label == 4) {
      return 'Good';
   }

   else if (label == 3) {
      return 'Average';
   }

   else if (label == 2) {
      return 'Unfavorable';
   } else {
      return 'Least Favorable'
   }

}


function EditScore({backToReports, buttonName, isProcessorScreen}) {
   const [roomParameters,setRoomParameters] = useState([]);
   const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const [showScore,setShowScore] = useState(false);
    const [scoreData,setScoreData] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [score, setScore] = useState(0);
    const [overallRating, setOverallRating] = useState(0);
    const currentUser = useSelector(selectCurrentUser);
    const currentToken = useSelector(selectCurrentToken);
    const currentRotation = useSelector(selectRotation);
    const [openHelp, setOpenHelp] = useState(false);
    const editQueryId = useSelector(selectQueryId);
    const [loadingText, setLoadingText] = useState([]);
    const [sessionId, setSessionId] = useState([]);
    const[showImage, setShowImage] = useState(false);
    const dispatch = useDispatch();
    const [title, setTitle] = useState([]);
    const [credits, setCredits] = useState([]);
    const currentCredit = useSelector(selectCurrentCredit);
    const [showReport, setShowReport] = useState(false);
    const navigate = useNavigate();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
   const [loadingReport, setLoadingReport] = useState(false);
   const [downloadQueryId, setDownloadQueryId] = useState(null);
   const [messageText, setMessageText] = useState([]);
   const[queryId, setQueryId] = useState(null);
   const [defaultScale, setDefaultScale] = useState(1);

  
  
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    };
  
    const downloadPdf = () => {
      // Check if pdfUrl is available and there is a valid queryId
      if (pdfUrl && downloadQueryId) {
        const reportId = downloadQueryId;
  
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `report_${reportId}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // Handle the case where pdfUrl is not available or there is no queryId
        console.error("Cannot download PDF. pdfUrl is not available or no queryId.");
      }
    };

    
    function openLearnMore() {
      setOpenHelp(true);
     }
   
     function handleCloseHelp() {
      setOpenHelp(false);
     }
   
     const zoomIn = () => {
      setDefaultScale(defaultScale + 0.1);
    };
  
    const zoomOut = () => {
      if (defaultScale > 0.1) setDefaultScale(defaultScale - 0.1);
    };

const handleViewPrint = async () => {
try {
  const pdfData = await fetch(pdfUrl).then((res) => res.blob());
  const blobUrl = URL.createObjectURL(pdfData);

  const newWindow = window.open('', '_blank');
  if (newWindow) {
    newWindow.document.write(`<iframe width="100%" height="100%" src="${blobUrl}"></iframe>`);
    newWindow.document.title = 'Print Preview';

    // Instruct users to use the browser's print option (e.g., Ctrl+P or Command+P)
    newWindow.document.write('<p>Please use the browser\'s print option (e.g., Ctrl+P or Command+P) to print this document.</p>');
  }
} catch (error) {
  console.error('Error fetching PDF data:', error);
}
};

   function reloadPage() {
      setShowScore(false);
      setScoreData([]);
      setScore(0)
      setOverallRating(0)
      setRoomParameters([]);
      setShowForm(false);
      dispatch(clearSession());
   }

   function showReportView() {
      setShowReport(true);
      setShowForm(false);
      setShowImage(false);
      //calculateScore(responseArray);
      loadReport(editQueryId);

  }

  function showEditReport() {
      setShowReport(false);
      setShowForm(true);
      setShowImage(true);
  }

  function gotoReports() {
      navigate('/myreports');
  }

  function loadReport(editQueryId) {
   setLoadingReport(true);
   //document.getElementById("reportFrame").srcdoc = `<div align="center"><img height="300px" width="300px" src="${loadingImage}" alt="Embedded Image in Iframe" /></div>`;
   RequestService.getReport(editQueryId, currentToken).then((response) => {
      if (response.status === 200) {
         // Check the Content-Type header
         const contentType = response.headers['content-type'];
         if (contentType === 'application/pdf') {
           // The response contains a PDF
           const blob = new Blob([response.data], { type: 'application/pdf' });
           const objectUrl = URL.createObjectURL(blob);
   
           setPdfUrl(objectUrl);
           setDownloadQueryId(queryId);
           setShowPdfViewer(true);
   
           setLoadingReport(false);
         } else {
           // Handle unexpected content type
           console.error('Unexpected Content-Type:', contentType);
           setMessageText('Error loading the report. Unexpected content type.');
           setLoadingReport(false);
         }
       } else if (response.status === 403) {
         setMessageText("Access is denied, token may have expired. Please logout and login again.");
         setLoadingReport(false);
       } else {
         setMessageText("Error loading the report. Please try again.");
         setLoadingReport(false);
       }

    });
}

   useEffect(() => {
     if (!isProcessorScreen) {
          //Change this to another endpoint to return all fields in response
          RequestService.getAllScoreFieldForUserQueryId(editQueryId, currentToken).then((response) => {
             if (response.status == 200) {
                return response.data;
             }
          })
         .then((data) => {
          var newRoomParameters = data.scoreFieldList.sort(sort_by('sequenceNumber', false, parseInt));
          return newRoomParameters;
         }).then((newRoomParameters) => {
          if (currentUser && editQueryId) {
             RequestService.getUserQuery(editQueryId, currentToken).then((response) => {
                if (response.status == 200) {
                  var queryString = response.data.queryString;
                  var imageSessionId = response.data.imageSessionId;
                  var northAngle = response.data.northAngle;
                  dispatch(saveSession(imageSessionId));
                  dispatch(setRotation(northAngle));
                  setSessionId(imageSessionId);
                  setShowImage(true);
                  var responseObject = JSON.parse(queryString);
                  var editRoomParameters = [...newRoomParameters];
                  let j = 0;
                  editRoomParameters.map((roomParameter, index) => {
                      for(let i = j; i < responseObject.query.length; i++) {
                         let obj = responseObject.query[i];
                         if (obj.objectCode == roomParameter.objectCode) {
                            if (!roomParameter.matchCondition) {
                               roomParameter.matchCondition = obj.matchCondition;
                               j = i+1;
                               break;
                            }

                         }
                     }
                  });
                  setRoomParameters(editRoomParameters);
                  setShowForm(true);
                  viewScore(editQueryId, currentToken);
                  setTitle(response.data.title);
                  dispatch(setReportTitle(response.data.title));
                } else if (response.status == 403) {
                   toast.error("Access is denied, token may have expired please logout and login again");
                } else {
                   toast.error("Unable to Load Saved Query");
                }
                return response;
             }).then((data) => {
                RequestService.getViewCredits(currentToken).then((response) => {
                   if (response.status == 200) {
                      dispatch(userCredits(response.data.creditsRemaining));
                      setCredits(response.data.creditsRemaining);
                   }
                });
             });
          } else {
             //setShowForm(true);
          }
         }).catch((err) => {
           setLoadingText("An error occurred loading data. Please try again later");
         });

     } else {
           //Change this to another endpoint to return all fields in response
           RequestService.getAllScoreFieldForUserQueryIdForProcessor(editQueryId, currentToken).then((response) => {
              if (response.status == 200) {
                 return response.data;
              }
           })
          .then((data) => {
           var newRoomParameters = data.scoreFieldList.sort(sort_by('sequenceNumber', false, parseInt));
           return newRoomParameters;
          }).then((newRoomParameters) => {
           if (currentUser && editQueryId) {
              RequestService.getUserQueryForProcessor(editQueryId, currentToken).then((response) => {
                 if (response.status == 200) {
                   var queryString = response.data.queryString;
                   var imageSessionId = response.data.imageSessionId;
                   var northAngle = response.data.northAngle;
                   dispatch(saveSession(imageSessionId));
                   dispatch(setRotation(northAngle));
                   setSessionId(imageSessionId);
                   setShowImage(true);
                   var responseObject = JSON.parse(queryString);
                   var editRoomParameters = [...newRoomParameters];
                   let j = 0;
                   editRoomParameters.map((roomParameter, index) => {
                       for(let i = j; i < responseObject.query.length; i++) {
                          let obj = responseObject.query[i];
                          if (obj.objectCode == roomParameter.objectCode) {
                             if (!roomParameter.matchCondition) {
                                roomParameter.matchCondition = obj.matchCondition;
                                j = i+1;
                                break;
                             }

                          }
                      }
                   });
                   setRoomParameters(editRoomParameters);
                   setShowForm(true);
                   viewScore(editQueryId, currentToken);
                   setTitle(response.data.title);
                   dispatch(setReportTitle(response.data.title));
                 } else if (response.status == 403) {
                    toast.error("Access is denied, token may have expired please logout and login again");
                 } else {
                    toast.error("Unable to Load Saved Query");
                 }
                 return response;
              }).then((data) => {
                 RequestService.getViewCredits(currentToken).then((response) => {
                    if (response.status == 200) {
                       dispatch(userCredits(response.data.creditsRemaining));
                       setCredits(response.data.creditsRemaining);
                    }
                 });
              });
           } else {
              //setShowForm(true);
           }
          }).catch((err) => {
            setLoadingText("An error occurred loading data. Please try again later");
          });

     }
   }, [editQueryId]);

   const sort_by = (field, reverse, primer) => {

      const key = primer ?
        function(x) {
          return primer(x[field])
        } :
        function(x) {
          return x[field]
        };
    
      reverse = !reverse ? 1 : -1;
    
      return function(a, b) {
        a = key(a); 
        b = key(b); 
        return reverse * ((a > b) - (b > a));
      }
    }



   function calculateScore(responseArray) {
       calcScoreAfterConfirm(responseArray);
   }

   function calcScoreAfterConfirm(responseArray) {
      const title = document.getElementById("userQueryTitle").value;
      responseArray.title = title;
      responseArray.compassRotation = currentRotation;
      if(!isProcessorScreen) {
          RequestService.calculateScore(JSON.stringify(responseArray),currentToken).then((response) => {
             if (response.status == 200) {

                 setShowScore(true)
                 setScore(response.data.score);
                 setOverallRating(getStatus(response.data.ratingCode));
                 //setShowForm(false);
                 setScoreData(response.data.scoringDataSetList);
                 dispatch(setQueryId(editQueryId));
                 showReportView();
                 RequestService.getViewCredits(currentToken).then((response) => {
                   if (response.status == 200) {
                      setCredits(response.data.creditsRemaining);
                      dispatch(userCredits(response.data.creditsRemaining));
                      document.getElementById('userQueryTitle').autofocus = true;
                   } else if (response.status == 403) {
                      if (currentCredit == 0) {
                         toast.error("Calculate - Vastuscore™ failed as there are not enough credits to process the request");
                      }
                   }
                });
             }
           })
           .catch((err) => {
             console.log(err);
             if (currentCredit == 0) {
                toast.error("Calculate - Vastuscore™ failed as there are not enough credits to process the request");
             }
         });
      } else {
           RequestService.calculateScoreForProcessor(JSON.stringify(responseArray),currentToken).then((response) => {
              if (response.status == 200) {

                  setShowScore(true)
                  setScore(response.data.score);
                  setOverallRating(getStatus(response.data.ratingCode));
                  //setShowForm(false);
                  setScoreData(response.data.scoringDataSetList);
                  toast.success("Calculate - Vastuscore™ completed, click Back To List button.");
              }
            })
            .catch((err) => {
              console.log(err);
                 toast.error("Calculate - Vastuscore™ failed");
          });

      }

   }

   function viewScore(queryId, currentToken) {
        if(!isProcessorScreen) {
         RequestService.getViewScore(queryId,currentToken).then((response) => {
            if (response.status == 200) {
   
                setShowScore(true);
                //setScoreView(response.data.score);
                setScore(response.data.score);
                setOverallRating(getStatus(response.data.ratingCode));
                //setOverallRatingView(getStatus(response.data.ratingCode));
                //setScoreDataView(response.data.scoringDataSetList);
                //setShowForm(false);
                setScoreData(response.data.scoringDataSetList);
            }
          })
          .catch((err) => {
            console.log(err);
        });
        } else {
                 RequestService.getViewScoreForProcessor(queryId,currentToken).then((response) => {
                    if (response.status == 200) {

                        setShowScore(true);
                        //setScoreView(response.data.score);
                        setScore(response.data.score);
                        setOverallRating(getStatus(response.data.ratingCode));
                        //setOverallRatingView(getStatus(response.data.ratingCode));
                        //setScoreDataView(response.data.scoringDataSetList);
                        //setShowForm(false);
                        setScoreData(response.data.scoringDataSetList);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                });
        }
      }

      function saveUserQuery(responseArray) {
         const title = document.getElementById("userQueryTitle").value;
         if (title === "") {
             toast.error("Title is required");
             return;
         }
         const queryObj = {};
         queryObj.title = title;
         queryObj.query = responseArray;
         queryObj.imageSessionId = sessionId;
         queryObj.compassRotation = currentRotation;
         var insertRecord = true;
         if (editQueryId != 0) {
             queryObj.queryId = editQueryId;
             insertRecord = false;
         }
         RequestService.saveUserQuery(queryObj, currentToken).then((response) => {
             if (response.status == 200) {
                 var messageToDisplay;
                 toast.info(messageToDisplay);
             } else {
                 toast.error('An error occurred saving the report. Please try again later.');
             }
             
         })
 
      }
     usePageView('Edit Score');

      return (
         <Box sx={{ width: '100%', display: isMobile ? 'relative' : 'flex'}}  alignContent={'center'} align={'center'} justifyContent={'center'}>
            <ToastContainer position='top-center' theme='dark'/>
            <VideoBar/>
            {currentUser? (
             <Box width="100%">
               {showScore ? 
               <Box mt={1} align="center" width="90vw">
               {buttonName ==  'My Reports' ? <div><Button size='small' variant='contained' onClick={() => backToReports(score, scoreData, overallRating)}  align="left">{buttonName}</Button>&nbsp; 
                  {showReport ? <Button size='small' variant='contained' onClick={showEditReport}  align="left">Edit</Button>: 
                     <Button size='small' onClick={showReportView}  align="left" variant='contained'>View Report</Button>}
                  </div>: <div>{!isProcessorScreen ? <Button size='small' onClick={gotoReports}  align="left" variant='contained'>My Reports</Button> :''} &nbsp;<Button onClick={() => backToReports(score, scoreData, overallRating)}  size='small' align="left" variant='contained'>{buttonName}</Button></div>}
               </Box>
               : ''}
               <br></br>
               {!isMobile?<Alert severity="warning" className='centeredAlertContent'>
               <Typography variant='body2'>Attention! Adjusting directions requires score recalculation. Click "Calculate - Vastuscore™" to update scores, Clicking "Save" only stores name changes. </Typography>
               <Typography variant='body2'>For a comprehensive understanding, review our important disclaimers and guidelines in the website footer. The Vastuscore™, rating, and report are provided for informational purposes.</Typography>

               </Alert>:''}
               <Button onClick={openLearnMore}>Learn More</Button> 
               <Box sx={{ width:"90vw", display: isMobile ? 'relative' : 'flex'}} align='left'>
               {showScore ? (  
                  
                  <ShowScore score={score} overallRating={overallRating} scoreData={scoreData} reloadPage={reloadPage} title={title} editQueryId={editQueryId} showReport={showReport} isProcessorScreen={isProcessorScreen}/>): ''}
               {showForm ? 
                  <ShowForm calculateScore={calculateScore} roomParameters={roomParameters} queryId={editQueryId} editMode={true} title={title} setTitle={setTitle} isProcessorScreen={isProcessorScreen}/> :''
               }    
                               {showReport ?  <Box sx={{ width: '100%', marginLeft: isMobile ? '1px':'5px' }}><br></br>
    <Box>
     {loadingReport ? (
       // Render a loading indicator or message instead of the download button
       <Box width={"100%"} display={"flex"} alignContent={"center"} justifyContent={"center"} flexDirection="column" align="center">
       <LinearProgress/>
       <p>Loading Report</p>
       </Box>
     ) : (
        
       // Render the download button only when the report is loaded
       <Box display={"flex"} alignContent={"center"} justifyContent={"center"} flexDirection="column" align="center" >
       {showReport && showPdfViewer ? (
       <div>
       <PDFViewerHeader downloadPdf={downloadPdf} zoomIn={zoomIn} zoomOut={zoomOut} handleViewPrint={handleViewPrint} pageNumber={pageNumber} numPages={numPages}/>
     <div className='pdf-viewer-container'>
       <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} mobile={true}>
       
         {Array.from(new Array(numPages), (el, index) => (
           <div key={`page_container_${index + 1}`} className='pdf-page'>
             <Page
               key={`page_${index + 1}`}
               pageNumber={index + 1}
               renderTextLayer={false}
               scale={defaultScale}
               renderAnnotationLayer={false}
               className="pdf-page-content"
             />
           </div>
         ))}
       </Document>
     </div>
     </div>  
     ) : (
       <div className="loading-error-message">
          {messageText}
       </div>
     )}

       </Box>
     )}

   </Box>
                           </Box>: ''}
                  {currentUser && showImage ? <ShowImages sessionId={sessionId} editMode={true} queryId={editQueryId}/>: ''}    
               </Box>
               <Dialog
                  maxWidth={"md"}
                  open={openHelp}
                >
                  <DialogContent>
                        <MarkdownViewerFromFile mdFilePath="./help/reports_help.md" />
                    </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseHelp()}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>
           </Box> 
            ): ''}

         </Box>
         
      )
}

export default EditScore;