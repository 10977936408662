import React, { useState } from 'react';
import { useEffect } from 'react';
import RequestService from '../RequestService';
import { selectCurrentUser, selectCurrentToken, selectCurrentCredit } from '../authSlice';
import { useSelector } from 'react-redux';
import { Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ModalFeedback from './ModalFeedback';
import { FaVideo } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
const VideoBarUpload = () => {   
const [credits, setCredits] = useState([]);
const [openVideo, setOpenVideo] = useState(false);
const [sourceVideo, setSourceVideo] = useState();
const currentCredit = useSelector(selectCurrentCredit);
const currentToken = useSelector(selectCurrentToken);
const [showFeedbackForm, setShowFeedbackForm] = useState(false);
const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
function toggleFeedback() {
    setOpenVideo(!openVideo);
    setSourceVideo("https://www.youtube.com/embed/WOKzh5oWVxs?si=yHP8kirl12mqNGrv&rel=0&hd=1");
}

function handleClose() {
    setOpenVideo(false);
}

useEffect(() => {
    if (currentToken) {
        RequestService.getViewCredits(currentToken).then((response) => {
            if (response.status == 200) {
               setCredits(response.data.creditsRemaining);   
            }
         });
    }
}, [currentCredit]);      
 return (
    currentToken ?<Box flexDirection="column" justifyContent={isMobile? "bottom":"center"} textAlign="center" style={{ backgroundColor: 'lightgray', height: '55px', width: isMobile ? '150px' : '200px', position: 'fixed', top: "20vh", right: isMobile ? '-75px' : '-75px', display:'flex', zIndex: 100, transform: "rotate(270deg)" }}>
        <Dialog
                  fullWidth={true}
                  maxWidth={"md"}
                  open={openVideo}
                >
                  <DialogTitle>Step by Step Instructions</DialogTitle>
                  <DialogContent>
                  {isMobile ? <iframe src={sourceVideo}
                    title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allow="fullscreen;" allowfullscreen>
                    </iframe>: <iframe height="400" width="800" src={sourceVideo}
                    title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allow="fullscreen;" allowfullscreen>
                    </iframe>}
                    
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                  </DialogActions>
              </Dialog>
        
        <Button variant='text' size="small" onClick={toggleFeedback}><FaVideo/>&nbsp; Show me how</Button>
        {isMobile ? '': <Typography variant='caption' color={"black"}>Step by Step Instructions</Typography>}
    </Box>: ''
    );
    
  };
export default VideoBarUpload