import { useState } from "react";
import { selectCurrentToken } from "../authSlice";
import { useSelector } from "react-redux";
import { FaFileCode, FaFilePdf} from "react-icons/fa";
import RequestService from "../RequestService";
import { Tooltip } from "react-tooltip";
import { Modal } from "@mui/material";
import {Box, Typography} from "@mui/material";
import { FaCopy } from "react-icons/fa";
export function LinkComponentCustom({queryId, viewReport, editReport, shareQueryId, reportName}) {
    const [link, setLink] = useState([]);
    const [embedLink, setEmbedLink] = useState([]);
    const [showLink, setShowLink] = useState(false);
    const [message, setMessage] = useState([]);
    const currentToken = useSelector(selectCurrentToken);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
      
    function getSharedReportLink() {
        setMessage([]);
        setLink([]);
        setEmbedLink([]);
        var hardcodedData = "/getSharedReport?reportId=" + shareQueryId;
        var fullUrl = window.location.protocol  + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + hardcodedData;
        navigator.clipboard.writeText(fullUrl);
    }

    function getEmbedReportLink() {
      setMessage([]);
      setLink([]);
      setEmbedLink([]);
      var hardcodedData = "/getSharedReport?reportId=" + shareQueryId;
      var fullUrl = window.location.protocol  + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + hardcodedData;
      navigator.clipboard.writeText(fullUrl);
  }

    function setShowLinkInModal(flag) {
        setShowLink(flag)
    }

    function copyLink(data) {
         // Copy the text inside the text field
        navigator.clipboard.writeText(data);
      }

      function copyEmbedLink() {
        // Get the text field
        var copyText = document.getElementById("embedLink");
         // Copy the text inside the text field
        navigator.clipboard.writeText(embedLink);
      }

    return (
            <p className="reportaction-font">
            <FaFilePdf id={"view_"+ queryId} onClick={viewReport} data-tooltip-content="View Report"/>&nbsp;&nbsp;
            <Tooltip anchorId={"view_"+ queryId} place="top"/>
            {shareQueryId != null ? <FaCopy id={"shareId_"+ queryId} onClick={getSharedReportLink} data-tooltip-content="Copy Sharable Link"/>: ''}
            <Tooltip anchorId={"shareId_"+ queryId} place="top"/> 
            &nbsp;
            {shareQueryId != null ? <FaFileCode id={"embedId_"+ queryId} onClick={getSharedReportLink} data-tooltip-content="Copy Embed Link"/>: ''}
            <Tooltip anchorId={"embedId_"+ queryId} place="top"/>        
            {/* {showLink ?<ModalShowLink url={link} setShowLinkInModal={setShowLinkInModal} message={message}/>: ''} */}
            {false ?<Modal
        open={() => setShowLinkInModal(true)}
        onClose={() => setShowLinkInModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className='titleCloseBtn-link'>
            <button
              onClick={() => {
                setShowLinkInModal(false);
              }}
            >
              X
            </button>
           
          </div>
           Generated Links for {reportName}
          
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <a href={link} id="queryLink">Link</a>&nbsp;<FaCopy onClick={copyLink}/>
            <br></br>        
            <a href="#" id="embedLink">Embed Link</a>&nbsp;<FaCopy onClick={copyEmbedLink}/>
          </Typography>
        </Box>
      </Modal>: ''}
            </p>
    )
}

export default LinkComponentCustom;