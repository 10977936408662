import React, { useState, useEffect } from 'react';
import { FaBoxOpen, FaCalculator, FaFilter, FaInfo, FaMinus, FaPlus, FaSave } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { Tooltip } from 'react-tooltip'
import ModalImage from '../components/ModalImage';
import MaterialModalImage from '../components/MaterialModalImage';
import ModalPadasMaindoor from '../components/ModalPadasMaindoor';
import { useSelector } from 'react-redux';
import { selectCurrentUser, selectCurrentToken, selectCurrentCredit } from '../authSlice';
import { selectSessionId } from '../imageSlice';
import { useDispatch } from 'react-redux';
import { clearSession, saveSession } from '../imageSlice';
import RequestService from '../RequestService';
import { ToastContainer, toast } from 'react-toastify';
import { Alert, AlertTitle, Button, Typography } from '@mui/material';
import MaterialMainDoorModal from '../components/MaterialMainDoorModal';
import DirectionDropDownMaterial from '../components/forms/DirectionDropDownMaterial';
import ModalPolarImage from '../components/ModalPolarChart';
import MaterialModalImageFilter from '../components/MaterialModalImageFilter';
import ModalPadasKitchen from '../components/ModalPadasKitchen';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { selectReportTitle } from '../reportSlice';
import MaterialPieImage from '../components/MaterialPieImage';
import MarkdownViewerFromFile from '../components/MarkdownViewerFromFile';
import ScrollTopFab from '../components/ScrollToTopFab';
import { useNavigate } from 'react-router-dom';
import { Box} from '@mui/material';
import usePageView from '../components/usePageView'; // Import the custom hook
import config from '../config';

const ShowForm = ({ calculateScore, roomParameters, queryId, editMode, setShowScore, reloadPage, setTitle, title, isProcessorScreen }) => {
    const [modalParameter, setModalParameter] = useState([]);
    const [formDialogOpen, setFormDialogOpen] = useState(false);
    const [formRoomParameters,setFormRoomParameters] = useState(roomParameters);
    const [modalIndex, setModalIndex] = useState(0);
    const [valueChanged, setValueChanged] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [showForm, setShowForm] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedField, setSelectedField] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const currentCredit = useSelector(selectCurrentCredit);
    const [modalOpenStreetFocus, setModalOpenStreetFocus] = useState(false);
    const [modalOpenPieImage, setModalOpenPieImage] = useState(false);
    const [mainDoorModalOpen, setMainDoorModalOpen] = useState(false);
    const [modalOpenPadas32, setModalOpenPadas32] = useState(false);
    const [modalOpenPadas16, setModalOpenPadas16] = useState(false);
    const currentUser = useSelector(selectCurrentUser);
    const sessionId = useSelector(selectSessionId);
    const currentToken = useSelector(selectCurrentToken);
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const [responseToSave, setResponseToSave] = useState([]);
    const reportTitle = useSelector(selectReportTitle);
    const [openHelp, setOpenHelp] = useState(false);
    const navigate = useNavigate();
    const [userRoles, setUserRoles] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);

    function openLearnMore() {
      setOpenHelp(true);
     }
   
     function handleCloseHelp() {
      setOpenHelp(false);
     }

     function buyCredit() {
      navigate('/buycredits');
  }

   const sort_by = (field, reverse, primer) => {

    const key = primer ?
      function(x) {
        return primer(x[field])
      } :
      function(x) {
        return x[field]
      };
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      a = key(a); 
      b = key(b); 
      return reverse * ((a > b) - (b > a));
    }
  }

  useEffect(() => {
    const cloneRoomParameters = [...formRoomParameters];
    cloneRoomParameters.map((roomParameter, index) => {
      if(roomParameter.objectCode + '_' + index == selectedField) {
        roomParameter.matchCondition = selectedValue;
      }
    });
    setFormRoomParameters(cloneRoomParameters);
 }, [valueChanged]);

    useEffect(() => {
      // Fetch user roles and update state
      if (currentToken) {
        RequestService.getUserRoles(currentToken).then((response) => {
          if (response.status === 200) {
            setUserRoles(response.data.map(role => role.name)); // Extract role names
          }
        });

      fetch(config[process.env.NODE_ENV].backendUrl + '/getAllRequestorsForProcessor', {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: 'Bearer ' + currentToken,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 403) {
            // Handle unauthorized access
            console.log('Access is denied, token may have expired.');
          }
        })
        .then((data) => {
          setUsers(data || []); // Ensure data is an array or default to an empty array
        })
        .catch((err) => {
          console.log(err.message);
        });
      }
    }, [currentToken]);


     function addAnother(roomParameter) {
        const cloneRoomParameter = { ...roomParameter };
        cloneRoomParameter.allowMultiple = false;
        cloneRoomParameter.allowRemove = true;
        setFormRoomParameters(formRoomParameters => [...formRoomParameters, cloneRoomParameter].sort(sort_by('sequenceNumber', false, parseInt)));
     }
     
     function validateAndScore() {
        var responseArray = [];
        var errorArray = [];
        var labelsArray = [];
        var proceed = true;
        formRoomParameters.map((roomParameter, index) => {
            var obj = {};
            const errors = {};
            obj.objectCode = roomParameter.objectCode;
            obj.matchCondition  = document.getElementById(roomParameter.objectCode + '_' + index).value;
            if (roomParameter.mandatory && document.getElementById(roomParameter.objectCode + '_' + index).value === "") {
               errors.objectCode = roomParameter.objectCode
               errors.display = roomParameter.objectDisplayName + ' direction is required for score calculation';
               errorArray.push(errors);
               proceed = false;
            }
           responseArray.push(obj)
           labelsArray.push(roomParameter.objectDisplayName); 
           return true; 
        });
        setFormErrors(errorArray);
        if (!proceed) {
            return;
        }
        setResponseToSave(responseArray);
        setFormDialogOpen(true);
     }

     function removeRoom(roomParameter, index) {
        const cloneRoomParameter = { ...roomParameter };
        const cloneRoomParameters = [...formRoomParameters];
        cloneRoomParameters.splice(index, 1);
        setFormRoomParameters(cloneRoomParameters.sort(sort_by('sequenceNumber', false, parseInt)));
     }

     function resetFields() {

        formRoomParameters.map((roomParameter, index) => {
          document.getElementById(roomParameter.objectCode + '_' + index).value = "";
        });
        
     }

     function setDropDownValue(id, value) {
        //document.getElementById(id).value = value;        
        setSelectedField(id);
        setSelectedValue(value);
        document.getElementById(id).value = value;
        setValueChanged(!valueChanged);
     }

     function handleClose() {
      setResponseToSave([]);
      setFormDialogOpen(false);
     }


     function calculateAfterConfirm() {
      try {
        var title = document.getElementById('titlePopup').value;
        setTitle(title);
      } catch(e) {
        //Ignore if unable to read
        const title = document.getElementById("userQueryTitle").value;
        setTitle(title);
      }

      var object = {};
      object.title = title;
      object.queryId = queryId;
      object.query = responseToSave;
      if((userRoles.includes('Processor') && queryId == null) ) {
        object.endUserId = selectedUser.id;
      }
      setFormDialogOpen(false);
      calculateScore(object);
     }

    usePageView('Calculate - Vastuscore™ Confirmation Form');

    return(
        <Box mr={1} ml={1} mb={1}>
                <Dialog open={formDialogOpen} onClose={handleClose} maxWidth="md" fullWidth>
                  <DialogTitle>Confirm</DialogTitle>
                  <DialogContent>
                   {(userRoles.includes('Processor') && queryId == null) ?

                     <label>
                       <span style={{ fontWeight: 'bold' }}>Who are you creating the report for : </span>
                       <select
                         value={selectedUser ? selectedUser.id : ''}
                         onChange={(e) => setSelectedUser(users.find(user => user.id === parseInt(e.target.value, 10)))}
                         // Add any additional styling or props you may need
                       >
                         <option value="" disabled>
                           Choose a user
                         </option>
                         {users.map((user) => (
                           <option key={user.id} value={user.id}>
                             {user.name}
                           </option>
                         ))}
                       </select>
                       <br></br>
                       <br></br>
                     </label>

                  : '' }
                  {!isProcessorScreen && !userRoles.includes('Processor') ?
                     <DialogContentText>
                      {currentCredit > 0 ?
                        <Typography variant='body'>You have {currentCredit} Credits. Calculate - Vastuscore™ will consume 1 credit. Please confirm with proceed button below.</Typography>
                        : <Typography variant='body'>You have no Credits to Continue. Please purchase credits to continue.</Typography>}
                     </DialogContentText>
                     :''}

                     {(title == null && (currentCredit > 0 || userRoles.includes('Processor'))) ? ( <TextField
                        autoFocus
                        margin="dense"
                        id="titlePopup"
                        label="Enter a title for your report"
                        type="text"
                        fullWidth
                        variant="standard"
                        defaultValue={title}
                     />)
                   : 'Click Proceed to Confirm' }
                  </DialogContent>
                  <DialogActions>
                  {currentCredit > 0 || isProcessorScreen || userRoles.includes('Processor') ? <Button variant="contained" onClick={calculateAfterConfirm} size='small'>Proceed</Button>: <Button variant="contained" onClick={handleClose} size='small'>Buy Credits</Button>}
                     <Button onClick={handleClose} size='small'>Cancel</Button>
                  </DialogActions>
            </Dialog>
  

        {modalOpen ? <MaterialModalImageFilter displayName={modalParameter.objectDisplayName} id={modalParameter.objectCode + '_' + modalIndex} options={modalParameter.matchingConditions} setOpenModal={setModalOpen} setDropDownValue={setDropDownValue} filterId={modalParameter.filterId} selectedValue={selectedValue} optionCode={modalParameter.objectCode}/>: ''}
        {modalOpenStreetFocus ? <ModalPolarImage displayName={modalParameter.objectDisplayName} id={modalParameter.objectCode + '_' + modalIndex} options={modalParameter.matchingConditions} setOpenModal={setModalOpenStreetFocus} setDropDownValue={setDropDownValue} selectedValue={selectedValue}/>: ''}
        {mainDoorModalOpen ? <MaterialMainDoorModal displayName={modalParameter.objectDisplayName} id={modalParameter.objectCode + '_' + modalIndex} options={modalParameter.matchingConditions} setMainDoorOpenModal={setMainDoorModalOpen} setDropDownValue={setDropDownValue} selectedValue={selectedValue}/>: ''}
        {modalOpenPadas32 ? <ModalPadasMaindoor displayName={modalParameter.objectDisplayName} id={modalParameter.objectCode + '_' + modalIndex} options={modalParameter.matchingConditions} setOpenModal={setModalOpenPadas32} setDropDownValue={setDropDownValue} selectedValue={selectedValue}/>: ''}
        {modalOpenPadas16 ? <ModalPadasKitchen displayName={modalParameter.objectDisplayName} id={modalParameter.objectCode + '_' + modalIndex} options={modalParameter.matchingConditions} setOpenModal={setModalOpenPadas16} setDropDownValue={setDropDownValue} selectedValue={selectedValue}/>: ''}
        {modalOpenPieImage ? <MaterialPieImage displayName={modalParameter.objectDisplayName} id={modalParameter.objectCode + '_' + modalIndex} options={modalParameter.matchingConditions} setMainDoorOpenModal={setModalOpenPieImage} setDropDownValue={setDropDownValue} selectedValue={selectedValue}/>: ''}

         <fieldset>

            <legend>Select the best direction/option</legend>
            {formErrors.length > 0 && (
        <Alert severity='error' width="100%" align="left">
          <AlertTitle>Error</AlertTitle>
          <ul>
            {formErrors.map((item, i) => {
              return <li key={item.objectCode}>{item.display}</li>;
            })}
          </ul>
        </Alert>
      )}   
      {currentCredit == 0 && !isProcessorScreen && !userRoles.includes('Processor')?<Alert severity='error' width="100%" align="left">
          <AlertTitle>Error</AlertTitle>
          <ul>
            <li>You do not have enough credits to Calculate - Vastuscore™</li>
          </ul>
        </Alert>: ''
      }   
         <table className='App-table'>
           <tbody>
           {
             formRoomParameters.map((roomParameter, index) => {
               return <tr key={roomParameter.objectCode + '_' + index}>
               <td width={"0%"}>
                <span id={roomParameter.objectDisplayName} data-tooltip-content={'Add another ' + roomParameter.objectDisplayName}>
                  {roomParameter.allowMultiple ? 
                 <FaPlus onClick={() => addAnother(roomParameter)}/>
                  : roomParameter.allowRemove ? <FaMinus onClick={() => removeRoom(roomParameter, index)} /> : ''}</span>
                  <Tooltip anchorId={roomParameter.objectDisplayName}/></td>   
               <td width={"45%"}>{roomParameter.mandatory ? <Typography variant='body2'><span>{roomParameter.objectDisplayName}<span className='mandatory'>*</span></span></Typography>: <Typography variant='body2'>{roomParameter.objectDisplayName}</Typography> }</td>
               <td width={"55%"}>{
               roomParameter.uiControlType == 'picklist' || roomParameter.uiControlType == 'picklist_helper' ? 
               <div>
               <DirectionDropDownMaterial key={roomParameter.matchCondition} id={roomParameter.objectCode + '_' + index} options={roomParameter.matchingConditions} value={roomParameter.matchCondition} isMobile={isMobile}/>
               {roomParameter.uiControlType == 'picklist_helper' && sessionId ? <Button sx={{ minHeight: 0, minWidth: 0, padding: 1 }} size="small" onClick={() => {
                setMainDoorModalOpen(true);
                setModalParameter(roomParameter);
                setModalIndex(index);
                setSelectedValue(document.getElementById(roomParameter.objectCode + '_' + index).value);
             }}>{isMobile ? <FaFilter/>: "Pick"}</Button>: roomParameter.uiControlType == 'picklist_helper' && roomParameter.objectCode == 'main_door' ? <Button sx={{ minHeight: 0, minWidth: 0, padding: 1 }} size="small" onClick={() => {
              setMainDoorModalOpen(true);
              setModalOpenPadas32(false);
              setModalParameter(roomParameter);
              setModalIndex(index);
              setSelectedValue(document.getElementById(roomParameter.objectCode + '_' + index).value);
           }}>{isMobile ? <FaFilter/>: "Pick"}</Button>: roomParameter.uiControlType == 'picklist_helper' && roomParameter.objectCode == 'kitchen' ? <Button sx={{ minHeight: 0, minWidth: 0, padding: 1 }} size="small" onClick={() => {
            setMainDoorModalOpen(true);
            setModalParameter(roomParameter);
            setModalIndex(index);
            setSelectedValue(document.getElementById(roomParameter.objectCode + '_' + index).value);
         }}>{isMobile ? <FaFilter/>: "Pick"}</Button>:''}
               </div>
               : 
               <div>
               <input readOnly='true' size={25} type='text' key={roomParameter.objectCode + '_' + index} id={roomParameter.objectCode + '_' + index} defaultValue={roomParameter.matchCondition} name={isMobile ? "showFormTextMobile": "showFormText"}/>   
               {roomParameter.uiControlType == 'image_picklist' ?
              <Button sx={{ minHeight: 0, minWidth: 0, padding: 1 }} size="small" id="plotshape" data-tooltip-content='click to select image depicting your plot' onClick={() => {
                setModalParameter(roomParameter);
                setModalIndex(index);
                setSelectedValue(document.getElementById(roomParameter.objectCode + '_' + index).value);
                setModalOpen(true);
             }}>{isMobile ? <FaFilter/>: "Pick"}</Button>:  roomParameter.uiControlType == 'pie_image_picklist' ?            
             <Button sx={{ minHeight: 0, minWidth: 0, padding: 1 }} size="small" id="plotshape" data-tooltip-content='click to select a plot street focus' onClick={() => {
              setModalParameter(roomParameter);
              setSelectedValue(document.getElementById(roomParameter.objectCode + '_' + index).value);
              setModalIndex(index);
              setModalOpenPieImage(true);
           }}>{isMobile ? <FaFilter/>: "Pick"}</Button>:
              <Button sx={{ minHeight: 0, minWidth: 0, padding: 1 }} size="small" id="plotshape" data-tooltip-content='click to select a plot street focus' onClick={() => {
                setModalParameter(roomParameter);
                setSelectedValue(document.getElementById(roomParameter.objectCode + '_' + index).value);
                setModalIndex(index);
                setModalOpenStreetFocus(true);
            }}>{isMobile ? <FaFilter/>: "Pick"}</Button>
           }
             
             </div>
             
             }
                  <Tooltip anchorId="plotshape"/>
       
               </td>
               </tr>;
           })}
          </tbody>
          <tfoot>
          
            <tr>
               <td>

               </td>
               <td>
                {currentCredit && !isProcessorScreen > 0 ?
              <Button onClick={buyCredit} variant="contained" size='small' color='warning'>Buy More Credits</Button>
               :''}
              
               </td>
               <td>
               
               <Button onClick={validateAndScore} variant="contained" size='small'>Calculate - Vastuscore™</Button>
               </td>
            </tr>
            <tr>
               <td>
              {!isProcessorScreen ? <Tooltip anchorId="learnMore"/>:''}
              {!isProcessorScreen ? <span id="learnMore" data-tooltip-content="Learn More how credits work"><FaInfo onClick={openLearnMore}/></span> :''}
               </td>
               <td>
               {!isProcessorScreen ?
               <Typography variant='subtitle2'>Your balance: {currentCredit}</Typography>:''
               }
               </td>
               <td>
                    
                {!isProcessorScreen ?
                 <Typography variant='body2'>(1 credit will be used)</Typography> :''}
               </td>
            </tr>
            <tr>
              <td colSpan={3} align='center'>
              {editMode || queryId != null ? '' : <Button size="small" onClick={reloadPage}>Reset Fields</Button>}
              </td>
            </tr>
          </tfoot>
       </table>
       
       </fieldset> 
       <Dialog
                  maxWidth={"md"}
                  open={openHelp}
                >
                  <DialogContent>
                        <MarkdownViewerFromFile mdFilePath="./help/credits_help.md" />
                    </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseHelp()}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>
       </Box>

    )
}

export default ShowForm