import { configureStore } from "@reduxjs/toolkit";
import authReducer from './authSlice';
import imageReducer from './imageSlice';
import reportReducer from './reportSlice';
import angleReducer from './angleSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,   
        image: imageReducer, 
        report: reportReducer,
        angle: angleReducer
    },
    devTools: true
})