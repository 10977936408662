import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentToken, selectCurrentUser } from '../authSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import RequestService from '../RequestService';
import DataTable, { createTheme, defaultThemes } from 'react-data-table-component';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { setQueryId } from '../reportSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditScore from './editScore';
import ListCustomReports from './listcustomreports';
import { Typography } from '@mui/material';
import config from '../config';
createTheme('light', {
    rows: {
        fontSize: '20px'
    }
});

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;


const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
    		<TextField
    			id="search"
    			type="text"
    			placeholder="Filter By Name"
    			aria-label="Search Input"
    			value={filterText}
    			onChange={onFilter}
    		/>
    			<FaTimes onClick={onClear}/>
    	</>
    );

export function MyCustomReports() {
    const token = useSelector(selectCurrentToken);
    const [userQueries, setUserQueries] = useState([]);
    const [showReport, setShowReport] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [pending, setPending] = useState(true);
    const [pdfContent, setPdfContent] = useState([]);
    const [messageText, setMessageText] = useState('Loading Saved reports')
    const [selectedRows, setSelectedRows] = React.useState([]);
	const [toggleCleared, setToggleCleared] = React.useState(false);
    const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const columns = [
        { name: 'Saved Report Title', selector: row => row.title, width:300}
      ];

      const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

    const filteredItems = userQueries.filter(
		item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()),
	);



      const customStyles = {
        	header: {
        		style: {
        			minHeight: '56px',
        		},
        	},
        	headRow: {
        		style: {
        			borderTopStyle: 'solid',
                    backgroundColor: "#1976d2",
        			borderTopWidth: '1px',
        			borderTopColor: defaultThemes.default.divider.default,
        		},
        	},
        	headCells: {
        		style: {
        			'&:not(:last-of-type)': {
        				borderRightStyle: 'solid',
        				borderRightWidth: '1px',
        				borderRightColor: defaultThemes.default.divider.default,
        			},
        		},
        	},
        	cells: {
        		style: {
        			'&:not(:last-of-type)': {
        				borderRightStyle: 'solid',
        				borderRightWidth: '1px',
        				borderRightColor: defaultThemes.default.divider.default,
        			},
        		},
        	},
            rows: {
                highlightOnHoverStyle: {
                    color: defaultThemes.default.highlightOnHover.text,
                    backgroundColor: "#97c1ec;",
                    transitionDuration: '0.15s',
                    transitionProperty: 'background-color',
                    borderBottomColor: defaultThemes.default.background.default,
                    outlineStyle: 'solid',
                    outlineWidth: '1px',
                    outlineColor: defaultThemes.default.background.default,
                },
            },
        };

    function backToReports() {
        setShowEdit(false);
        setShowReport(false);
    }
 
    function loadReport(queryId) {
 
        RequestService.getSellerReportAsPDF(queryId, token).then((response) => {
            if (response.status == 200) {
                setShowReport(true);
                
                 var blob = new Blob([response.data], {
                     type: 'application/pdf'
                 })
                 var objectUrl = URL.createObjectURL(blob);
                 var iframe = document.getElementById('reportFrame');
                 iframe.removeAttribute('srcdoc');
                 iframe.src = objectUrl;
                 iframe.style.display = '';
             } else if (response.status == 403) {
                setMessageText("Access is denied, token may have expired please logout and login again");
            }

         });
    }

    const handleRowClicked = row => {
        loadReport(row.queryId);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);

    return (
        <Typography variant='body2'>
       {token ? (
        <div className='App-profile'>
            
            {showEdit ? <EditScore backToReports={backToReports}/> :
            <ListCustomReports setShowEdit={setShowEdit}/>
        }
        
        </div>): <div id='accessMessage'><Typography variant='body1'>Access denied, user must be logged in to access the reports</Typography></div>}
        </Typography>
    )
}

export default MyCustomReports;