import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const PhotoCarousel = ({ images }) => {
  const [isFullSize, setIsFullSize] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imageAspectRatios, setImageAspectRatios] = useState([]);

  useEffect(() => {
    const aspectRatios = images.map((imageObj) => {
      const img = new Image();
      img.src = imageObj.image;
      return img.width / img.height;
    });
    setImageAspectRatios(aspectRatios);
  }, [images]);

  const toggleSize = () => {
    setIsFullSize(!isFullSize);
  };

  const handleImageChange = (index) => {
    setSelectedImageIndex(index);
  };

  const calculateMaxWidth = (index) => {
    if (isFullSize || imageAspectRatios[index] >= 1) {
      return '100%';
    } else {
      return '440px';
    }
  };

    const calculateMaxHeight = (index) => {
      if (isFullSize || imageAspectRatios[index] >= 1) {
        return '560px';
      } else {
        return '100%';
      }
    };

  const photoStyle = {
    maxWidth: calculateMaxWidth(selectedImageIndex),
    maxHeight: calculateMaxHeight(selectedImageIndex),
    cursor: 'pointer',
  };

  const thumbnailStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '10px',
  };

    const descriptionStyle = {
      textAlign: 'center',
      fontWeight: 'normal', // Make text bold
      fontSize: '18px', // Increase font size
    };

  return (
    <div className="photo-carousel">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: '1' }}>
          <Carousel
            selectedItem={selectedImageIndex}
            onChange={handleImageChange}
            showThumbs={false}
          >
            {images.map((imageObj, index) => (
              <div key={index} onClick={toggleSize}>
                <img
                  src={imageObj.image}
                  alt={imageObj.description}
                  style={photoStyle}
                />
                <p style={descriptionStyle}>{imageObj.description}</p>
                <br></br>
              </div>
              
            ))}
          </Carousel>
        </div>
        <div style={thumbnailStyle}>
          {images.map((imageObj, index) => (
            <img
              key={index}
              src={imageObj.image}
              alt={imageObj.description}
              style={{
                width: '100px',
                height: 'auto',
                cursor: 'pointer',
                marginBottom: '10px',
              }}
              onClick={() => setSelectedImageIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhotoCarousel;
