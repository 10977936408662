import React, { useState } from 'react';
import { useEffect } from 'react';
import RequestService from '../RequestService';
import { selectCurrentUser, selectCurrentToken, selectCurrentCredit } from '../authSlice';
import { useSelector } from 'react-redux';
import { Typography, Box, Button } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
const StaticBar = () => {   
const [credits, setCredits] = useState([]);
const currentCredit = useSelector(selectCurrentCredit);
const currentToken = useSelector(selectCurrentToken);
const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
const navigate = useNavigate();
useEffect(() => {
    if (currentToken) {
        RequestService.getViewCredits(currentToken).then((response) => {
            if (response.status == 200) {
               setCredits(response.data.creditsRemaining);   
            }
         });
    }
}, [currentCredit]);    

function buyCredits() {
    navigate('/buycredits');
}
 return (
    currentToken ?<Box className="shadow-md" style={{ backgroundColor: '#D2AC47', height: isMobile ? '70px': '100px', width: isMobile ? '70px': '100px', position: 'fixed', top: isMobile ? "0vh": "8vh", left: 0, display:'relative', zIndex: 110}} justifyContent={'center'} >
    
        <Typography color="primary" variant='h6' align='center'>
            <Typography color="primary" variant='caption' align='center'>Credits</Typography><br></br> <a onClick={buyCredits}>{currentCredit}</a></Typography>
        {isMobile ? '': <Button size='small' onClick={buyCredits}>Buy Credits</Button>}
    </Box>: ''
    );
  };
export default StaticBar