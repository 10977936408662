import { useState } from "react";
import { selectCurrentToken } from "../authSlice";
import { useSelector } from "react-redux";
import { setQueryId } from '../reportSlice';
import { useDispatch } from 'react-redux';
import { FaEdit, FaEye, FaFileCode, FaFilePdf, FaShare } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import RequestService from "../RequestService";
import ModalShowLink from "./ModalShowLink";
import { Modal, Tooltip } from "@mui/material";
import {Box, Typography} from "@mui/material";
import { FaCopy } from "react-icons/fa";

export function LinkComponent({queryId, viewReport, editReport, shareQueryId, reportName}) {
    const [link, setLink] = useState([]);
    const [embedLink, setEmbedLink] = useState([]);
    const [showLink, setShowLink] = useState(false);
    const [message, setMessage] = useState([]);
    const currentToken = useSelector(selectCurrentToken);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    function getSharedReportLink() {
        setMessage([]);
        setLink([]);
        setEmbedLink([]);
        var hardcodedData = "/getSharedReport?reportId=" + shareQueryId;
        var fullUrl = window.location.protocol  + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + hardcodedData;
        navigator.clipboard.writeText(fullUrl);
    }

    function getEmbedReportLink() {
      setMessage([]);
      setLink([]);
      setEmbedLink([]);
      var hardcodedData = "/getSharedReport?reportId=" + shareQueryId;
      var fullUrl = window.location.protocol  + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + hardcodedData;
      navigator.clipboard.writeText(fullUrl);
  }

    function setShowLinkInModal(flag) {
        setShowLink(flag)
    }

    function copyLink(data) {
         // Copy the text inside the text field
        navigator.clipboard.writeText(data);
      }

      function copyEmbedLink() {
        // Get the text field
        var copyText = document.getElementById("embedLink");
         // Copy the text inside the text field
        navigator.clipboard.writeText(embedLink);
      }

    return (
            <p className="reportaction-font">
            <Tooltip title="View Report" placement="top" arrow>
              <FaFilePdf id={"view_"+ queryId} onClick={viewReport}/>&nbsp;&nbsp;
            </Tooltip>  
            <Tooltip title="Edit" placement="top" arrow>
            <FaEdit id={"edit_"+ queryId} onClick={editReport}/>&nbsp;&nbsp;
            </Tooltip>
            
            {shareQueryId != null ? <Tooltip title="Copy Sharable Link" placement="top" arrow><FaCopy id={"shareId_"+ queryId} onClick={getSharedReportLink}/></Tooltip>: ''}
            &nbsp;
            {shareQueryId != null ? <Tooltip title="Copy Embed Link" placement="top" arrow><FaFileCode id={"embedId_"+ queryId} onClick={getSharedReportLink}/></Tooltip>: ''} 
            </p>
    )
}

export default LinkComponent;