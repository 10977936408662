import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import RequestService from '../RequestService';
import DataTable, { createTheme, defaultThemes } from 'react-data-table-component';
import styled from 'styled-components';
import { setQueryId } from '../reportSlice';
import { useMediaQuery } from 'react-responsive';
import { Typography, Box, Collapse, IconButton, Alert, Button } from '@mui/material';
import config from '../config';
import usePageView from '../components/usePageView'; // Import the custom hook
import { useDispatch } from 'react-redux';
import {selectCurrentToken, userCredits} from '../authSlice'

createTheme('light', {
    rows: {
        fontSize: '15px'
    }
});

const customStyles = {
    header: {
        style: {
            minHeight: '56px',
        },
    },
    headRow: {
        style: {
            borderTopStyle: 'solid',
            backgroundColor: "#1976d2",
            borderTopWidth: '1px',
            borderTopColor: defaultThemes.default.divider.default,
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
            },
        },
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
            },
        },
    },
    rows: {
        highlightOnHoverStyle: {
            color: defaultThemes.default.highlightOnHover.text,
            backgroundColor: "#97c1ec;",
            transitionDuration: '0.15s',
            transitionProperty: 'background-color',
            borderBottomColor: defaultThemes.default.background.default,
            outlineStyle: 'solid',
            outlineWidth: '1px',
            outlineColor: defaultThemes.default.background.default,
        },
    },
};    

export function ListOrders() {
    usePageView('List Orders');

    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const token = useSelector(selectCurrentToken);
    const [userOrders, setUserOrders] = useState([]);
    const [pending, setPending] = useState(true);
    const [messageText, setMessageText] = useState([]);
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [userDataChanged, setUserDataChanged] = useState(true);
    const dispatch = useDispatch();
    
    const columns = [
        { name: 'Id', selector: row => <Typography variant='body2'>{row.orderId}</Typography>, width:isMobile?"10vw":"12%", center: true},
        { name: 'Product', selector: row => <Typography variant='body2'>{row.productTypeDisplayName}</Typography>, width:isMobile?"18vw":"20%", center: true},
        { name: 'Amount', selector: row => <Typography variant='body2'>${row.totalPurchaseAmount}</Typography>, width:isMobile?"18vw":"15%", center: true},
        { name: 'Quantity', selector: row => <Typography variant='body2'>{row.quantity}</Typography>, width:isMobile?"18vw":"12%", center: true},
        { name: 'Credits', selector: row => <Typography variant='body2'>{row.totalCreditsPurchased}</Typography>, width:isMobile?"10vw":"10%", center: true},
        { name: 'Date Purchased', selector: row => <Typography variant='body2'>{new Date(row.orderDateTime).toLocaleString()}</Typography>, width:isMobile?"15vw":"25%", center: true},

    ];
    useEffect(() => {
        if (userDataChanged) {
            loadAllOrders();
            setUserDataChanged(false);
        }
        
    }, [userOrders]);

    function refreshOrderData() {
        
        fetch(config[process.env.NODE_ENV].backendUrl + '/payment/refreshOrderStatus', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status == 200) {
                RequestService.getViewCredits(token).then((response) => {
                    if (response.status == 200) {
                       dispatch(userCredits(response.data.creditsRemaining));
                    }
                 });
                return response.json();
            } else if (response.status == 403) {
                setMessageText("Access is denied, token may have expired please logout and login again");
            }

        })
            .then((data) => {
                if (data.length == 0) {
                    setMessageText('No Saved Reports found');
                }
                setPending(false);
                setUserOrders(data);
                setUserDataChanged(true);
            }).catch((err) => {
                console.log(err.message);
            });
    }

 
    function loadAllOrders() {

        fetch(config[process.env.NODE_ENV].backendUrl + '/orders', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status == 200) {
                return response.json();
            } else if (response.status == 403) {
                setMessageText("Access is denied, token may have expired please logout and login again");
            }

        })
            .then((data) => {
                if (data.length == 0) {
                    setMessageText('No Saved Reports found');
                }
                setPending(false);
                setUserOrders(data);
            }).catch((err) => {
                console.log(err.message);
            });
    }
 
    return (
       token ? (
        <Box style={{display: 'relative', width:isMobile? "90%":"60%"}} alignContent="left">
                    <br></br>
                    <br></br>
                    <Box alignContent={"right"} align="right" justifyContent={"right"} mt="10">
                    <Typography variant='body' align='right'>Click this button if you do not see your order </Typography>
                    <Button variant='contained' size='small' onClick={refreshOrderData}>Refresh</Button>
                    </Box>
                    <DataTable theme="light" title="Orders"
                        columns={columns} data={userOrders}
                        pagination   
                        highlightOnHover 
                        responsive 
                        pointerOnHover 
                        progressPending={pending}
                        customStyles={customStyles}
                        paginationResetDefaultPage={resetPaginationToggle}
                        persistTableHead
                        />
                     </Box>   
        ): <div id="accessMessage">Access denied, user must be logged in to access the reports</div>
    )
}

export default ListOrders;