import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../authSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import RequestService from '../RequestService';
import DataTable, { createTheme, defaultThemes } from 'react-data-table-component';
import loadingImage from '../assets/images/loading.gif';
import styled from 'styled-components';
import { FaQuestionCircle, FaTimes } from 'react-icons/fa';
import { setQueryId } from '../reportSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditScore from './editScore';
import Toggle from 'react-toggle'
import ShareToggle from '../components/ShareToggle';
import ReportActions from '../components/ReportActions';
import { useMediaQuery } from 'react-responsive';
import { Typography, Box, Collapse, IconButton, Alert, Button, Dialog, DialogContent, DialogActions, LinearProgress, Container, Grid, ButtonGroup } from '@mui/material';
import config from '../config';
import MarkdownViewerFromFile from '../components/MarkdownViewerFromFile';
import ScrollTopFab from '../components/ScrollToTopFab';
import { Document, Page, pdfjs } from 'react-pdf';
import ReportCard from '../components/ReportCard';
import CardListComponent from '../components/CardListComponent';
import PDFViewerHeader from '../components/PDFViewerHeader';
import DocumentViewer from '../components/DocumentViewer';
import { Link } from 'react-router-dom';
import SendBackModal from '../components/SendBackModal';
import CommentModal from '../components/CommentModal';
import AwaitingInputModal from '../components/AwaitingInputModal';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

createTheme('light', {
    rows: {
        fontSize: '15px'
    }
});

const TextField = styled.input`
	height: 32px;
	width: 400px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;



const FilterComponent = ({ filterText, onFilter, onClear, open, setOpen, isMobile, hasSubmittedRequests }) => (
    <>
    		<Box align='left' style={{width: isMobile ? "100%": "100%", maxWidth: isMobile ? 500 : 900}}>
            <Typography variant='body2'>Access and review your detailed Vastu Reports for properties you've previously calculated. Understand positive and negative aspects, aiding informed decisions.</Typography>  
           {hasSubmittedRequests && (

                <Typography variant='body2' style={{ marginTop: '5px', marginBottom: '5px' }}>
                  <strong>Note:</strong> It takes a maximum of 2 days to get a response on submitted requests.
                </Typography>
           )}
            <TextField style={{ width: isMobile ? '70%': '30%' }}
    			id="search"
                name="search_filter"
    			type="text"
    			placeholder="Filter By Name"
    			aria-label="Search Input"
    			value={filterText}
    			onChange={onFilter}
    		/>
    		<Button onClick={onClear}>Clear</Button>
            </Box> 
            <br></br>
    	</>
    );

export function ListReports({setShowEdit}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
    const [loadingReport, setLoadingReport] = useState(false);
    const [downloadQueryId, setDownloadQueryId] = useState(null);
    const [hasSubmittedRequests, setHasSubmittedRequests] = useState(false);


const [showPdfViewer, setShowPdfViewer] = useState(false);
const [pdfUrl, setPdfUrl] = useState('');
const [compareCount,setCompareCount] = useState('');
const [showSendBackForm, setShowSendBackForm] = useState(false);
const [sendBackQueryId, setSendBackQueryId] = useState(null);
const [showCommentsForm, setShowCommentsForm] = useState(false);
const [commentsQueryId, setCommentsQueryId] = useState(null);
const [showAwaitingInputForm, setShowAwaitingInputForm] = useState(false);


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const downloadPdf = () => {
    // Check if pdfUrl is available and there is a valid queryId
    if (pdfUrl) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = `vastu_report.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // Handle the case where pdfUrl is not available or there is no queryId
      console.error("Cannot download PDF. pdfUrl is not available or no queryId.");
    }
  };

    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const token = useSelector(selectCurrentToken);
    const [userQueries, setUserQueries] = useState([]);
    const [pending, setPending] = useState(true);
    const [openHelp, setOpenHelp] = useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [showReport, setShowReport] = useState(false);
    const [messageText, setMessageText] = useState([]);
	const [toggleCleared, setToggleCleared] = React.useState(false);
    const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [dataTableKey, setDataTableKey] = useState(0); // New state variable

    const handleAddViewComments = (queryId) => {
      setCommentsQueryId(queryId);
      setShowCommentsForm(!showCommentsForm);
    };

    const handleSendBack = (queryId) => {
        setSendBackQueryId(queryId);
        setShowSendBackForm(!showSendBackForm);
    };

    const handleProvideClarification = (queryId) => {
        setSendBackQueryId(queryId);
        setShowAwaitingInputForm(!showAwaitingInputForm);
    };

    const handleSendBackSubmit = (comment) => {
    // Implement the logic to send back the comment to the API
        alert(`Sending back with comment: ${comment}`);
    };

    const dispatch = useDispatch();
    const columns = [
        { name: 'Title', selector: row => <Typography variant='body2'>{row.title}</Typography>, width:isMobile? "20vw":"20%", center: true},
        { name: 'Score', selector: row => <Typography variant='body2'>{row.lastScore}</Typography>, width:isMobile? "13vw":"12%", center: true},
        { name: 'Share Report?', selector: row => <ShareToggle shareQueryId={row.sharedQueryId} queryId={row.queryId} loadAllReports={loadAllReports}/>, width: !hasSubmittedRequests ? (isMobile ? "13vw" : "12%") : (isMobile ? "18vw" : "12%"), center: true},
        { name: 'Actions', selector: row => <ReportActions shareQueryId={row.sharedQueryId} queryId={row.queryId} viewFunction={loadReport} editFunction={setShowEdit} reportName={row.title} statusCode={row.statusCode}/>, width:isMobile? "30vw":"20%", center: true},
{
  name: 'Status',
  cell: (row) => (
    statusDisplay(row, handleAddViewComments, handleSendBack, handleProvideClarification)
  ),
  width: isMobile ? "30vw" : "31%",
  center: true,
  omit: !hasSubmittedRequests, // Conditionally omit the column
}

 ];



 useEffect(() => {
   // Remove or comment out the alert
   // if (compareCount !== '') {
   //   alert(compareCount);
   // }
 }, [compareCount]);

  useEffect(() => {
    // Call a function to check if any request has a submittedDate
    updateHasSubmittedRequests();
  }, [userQueries, hasSubmittedRequests]);


  const updateHasSubmittedRequests = () => {
    // Check if any request has a submittedDate
    const hasSubmitted = userQueries.some((row) => row.submittedDate !== null && row.submittedDate !== '');
    // Update the state based on the check
    setHasSubmittedRequests(hasSubmitted);
    setDataTableKey((prevKey) => prevKey + 1);

  };

 const addObject = (newObj) => {
  const cloneSelectedRows = [...selectedRows];
    if (!cloneSelectedRows.some(obj => obj.queryId === newObj.queryId)) {
      cloneSelectedRows.push(newObj);
    }
    return cloneSelectedRows;
};
  const removeObject = (newObj) => {
    const cloneSelectedRows = [...selectedRows];
    const index = cloneSelectedRows.findIndex(item => item.queryId === newObj.queryId);
    if (index !== -1) {
      cloneSelectedRows.splice(index, 1);
    }
    return cloneSelectedRows;
  };  

  function selectCard(report, add) {
    if (add) {
      setSelectedRows(addObject(report));
    } else {
      setSelectedRows(removeObject(report));
    }
  }    

  const handleRowSelected = React.useCallback(state => {
		      setSelectedRows(state.selectedRows);
	}, []);

    const filteredItems = userQueries.filter(
		item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()),
	);

  const contextActions = React.useMemo(() => {
		const handleCompare = () => {

			          setLoadingReport(true);
                setShowPdfViewer(false);
				        setToggleCleared(!toggleCleared);
                var queryIds = [];
                var obj = {};
                selectedRows.map((row, index) => {
                    queryIds.push(row.queryId);
                });
                obj.queryIds = queryIds;

                //document.getElementById("reportFrame").srcdoc = `<div align="center"><img height="300px" width="300px" src="${loadingImage}" alt="Embedded Image in Iframe" /></div>`;
                RequestService.compareReport(obj, token).then((response) => {
                  if (response.status === 200) {
                    // Check the Content-Type header
                    const contentType = response.headers['content-type'];
                    if (contentType === 'application/pdf') {
                      // The response contains a PDF
                      const blob = new Blob([response.data], { type: 'application/pdf' });
                      const objectUrl = URL.createObjectURL(blob);
                      setPdfUrl(objectUrl);
                      setShowPdfViewer(true);
                      setCompareCount(queryIds.length);
                      setLoadingReport(false);
                    } else {
                      // Handle unexpected content type
                      console.error('Unexpected Content-Type:', contentType);
                      setMessageText('Error loading the report. Unexpected content type.');
                      setLoadingReport(false);
                    }
                  } else if (response.status === 403) {
                    setMessageText("Access is denied, token may have expired. Please logout and login again.");
                    setLoadingReport(false);
                    setCompareCount(0);
                  } else {
                    setMessageText("Error loading the report. Please try again.");
                    setLoadingReport(false);
                    setCompareCount(0);
                  }
        
                 });
		};

        const handleEdit = () => {
            dispatch(setQueryId(selectedRows[0].queryId));
            setShowEdit(true);
        }

        const handleView = () => {
           loadReport(selectedRows[0].queryId);
        }



		return (
            selectedRows.length > 1 &&  selectedRows.length < 5 ?
            <span>
            
			<button key="compare" onClick={handleCompare} className="App-butt">
				Compare
			</button></span>: 
            selectedRows.length == 1 ?
            <span>
            
            <button key="compare" onClick={handleCompare} className="App-butt" disabled>
                Compare
            </button>
            </span>
            : <span>
            
            <button key="compare" onClick={handleCompare} className="App-butt" disabled>
				Compare
			</button>
            </span>
            
            
		);
	}, [userQueries, selectedRows, toggleCleared]);


      const customStyles = {
        	header: {
        		style: {
        			minHeight: '56px',
        		},
        	},
        	headRow: {
        		style: {
        			borderTopStyle: 'solid',
                    backgroundColor: "#1976d2",
        			borderTopWidth: '1px',
        			borderTopColor: defaultThemes.default.divider.default,
        		},
        	},
        	headCells: {
        		style: {
        			'&:not(:last-of-type)': {
        				borderRightStyle: 'solid',
        				borderRightWidth: '1px',
        				borderRightColor: defaultThemes.default.divider.default,
        			},
        		},
        	},
        	cells: {
        		style: {
        			'&:not(:last-of-type)': {
        				borderRightStyle: 'solid',
        				borderRightWidth: '1px',
        				borderRightColor: defaultThemes.default.divider.default,
        			},
        		},
        	},
            rows: {
                highlightOnHoverStyle: {
                    color: defaultThemes.default.highlightOnHover.text,
                    backgroundColor: "#97c1ec;",
                    transitionDuration: '0.15s',
                    transitionProperty: 'background-color',
                    borderBottomColor: defaultThemes.default.background.default,
                    outlineStyle: 'solid',
                    outlineWidth: '1px',
                    outlineColor: defaultThemes.default.background.default,
                },
            },
        };    
        
    useEffect(() => {
       loadAllReports();
    }, []);

 
    function loadAllReports() {


        fetch(config[process.env.NODE_ENV].backendUrl + '/userQueries', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status == 200) {
                return response.json();
            } else if (response.status == 403) {
                setMessageText("Access is denied, token may have expired please logout and login again");
            }

        })
            .then((data) => {
                if (data.length == 0) {
                    setMessageText('No Saved Reports found');
                }
                setPending(false);
                setUserQueries(data);
            }).catch((err) => {
                console.log(err.message);
            });
    }
    function handleOpenHelp() {
        setOpenHelp(false);
      }

      function openHelpContent() {
        setOpenHelp(true);
      } 
function loadReport(queryId) {
  // Set loading state before making the request
  setLoadingReport(true);
  setShowPdfViewer(false);
  setCompareCount(0);

  // Clear any previous error messages
  setMessageText('');

  RequestService.getReport(queryId, token).then((response) => {
    if (response.status === 200) {
      // Check the Content-Type header
      const contentType = response.headers['content-type'];
      if (contentType === 'application/pdf') {
        // The response contains a PDF
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const objectUrl = URL.createObjectURL(blob);

        setPdfUrl(objectUrl);
        setDownloadQueryId(queryId);
        setShowPdfViewer(true);

        setLoadingReport(false);
      } else {
        // Handle unexpected content type
        console.error('Unexpected Content-Type:', contentType);
        setMessageText('Error loading the report. Unexpected content type.');
        setLoadingReport(false);
      }
    } else if (response.status === 403) {
      setMessageText("Access is denied, token may have expired. Please logout and login again.");
      setLoadingReport(false);
    } else {
      setMessageText("Error loading the report. Please try again.");
      setLoadingReport(false);
    }
  });
}


    const handleRowClicked = row => {
        loadReport(row.queryId);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

        const setInfoOpen = () => {
            if (open) {
                setOpen(!open);
            }
            
        }

		return (
            <Box style={{display: 'flex', width:"100%",}}>
            
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} open={open} setOpen={setInfoOpen} isMobile={isMobile} hasSubmittedRequests={hasSubmittedRequests}/>
            <Typography variant='body'>&nbsp;<FaQuestionCircle onClick={openHelpContent}/></Typography>
            </Box>
		);
	}, [filterText, resetPaginationToggle]);

    return (
       token ? (
            <Box style={{display: 'relative', width:"100%"}} alignContent="center">
                <Box>
                 <Collapse in={open}>
                  <Alert severity="info" style={{width: "100%"}} justify="left" action={
                     <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setOpen(false);
                     }}
                     >
                     x
                     </IconButton>
                  }> Choose up to four reports for comparison. Each report can be shared, and you have the option to copy and distribute both shareable and embed links for every report.
                  </Alert>
                  </Collapse>
                  <Button size="small" variant="text"
                     disabled={open}
                     onClick={() => {
                    setOpen(true);
                     }}
                  >
                  Show Info
                </Button>
                </Box>

                  {showSendBackForm && (
                    <SendBackModal setShowSendBackForm={setShowSendBackForm} queryId={sendBackQueryId} loadAllReports={loadAllReports}/>
                  )}
                  {showAwaitingInputForm && (
                    <AwaitingInputModal setShowAwaitingInputForm={setShowAwaitingInputForm} queryId={sendBackQueryId} loadAllReports={loadAllReports}/>
                  )}
                  {showCommentsForm && (
                    <CommentModal setShowCommentsForm={setShowCommentsForm} queryId={commentsQueryId} currentToken={token}/>
                  )}

                   {!isMobile ?<DataTable theme="light" title="Reports"
                        key={dataTableKey}
                        columns={columns} data={filteredItems}  
                        pagination
                        paginationPerPage={4}
                        paginationRowsPerPageOptions={[4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80, 84, 88, 92, 96, 100]} // Define the available options
                        noDataComponent="There are no reports to display"
                        highlightOnHover 
                        responsive 
                        pointerOnHover 
                        progressPending={pending}
                        onRowClicked={handleRowClicked}
                        customStyles={customStyles}
                        selectableRows
                        contextActions={contextActions}
			                  onSelectedRowsChange={handleRowSelected}
			                  clearSelectedRows={toggleCleared}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        paginationResetDefaultPage={resetPaginationToggle}
                        //persistTableHead
                        />: <CardListComponent 
                                data={filteredItems} 
                                subHeaderComponentMemo={subHeaderComponentMemo} 
                                loadAllReports={loadAllReports} 
                                loadReport={loadReport} 
                                setShowEdit={setShowEdit} 
                                handleRowSelected={selectCard}
                                contextActions={contextActions}
                                userQueries={userQueries}
                                selectedRows={selectedRows}
                                toggleCleared={toggleCleared}
                                statusDisplay={statusDisplay}
                                handleAddViewComments={handleAddViewComments} 
                                handleSendBack={handleSendBack} 
                                handleProvideClarification={handleProvideClarification}
                              />
                       
                      }



                  <Dialog
                  maxWidth={"md"}
                  open={openHelp}
                >
                  <DialogContent>
                        <MarkdownViewerFromFile mdFilePath="./help/reports_help.md" />
                    </DialogContent>
                  <DialogActions>
                    <Button onClick={handleOpenHelp}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>
   <DocumentViewer loadingReport={loadingReport} pdfUrl={pdfUrl} messageText={messageText} showPdfViewer={showPdfViewer} compareCount={compareCount}/>                
            </Box>

        ): <div id="accessMessage">Access denied, user must be logged in to access the reports</div>

    )
}

export default ListReports;

function statusDisplay(row, handleAddViewComments, handleSendBack, handleProvideClarification) {
  var buttons = [];
  {row.statusCode != 'AWAITING_INPUT' && ( // Show Send Back button only when status is not 'COMPLETED'
           buttons.push(<Button
            size="small"
            onClick={() => handleAddViewComments(row.queryId)}
            style={{ marginRight: '4px', marginBottom: '5px' }}
          >Comments
          </Button>)
        )}
        {row.statusCode === 'COMPLETED' && ( // Show Send Back button only when status is not 'COMPLETED'
          buttons.push(<Button
            size="small"
            onClick={() => handleSendBack(row.queryId)}
            style={{ marginBottom: '5px' }}
          >
            Send Back
          </Button>)
        )}
        {row.statusCode === 'AWAITING_INPUT' && ( // Show Send Back button only when status is not 'COMPLETED'
          buttons.push(<Button
            size="small"
            onClick={() => handleProvideClarification(row.queryId)}
            style={{ marginBottom: '5px' }}
          >
            Provide Clarification
          </Button>)
        )}
  return <div style={{ textAlign: 'center', marginTop: '8px', width: '100%' }}>
    {row.statusName && (
      <div>
        <strong>{row.statusName}</strong>
        {row.submittedDate && (
          <div style={{ marginTop: '5px' }}>
            Submitted on - {new Date(row.submittedDate).toLocaleDateString()}
          </div>
        )}
      </div>
    )}
    
    {row.statusName && ( // Show Comments and Send Back buttons only when status is not null
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px'}}>
        <ButtonGroup size="small" aria-label="small button group">
          {buttons}
        </ButtonGroup>
      </div>
    )}
  </div>;
}
