import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import compassimg from '../assets/images/compass2.jpg';
import homeimg from '../assets/images/house-2.jpg';
import planimg from '../assets/images/floorplan-3.jpg';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'Compass',
    imgPath: {compassimg}
  },
  {
    label: 'Floor Plan',
    imgPath: {planimg}
  },
  {
    label: 'House',
    imgPath: {homeimg},
  }
];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 350, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >

          <div key='compass'>
            <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  maxWidth: 255,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={compassimg}
              />
          </div>
          <div key='plan'>
            <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  maxWidth: 255,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={planimg}
              />
          </div>
          <div key='house'>
            <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  maxWidth: 255,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={homeimg}
              />
          </div>

      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}

export default SwipeableTextMobileStepper;