import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import usePageView from '../components/usePageView'; // Import the custom hook


import { Box, Typography, Paper, Card, CardActionArea, CardMedia, CardContent, CardActions, Button } from '@mui/material';

export function Aboutus() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });

  // Use the custom hook with the page name 'Aboutus'
  usePageView('About Us');

    return (

        <Box sx={{ width: '80%', backgroundColor: 'white'}} align='center' p={5}>

        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>About Vastu Report</Typography>

        <Typography variant="body2" paragraph align='justify'>
        Welcome to VastuReport.com, your gateway to the harmonious intersection of ancient Vastu wisdom and cutting-edge technology. At VastuReport.com, we believe in making the age-old principles of Vastu accessible to everyone. By seamlessly integrating ancient wisdom with modern technology, we've created a platform that simplifies property evaluations, putting the power of positive living in the hands of individuals, families, and businesses alike. Join us on a journey to discover the transformative potential of Vastu in shaping spaces that inspire well-being, health, and prosperity for all.
        </Typography>
        <br/>

        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>What is Vastu?</Typography>

        <Typography variant="body2" paragraph align='justify'>Vastu (also spelled as Vaastu) is an ancient Indian science of architecture and building design that involves the placement and orientation of buildings in harmony with the natural forces and energy fields of the universe. The word "Vaastu" is derived from the Sanskrit word "Vas" which means "to dwell" or "to live".According to Vaastu, every building or structure has a life force or energy field, and it is believed that by designing and constructing buildings in harmony with the natural energy flows, we can create a positive environment that enhances our well-being, health, and prosperity. The principles of Vaastu are based on the five elements of nature - earth, water, fire, air, and space - and aim to balance and harmonize these elements in the built environment.Vaastu also considers the placement and orientation of rooms, doors, windows, and other features of a building in relation to the cardinal directions and the movement of the sun and stars. It is believed that by aligning a building with the natural forces of the universe, we can create a space that is conducive to good health, happiness, and prosperity.
        </Typography>
        <Typography variant="body2" paragraph align='justify'> It's important to note that Vastu Shastra is not universally accepted or scientifically proven. The effectiveness of Vastu principles may vary from person to person, and personal beliefs and cultural backgrounds play a significant role in its adoption. When buying a property, it's advisable to consider factors like location, budget, functionality, and personal preferences alongside any Vastu considerations.
        </Typography>

    <br/>
      <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>
        Mission and Value
      </Typography>

      <Typography variant="body2" paragraph align='justify'>
        Empower property seekers and industry professionals with streamlined and efficient Vastu evaluations, saving time and money in the decision-making process.
      </Typography>

      <Typography variant="body2" paragraph align='justify'>
        At VastuReport.com, we are committed to democratizing Vastu evaluation, transcending barriers to empower individuals and businesses alike with instant access to the benefits of Vastu. Our mission is to simplify the evaluation process, fostering positive environments for well-being, health, and prosperity.
      </Typography>

      <Typography variant="body2" paragraph align='justify'>
        We endeavor to bring the ancient wisdom of Vastu Shastra to your fingertips, providing a seamless and instant evaluation of your property based on its floor plan and directional information. By bridging the gap between traditional knowledge and modern technology, we aim to make Vastu-enhanced living accessible to everyone, ensuring that the path to a harmonious space is just a click away.
      </Typography>

      <Typography variant="body2" paragraph align='justify'>
        <strong>Our Value Proposition:</strong>
        <ul>
          <li>Instant Vastu scores, ratings, and detailed reports for swift decision-making.</li>
          <li>Accessibility to Vastu-enhanced living for individuals.</li>
          <li>Invaluable tools for builder sales agents and real estate agents to enhance service quality and cater to the growing demand for Vastu-compliant properties.</li>
          <li>Empower builders to create properties with stronger Vastu compliance, meeting the rising expectations for harmonious and balanced living spaces.</li>
        </ul>
      </Typography>

    <br/>
      <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>
        Take the Next Step
      </Typography>

      <Typography variant="body2" paragraph align='justify'>
        Ready to experience the benefits of Vastu-enhanced living? Explore our platform and start your journey towards balanced, harmonious spaces. Your ideal property is just a click away.
      </Typography>

      <Link to="/">
      <Button variant="contained" color="primary">
        Explore Now
      </Button>
      </Link>
          <br/>

      <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mt={4} mb={1}>
        Contact Us
      </Typography>

      <Typography variant="body2" paragraph align='justify'>
        Have questions or need assistance? Feel free to reach out to us using the <a href="/contact-us">contact us</a> form. Our team is here to help you on your Vastu journey.
      </Typography>


    </Box>





    )
}

export default Aboutus;
