import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/images/vastu_report_logo.png';
import { selectCurrentToken, selectCurrentUser} from '../authSlice';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { FaUserCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import {userLogout} from '../authSlice'
import UserActions from './UserActions';
import { useMediaQuery } from 'react-responsive';
import { Menu, MenuItem, Typography } from '@mui/material';
import RequestService from '../RequestService';

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const currentToken = useSelector(selectCurrentToken);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    // Fetch user roles and update state
    if (currentToken) {
      RequestService.getUserRoles(currentToken).then((response) => {
        if (response.status === 200) {
          setUserRoles(response.data.map(role => role.name)); // Extract role names
        }
      });
    }
  }, [currentToken]);


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    closeMobileMenu();
  };

  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/');
  };

  const logOut = () => {
    /* global google */
    // eslint-disable-next-line
    google.accounts.id.disableAutoSelect();
    dispatch(userLogout())
    closeMobileMenu();
};

const myorders = () => {
  navigate('/myorders');
  closeMobileMenu();
}

const myFeedbacks = () => {
  navigate('/myfeedbacks');
  closeMobileMenu();
}

const administration = () => {
  navigate('/ad-us-re');
  closeMobileMenu();
}

const processor = () => {
  navigate('/pr-oc-re');
  closeMobileMenu();
}
  return (
    <>
      <header className='sticky'>
      <section className='shadow-md'>
      <nav className={isMobile ? 'navbar-mobile': 'navbar'}>
        <Link to='/' onClick={closeMobileMenu}>
          <img src={logo} height={isMobile ?'70': '70'} width={isMobile ?'150': '150'} alt='VaastuScore'/>
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              <Typography variant='body1'>Home</Typography>
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/contactus' className='nav-links' onClick={closeMobileMenu}>
            <Typography variant='body1'>Contact Us</Typography>
            </Link>
          </li>
          {currentUser? (   
          <li className='nav-item'>
            <Link to='/buycredits' className='nav-links' onClick={closeMobileMenu}>
            <Typography variant='body1'>Buy Credits</Typography>
            </Link>
          </li>
          ): ''}
          {currentUser? (          
          <li className='nav-item'>
            <Typography aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick} variant='body1' className='nav-links'>
                Evaluate
            </Typography>
           <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
                <MenuItem onClick={handleClose}>
                  <Link to='/getscore' className='nav-links'>
                    Do it yourself (1 Credit)
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to='/submitRequest' className='nav-links'>
                    Let Us Handle It (2 Credits)
                  </Link>
                </MenuItem>
                {userRoles.includes('Agent') && (
                    <MenuItem onClick={handleClose}>
                      <Link to='/submitBulkRequest' className='nav-links'>
                        Submit Bulk Request
                      </Link>
                    </MenuItem>
                )}
          </Menu>
          </li>
          ): ''}
         
          {isMobile && currentUser? (          
          <li className='nav-item'>
            <Link
              to='/myreports'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              <Typography variant='body1'>Reports</Typography>
            </Link>
            </li>)
            : ''}
          {isMobile && currentUser? <li className='nav-item'>
            <Link
              to='/customReports'
              className='nav-links'
              onClick={closeMobileMenu}
            >
               <Typography variant='body1'>Seller Reports</Typography>
            </Link>
          </li>: ''}  

          {!isMobile && currentUser? (          
          <li className='nav-item'>
            <Link
              to='/myreports'
              className='nav-links'
              onClick={closeMobileMenu}
            >
               <Typography variant='body1'>Reports</Typography>
            </Link>
            <Link
              to='/customReports'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              <Typography variant='body1'>Seller Reports</Typography>
            </Link>
            </li>)
            : ''}
          
         
          {currentUser ? (
            <li className='nav-item'>
              <UserActions name={currentUser} logOut={logOut} myorders={myorders} myFeedbacks={myFeedbacks} administration={administration} processor={processor}/>
            </li>
          )  :
          <li className='nav-item'>
          <Link
              to='/login'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              <Typography variant='body1'> Login</Typography>
            </Link>
          </li>
          }
        </ul>
      </nav>
      </section>  
      </header>

    </>
  );
}

export default Navbar;
