import { Alert, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Typography from '@mui/material/Typography';
import { Dialog, DialogActions, DialogTitle, Box, Slide } from '@mui/material';
import RequestService from '../RequestService';
import { selectCurrentToken } from '../authSlice';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { userCredits } from '../authSlice';
import { selectCurrentUser} from '../authSlice';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SendBackModal = ({ setShowSendBackForm, queryId, loadAllReports }) => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const [value, setValue] = useState(null);
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const currentToken = useSelector(selectCurrentToken);
  const [sendBackText, setSendBackText] = useState("");
  const [formVisible, setFormVisible] = useState(true);
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });

  function handleClose() {
    setShowSendBackForm(false);
  }

  const handleSendBack = (event) => {
    setSendBackText(event.target.value);
  };

  function submitSendBack() {
    if (document.getElementById("sendBackText").value === "") {
      setMessage("Please enter a comment");
      return;
    }

    const sendBackObject = {
      sendBackText: sendBackText,
      queryId: queryId, // Add the queryId to the sendBackObject
    };

    RequestService.sendBackRequest(JSON.stringify(sendBackObject), currentToken)
      .then((response) => {
        if (response.status === 200) {
             setSuccessMessage('The request has been sent back. You can click the "Close Window" button to close this window.');
             setFormVisible(false);
             loadAllReports();
        }
      })
      .catch((err) => {
        toast.error("Unable to send back at this time");
        console.log(err);
      });
  }

  return (
    <Dialog open={true} scroll={"paper"} onClose={handleClose} TransitionComponent={Transition} align="center">
      <DialogActions>
        <Button onClick={handleClose}>Close Window X</Button>
      </DialogActions>

      {successMessage && successMessage !== "" ? (
        <div style={{ padding: '16px' }}>
          <Alert severity="success">{successMessage}</Alert>
        </div>
      ) : (
        ''
      )}

      {formVisible && (
        <Box width={isMobile ? 300 : 500} height={500} style={{ display: 'relative' }} m={2}>
          <DialogTitle id="scroll-dialog-title" align="center">
            Send Back
            <br />
          </DialogTitle>

          <ToastContainer position="top-center" theme="dark" />
          {message && message !== "" ? <Alert severity="error">{message}</Alert> : ''}

          <Typography>Enter your comments <span className='mandatory'>*</span></Typography>
          <br />

          <TextField id="sendBackText" onChange={handleSendBack} fullWidth multiline rows={10} variant="filled" />
          <br />
          <br />
          <br />
          <br />
          <Button variant="contained" size="small" onClick={submitSendBack}>
            Send Back
          </Button>
        </Box>
      )}
    </Dialog>
  );
};

export default SendBackModal;
