import React, { useState } from 'react';
import colorLib from '@kurkle/color';
import Dialog from '@mui/material/Dialog';
import {DialogTitle, DialogActions, Button, DialogContent, Accordion, AccordionSummary, Select, AccordionDetails, MenuItem, FormControl, InputLabel, Collapse, IconButton} from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import Slide from '@mui/material/Slide';
import { selectSessionId } from '../imageSlice';
import blank_image from '../assets/images/blank2.png';
import { setRotation, selectRotation} from '../angleSlice';
import { selectCurrentToken } from '../authSlice';
import "./Modal.css";
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { getElementAtEvent, Pie } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import autocolors from 'chartjs-plugin-autocolors';
import { useEffect } from 'react';
import UploadService from "../UploadFileService";
import { FaUndo, FaRedo, FaQuestionCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { red } from '@mui/material/colors';
import ScrollTopFab from './ScrollToTopFab';
import MarkdownViewerFromFile from './MarkdownViewerFromFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CompassReading from './CompassReading';


ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, autocolors);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    customAccordionHeader: {
      backgroundColor: '#E4E4DE',  // Change this color as desired
    }
  }));  

const MaterialMainDoorModal = ({ id, setMainDoorOpenModal, options, displayName, setDropDownValue, selectedValue }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const [openHelp1, setOpenHelp1] = useState(false);
    const [expanded, setExpanded] = useState('panel1');
    const [tabIndex, setTabIndex] = useState(0);
    const [openHelp2, setOpenHelp2] = useState(false);
    const [openHelp3, setOpenHelp3] = useState(false);
    const [openHelp4, setOpenHelp4] = useState(false);
    const [capturedAngle, setCapturedAngle] = useState(0);
    const currentSessionId = useSelector(selectSessionId);
    const currentRotation = useSelector(selectRotation);
    const [doorAngleSet, setDoorAngleSet] = useState(false);
    const currentToken = useSelector(selectCurrentToken);
    const [selectedDisplayName, setSelectedDisplayName] = useState([]);
    const [defaultRotationAngle, setDefaultRotationAngle] = useState(0);
    const [option1Angle, setOption1Angle] = useState(0);
    const [defaultAngle, setDefaultAngle] = useState(1);
    const [defaultRotateEntrace, setDefaultRotateEntrace] = useState(null);
    const [showImage, setShowImage] = useState(true);
    const [imagePresent, setImagePresent] = useState(true);
    const [reloadPage, setReloadPage] = useState(false);
    const [usingRotationOption, setUsingRotationOption] = useState(false);
    const [showSelection, setShowSelection] = useState(true);
    const [errors, setErrors] = useState([]);
    const [imageUrl, setImageUrl] = useState(blank_image);
    const [floorTypes, setFloorTypes] = useState([]);
    const chartRef = useRef();
    const [open, setOpen] = useState(true);
    const [imageAvailable, setImageAvailable] = useState(false);
    const dispatch = useDispatch();
    const [defaultImageValue, setDefaultImageValue] = useState('F');
    const [defaultRotation, setDefaultRotation] = useState(0);
    const [chartRotation, setChartRotation] = useState(0);
    const [defaultOffset, setDefaultOffset] = useState(options.length == 8 ? -22.5: options.length == 16 ? -11.25: 0);
    const [toolTipContent, setToolTipContent] = useState(
      ['Set the north properly using one of the option below and then pick the section where your main door is located. Use the reset button to change the angle.']);
    var labelsArray = [];
    var labelsDisplayArray = [];
    var labelsShortDiplayArray = [];
    options.map((optionCode) => {
      labelsArray.push(optionCode.matchingConditionCode);
      labelsDisplayArray.push(optionCode.matchingConditionDescription);
      labelsShortDiplayArray.push(optionCode.matchingConditionDisplayName)

    });
    var dataArray = [];
    var borderWidth = [];
    for (var i = 0; i < labelsArray.length; i++) {
        dataArray.push(360/labelsArray.length);
        borderWidth.push(1);
    }
    const data = {
      labels: labelsArray,
      labelDisplay: labelsDisplayArray,
      labelShortDisplay: labelsShortDiplayArray,
      datasets: [
        {
          data: dataArray,
          borderWidth: borderWidth,
          rotation: chartRotation,
        },
      ],
    };
    const handleAccChange = (panel) => (event, isExpanded) => {
      setDefaultRotateEntrace(-1*defaultRotationAngle)
      setReloadPage(!reloadPage)
      setExpanded(isExpanded ? panel : false);
    };

    function handleCloseHelp1() {
      setOpenHelp1(false);
    }

    function handleCloseHelp2() {
      setOpenHelp2(false);
    }

    function handleCloseHelp3() {
      setOpenHelp3(false);
    }

    function handleCloseHelp4() {
      setOpenHelp4(false);
    }

    function switchTabs(index) {
      setTabIndex(index);
      if (index == 0) {
        setDefaultRotateEntrace(-1*defaultRotationAngle)
        setReloadPage(!reloadPage)
        setExpanded('panel1');
      } else {
        setDefaultRotateEntrace(-1*defaultRotationAngle)
        setExpanded('panel2');
      }
    }

    function flipImage(tempImageId) {
      var inputImage = document.getElementById(tempImageId);
      // create a canvas that will present the output image
      const outputImage = document.createElement('canvas');
      // set it to the same size as the image
      outputImage.width = inputImage.naturalWidth;
      outputImage.height = inputImage.naturalHeight;
      // get the drawing context, needed to draw the new image
      const ctx = outputImage.getContext('2d');
      // scale the drawing context negatively to the left (our image is 400 pixels wide)
      // resulting change to context: 0 to 400 -> -400 to 0
      ctx.scale(-1, 1);
      // draw our image at position [-width, 0] on the canvas, we need
      // a negative offset because of the negative scale transform
      ctx.drawImage(inputImage, -outputImage.width, 0);
      var dataURL = outputImage.toDataURL();
      return dataURL;
   }

   function lighten(value, opacity) {
    var alpha = opacity === undefined ? 0.5 : 1 - opacity;
    alpha = "0.2";
    return colorLib(value).alpha(alpha).rgbString();
  }

  const pieLabelsLine = {
    id: "pieLabelsLine",
    afterDraw: function(chart) {
      var centerX = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
      var centerY = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
      var radius = Math.min(chart.chartArea.right - chart.chartArea.left, chart.chartArea.bottom - chart.chartArea.top) / 2;
      
      // Draw four lines radiating from the center of the chart
      var angleIncrement = 2 * Math.PI / 4;
      for (var i = 0; i < 4; i++) {
        var initialAngle = document.getElementById("rotationAngle").value;
        var angle = (i * angleIncrement + initialAngle * (Math.PI/180));
        var startX = centerX + Math.cos(angle) * radius;
        var startY = centerY + Math.sin(angle) * radius;
        var lengthOfLine = isMobile ? 20 : 30;
        var endX = centerX + Math.cos(angle) * (radius + lengthOfLine); // adjust line length as needed
        var endY = centerY + Math.sin(angle) * (radius + lengthOfLine);
        chart.ctx.beginPath();
        chart.ctx.moveTo(startX, startY);
        chart.ctx.lineTo(endX, endY);
        chart.ctx.strokeStyle = red[600];
        chart.ctx.lineWidth=3;
        chart.ctx.stroke();
        var arrowLength = isMobile ? 10: 20; // length of the arrowhead
        chart.ctx.save();
        chart.ctx.beginPath();
        chart.ctx.translate(endX, endY);
        chart.ctx.rotate(angle + Math.PI / 2);
        chart.ctx.moveTo(0, 0);
        chart.ctx.lineTo(-arrowLength, arrowLength);
        chart.ctx.lineTo(arrowLength, arrowLength);
        chart.ctx.closePath();
        chart.ctx.fillStyle = red[700];
        chart.ctx.fill();
        chart.ctx.restore();
        var addLength = isMobile ? 16: 22;
        var fontText = isMobile ? "8px Arial" : "14px Arial"
        var labelX = centerX + Math.cos(angle) * (radius + addLength); // adjust label distance as needed
        var labelY = centerY + Math.sin(angle) * (radius + addLength);
        var label = "N";
        if (i == 0) {
          label = "E";
        } else if (i == 1) {
          label = "S";
        } else if (i == 2) {
          label = "W";
        }

        chart.ctx.save();
        chart.ctx.translate(labelX, labelY);
        chart.ctx.rotate(angle - Math.PI / 2);
        chart.ctx.textAlign = "center";
        chart.ctx.fillStyle = "#fff";
        chart.ctx.font = fontText;
        chart.ctx.fillText(label, 0, 0);
        chart.ctx.restore();    
      }
    },
  };
  const padding = isMobile ? 20 : 40
    const chartOptions = {
      layout: {
        padding: padding
      },
      plugins: {
        autocolors : {
          mode: 'data',
          enabled: true,
          customize(context) {
            const colors = context.colors;
            return {
              background: lighten(colors.background, 0.7),
              border: lighten(colors.border, 0.7)
            };
          }
        },
        scales: {
          ticks: {
            display: true,
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (index) {
              //setToolTipContent(data.labelDisplay[index.dataIndex]);  
              //document.getElementById('toolTipContent').innerHTML = data.labelDisplay[index.dataIndex];
              return data.labelShortDisplay[index.dataIndex];    
            }
          },
          anchor: "end",
          align: "start",
          clamp: true,
          clip: false,
        },
        datalabels: {
          color: "blue",
          anchor: "end",
          align: "start",
          clamp: true,
          clip: false,
          formatter: function (value, context) {
              var labelData = context.chart.data.labels[
                  context.dataIndex
              ];
              
              return labelData;
          },
        }, 
      }
    
    };
 
    useEffect(() => {
      if (selectedValue) {
        options.map((option, index) => {
          if (option.matchingConditionCode == selectedValue) {
            setSelectedDisplayName(option.matchingConditionDisplayName);
          }
        });
      }

      loadImage('F');
      if (currentRotation != null && !isNaN(currentRotation)) {
        if (currentRotation == 0 || usingRotationOption || defaultRotateEntrace != null) {
          setShowSelection(true);
        } else {
          setShowSelection(false);
          setOpen(false);
        }
        setShowImage(true);
        setDefaultRotation((currentRotation));
        setDefaultRotationAngle((currentRotation));
        setChartRotation((Number(currentRotation) + Number(defaultOffset)));
      }

      setOption1Angle(defaultRotationAngle*-1);
      
    }, [reloadPage]) 

    function loadFloorImage(e) {
      setDefaultRotation(document.getElementById("rotationAngle").value);
      loadImage(e.target.value)
      setDefaultImageValue(e.target.value);
    }

    const captureAngle = (event) => {
      let angle = event.target.value;
      setCapturedAngle(angle);
      setOption1Angle(angle);
    }


    function loadImage(floorType) {
      UploadService.getFiles(currentSessionId, currentToken)
      .then((files) => {
        var urls = [];
        var imageTypes = [];
          for(let i = 0; i < files.data.length; i++){
            setImageAvailable(true);
            
            imageTypes.push(files.data[i].fileType);
            if (files.data[i].fileType == floorType) {
              UploadService.getFile(files.data[i].id, currentToken).then((file) => {
                setImagePresent(true);
                var url
                if (files.data[i].flipImage) {
                  var targetDivTemp = document.querySelector('#tempImageId');
                  url = 'data:image/png;base64,' + file.data;
                  targetDivTemp.src = url;
                  url = flipImage('tempImageId');    
                } else {
                  url = 'data:image/png;base64,' + file.data;
                }                
                setImageUrl(url);
            });
          }               
        } 
        setFloorTypes(imageTypes);
      }).catch(() => {
        console.log("Unable to load the first floor image");   
      });

    }

    function handleChange(event) {    
      try {
        var element = getElementAtEvent(chartRef.current, event);
        if (element) {
          const { datasetIndex, index } = element[0];
          //set the rotation angle to state so we can default the rotation
          setDropDownValue(id, data.labels[index]);
          dispatch(setRotation(document.getElementById("rotationAngle").value));
        }
      } catch(e) {
        //ignore
      } 

      setMainDoorOpenModal(false);
    }

  
    function rotateImageNegative() {
      let angle = document.getElementById("rotateAngle").value;
      setUsingRotationOption(true);
      setShowImage(true);
      if (!isInt(angle)) {
        setErrors("Please enter value between 0 & 360");
        return;
      } 
      const chart = chartRef.current;
      var currentRotation = chart.data.datasets[0].rotation;
      var angleToRotate = parseFloat(currentRotation) - parseFloat(angle) - parseFloat(defaultOffset);;
      if (angleToRotate > 360) {
        angleToRotate = angleToRotate - 360;
      }
      chart.data.datasets[0].rotation=angleToRotate;
      document.getElementById("rotationAngle").value = parseFloat(angleToRotate).toFixed(2);
      chart.update();
      setReloadPage(!reloadPage);
      dispatch(setRotation(angleToRotate));
 
    }

    function setAngle(e) {
      var elements = document.getElementsByName("angleInput");
      for (var i = 0; i < elements.length; i++) {
        elements[i].value = e.target.value;
      }
    }

    function isInt(value) {
      var x;
      if (isNaN(value)) {
        return false;
      }
      x = parseFloat(value);
      return x;
    }

    function getOptionValue(code) {
      if (code == 'F') {
        return 'Main Entrance Floor';
      } else if (code == 'S') {
        return 'Upper floor';
      } else {
        return 'Basement/Other floor'
      }
    }

    function rotateImageWithDegree() {
        let angle = document.getElementById("rotateAngle").value;
        setUsingRotationOption(true);
        setShowImage(true);
        if (isInt(angle) != 0 && !isInt(angle)) {
          setErrors("Please enter value between 0 & 360");
          return;
        } 
        const chart = chartRef.current;
        var currentRotation = chart.data.datasets[0].rotation;
        var angleToRotate = parseFloat(currentRotation) + parseFloat(angle) - parseFloat(defaultOffset);
        if (angleToRotate > 360) {
          angleToRotate = angleToRotate - 360;
        }
        chart.data.datasets[0].rotation=angleToRotate;
        document.getElementById("rotationAngle").value = parseFloat(angleToRotate).toFixed(2);
        chart.update();
        setDefaultRotationAngle(angleToRotate);
        setReloadPage(!reloadPage);
        dispatch(setRotation(angleToRotate));
        setDefaultRotateEntrace(-1*angleToRotate);
    }

    function setDoorAngle() {

      let angle = document.getElementById('rotateEntrance').value;
      setDoorAngleSet(true);
      setShowImage(true);
      if (isInt(angle) != 0 && !isInt(angle)) {
        setErrors("Please enter value between 0 & 360");
        return;
      } 
      const chart = chartRef.current;
      var angleToRotate = parseFloat(0) - parseFloat(angle);
      if (angleToRotate > 360) {
        angleToRotate = angleToRotate - 360;
      }
      dispatch(setRotation(angleToRotate));
      setDefaultRotationAngle(angleToRotate);
      chart.data.datasets[0].rotation=angleToRotate;
      chart.update();
      setExpanded('');
      document.getElementById("rotationAngle").value = parseFloat(angleToRotate).toFixed(2);
      setShowSelection(false);
      switchTabs(0);  
      setReloadPage(!reloadPage);
      setOpen(false);

  }

  function setDoorDirection() {
    
    let angle = capturedAngle;
    setShowImage(true);
    setDoorAngleSet(true);
    if (isInt(angle) != 0 && !isInt(angle)) {
      setErrors("Please enter value between 0 & 360");
      return;
    } 
    const chart = chartRef.current;
    var angleToRotate = parseFloat(0) - parseFloat(angle);
    if (angleToRotate > 360) {
      angleToRotate = angleToRotate - 360;
    }
    dispatch(setRotation(angleToRotate));
    setDefaultRotationAngle(angleToRotate);
    chart.data.datasets[0].rotation=angleToRotate;
    chart.update();

    document.getElementById("rotationAngle").value = parseFloat(angleToRotate).toFixed(2);
    setShowSelection(false);
    setReloadPage(!reloadPage);
    setOpen(false);

}

    function resetImage() {
      setDefaultAngle(1);
      setDefaultRotateEntrace(0);
      const chart = chartRef.current;
      chart.data.datasets[0].rotation=0
      if (showSelection) {
        if (document.getElementById("rotateAngle")) {
          document.getElementById("rotateAngle").value=1;
        }
        
      }
      chart.update();
      document.getElementById("rotationAngle").value = 0;
      if (document.getElementById("rotateEntrance")) {
        document.getElementById("rotateEntrance").value = 0;
      }

      dispatch(setRotation(0));
      setCapturedAngle(0);
      setOption1Angle(0);
      setShowImage(false);
      setReloadPage(!reloadPage)
      setErrors([]);
      setExpanded('');
      setOpen(true);
    }

    function editAngle() {
      setShowSelection(true);
      setDoorAngleSet(false);
      if (usingRotationOption) {
        setDefaultAngle(1);
      } else {
        setDefaultAngle(1);
        setDefaultRotateEntrace(-currentRotation);
      }
      setReloadPage(!reloadPage);
    }

    function openHelpOne() {
      setOpenHelp1(true);
    }

    function openHelpTwo() {
      setOpenHelp2(true);
    }

    function openHelpThree() {
      setOpenHelp3(true);
    }


    function openHelpFour() {
      setOpenHelp4(true);
    }

    const setAngleDropDown = (event) => {
      let angle = event.target.value;
      setReloadPage(!reloadPage);
      setShowImage(true);
      if (!isInt(angle)) {
        setErrors("Please enter value between 0 & 360");
        return;
      } 
      const chart = chartRef.current;
      var angleToRotate = parseFloat(0) - parseFloat(angle);
      if (angleToRotate > 360) {
        angleToRotate = angleToRotate - 360;
      }
      dispatch(setRotation(angleToRotate));
      setDefaultRotationAngle(angleToRotate);
      chart.data.datasets[0].rotation=angleToRotate;
      chart.update();

      document.getElementById("rotationAngle").value = parseFloat(angleToRotate).toFixed(2);
    }
  
    return (
        <Box>
        <Dialog
        fullScreen
        open={true}
        scroll={"paper"}
        onClose={handleChange}
        TransitionComponent={Transition}
        align="right"
      >
        <DialogActions>
          <Button onClick={handleChange}>Close Window X</Button>
        </DialogActions>
       
        <input size="10" type="hidden" readOnly id="rotationAngle" value={defaultRotationAngle}/>     
          {errors != "" ? <Alert severity="error" style={{width: "100%"}} justify="center" align="center" id="toolTipContent">{errors}</Alert>:''}
          <Box style={{width:isMobile ? "90%":"40%"}} mb={5} align="center" m="auto">  
                  <Collapse in={open}>
                  <Alert severity='info' width="100%" justify="left" action={
                     <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setOpen(false);
                     }}
                     >
                     X
                     </IconButton>
                  }>If you have uploaded a floor plan image, make sure the orientation of the plan image is in such a way that the home entrance or front of the home is at the top for all your floor plans. {toolTipContent}
                  </Alert>
                  </Collapse>
                  <Button size="small" variant="text"
                     disabled={open}
                     onClick={() => {
                    setOpen(true);
                     }}
                  >
                  Show Info
                </Button>
                </Box>
          <br></br>        
          <Box style={{width:"100%", display:"relative"}} mb={5} align="center" m="auto">
          {showSelection && !doorAngleSet ? <Box style={{width: isMobile? "100%": "80%", display:"relative"}}>
            <Box width={isMobile ? "90%":"50%"} sx={{ borderTop: 0, borderColor: 'divider'}}>
            <Typography variant='body2' alignItems={"center"}><span style={{ fontWeight: 'bold'}}>Note: </span>After setting the orientation using either the below basic/advanced option, please make sure you proceed to the next section at the bottom of this page to choose the section in the image where the {displayName} is located.</Typography>

            <br></br>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => switchTabs(index)} width={"100%"}>
                <TabList>
                    <Tab><a href="#basic">Basic</a></Tab>
                    <Tab><a href="#advanced">Advanced</a></Tab>
                </TabList>  
            <TabPanel id="basic">
              <Accordion alignContent="center" className={classes.customAccordionHeader} expanded={expanded === 'panel1'} onChange={handleAccChange('panel1')}>
                <AccordionSummary
                  align="center"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                    <Typography variant='body2' alignItems={"center"}>Click here to select the orientation of the front of your home (using the Dropdown Menu)&nbsp;&nbsp;<FaQuestionCircle onClick={openHelpFour}/></Typography>
                </AccordionSummary>
                <AccordionDetails>
                <FormControl variant="standard" sx={{minWidth: 120 }} alignContent="center">

                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    size='small'
                    value={option1Angle}
                    label="Direction"
                    onChange={captureAngle}
                  >                 
                    <MenuItem value={0}><Typography variant='body2'>North</Typography></MenuItem>
                    <MenuItem value={45}><Typography variant='body2'>North East</Typography></MenuItem>
                    <MenuItem value={90}><Typography variant='body2'>East</Typography></MenuItem>
                    <MenuItem value={135}><Typography variant='body2'>South East</Typography></MenuItem>
                    <MenuItem value={180}><Typography variant='body2'>South</Typography></MenuItem>
                    <MenuItem value={225}><Typography variant='body2'>South West</Typography></MenuItem>
                    <MenuItem value={270}><Typography variant='body2'>West</Typography></MenuItem>
                    <MenuItem value={315}><Typography variant='body2'>North West</Typography></MenuItem>
                </Select>
                <br></br>
                <Button size='small' onClick={setDoorDirection}>Set</Button>
                </FormControl>
              </AccordionDetails>
              </Accordion>
              <Box justifyContent="center" alignContent="center" m="auto" marginTop={2} marginBottom={2}><b>OR</b></Box>

              <Accordion alignContent="center" className={classes.customAccordionHeader} expanded={expanded === 'panel3'} onChange={handleAccChange('panel3')}>
              <AccordionSummary
                  align="center"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1c-header">
                      <div style={{ display: 'flex', alignItems: 'center' }}>

                    <Typography variant='body2'>Click here to manually and precisely adjust the direction marker, aligning the North relative to the front of the house using the provided rotate buttons&nbsp;&nbsp;<FaQuestionCircle onClick={openHelpOne}/></Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails>   
              <Box style={{width:"100%"}}>
            <input type="hidden" size="10" align="center" id="rotateAngle" defaultValue={defaultAngle} textAlign="center" onChange={setAngle} name="angleInput"/>
            {!showImage ? <Button variant='contained' onClick={rotateImageWithDegree} size='small'>Start</Button>: ''}
            <Button size='small' onClick={rotateImageWithDegree} id="forward" variant='contained'><FaRedo/>&nbsp;Clockwise</Button>&nbsp;
            <Button size='small' onClick={rotateImageNegative} id="backward" variant='contained'><FaUndo/>&nbsp;Anti-Clockwise</Button>
              </Box>  
            </AccordionDetails>
              </Accordion>
            </TabPanel>  
            <TabPanel id="advanced">
              <Accordion alignContent="center" className={classes.customAccordionHeader} expanded={expanded === 'panel2'} onChange={handleAccChange('panel2')}>   
              <AccordionSummary
                  align="center"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1b-header">
                    <Typography variant='body2' alignItems={"center"}>This option requires you to use a compass app on your phone while being at the home/plot. You will need to enter the exact degree displayed on the compass following our instructions. Click on the '?' for detailed instructions on how to find this degree, ensuring a highly precise Vastu calculation. &nbsp;&nbsp;<FaQuestionCircle onClick={openHelpTwo}/></Typography>
                </AccordionSummary>
                <AccordionDetails>   
                      
              <Box style={{width:"100%"}}>
                <input type="text" size="10" align="center" id="rotateEntrance" textAlign="center" defaultValue={defaultRotateEntrace < 0 ? (360 - (-1*defaultRotateEntrace)): defaultRotateEntrace > 360 ? defaultRotateEntrace -360: defaultRotateEntrace}/>
                <Button size='small' onClick={setDoorAngle}>Set</Button>
              </Box>  
            </AccordionDetails>
              </Accordion>
              
            </TabPanel>
            </Tabs>
            </Box>
          </Box>: ''}
          <br></br>
          {showImage ?
          <Box style={{width:"100%", display:"relative"}} mb={5} align="center" m="auto">
          <Box> 
          <img id="tempImageId" hidden/>
          {imageAvailable ?
          <select onChange={loadFloorImage} value={defaultImageValue} className="imageSelect">
          {
                floorTypes.map((optionCode) => {
                    return <option value={optionCode}>{getOptionValue(optionCode)}</option>
                })
          }
          </select>: ''}
          <br></br><br></br>
          {selectedDisplayName != '' ? <Box style={{width:"100%", display:"relative"}} mb={5} align="center">
          <Typography >Current Selection: {selectedDisplayName}</Typography>
        </Box>: ''}   
          <Typography variant='h5'>The front of your property is at: {<CompassReading value={defaultRotationAngle}/>}</Typography>
          <Button size='small' onClick={editAngle}>Edit</Button>
          <Button size='small' onClick={resetImage}>Reset</Button>  
          &nbsp; <FaQuestionCircle onClick={openHelpThree}/>
          </Box>
          <Box style={{width:isMobile? "80%": "40%", display:"relative"}} mb={5} align="center" m="auto">


          <DialogTitle id="scroll-dialog-title" align='center' justifyContent="center">Select the section in the image where the {displayName} is located.</DialogTitle>
          <Alert severity="warning" className='centeredAlertContent'>* If multiple sectors cover the area's location, select the sector that encompasses the largest portion or most used space</Alert>
<br></br>


          <div style={{ backgroundImage: `url(${imageUrl})`, backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition:"center", border:"1px solid black"}}>
            <Pie ref={chartRef} data={data} options={chartOptions} onClick={handleChange} plugins={[pieLabelsLine]}/>
          </div>
          <br></br>
          <br></br>
          </Box></Box>:''}
          <br></br>
          <br></br>
        </Box>  
      </Dialog>
      <Dialog
                  maxWidth={"md"}
                  open={openHelp1}
                >
                  <DialogContent>
                  <div>
                    {imageAvailable ? 
                    <MarkdownViewerFromFile mdFilePath="./help/manualEntranceDirRotationHelp_with_image.md" />
                    :
                    <MarkdownViewerFromFile mdFilePath="./help/manualEntranceDirRotationHelp_no_image.md" />
                    }
                    
                  </div>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseHelp1}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>
              <Dialog
                  maxWidth={"md"}
                  open={openHelp2}
                >
                  <DialogContent>
                  <div>
                    {imageAvailable ?
                     <MarkdownViewerFromFile mdFilePath="./help/advanced_MainEntranceDegreeHelp_with_image.md" />
                    :
                    <div>
                     <MarkdownViewerFromFile mdFilePath="./help/advanced_MainEntranceDegreeHelp_no_image.md" />
                    </div>
                    }

                  </div>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseHelp2}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>
              <Dialog
                  maxWidth={"md"}
                  open={openHelp3}
                >
                  <DialogContent>
                  <div>
                    {imageAvailable ? 
                    <MarkdownViewerFromFile mdFilePath="./help/reset_main_entrance_help.md" />

                    :
                    <MarkdownViewerFromFile mdFilePath="./help/reset_main_entrance_help.md" />
                    }
                    
                  </div>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseHelp3}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>
              <Dialog
                  maxWidth={"md"}
                  open={openHelp4}
                >
                  <DialogContent>
                  <div>
                    {imageAvailable ? 
                    <MarkdownViewerFromFile mdFilePath="./help/front_of_home_dropdown_with_image.md" />

                    :
                    <MarkdownViewerFromFile mdFilePath="./help/front_of_home_dropdown_no_image.md" />
                    }
                    
                  </div>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseHelp4}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>

      </Box>

         
    );
  }

  export default MaterialMainDoorModal