import { useState } from "react";
import Toggle from "react-toggle";
import { selectCurrentToken } from "../authSlice";
import { useSelector } from "react-redux";
import { setQueryId } from '../reportSlice';
import { useDispatch } from 'react-redux';
import { FaEdit, FaEye, FaShare } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import RequestService from "../RequestService";
import LinkComponentCustom from "./LinkComponentCustom";
export function ReportActionsCustom({shareQueryId, queryId, viewFunction, editFunction, reportName}) {
    const [ready, setReady] = useState(shareQueryId ? true : false);
    const currentToken = useSelector(selectCurrentToken);
    const dispatch = useDispatch();
    function viewReport() {
        viewFunction(queryId);
    }

    function editReport() {
        dispatch(setQueryId(queryId));
        editFunction(true);
    }



    return (
        <div>
            <ToastContainer position='top-center' theme='dark'/>
            <LinkComponentCustom queryId={queryId} viewReport={viewReport} editReport={editReport} shareQueryId={shareQueryId} reportName={reportName}/>

        </div>
        
    
    )
}

export default ReportActionsCustom;