// usePageView.js

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageView = (pageName) => {
  const location = useLocation();
  const trackingID = process.env.REACT_APP_TRACKING_ID;
  useEffect(() => {
    // Update the document title dynamically
    document.title = `${pageName || 'Vastu Report'}`; // Set a default title if pageName is not provided

    // Send page view event to GA4
    window.gtag('config', trackingID, { page_path: location.pathname + (pageName ? '/' + pageName : '') });
  }, [location.pathname, pageName]);
};

export default usePageView;
