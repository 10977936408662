import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogTitle, Grid, Slide, Button, TextField, Typography, CircularProgress, Box } from '@mui/material';
import { Alert } from '@mui/material';
import RequestService from '../RequestService';
import { selectCurrentToken } from '../authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { userCredits } from '../authSlice';
import SupportAgentSharpIcon from '@mui/icons-material/SupportAgentSharp';
import CommentSection from '../components/CommentSection';
import { selectCurrentUser} from '../authSlice';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AwaitingInputModal = ({ setShowAwaitingInputForm, queryId, loadAllReports }) => {
  const dispatch = useDispatch();
  const currentToken = useSelector(selectCurrentToken);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [clarificationText, setClarificationText] = useState('');
  const [formVisible, setFormVisible] = useState(true);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    loadComments();
  }, []);

  function loadComments() {
    RequestService.getAllUserCommentsByQueryId(queryId, currentToken)
      .then((commentsResponse) => {
        setComments(commentsResponse.data);
      })
      .catch((commentsError) => {
        console.error('Error fetching comments:', commentsError);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleClose() {
    setShowAwaitingInputForm(false);
  }

  const handleProvideClarification = (event) => {
    setClarificationText(event.target.value);
  };

  function submitClarification() {
    if (document.getElementById('clarificationText').value === '') {
      setMessage('Please enter your comments.');
      return;
    }

    const clarificationObject = {
      clarificationText: clarificationText,
      queryId: queryId,
    };

    RequestService.provideClarification(JSON.stringify(clarificationObject), currentToken)
      .then((response) => {
        if (response.status === 200) {
             setSuccessMessage('Thank you for providing the clarification ! Your request has been submitted back for further processing. You can click the "Close Window" button to close this window.');
             setFormVisible(false);
             loadAllReports();
        }
      })
      .catch((err) => {
        console.error('Unable to send back at this time', err);
        setMessage('Unable to send back at this time');
      });
  }

  return (
    <Dialog open={true} scroll={'paper'} onClose={handleClose} TransitionComponent={Transition} maxWidth="md" fullWidth align="center">
      <DialogActions>
        <Button onClick={handleClose}>Close Window X</Button>
      </DialogActions>

      {successMessage && successMessage !== '' ? (
        <div style={{ padding: '16px' }}>
          <Alert severity="success">{successMessage}</Alert>
        </div>
      ) : (
        ''
      )}

      {formVisible && (
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <DialogTitle id="scroll-dialog-title" align="center">
              Provide Clarification
              <br />
            </DialogTitle>

            {message && message !== '' ? <Alert severity="error">{message}</Alert> : ''}

            <Typography>Enter your clarification for the question in last comment (review comments below for questions from the support agent)<span className="mandatory">*</span></Typography>
            <br />

            <TextField id="clarificationText" onChange={handleProvideClarification} fullWidth multiline rows={10} variant="filled" />
            <br />
            <br />
            <br />
            <br />
            <Button variant="contained" size="small" onClick={submitClarification}>
              Submit Clarification
            </Button>
            <br></br>
            <br></br>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', width: '100%' }}>
                <CircularProgress />
              </Box>
            ) : (
              <CommentSection queryId={queryId} currentToken={currentToken} currentUser={currentUser} />
            )}
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export default AwaitingInputModal;
