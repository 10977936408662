import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import ImageMapper from 'react-img-mapper';
import { useState, useEffect } from 'react';
import { Alert, AlertTitle, Button } from '@mui/material';
const CardImageSelect = ({ src, map, handleClick, roads, removeItem, submitSelection }) => {
    const { loading, error } = useState(false)
    useEffect(() => {
      //reload
    }, [roads]);
    if (error) return <p>Error Loading</p>


    return (
        <>
            {loading ? (
                <p>Loading</p>
            ) : (
    <Card>
      <CardActionArea>
      {map && map.areas && map.areas.length > 0 ?   
      <ImageMapper
      src={src}
      width={610}
      height={610}
      map={map}
      onClick={handleClick}/> : ''}
      
        <CardContent>
          <Typography variant="body3" color="text.primary">
            Select all the roads that hits the house from any direction.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
            )}
        </>
    )
}

export default CardImageSelect