import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

const MarkdownViewerFromFile = ({ mdFilePath }) => {
  const [mdContent, setMdContent] = useState('');

const CustomImage = ({ alt, src, title, width, height }) => {
  return <img alt={alt} src={src} title={title} style={{ width: width, height: height }} />;
};

  useEffect(() => {
    const fetchMDContent = async () => {
      try {
        const response = await fetch(mdFilePath);
        const content = await response.text();
        setMdContent(content);
      } catch (error) {
        console.error('Error fetching MD content:', error);
      }
    };
    fetchMDContent();
  }, [mdFilePath]);

  return (
      <div style={{ textAlign: 'justify',lineHeight: '1.1'  }}>
        <ReactMarkdown components={{ img: CustomImage }}>{mdContent}</ReactMarkdown>
      </div>
  );
};

export default MarkdownViewerFromFile;
