import React, { useEffect, useRef, useState } from 'react';
import { Paper, Typography, Grid, Card, CardMedia, CardContent, CardActions, Button, Avatar, Dialog, Alert } from '@mui/material';

const DragAndDropUpload = ({setSelectedFiles, uploadProgress, setUploadedFileCount}) => {
    const [dragOver, setDragOver] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const fileInputRef = useRef();
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
    const MAX_FILES = 20;

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    useEffect(() => {
        setSelectedFiles(uploadedFiles);
    }, [uploadedFiles]);

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        setErrorMessage('');

        const files = Array.from(e.dataTransfer.files);
        if (files.length + uploadedFiles.length > MAX_FILES) {
            setErrorMessage(`You can only upload up to ${MAX_FILES} files.`);
            return;
        }

        const validFiles = files.filter(file => file.size <= MAX_FILE_SIZE);
        if (validFiles.length !== files.length) {
            setErrorMessage(`Each file must be less than ${MAX_FILE_SIZE / 1024 / 1024} MB.`);
        }

        const processedFiles = validFiles.map(file => ({
            file,
            previewUrl: file.type.startsWith('image/') ? URL.createObjectURL(file) : '',
            id: Math.random()
        }));

        setUploadedFiles(currentFiles => [...currentFiles, ...processedFiles]);
    };

    const handleFileChange = (event) => {
        event.preventDefault();
        setErrorMessage('');
        const files = Array.from(event.target.files);
        if (files.length + uploadedFiles.length > MAX_FILES) {
            setErrorMessage(`You can only upload up to ${MAX_FILES} files.`);
            return;
        }

        const validFiles = files.filter(file => file.size <= MAX_FILE_SIZE);
        if (validFiles.length !== files.length) {
            setErrorMessage(`Each file must be less than ${MAX_FILE_SIZE / 1024 / 1024} MB.`);
        }

        const processedFiles = validFiles.map(file => ({
            file,
            previewUrl: file.type.startsWith('image/') ? URL.createObjectURL(file) : '',
            id: Math.random()
        }));

        setUploadedFiles(currentFiles => [...currentFiles, ...processedFiles]);
        setUploadedFileCount(0);
    };


    const handleRemoveFile = (fileId) => {
        setUploadedFiles(currentFiles => currentFiles.filter(file => file.id !== fileId));
        // Revoke the URL for the removed file to avoid memory leaks
        const fileToRemove = uploadedFiles.find(file => file.id === fileId);
        if (fileToRemove && fileToRemove.previewUrl) {
            URL.revokeObjectURL(fileToRemove.previewUrl);
        }
    };

    const renderFilePreview = (file) => {
        if (file.previewUrl) {
            return <Avatar 
            src={file.previewUrl} 
            alt={file.name} 
            style={{ ...avatarStyle, margin: 'auto' }} 
            onClick={() => handleImageClick(file.previewUrl)}
        />;
        }
        // You can add more conditions to handle different file types
        return <Avatar style={{ ...avatarStyle, margin: 'auto' }}>No Preview Available</Avatar>;
    };

    const avatarStyle = {
        width: '100px', // Increase the width
        height: '100px', // Increase the height
        marginRight: '10px',
        borderRadius: '4px',
        cursor: 'pointer' 
    };

    const handleFileInputChange = (event) => {
        handleFileChange(event); // Your existing file change handler
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <Paper
                variant="outlined"
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                style={{ 
                    padding: 20, 
                    textAlign: 'center', 
                    border: '2px dashed #ccc', // Add a dotted border
                    borderRadius: '4px', // Optional: add a border radius
                    ...(dragOver ? { backgroundColor: '#f0f0f0' } : {}) 
                }}
            >
                <Typography>Drag and drop files here</Typography>
                <Typography>OR</Typography>
                <Button 
                            variant="contained" 
                            color="primary" 
                            size='small'
                            onClick={handleButtonClick}
                            style={{ marginBottom: '20px', marginTop: '10px' }}
                        >
                            Select Images
                        </Button>
                        <input 
                            type="file" 
                            multiple 
                            ref={fileInputRef}
                            onChange={handleFileInputChange} 
                            style={{ display: 'none' }}
                        />
            </Paper>
            
            <Grid container spacing={2} style={{ marginTop: 20 }}>
                {uploadedFiles.map((uploadedFile, index) => (
                    <Grid item xs={6} key={uploadedFile.id}>
                        <div style={{ textAlign: 'center' }}>
                            {renderFilePreview(uploadedFile)}
                                <Typography variant="body2">
                                    {uploadedFile.file.name} ({Math.round(uploadedFile.file.size / 1024)} KB)
                                </Typography>
                                <Button size="small" onClick={() => handleRemoveFile(uploadedFile.id)}>Remove</Button>
                        </div>
                    </Grid>
                ))}
            </Grid>
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md">
                    <img src={selectedImage} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
            </Dialog>
        </div>
    );
};

export default DragAndDropUpload;
