import { Alert, AlertTitle, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ImageMapper from 'react-img-mapper';
import { useMediaQuery } from 'react-responsive';
import vs from '../assets/images/padas_32.png';
import vs_rotated from '../assets/images/padas_32_rotated.png';
import deadend from '../assets/plot_street_focus/psf_dead_end.png'
import CardImage from './CardImage';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {Dialog, DialogActions, DialogTitle, Box, Slide} from '@mui/material';
import CardImageSelect from './CardImageSelect';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ModalPadasMaindoor = ({ displayName, id, options, setOpenModal, setDropDownValue, selectedValue }) => {
  const [roads, setRoads] = useState([]);
  const [roadsRotated, setRoadsRotated] = useState([]);
  const [selectedRoad, setSelectedRoad] = useState([]);
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  const [reload, setReload] = useState(false);
  const [value, setValue] = useState(selectedValue);
  const [optionsList, setOptionsList] = useState(options);
  const [map, setMap] = useState([]);
  const [mapRotated, setMapRotated] = useState([]);
  const [rotatedMap, setRotatedMap] = useState([]);
  const [deadEndImage, setDeadEndImage] = useState('../assets/plot_street_focus/psf_dead_end.png');
  const [deadEndArea, setDeadEndArea] = useState([]);
  const [selectedArray, setSelectedArray] = useState([]);
  const [selectedDisplayName, setSelectedDisplayName] = useState([]);
  const [showImages, setShowImages] = useState(false);
  const [showStraight, setShowStraight] = useState(false);
  const [coords, setCoords] = useState([{
    areas: [
      {  
      "coords": [
        274,2,
        340,2,
        340,68,
        274,68
      ]
     },
     {
      "coords": [
        408,2,
        340,2,
        340,68,
        408,68
      ]
     },
     {
      "coords": [
        410,68,
        410,2,
        475,2,
        475,68
      ]
     },
     
     {
      "coords": [
        475,68,
        475,2,
        540,2,
        540,68
      ]
     },
     {
      "coords": [
        540,68,
        540,2,
        610,2,
        610,68
      ]
     },
     {
      "coords": [
        543,135,
        543,68,
        610,68,
        608,135
      ]
     },
     {
      "coords": [
        543,135,
        610,135,
        608,203,
        544,203
      ]
     },
     {
      "coords": [
        543,203,
        608,203,
        608,269,
        543,269
      ]
     },
     {
      "coords": [
        545,273,
        608,273,
        608,338,
        545,338      
      ]
     },
     {
      "coords": [
        545,338,
        608,338,
        608,406,
        545,406
      ]
     },
     {
      "coords": [
        545,406,
        608,406,
        608,475,
        545,475
      ]
     },
     {
      "coords": [
        545,475,
        608,475,
        608,542,
        545,542
      ]
     },
     {
      "coords": [
        545,545,
        606,545,
        608,611,
        545,611
      ] 
     },
     {
      "coords": [
        476,545,
        545,545,
        543,611,
        476,611,
      ]
     },
     {
      "coords": [
        476,541,
        476,611,
        410,611,
        410,542
      ]
     },
     {
      "coords": [
        411,542,
        410,611,
        341,611,
        341,542
      ]
     },
     {
      "coords": [
        341,544,
        344,611,
        273,611,
        272,542
      ]
     },
     {
      "coords": [
        272,542,
        272,611,
        206,611,
        205,542
      ]
     },
     {
      "coords": [
        206,542,
        206,611,
        137,611,
        137,542
      ]
     },
     {
      "coords": [
        138,542,
        138,611,
        70,611,
        70,542
      ]
     },
     {
      "coords": [
        68,542,
        68,611,
        2,611,
        2,542
      ]
     },
     {
      "coords": [
        2,542,
        2,475,
        68,475,
        68,542
      ]
     },
     {
      "coords": [
        2,475,
        2,405,
        68,405,
        68,475
      ]
     },
     {
      "coords": [
        2,406,
        2,338,
        68,338,
        68,406
      ]
     },
     {
      "coords": [
        2,338,
        2,270,
        68,270,
        68,338
      ]
     },
     {
      "coords": [
        2,270,
        2,202,
        68,202,
        68,270
      ]
     },
     {
      "coords": [
        2,202,
        2,136,
        68,136,
        68,202
      ]
     },
     {
      "coords": [
        2,136,
        2,70,
        68,70,
        68,136
      ]
     },
     {
      "coords": [
        2,70,
        2,1,
        68,1,
        68,70
      ]
     },
     {
      "coords": [
        70,66,
        70,2,
        136,2,
        136,66
      ]
     },
     {
      "coords": [
        137,66,
        137,2,
        202,2,
        202,66
      ]
     },
     {
      "coords": [
        205,67,
        205,2,
        272,2,
        272,67
      ]
     }
    ]
  }])

  const [coordsRoated, setCoordsRotated] = useState([{
    areas: [
      {
        "coords": [
          2,70,
          2,1,
          68,1,
          68,70
        ]
       },
       {
        "coords": [
          70,66,
          70,2,
          136,2,
          136,66
        ]
       },
       {
        "coords": [
          137,66,
          137,2,
          202,2,
          202,66
        ]
       },
       {
        "coords": [
          205,67,
          205,2,
          272,2,
          272,67
        ]
       },
      {  
        "coords": [
          274,2,
          340,2,
          340,68,
          274,68
        ]
       },
       {
        "coords": [
          408,2,
          340,2,
          340,68,
          408,68
        ]
       },
       {
        "coords": [
          410,68,
          410,2,
          475,2,
          475,68
        ]
       },
       
       {
        "coords": [
          475,68,
          475,2,
          540,2,
          540,68
        ]
       },
     {
      "coords": [
        540,68,
        540,2,
        610,2,
        610,68
      ]
     },
     {
      "coords": [
        543,135,
        543,68,
        610,68,
        608,135
      ]
     },
     {
      "coords": [
        543,135,
        610,135,
        608,203,
        544,203
      ]
     },
     {
      "coords": [
        543,203,
        608,203,
        608,269,
        543,269
      ]
     },
     {
      "coords": [
        545,273,
        608,273,
        608,338,
        545,338      
      ]
     },
     {
      "coords": [
        545,338,
        608,338,
        608,406,
        545,406
      ]
     },
     {
      "coords": [
        545,406,
        608,406,
        608,475,
        545,475
      ]
     },
     {
      "coords": [
        545,475,
        608,475,
        608,542,
        545,542
      ]
     },
     {
      "coords": [
        545,545,
        606,545,
        608,611,
        545,611
      ] 
     },
     {
      "coords": [
        476,545,
        545,545,
        543,611,
        476,611,
      ]
     },
     {
      "coords": [
        476,541,
        476,611,
        410,611,
        410,542
      ]
     },
     {
      "coords": [
        411,542,
        410,611,
        341,611,
        341,542
      ]
     },
     {
      "coords": [
        341,544,
        344,611,
        273,611,
        272,542
      ]
     },
     {
      "coords": [
        272,542,
        272,611,
        206,611,
        205,542
      ]
     },
     {
      "coords": [
        206,542,
        206,611,
        137,611,
        137,542
      ]
     },
     {
      "coords": [
        138,542,
        138,611,
        70,611,
        70,542
      ]
     },
     {
      "coords": [
        68,542,
        68,611,
        2,611,
        2,542
      ]
     },
     {
      "coords": [
        2,542,
        2,475,
        68,475,
        68,542
      ]
     },
     {
      "coords": [
        2,475,
        2,405,
        68,405,
        68,475
      ]
     },
     {
      "coords": [
        2,406,
        2,338,
        68,338,
        68,406
      ]
     },
     {
      "coords": [
        2,338,
        2,270,
        68,270,
        68,338
      ]
     },
     {
      "coords": [
        2,270,
        2,202,
        68,202,
        68,270
      ]
     },
     {
      "coords": [
        2,202,
        2,136,
        68,136,
        68,202
      ]
     },
     {
      "coords": [
        2,136,
        2,70,
        68,70,
        68,136
      ]
     }
    ]
  }])

  useEffect(() => {
    //reload
    var newSelectedArray = selectedArray;
    if (Array.isArray(newSelectedArray) && newSelectedArray.length === 0) {
      if (value && value != undefined) {
        newSelectedArray = value.toString().split(',');
        setSelectedArray(newSelectedArray);
      }
    }

    var areasArray = [];
    var roadsArray = [];
    {options.map((item, i) => {
          var area = {};
          var road = {};
          area.id = i;
          area.title = item.matchingConditionDisplayName;
          area.name = item.matchingConditionCode;
          area.shape = "poly";
          area.fillColor = "#00ff194c";
          area.strokeColor = "black";
          area.coords = coords[0].areas[i].coords;         
          areasArray.push(area);  
          if (newSelectedArray && newSelectedArray != undefined && newSelectedArray != '') {
            if (newSelectedArray.includes(item.matchingConditionCode)) {
              area.preFillColor = true;
              //area.active = true;
              roadsArray.push(area);
            }
          }
    })}
    var areasArrayRotated = [];
    var roadsArrayRotated = [];
    {options.map((item, i) => {
      var area = {};
      var road = {};
      area.id = i;
      area.title = item.matchingConditionDisplayName;
      area.name = item.matchingConditionCode;
      area.shape = "poly";
      area.fillColor = "#00ff194c";
      area.strokeColor = "black";
      area.coords = coordsRoated[0].areas[i].coords;         
      areasArrayRotated.push(area);  
      if (newSelectedArray && newSelectedArray != undefined && newSelectedArray != '') {
        if (newSelectedArray.includes(item.matchingConditionCode)) {
          area.preFillColor = true;
          //area.active = true;
          roadsArrayRotated.push(area);
        }
      }
})}
    var map = {}
    map.areas = areasArray;
    map.name = "my-map";

    var mapRoated = {}
    mapRoated.areas = areasArrayRotated;
    mapRoated.name = "my-map-rotated";
    setMapRotated(mapRoated);
    setRoadsRotated(roadsArrayRotated);
    setMap(map)
    setRoads(roadsArray)
  }, [reload]);
  
  
  function handleClick(area) {    
    setDropDownValue(id, area.name);
    setOpenModal(false);
  };

  function containsCode(roads, name) {
    for (var i = 0; i < roads.length; i++) {
      if (roads[i].name == name) {
        return true;
        break;
      }
    }
    return false;
  }
  function getIndex(roads, name) {
    for (var i = 0; i < roads.length; i++) {
      if (roads[i].name == name) {
        return i;
        break;
      }
    }
    return -1;
  }

  function handleDndClick() {
    for (let i = roads.length-1; i >= 0; i--) {
      roads.splice(i, 1);
    }
    roads.push(deadEndArea);
    setRoads(roads);
    setReload(!reload)
    setSelectedRoad(deadEndArea)
    setValue(deadEndArea.name);
    
  };

  function clearSelection() {
    for (let i = roads.length-1; i >= 0; i--) {
      roads.splice(i, 1);
    }
    setValue([]);
    setRoads(roads);
    setReload(!reload)
    setSelectedRoad([])
  }

  function removeItem(item) {
    if(roads.includes(item)) {
      const index = roads.indexOf(item);
      if (index > -1) { // only splice array when item is found
        roads.splice(index, 1); // 2nd parameter means remove one item only
        setSelectedRoad([]);
        var newArray = [];
        roads.map((item, i) => {
          newArray.push(item.name);
        });
        setSelectedArray(newArray);
        selectedValue = newArray.join(",");
        setValue(selectedValue);
        setReload(!reload)
      }
    }
  }

  function submitSelection() {
    var selection = "";
    if (roads.length == 0) {
      //you have not made any selection
    } else {
      for (let i=0; i < roads.length; i++) {
        if (i == 0) {
          selection = selection + roads[i].name;
        } else {
          selection = selection + "," + roads[i].name;
        }
      }
    }
    setDropDownValue(id, selection);
    setOpenModal(false);
  }

  function handleClose() {
    setOpenModal(false);
    if (!selectedRoad) {
      setSelectedRoad([]);
    } else {
      setSelectedRoad(deadEndArea);
    }
    
  }

  function handleSelect() {
    var selectedDirection = document.getElementById("frontRoad").value;
    if (selectedDirection) {    
     if(selectedDirection.length == 1) {
      setShowStraight(true);
     } else {
      setShowStraight(false);
     }
     setShowImages(true);
    }
  }
  
    return (
      <Dialog
        fullScreen
        open={true}
        scroll={"paper"}
        onClose={handleClose}
        TransitionComponent={Transition}
        align="center"
      >
        <DialogActions>
          <Button onClick={handleClose}>Close Window X</Button>
        </DialogActions>
        
        <DialogTitle id="scroll-dialog-title" align='center'>Select {displayName} Placement
        <br></br>
        </DialogTitle>
        
        {showImages ? <Box style={{display: isMobile ? 'relative': 'flex', width:"100%", align:'right'}} justify="right">
      {map ? <Card sx={{ width: "100%" }}>
      <CardActionArea>
      {showStraight ?   
      <ImageMapper
      src={vs}
      width={isMobile ? 285 : 450} 
      imgWidth={610}
      map={map}
      fillColor={"#00ff194c"}
      stayHighlighted={true}
      stayMultiHighlighted={true}
      onClick={handleClick}/> : <ImageMapper
      src={vs_rotated}
      width={isMobile ? 285 : 450} 
      imgWidth={610}
      map={mapRotated}
      fillColor={"#00ff194c"}
      stayHighlighted={true}
      stayMultiHighlighted={true}
      onClick={handleClick}/>}
      
        <CardContent>
          <Typography variant="body3" color="text.primary">
            Click on the section where your main door/entrace of the house falls. 
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>: ''}
      </Box>: <Box><Typography variant="body3" color="text.primary">
            Pick the direction of the road in front of the house: &nbsp;
          </Typography>
          <select id="frontRoad" onChange={handleSelect}>
            <option value="">Select one</option>
            <option value="N">North</option>
            <option value="NE">Northeast</option>
            <option value="E">East</option>
            <option value="SE">Southeast</option>
            <option value="S">South</option>
            <option value="SW">Southwest</option>
            <option value="W">West</option>
            <option value="NW">Northwest</option>
          </select>
          </Box>}
      </Dialog>
    );
}


  export default ModalPadasMaindoor


