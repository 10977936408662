import React, { useState, useEffect } from 'react';
import { selectCurrentUser, selectCurrentToken } from '../authSlice';
import { selectSessionId } from '../imageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FaCalculator, FaRegCalendar, FaSave, FaTruckLoading } from 'react-icons/fa';
import BatteryGauge from 'react-battery-gauge';
import RequestService from '../RequestService';
import DataTable, {defaultThemes} from 'react-data-table-component';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import GaugeChart from 'react-gauge-chart'
import { selectReportTitle } from '../reportSlice';
import { selectRotation } from '../angleSlice';
import { Box } from '@mui/material';
const ShowScore = ({ score, overallRating, scoreData, reloadPage, title, editQueryId, showReport, isProcessorScreen }) => {

    const currentUser = useSelector(selectCurrentUser);
    const currentToken = useSelector(selectCurrentToken);
    const currentRotation = useSelector(selectRotation);
    const sessionId = useSelector(selectSessionId);
    const [messageText, setMessageText] = useState([]);
    const [queryId, setQueryId] = useState(editQueryId);
    const reportTitle = useSelector(selectReportTitle);
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ratingText, setRatingText] = useState('Vastuscore™');
    
    const columns = [
        { name: 'Room', selector: row => row.objectDisplayName, grow:50},
        { name: ratingText, selector: row => row.displayScore, grow:35},
        { name: 'Vastu Rating', selector: row => row.comments, grow: 40}
      ];
 
      const customStyles = {
        header: {
            style: {
                minHeight: '56px',
            },
        },
        headRow: {
            style: {
                borderTopStyle: 'solid',
                backgroundColor: "#1976d2",
                borderTopWidth: '1px',
                borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
    };
    
    useEffect(() => {
        const domain = window.location.hostname;
        if (domain === 'vastureport.com') {
            setRatingText('Vastuscore™');
        } else if (domain === 'vaastureport.com') {
            setRatingText('Vaastu Score');
        } else {
            setRatingText('Vastuscore™');
        }
        if (editQueryId) {
            saveUserQuery(false);
        }
    },[score]);
   
    var displayArray = [];  
    scoreData.map((data, index) => {
        const obj = {};
        obj.objectCode = data.objectCode;
        obj.objectDisplayName = data.objectDisplayName;
        obj.matchingConditionCode = data.matchingConditionCode;
        obj.score = data.score;
        obj.rating = data.rating;
        obj.comments = data.comments;
        obj.displayScore = (<BatteryGauge customization={{
            "batteryBody": {
              "fill": "silver",
              "strokeColor": "silver",
              "strokeWidth": 2
            },
            "batteryCap": {
              "fill": "silver",
              "strokeColor": "silver",
              "cornerRadius": 3,
              "strokeWidth": 0,
              "capToBodyRatio": 0.4
            },
            "batteryMeter": {
              "outerGap": 0,
              "gradFill": [
                {
                  "color": "red",
                  "offset": 0
                },
                {
                  "color": "orange",
                  "offset": 20
                },
                {
                   "color": "yellow",
                   "offset": 40
                 },
                {
                   "color": "lightgreen",
                   "offset": 60
                 },
                {
                  "color": "green",
                  "offset": 80
                }
              ]
            },
            "readingText": {
              "lightContrastColor": "purple",
              "darkContrastColor": "black",
              "lowBatteryColor": "red",
              "fontFamily": "Arial",
              "fontSize": 15
            }
          }} size={50} aspectRatio={0.35} animated={true} value={
                                              ((data.score)/10)*100} />);
        displayArray.push(obj);  
    });

    function saveUserQuery(showToast) {
        setMessageText('');
        var localTitle = document.getElementById("userQueryTitle").value;
        if (localTitle === "") {
            toast.error("Title is required");
            return;
        }
        var responseArray = [];
        scoreData.map((data, index) => {
            const obj = {};
            obj.objectCode = data.objectCode;
            obj.matchCondition = data.matchingConditionCode;
            responseArray.push(obj);  
        });
        const queryObj = {};
        queryObj.title = localTitle;
        queryObj.queryId = queryId;
        queryObj.query = responseArray;
        queryObj.imageSessionId = sessionId;
        queryObj.compassRotation = currentRotation;
        var insertRecord = true;
        if (queryId != 0) {
            queryObj.queryId = queryId;
            insertRecord = false;
        }
        RequestService.saveUserQuery(queryObj, currentToken).then((response) => {
            if (response.status == 200) {
                setQueryId(response.data);
                var messageToDisplay;
                if (insertRecord) {
                    messageToDisplay = 'Report Successfully saved!';   
                } else {
                    messageToDisplay = 'Report Successfully Updated!';   
                }
                
                if (showToast) {
                    toast.info(messageToDisplay, {
                        onClose: ({ uid }) => navigate('/myreports')
                    });                    
                }

                //navigate('/myreports');
            } else {
                toast.error('An error occurred saving the report. Please try again later.');
            }
            
        })

     }

     function goToReports() {
        navigate('/myreports')
     }

    return(
        <Box mr={1} ml={1} mb={1} width={showReport ? isMobile ? "87vw": "50vw": isMobile ? "87vw": "28vw"}>                  
                     <fieldset>
                        <legend>Vastuscore™</legend>
                        {currentToken ? 
                        <Box display={"flex"} width={"100%"}>
                            <input type="text" name={isMobile? "search_filter_mobile":"search_filter"} autoFocus={true} id="userQueryTitle" size={100} defaultValue={title != null ? title: reportTitle} placeholder='Enter a title and Save report.'/>&nbsp;
                            {!isProcessorScreen ? <Button variant='contained' size="small" onClick={saveUserQuery}>Save &nbsp;<FaSave/></Button>:''}
                            <ToastContainer position='top-center' theme='light'/>
                        </Box> : ''}
                        <br></br> 
                        <GaugeChart id="score-chart"  
                            nrOfLevels={25} 
                            percent={score/100}
                            textColor="black"
                            formatTextValue = {value => ratingText + ' : ' + value}
                            colors={['#EA4228', '#F5CD19', '#5BE12C']}
                        />           
                        <br>
                        </br>
                        <p className='App-p'>{overallRating}</p>
                        <br>
                        </br>
                     <DataTable theme="light"
                        columns={columns} data={displayArray}  
                        highlightOnHover 
                        dense
                        responsive
                        persistTableHead
                        pagination
                        customStyles={customStyles}
                        striped/>       
                     </fieldset>
                 </Box> 

    )

}

export default ShowScore