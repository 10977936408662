import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import { selectCurrentToken } from "../authSlice";
import { useSelector } from "react-redux";
import RequestService from "../RequestService";
import { useMediaQuery } from "react-responsive";
import { Tooltip } from "@mui/material";

export function ShareToggle({ shareQueryId, queryId, loadAllReports }) {
    const [ready, setReady] = useState(shareQueryId ? true : false);
    const currentToken = useSelector(selectCurrentToken);
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });

    const handleEggsChange = async (event) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            const response = await RequestService.enableShared(queryId, currentToken);
            if (response.status === 200) {
                loadAllReports();
            }
        } else {
            // Ask for user confirmation before disabling
            const shouldDisable = window.confirm(
                "Disabling sharing will deactivate any previously shared links. " +
                "If you have distributed Vastu reports to anyone, the QR codes on those reports might not work, as this shared link is getting deactivated. " +
                "Reactivating sharing will require you to generate a new report with an updated QR code. " +
                "Are you sure you want to proceed?"
            );
            if (shouldDisable) {
                const response = await RequestService.disableShared(queryId, currentToken);
                if (response.status === 200) {
                    loadAllReports();
                }
            } else {
                // Do not change the state if the user cancels
                return;
            }
        }

        // Update the state after the asynchronous operation
        setReady(isChecked);
    };

    return (
        <Tooltip title="Share" placement="top" arrow>
        <Switch checked={ready} onChange={handleEggsChange} size={isMobile ? "small" : "medium"} />
        </Tooltip>
    );
}

export default ShareToggle;
