import React, { useEffect, useState } from 'react';
import colorLib from '@kurkle/color';
import "./Modal.css";
import { FaMinus, FaPlus, FaRedo, FaStepForward, FaUndo } from 'react-icons/fa';
import { useRef } from 'react';
import { Alert, Box, Typography, Collapse, Button, IconButton, DialogActions } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut, getElementAtEvent, Pie } from 'react-chartjs-2';
import { setRotation, selectRotation} from '../angleSlice';
import ChartDataLabels from "chartjs-plugin-datalabels";
import autocolors from 'chartjs-plugin-autocolors';
import { useSelector } from 'react-redux';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, autocolors);

const ModalPieImage = ({ url, setShowImageInModal, imageValue, floorTypes }) => {
    const [errors, setErrors] = useState([]);
    const chartRef = useRef();
    const [openInfo, setOpenInfo] = useState(true);
    const [urlToShow, setUrlToShow] = useState([]);
    const [defaultRoation, setDefaultRotation] = useState(0);
    const [defaultImageValue, setDefaultImageValue] = useState(imageValue);
    const currentRotation = useSelector(selectRotation);

    function setUrlInformation(imageValue) {
      if (imageValue == 'F') {
        setUrlToShow(url[0]);
      } else if (imageValue == 'S') {
        setUrlToShow(url[1]);
      } else {
        setUrlToShow(url[2]);
      }
    }

    function loadImage(e) {
      setUrlInformation(e.target.value);
      if (document.getElementById("rotationAngle")) {
        setDefaultRotation(document.getElementById("rotationAngle").value);
      }
      setDefaultImageValue(e.target.value);
    }

    useEffect(() => {
      setUrlInformation(imageValue);
      /*if (currentRotation != null && !isNaN(currentRotation)) {
        document.getElementById("rotationAngle").value = currentRotation;
        setDefaultRotation(currentRotation);
      }*/
    }, []);
  
    const data = {
      labels: [
      'N',
      'NE',
      'E',
      'SE',
      'S',
      'SW',
      'W',
      'NW'],
      datasets: [
        {
          data: [45,45,45,45,45,45,45,45],
          borderWidth: 1,
          rotation: defaultRoation,
        },
      ],
    };
    function lighten(value, opacity) {
      var alpha = opacity === undefined ? 0.5 : 1 - opacity;
      return colorLib(value).alpha(alpha).rgbString();
    }

    const options = {
      plugins: {
        autocolors : {
          mode: 'data',
          enabled: true,
          customize(context) {
            const colors = context.colors;
            return {
              background: lighten(colors.background, 0.8),
              border: lighten(colors.border, 0.8)
            };
          }
        },
        
        scales: {
          ticks: {
            display: false,
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          display: false,
  /*         callbacks: {
            label: function (index) {
             return data.labels[index.dataIndex];   
            }
          }, */
          anchor: "end",
          align: "start",
          clamp: true,
          clip: false,
        },
        datalabels: {
          color: "blue",
          anchor: "end",
          align: "start",
          clamp: true,
          clip: false,
          formatter: function (value, context) {
              var labelData = context.chart.data.labels[
                  context.dataIndex
              ];
              
              return labelData;
          },
        }, 
      }
    
    };
    

    function onSelectData(event) {
      var element = getElementAtEvent(chartRef.current, event);
      const { datasetIndex, index } = element[0];
    }
    
    function rotateImage() {
      const chart = chartRef.current;
      var currentRotation = chart.data.datasets[0].rotation;
      chart.data.datasets[0].rotation=currentRotation + 45;
      chart.update();
    }

    function rotateImageNegative() {
      let angle = document.getElementById("rotateAngle").value;

      if (!isInt(angle)) {
        setErrors("Please enter value between 0 & 360");
        return;
      } 
      const chart = chartRef.current;
      var currentRotation = chart.data.datasets[0].rotation;
      var angleToRotate = currentRotation - parseFloat(angle);
      if (angleToRotate > 360) {
        angleToRotate = angleToRotate - 360;
      }
      chart.data.datasets[0].rotation=angleToRotate;
      document.getElementById("rotationAngle").value = parseFloat(angleToRotate).toFixed(2);
      chart.update();

  }


  function isInt(value) {
    var x;
    if (isNaN(value)) {
    return false;
    }
    x = parseFloat(value);
    return x;
  }

  function rotateImageWithDegree() {
    let angle = document.getElementById("rotateAngle").value;

    if (!isInt(angle)) {
      setErrors("Please enter value between 0 & 360");
      return;
    } 
    const chart = chartRef.current;
    var currentRotation = chart.data.datasets[0].rotation;
    var angleToRotate = parseFloat(currentRotation) + parseFloat(angle);
    if (angleToRotate > 360) {
      angleToRotate = angleToRotate - 360;
    }
    chart.data.datasets[0].rotation=angleToRotate;
    document.getElementById("rotationAngle").value = parseFloat(angleToRotate).toFixed(2);
    chart.update();
  }

  function getOptionValue(code) {
    if (code == 'F') {
      return 'Main Entrance Floor';
    } else if (code == 'S') {
      return 'Upper floor';
    } else {
      return 'Basement/Other floor'
    }
  }

  function resetImage() {
    const chart = chartRef.current;
    chart.data.datasets[0].rotation=0
    document.getElementById("rotateAngle").value=11.25;
    chart.update();
    document.getElementById("rotationAngle").value = 0;
    setErrors([]);
  }



      return (
        <div className="modalBackgroundUpload">
        <div className="modalContainerUpload">
          <DialogActions>
          <Button onClick={() => {
                setShowImageInModal(false);
              }}>Close Window X</Button>
        </DialogActions>
          <Box style={{width:"100%", display:"relative"}} mb={5} align="center"> 
          <select onChange={loadImage} value={defaultImageValue} className="imageSelect">
          {
                floorTypes.map((optionCode) => {
                    return <option value={optionCode}>{getOptionValue(optionCode)}</option>
                })
          }
          </select>  
          <br></br>
          <br></br>
         <img src={urlToShow} width={"100%"}></img>
          
          </Box> 
        </div>
      </div> 
    );
    }


  export default ModalPieImage


