import React, { useEffect, useRef, useState } from 'react';
import { Button, TextField, Paper, Typography, List, ListItem, ListItemText, Alert, ListItemAvatar, Avatar, Box, Dialog, FormControl, InputLabel, Select, MenuItem, Grid, FormControlLabel, Checkbox, AlertTitle, Step, Stepper, StepLabel, StepContent } from '@mui/material';
import usePageView from './usePageView';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCredit, selectCurrentToken, userCredits } from '../authSlice';
import { useNavigate } from 'react-router-dom';
import RequestService from '../RequestService';
export function SubmitRequest() {
    const [title, setTitle] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [angle, setAngle] = useState('');
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const directions = ['North', 'North-East', 'East', 'South-East', 'South', 'South-West', 'West', 'North-West'];
    const directionAngle = ['0', '45', '90', '135', '180', '225', '270', '315'];
    const [comment, setComment] = useState('');
    const currentCredit = useSelector(selectCurrentCredit);
    const currentToken = useSelector(selectCurrentToken);
    const navigate = useNavigate();
    const [flipStatus, setFlipStatus] = useState(false);
    const fileInputRef = useRef();
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Request Details', 'Upload Images', 'Add Comments'];
    const dispatch = useDispatch();

    usePageView('Submit Request');
    useEffect(() => {
    }, [errorMessages]);

    function handleFlipChange(checked) {
        setFlipStatus(checked);
    };

    const handleNext = () => {
        var errorArray = [];
        // Perform any validation or processing needed before moving to the next step  
        if (activeStep == 0) {
            if (currentCredit < 2) {
                errorArray.push("Not enough credits to Submit request");
                setErrorMessages(errorArray);
                return;    
            }
    
            if (!title ||  title == '') {
                errorArray.push("Request Title is required to submit request");
                setErrorMessages(errorArray);
                return;  
            }
        }
        setErrorMessages([]);
        if (activeStep == 1) {
            if (selectedFiles && selectedFiles.length == 0) {
                errorArray.push("No Image files are added, images are required to submit request");
                setErrorMessages(errorArray);
                return;  
            }

            if (selectedFiles && selectedFiles.length > 6) {
                errorArray.push("Maximum of 6 images can be uploaded");
                setErrorMessages(errorArray);
                return;  
            }

        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleAngleChange = (event) => {
        setAngle(event.target.value);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleFileInputChange = (event) => {
        handleFileChange(event); // Your existing file change handler
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleRemoveFile = (index) => {
        const newFiles = selectedFiles.filter((_, fileIndex) => index !== fileIndex);
        setSelectedFiles(newFiles);
    
        const newPreviewUrls = previewUrls.filter((_, urlIndex) => index !== urlIndex);
        setPreviewUrls(newPreviewUrls);
    };

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        const combinedFiles = [...selectedFiles, ...newFiles];
        var errorArray = [];
        // Limit the number of files to 6
        if (combinedFiles.length > 6) {
            errorArray.push("Maximum of 6 files are allowed");
            setErrorMessages(errorArray);
            return;
        }
    
        // Check for file size (5MB maximum)
        const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
        const areFilesTooLarge = combinedFiles.some(file => file.size > maxSize);
    
        if (areFilesTooLarge) {
            errorArray.push("Each file must be 5 MB or less.");
            setErrorMessages(errorArray);
            return;
        }
    
        setSelectedFiles(combinedFiles);

        // Generate previews
        const newPreviewUrls = combinedFiles.map(file => {
            if (file.type.startsWith('image/')) {
                return URL.createObjectURL(file);
            }
            return null; // For non-image files, you might want to handle differently
        });
        setPreviewUrls(newPreviewUrls);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        var errorArray = [];
        if (currentCredit < 2) {
            errorArray.push("No enough credits to Submit request");
            setErrorMessages(errorArray);
            return;    
        }

        if (!title ||  title == '') {
            errorArray.push("Request Name is required to submit request");
            setErrorMessages(errorArray);
            return;  
        }

        if (selectedFiles && selectedFiles.length == 0) {
            errorArray.push("No Image files are added, images are required to submit request");
            setErrorMessages(errorArray);
            return;  
        }
        // Here you can handle the submission of the title and files
        var requestArray = {};
        requestArray.title=title;
        requestArray.comment = comment;
        requestArray.flip = flipStatus;
        RequestService.submitCalculateRequest(JSON.stringify(requestArray), selectedFiles, currentToken).then((response) => {
            if (response.status === 200) {
                RequestService.getViewCredits(currentToken).then((response) => {
                    if (response.status == 200) {
                       dispatch(userCredits(response.data.creditsRemaining));
                       setRequestSubmitted(true);    
                    }
                 });
                
            } else {
                setRequestSubmitted(false);
            }
        }).catch((err) => {
            errorArray.push("An error occurred while submitting request");
            setErrorMessages(errorArray);
         });
    };

    const avatarStyle = {
        width: '100px', // Increase the width
        height: '100px', // Increase the height
        marginRight: '10px',
        borderRadius: '4px',
        cursor: 'pointer' 
    };

    function buyCredits() {
        navigate('/buycredits');
    }
  

    return (
        <>
        { currentToken ? !requestSubmitted ?
        <Paper style={{ padding: '20px', maxWidth: '100vw', margin: '20px auto', width:isMobile ? "98vw": "42vw" }}>
<Typography variant="h6" align="center" gutterBottom>
    Submit Vastu Evaluation Request
</Typography>
<Typography align="justify" paragraph>
    Experience the convenience of our premium service – simply submit the images of your floor plan and the direction of the front of your home. Let us handle the comprehensive Vastu evaluation for you, ensuring accuracy and peace of mind in every detail.
</Typography>
<Typography align="justify" paragraph>
    Expect a detailed Vastu report within 24-48 hours. Once complete, you can access your reports conveniently under 'My Reports' for a thorough understanding of the Vastu evaluation.
</Typography>


            <br></br>
            <Alert severity="info">2 Credits are required to submit a request</Alert>
            <br></br>
            {currentCredit < 2 ? <Box display={"flex"} alignContent={"center"} justifyContent={"center"} alignItems={"center"}>
                <Button size='small' onClick={buyCredits} variant='contained' color='warning'>Buy Credits</Button></Box>:''}
            <Box marginTop={2}>
            <fieldset>
                <legend>Provide details for calculating score</legend>
                <br></br>
                {errorMessages.length > 0 && (
                    <Alert severity='error' width="100%" align="left">
                    <AlertTitle>Error</AlertTitle>
                    <ul>
                        {errorMessages.map((item, i) => {
                        return <li>{item}</li>;
                        })}
                    </ul>
                </Alert>      
                )}   
                 
                    <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>

                                {index == 0 ? <Grid container spacing={2} alignItems="center"><Grid item xs={12}>
                                <Typography variant="caption">
                                    (Any text that will help you identify the property for which this request is being submitted.)
                                </Typography>    
                                <TextField 
                                    label="Property Title"
                                    fullWidth={true} 
                                    variant="standard"
                                    margin="normal"
                                    defaultValue={title} 
                                    onChange={handleTitleChange} 
                                    size='small'
                                    required
                                />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">What is the angle at the front of your house?</Typography>
                                    <Typography variant="caption">(If you don't know, please upload a site map in next step, we will figure out)</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl fullWidth style={{ marginTop: '20px' }}>
                                    <InputLabel id="direction-select-label">Direction</InputLabel>
                                    <Select
                                        labelId="direction-select-label"
                                        id="direction-select"
                                        value={angle}
                                        label="Direction"
                                        variant='standard'
                                        size='small'
                                        onChange={handleAngleChange}
                                    >
                                        {directions.map((dir, index) => (
                                            <MenuItem key={index} value={directionAngle[index]}>{dir}</MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: 'center' }}>
                                    <Typography variant="subtitle2" component="div">
                                        OR
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label="Angle"
                                        variant="standard"
                                        fullWidth={true}
                                        margin="normal"
                                        value={angle}
                                        onChange={handleAngleChange}
                                        type="number"
                                        size='small'
                                        inputProps={{ min: 0, max: 360 }}
                                    />
                                </Grid></Grid>: index == 1 ? <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                       <Typography variant="body1" gutterBottom style={{ marginTop: '20px', fontWeight: 'bold' }}>
                           Upload Floor Plans and Site Plans
                       </Typography>

                        <Typography component="div" paragraph variant="body2" >
                            <ul>
                                <li>Upload images of your floor plans, including multiple floors like the basement and upper floors.</li>
                                <li>
                                    Upload an images of your site plan that shows the following:
                                    <ul>
                                        <li>Streets around your home.</li>
                                        <li>Your plot shape.</li>
                                        <li>Any water bodies, such as lakes, around your home.</li>
                                    </ul>
                                    <br></br>
                                   <Typography variant="body2">
                                       <strong>Note:</strong> If you don't have images of the site plan with the above requested details, please provide the information in writing in the comments section. Providing these details will give you more accurate Vastu evaluation.
                                   </Typography>
                                </li>
                            </ul>
                        </Typography>


                        <br></br>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            size='small'
                            onClick={handleButtonClick}
                            style={{ marginBottom: '20px', marginTop: '10px' }}
                        >
                            Upload Images
                        </Button>
                        <input 
                            type="file" 
                            multiple 
                            ref={fileInputRef}
                            onChange={handleFileInputChange} 
                            style={{ display: 'none' }}
                        />
                        {selectedFiles.length > 0 && (
                            <List style={{ overflow: 'hidden' }}>
                                <Grid container spacing={2}>
                                    {selectedFiles.map((file, index) => (
                                        <Grid item xs={6} key={index}>
                                            <div style={{ textAlign: 'center' }}>
                                                {previewUrls[index] && (
                                                    <Avatar 
                                                        src={previewUrls[index]} 
                                                        alt={file.name} 
                                                        style={{ ...avatarStyle, margin: 'auto' }} 
                                                        onClick={() => handleImageClick(previewUrls[index])}
                                                    />
                                                )}
                                                <Typography variant="body2">{file.name}</Typography>
                                                <Button
                                                    size="small"
                                                    onClick={() => handleRemoveFile(index)}>
                                                        Remove
                                                </Button>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </List>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                            <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={flipStatus}
                                            onChange={(e) => handleFlipChange(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Flip floor plans"
                                />
                                <br></br>
                                <Typography variant='body2'>(If the uploaded floor plan images are mirror images of the actual floor plan select this option.)</Typography>
                    </Grid>
                                    </Grid>:<Grid container spacing={2} alignItems="center"><Grid item xs={12}>

                                <Typography variant="body2" align="justify" >
                                    <strong>Note:</strong> If your uploaded image of the site plan does not show the below details, please provide the information in writing in this comments section. Providing these details will give you more accurate Vastu evaluation.
                                    <ul>
                                        <li>Streets around your home</li>
                                        <li>Your plot shape</li>
                                        <li>Any water bodies, such as lakes, around your home</li>
                                    </ul>
                                </Typography>
                            <TextField
                            label="Comment"
                            variant="filled"
                            fullWidth
                            margin="normal"
                            multiline
                            size='small'
                            rows={4}  // Adjust the number of rows as needed
                            value={comment}
                            onChange={handleCommentChange}
                        />
                                </Grid></Grid>
                                }
                
                    </StepContent>
                    </Step>
                    ))}
                  </Stepper>      
           
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button disabled={activeStep === steps.length - 1 && currentCredit < 2} onClick={activeStep === steps.length - 1 ? handleSubmit: handleNext}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>     
                
                <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md">
                    <img src={selectedImage} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                </Dialog>
                </fieldset>   
                </Box>
        </Paper>: <Box display={"flex"} alignContent={"center"} justifyContent={"center"} alignItems={"center"}><Typography variant='body1' padding={2}>Vastu Evaluation Request has been successfully Submitted. You can track the status in My Reports page. Expect a response in 24 - 48 hours.</Typography></Box>:
        <Box display={"flex"} alignContent={"center"} justifyContent={"center"} alignItems={"center"}>
            <Typography component={"div"}>Access Denied, Please login to access this page</Typography></Box>}
        </>
    );
};
export default SubmitRequest;