import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/system';
import { Paper, Typography, Dialog, DialogContent, DialogTitle, DialogActions, ListItem, List, Divider, Tab, Tabs, ButtonGroup  } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import compassimg from '../assets/images/compass2.jpg';
import homeimg from '../assets/images/house-2.jpg';
import planimg from '../assets/images/floorplan-3.jpg';
import report from '../assets/images/seo-report.png';
import buy from '../assets/images/payment-method.png';
import calculate from '../assets/images/calculator.png'
import builder from '../assets/images/builder.png';
import buyer from '../assets/images/buyer.png';
import agent from '../assets/images/agent.png';
import { useNavigate } from 'react-router-dom';
import { deepOrange, deepPurple, deepGreen } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../authSlice';
import { Link, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import score from '../assets/images/score.png';
import vastuReport from '../assets/images/report.png';
import vastuComparisonReport from '../assets/images/vastu_comparison_report.png';
import { Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SwipeableTextMobileStepper from './SwipeableTextMobileStepper';
import usePageView from '../components/usePageView'; // Import the custom hook
import TextCarousel from '../components/TextCarousel';

export function Home() {
    usePageView('Home');

    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const [openVideo, setOpenVideo] = useState(false);
    const [sourceVideo, setSouceVideo] = useState('https://youtu.be/poKNSpPXuKg');
    const [dialogTitle, setDialogTitle] = useState('How It Works');
    const [value, setValue] = useState('Buyer');
    const currentToken = useSelector(selectCurrentToken)

    function myreports() {
      navigate('/myreports');
    }

    function handleClose() {
      setOpenVideo(false);
    }

    function handleOpenVideo(newValue){
      setValue(newValue);
      if (newValue == 'Buyer') {
        setSouceVideo("https://www.youtube.com/embed/hnzhDnsRYTg?si=XsJLca2ojIDlNA6o&rel=0&hd=1");
        setDialogTitle('How does Vastu Report help a Home Buyer?')
      } else if (newValue == 'Seller') {
        setSouceVideo('https://www.youtube.com/embed/6pIZw73-lbQ?si=ZHLXOkA7hXvdoiH0&rel=0&hd=1');
        setDialogTitle('How does Vastu Report help a Home Seller?')
      } else  if (newValue == 'RealEstateAgent') {
        setSouceVideo('https://www.youtube.com/embed/Lw1dHA-2CjQ?si=hlTZDyLpj5nlSTYf&rel=0&hd=1');
        setDialogTitle('How does Vastu Report help a Real Estate Agent?')
      } else if (newValue == 'Builder') {
        setDialogTitle('How does Vastu Report help a Builder Sales Agent?')
        setSouceVideo('https://www.youtube.com/embed/c3Uqy5KUwm0?si=_u2Vctw8WRo6k5GT&rel=0&hd=1');
      } else {
        setDialogTitle('How it Works');
        setSouceVideo('https://www.youtube.com/embed/poKNSpPXuKg?si=rzjE6PY_Gqj0FnIx&rel=0&hd=1');
      }
      setOpenVideo(true);
    }

    function goToLogin() {
        navigate('/login');
    }

    function buycredits() {
      navigate('/buycredits');
    }

    function goToRegister() {
        navigate('/login#register');
    }

    function goToBuyerSellerSpace() {
        navigate('/buyerSeller');
    }

    function goToRealEstateSpace() {
        navigate('/agents');
    }

    function goToBuilderPage() {
        navigate('/builders');
    }

    const avatarStyle = {
      backgroundColor: "rgba(210,180,140,1)",
      border: "2px solid blue",
      boxShadow: 20
    };



    const buyerSellerFeaturesBulletItems = [
      'Get Vastuscore™ Report for Your Property',
      'Get Comprehensive Evaluation Reports',
      'Help Choose Plan',
      'Compare Vastu Shastra adherence Across Multiple Properties',
      'Customize and Share Detailed Reports',
      'Boost your home\'s selling price',
      'Highlight Property\'s Strengths for Selling',
      'Include in Ads and make your property stand out'
    ];

    const realEstateAgentFeaturesBulletItems = [
      'Get Vastuscore™ Report for any Property',
      'Get Comprehensive Evaluation Reports',
      'Help Clients Choose Plan',
      'Compare Vastu Shastra adherence Across Multiple Properties',
      'Showcase Positive Attributes of a property ',
      'Enhance Marketing Opportunities and Customer Satisfaction',
      'Include in Ads and make your property stand out',
      'Share Reports Easily with Co-Agents or Buyers'
    ];


    const builderFeaturesBulletItems = [
      'Leverage Vastu Shastra adherence as a Selling Feature',
      'Get Vastuscore™ Report for all site/plan combinations',
      'Find if you can Add Vastu Shastra adherence Premium',
      'Help Clients Choose Plan',
      'Increase Sales by Showcasing Positive Attributes of a property ',
      'Share Detailed Reports via Shareable Links',
      'Include in Ads and make your property stand out',
      'Enhance Marketing Opportunities and Customer Satisfaction'
    ];

      const bulletItems = [
        'Vastuscore™ & Rating: Get a Vastuscore™ and rating for your property',
        'Detailed Reports: Receive room-by-room explanations for property ratings',
        'Compare Properties: Compare Vastu Shastra adherence across multiple properties',
        'Plan Comparison: Evaluate different construction plans for the same site',
        'Share Reports: Share your complete report with others',
        'Home Selling: Highlight positive features for effective property promotion',
        'Modification Impact: Use our calculator to assess changes to your existing home',
      ];

    const bulletItemsRealEstate = [
      'Vastuscore™ & Rating',
      'Detailed Reports',
      'Compare Properties',
      'Plan Comparison',
      'Share Reports',
      'Home Selling',
      'Modification Impact',
    ];


    return (
        <div style={{ width: "100%", backgroundColor:"white", align:"center"}}>
              <Helmet>
                <title>VastuReport.com: Vastu Report - Evaluate Your Home's Vastu with Our Advanced Calculator</title>
                <meta name="description"  content="Vastu Report - Evaluate your property's adherence to Vastu Shastra for Health, Prosperity, Harmony, and Success!"/>
                <meta name="keywords" content="Vastu for USA, Vastu in America, American Vastu Shastra, USA Property Vastu, Vastu Guidelines for US Homes, Vastu Consultant USA, Vastu Services in America, Vastu Expert in USA, Vastu Tips for American Homes, USA Vastu Solutions, Vastu Remedies USA, United States Vastu, Vastu Consultation in USA,vastu calculator, online vastu check, vastu check online free, vastu online free, online vastu calculator, check vastu online, online vastu consultant free, Vastu Evaluation, Vastu Score, Vastu Rating, Property Vastu, Home Vastu, Vastu Consultation, Vastu for Main Door, Main Door Vastu, Vastu for Kitchen, Kitchen Vastu Tips, Vastu for Living Room, Living Room Vastu, Vastu for Master Bedroom, Master Bedroom Vastu, Vastu for Children's Bedroom, Children's Bedroom Vastu, Vastu for Guest Bedroom, Guest Bedroom Vastu, Vastu for Garage, Garage Vastu, Vastu for Study Room, Study Room Vastu, Vastu for Pooja Room, Pooja Room Vastu, Vastu for Dining Room, Dining Room Vastu, Vastu for Toilet, Toilet Vastu, Vastu for Plot Shape, Plot Shape Vastu, Vastu for Streets around the Plot, Plot Street Focus Vastu, Vastu for Water Bodies, Water Bodies Vastu, Vastu for Underground Water Tank, Underground Water Tank Vastu, Vastu for Overhead Water Tank, Overhead Water Tank Vastu, Vastu for Septic Tank, Septic Tank Vastu, Vastu for Sewage System, Sewage System Vastu, Vastu for Plot Shape, Streets around the Plot, Streets in front of the House, Shape of the Plot, Number of Streets around the House"/>
                <link rel="canonical" href="https://vastureport.com/" />

                <meta property="og:url" content="https://vastureport.com/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Vastu Report" />
                <meta property="og:description" content="Evaluate your property's adherence to Vastu Shastra for Health, Prosperity, Harmony, and Success!" />
                <meta property="og:image" content="https://vastureport.com/favicon.ico" />

  <script type="application/ld+json">
    {`{
      "@context": "http://schema.org",
      "@type": "Organization",
      "name": "Vastu Report",
      "url": "https://vastureport.com/",
      "logo": "https://vastureport.com/vastu_report_logo.png",
      "description": "Evaluate your property's adherence to Vastu Shastra for Health, Prosperity, Harmony, and Success!",
      "sameAs": [
        "https://www.facebook.com/profile.php?id=61554448146596",
        "https://twitter.com/VastuReport"
      ]
    }`}
  </script>
              </Helmet>
            <TextCarousel/>
            <Box sx={{ width: '100%', display: isMobile ? 'relative' : 'flex'}} align='center'>
            <Paper style={{ border: "none", boxShadow: "none", width: '100%', backgroundColor:'rgb(250, 250, 252)'}}>
                <Typography variant='h2' align='center' sx={{ width: '100%', fontSize: '1.30rem', fontWeight: '425', lineHeight: '1.5'}} p='10px'>Get Your Vastuscore™ Report: A Comprehensive Vastu Shastra Evaluation Report for Your Property</Typography>
                {currentToken == null ? <div><Typography variant='body1' align='center' sx={{ width: '100%'}} p='10px'>Sign in for a more personalised experience.</Typography>
                <Button component={Link} to="/login#register" variant="outlined">Sign up</Button><br></br>
                <br></br></div>:<div><Typography variant='body1' align='center' sx={{ width: '100%'}} p='10px'>Get started.</Typography>
                <Button component={Link} to="/getscore" variant="outlined">Evaluate Vastu</Button><br></br>
                <br></br></div>}
                
                <Typography variant='body1'>Watch how this will be helpful to you</Typography>
                
                <ButtonGroup variant="text" aria-label="text button group">
                  
  <Button size='small' onClick={() =>handleOpenVideo('Buyer')}>Buyer</Button>
  <Button onClick={() =>handleOpenVideo('Seller')} size='small'>Seller</Button>
  <Button size='small' onClick={() =>handleOpenVideo('RealEstateAgent')}>Real Estate Agent</Button>
  <Button size='small' onClick={() =>handleOpenVideo('Builder')}>Builder Sales Agent</Button>
</ButtonGroup>

          
              
                <Dialog
                  fullWidth={true}
                  maxWidth={"md"}
                  open={openVideo}
                >
                  <DialogTitle>{dialogTitle}</DialogTitle>
                  <DialogContent>
                  {isMobile ? <iframe src={sourceVideo}
                    title="YouTube video player" frameborder="0" allowFullScreen="allowFullScreen"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allow="fullscreen;" allowfullscreen>
                    </iframe>: <iframe height="400" width="800" src={sourceVideo}
                    title="YouTube video player" frameborder="0" allowFullScreen="allowFullScreen"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allow="fullscreen;" allowfullscreen>
                    </iframe>}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                  </DialogActions>
              </Dialog>
            </Paper>
            {!isMobile ? <Paper elevation={0} style={{ boxShadow: "none", width: '100%', backgroundColor:'rgb(250, 250, 252)'}} sx={{display: isMobile ? 'relative':'flex'}}>
                {!isMobile ?<Paper elevation={0} align="center">
                    <img src={compassimg} width={200}/>
                </Paper>: ''}
                {!isMobile ?<Paper elevation={0} align="center">
                    <img src={planimg} width={200}/>
                </Paper>: ''}
                <Paper elevation={0} align="center">
                    <img src={homeimg} width={200}/>
                </Paper>
            </Paper>:<SwipeableTextMobileStepper/>}       
        </Box>

       <Box sx={{ width: '100%', backgroundColor: 'rgb(255, 255, 255)'}} align='center' p={5}>
        <Typography variant='h1' align='center' sx={{ fontSize: "2.25rem", fontWeight: '400', width: '100%', maxWidth: '100%'}} p={isMobile? '1px':'10px'}>Evaluate your property's adherence to Vastu Shastra for Health, Prosperity, Harmony, and Success!</Typography>
        <Typography variant='body1' align='center' sx={{ width: '100%', maxWidth: '950px'}} p={isMobile? '1px':'10px'}>Discover How Our Platform Empowers Buyers, Sellers, Real Estate Agents, and Builder Sales Agents with Comprehensive Vastuscore™ Report, Advanced Tools for Seamless Comparisons, and the Ability to Generate Customized Reports.</Typography>
        <br></br>
        <br></br>

         <Paper elevation={0} sx={{display: isMobile ? 'relative' : 'flex', backgroundColor: 'rgb(250, 250, 252)'}} style={{ width: '100%', justifyContent: 'center' }} p='10px'>
        <Card
          style={{ border: "none", boxShadow: "none" }}
          sx={{ minWidth: "32vw", backgroundColor: 'rgb(250, 250, 252)' }}
        >
          <CardActionArea>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CardMedia
                component="img"
                image={buyer}
                height={75}
                width={75}
                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                alt="Buyer or Seller"
              />
              <CardContent>
                <Typography gutterBottom variant='h2' align='center' sx={{ width: '100%', fontSize: '1.5rem', fontWeight: 'normal'}} component="div">
                  Buyer or Seller
                </Typography>
              <Typography variant="body2" color="text.secondary">

                <List
                  sx={{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                      display: 'list-item',
                    },
                  }}
                >
                  {buyerSellerFeaturesBulletItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <ListItem alignItems="flex-start">{item}</ListItem>
                      {index < buyerSellerFeaturesBulletItems.length - 1 && (
                        <Divider />
                      )}
                    </React.Fragment>
                  ))}
                </List>

                </Typography>
              </CardContent>
            </div>
          </CardActionArea>
          <CardActions style={{ width: '100%', justifyContent: 'center' }}>
          {currentToken ?
                  <Button component={Link} to="/getscore">
                    Evaluate Vastu
                  </Button>: <Button component={Link} to="/buyerSeller">Learn More</Button>
          }


          </CardActions>
        </Card>

      <Card
        style={{ border: "none", boxShadow: "none" }}
        sx={{ minWidth: "32vw", backgroundColor: 'rgb(250, 250, 252)' }}
      >
        <CardActionArea>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CardMedia
              component="img"
              image={agent}
              height={75}
              width={75}
              sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
              alt="Agents"
            />
            <CardContent>
              <Typography gutterBottom variant='h2' align='center' sx={{ width: '100%', fontSize: '1.5rem', fontWeight: 'normal'}} component="div">
                Real Estate Agent
              </Typography>
            <Typography variant="body2" color="text.secondary">

              <List
                sx={{
                  listStyleType: 'disc',
                  pl: 2,
                  '& .MuiListItem-root': {
                    display: 'list-item',
                  },
                }}
              >
                {realEstateAgentFeaturesBulletItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <ListItem alignItems="flex-start">{item}</ListItem>
                    {index < realEstateAgentFeaturesBulletItems.length - 1 && (
                      <Divider />
                    )}
                  </React.Fragment>
                ))}
              </List>
              </Typography>
            </CardContent>
          </div>
        </CardActionArea>
        <CardActions style={{ width: '100%', justifyContent: 'center' }}>
          <Button component={Link} to="/agents">
            Find More
          </Button>
        </CardActions>
      </Card>

       <Card
         style={{ border: "none", boxShadow: "none" }}
         sx={{ minWidth: "32vw", backgroundColor: 'rgb(250, 250, 252)' }}
       >
         <CardActionArea>
           <div
             style={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
             }}
           >
             <CardMedia
               component="img"
               image={builder}
               height={75}
               width={75}
               sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
               alt="Builder Sales Agent"
             />
             <CardContent>
               <Typography gutterBottom variant='h2' align='center' sx={{ width: '100%', fontSize: '1.5rem', fontWeight: 'normal'}} component="div">
                 Builder Sales Agent
               </Typography>
             <Typography variant="body2" color="text.secondary">

               <List
                 sx={{
                   listStyleType: 'disc',
                   pl: 2,
                   '& .MuiListItem-root': {
                     display: 'list-item',
                   },
                 }}
               >
                 {builderFeaturesBulletItems.map((item, index) => (
                   <React.Fragment key={index}>
                     <ListItem alignItems="flex-start">{item}</ListItem>
                     {index < builderFeaturesBulletItems.length - 1 && <Divider />}
                   </React.Fragment>
                 ))}
               </List>
               </Typography>
             </CardContent>
           </div>
         </CardActionArea>
         <CardActions style={{ width: '100%', justifyContent: 'center' }}>
             <Button component={Link} to="/builders">Learn More</Button>
         </CardActions>
       </Card>
        </Paper>
    </Box>

    <Box sx={{ width: '100%', display: isMobile ? 'relative' : 'relative',backgroundColor: 'rgb(255, 255, 255)'}} align='center'>
        <Typography variant='h2' align='center' sx={{ width: '100%', maxWidth: '70%',fontSize: '2rem', fontWeight: 'normal'}} p='10px'>How does this work?</Typography>
        <Typography variant='body1' align='center' sx={{ width: '100%', maxWidth: '70%'}} p='10px'>Finding the vastu for your home is no easy task. We offer a easy to use tools for vastu evaluation so you can get a better understanding of harmony and balance between the natural elements and the built environment.</Typography>
    </Box>

        <Paper elevation={0} sx={{display: isMobile ? 'relative' : 'flex', backgroundColor: 'rgb(250, 250, 252)'}} style={{ width: '100%', justifyContent: 'center' }} p='10px'>
      <Card style={{ border: "none", boxShadow: "none" }} sx={{ minWidth: "32vw", backgroundColor: 'rgb(250, 250, 252)' }}>
      <CardActionArea>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <CardMedia
          component="img"
          image={buy}
          height={75}
          width={75}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          alt="Buyer or Seller"
        />
        <CardContent>
          <Typography gutterBottom variant='h2' align='center' sx={{ width: '100%', fontSize: '1.5rem', fontWeight: 'normal'}} component="div">
          Step 1 - Buy Credits
          </Typography>
          <Typography variant="body2" color="text.secondary">
          <List sx = {{
 listStyleType: 'disc',
 pl: 2,
 '& .MuiListItem-root': {
  display: 'list-item',
 },
}}>
            <ListItem alignItems="center">Sign up to get a free credit</ListItem>
            <Divider/>
            <ListItem>Purchase additional credits</ListItem>
            <Divider/>
            <ListItem>No Subscription required</ListItem>
            <Divider/>
          </List>
          </Typography>
        </CardContent>
        </div>
      </CardActionArea>
      <CardActions style={{ width: '100%', justifyContent: 'center' }}>
        {currentToken ? 
        <Button component={Link} to="/buycredits">
          Buy Credits
        </Button>: <Button onClick={goToLogin}>
          Sign Up/Sign In
        </Button>
        }
      </CardActions>
      </Card>
      <Card style={{ border: "none", boxShadow: "none" }} sx={{ minWidth: "32vw", backgroundColor: 'rgb(250, 250, 252)' }}>
      <CardActionArea>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

       <CardMedia
          component="img"
          image={calculate}
          height={75}
          width={75}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          alt="Agents"
        />
        <CardContent>
          <Typography gutterBottom variant='h2' align='center' sx={{ width: '100%', fontSize: '1.5rem', fontWeight: 'normal'}} component="div">
          Step 2 - Evaluate Vastu
          </Typography>
          <Typography variant="body2" color="text.secondary">
          <List sx = {{
 listStyleType: 'disc',
 pl: 2,
 '& .MuiListItem-root': {
  display: 'list-item',
 },
}}>
            <ListItem>Upload Floor Plan</ListItem>
            <Divider/>
            <ListItem>Select Directions</ListItem>
            <Divider/>
            <ListItem>Evaluate Vastu</ListItem>
            <Divider/>
          </List>
          </Typography>
        </CardContent>
        </div>
      </CardActionArea>
      <CardActions style={{ width: '100%', justifyContent: 'center' }}>
        {currentToken ? 
        <Button component={Link} to="/getscore">
          Calculate
        </Button>: ''
        }
      </CardActions>
        </Card>
        <Card style={{ border: "none", boxShadow: "none" }} sx={{minWidth: "32vw",backgroundColor: 'rgb(250, 250, 252)' }}>
      <CardActionArea>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <CardMedia
          component="img"
          image={report}
          height={75}
          width={75}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          alt="Builder"
        />
        <CardContent>
          <Typography gutterBottom variant='h2' align='center' sx={{ width: '100%', fontSize: '1.5rem', fontWeight: 'normal'}} component="div">
            Step 3 - Get Reports
          </Typography>
          <Typography variant="body2" color="text.secondary">
          <List sx = {{
 listStyleType: 'disc',
 pl: 2,
 '& .MuiListItem-root': {
  display: 'list-item',
 },
}}>
            <ListItem>Save and View detailed report</ListItem>
            <Divider/>
            <ListItem>Compare Reports</ListItem>
            <Divider/>
            <ListItem>Customize and Share reports</ListItem>
            <Divider/>
          </List>
          </Typography>
        </CardContent>
        </div>
      </CardActionArea>
      <CardActions style={{ width: '100%', justifyContent: 'center' }}>
        {currentToken ? 
        <Button component={Link} to="/myreports">
          My Reports
        </Button>: ''
        }
      </CardActions>
        </Card>
        </Paper>
        <br></br>
        <Box style={{ width: '100%', justifyContent: 'center', align:'center' }} align='center'>
          <a href='#' onClick={() =>handleOpenVideo('HowItWorks')}>
                      <span><svg fill="currentColor" height="14" viewBox="0 0 500 1000" width="14" xmlns="http://www.w3.org/2000/svg">
                          <path d="M486 474q14 10 14 26 0 14-14 24L58 790q-24 16-41 6T0 756V242q0-30 17-40t41 6z"></path>
                          </svg>
                      </span>
                      <Typography variant='button' align='center' sx={{ width: '100%'}}>Watch how it works</Typography>
                  </a>
        </Box>
        <br></br>
              <Typography component="div" align='center' variant="body1" color="text.secondary">
                If you would like us to do the work on your behalf send us a note through <Link to='/contactus'>Contact Us</Link> .
               </Typography>
    <br></br>

    </div>
    )
}

export default Home;
