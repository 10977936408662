import { useState } from "react";
import { selectCurrentToken } from "../authSlice";
import { useSelector } from "react-redux";
import { setQueryId } from '../reportSlice';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import LinkComponent from "./LinkComponent";
import DropDownActionComponents from "./DropDownActionComponents";
import { useMediaQuery } from "react-responsive";
export function ReportActions({shareQueryId, queryId, viewFunction, editFunction, reportName, statusCode}) {
    const [ready, setReady] = useState(shareQueryId ? true : false);
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const currentToken = useSelector(selectCurrentToken);
    const dispatch = useDispatch();
    function viewReport() {
        viewFunction(queryId);
    }

    function editReport() {
        dispatch(setQueryId(queryId));
        editFunction(true);
    }



    return (
        <div>
            <ToastContainer position='top-center' theme='dark'/>
            {statusCode == null || statusCode === 'COMPLETED' ? <LinkComponent queryId={queryId} viewReport={viewReport} editReport={editReport} shareQueryId={shareQueryId} reportName={reportName}/>:''}
        </div>
        
    
    )
}

export default ReportActions;