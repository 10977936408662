import { Alert, Typography, Box, Button, Dialog, DialogContent, DialogActions } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { FaCrop, FaExchangeAlt, FaUpload, FaRedo, FaTimes, FaQuestionCircle } from "react-icons/fa";
import UploadService from "./UploadFileService";
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import { useMediaQuery } from 'react-responsive';
import RequestService from "./RequestService";
import ScrollTopFab from "./components/ScrollToTopFab";
import MarkdownViewerFromFile from './components/MarkdownViewerFromFile';


const UploadImage = ({sessionId, token, imageId, mdFilePath}) => {

  const [src, setSrc] = useState(null);
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [openHelp, setOpenHelp] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [crop, setCrop] = useState({
    unit: '%', // Percentages ensure it's relative to the parent image size
    x: 0,      // Start at the top-left corner of the image
    y: 0,
    width: 100, // Cover the full width
    height: 100 // Cover the full height
});
  const [image, setImage] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [rotateImageDisabled, setRotateImageDisabled] = useState(true);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const [flipDisabled, setFlipDisabled] = useState(true);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [scale, setScale] = useState(1)
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  const [isIos, setIsIos] = useState(false);

  function isIosDevice() {
    const userAgent = navigator.userAgent || window.navigator.userAgent;
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  }

  useEffect(() => {
    UploadService.getFiles(sessionId, token)
    .then((files) => {
      var urls = [];
      setSrc(null);
      setFlipDisabled(true);
      setDeleteDisabled(true);
      setRotateImageDisabled(true);
      setImage(null);
        for(let i = 0; i < files.data.length; i++){
          if (files.data[i].fileType == imageId) {
            UploadService.getFile(files.data[i].id, token).then((file) => {
              var url
                url = 'data:image/png;base64,' + file.data;
              setCrop({
                unit: '%', // Percentages ensure it's relative to the parent image size
                x: 0,      // Start at the top-left corner of the image
                y: 0,
                width: 100, // Cover the full width
                height: 100 // Cover the full height
            });
              setSrc(url);
              setFlipDisabled(false);
              setDeleteDisabled(false);
              setRotateImageDisabled(false);
          });
        } else {
      
          setSrc(null);
          setFlipDisabled(true);
          setDeleteDisabled(true);
          setRotateImageDisabled(true);
        }
      }
    }).catch(() => {
      console.log("Unable to load the first floor image");   
    });
  }, [reloadPage]) 


  function handleOpenHelp() {
    setOpenHelp(false);
  }

  function openHelpContent() {
    setOpenHelp(true);
  }

  function deleteFile() {
    setMessage([]);
    setErrorMessage([]);
    RequestService.deleteFile(sessionId, imageId, token).then((response) => {
       if (response.status == 200) {
          setMessage('File Successfully deleted');
          setReloadPage(!reloadPage);
       } else {
          setErrorMessage('An error occurred deleting the image, please try again later');
       }   
    });
 }


  function flipImage() {
    var inputImage = image;
    // create a canvas that will present the output image
    const outputImage = document.createElement('canvas');
    // set it to the same size as the image
    outputImage.width = inputImage.naturalWidth;
    outputImage.height = inputImage.naturalHeight;
    // get the drawing context, needed to draw the new image
    const ctx = outputImage.getContext('2d');
    // scale the drawing context negatively to the left (our image is 400 pixels wide)
    // resulting change to context: 0 to 400 -> -400 to 0
    ctx.scale(-1, 1);
    // draw our image at position [-width, 0] on the canvas, we need
    // a negative offset because of the negative scale transform
    ctx.drawImage(inputImage, -outputImage.width, 0);
    var dataURL = outputImage.toDataURL();
    inputImage.src = dataURL;
    outputImage.toBlob((blob) => {
      let file = new File([blob], fileName, { type: "image/jpeg" })
      setFile(file);
    }, 'image/jpeg');
    setUploadDisabled(false);
    setProgress(0);
}

function rotateImage() {
  var imgElement = image;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const scaleX = imgElement.naturalWidth / imgElement.width;
  const scaleY = imgElement.naturalHeight / imgElement.height;
  canvas.width = imgElement.naturalHeight; // Swap width and height
  canvas.height = imgElement.naturalWidth;
  const pixelRatio = window.devicePixelRatio;

  // Translate to the center of the canvas
  //ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.imageSmoothingQuality = 'high';
  // Rotate the canvas by 90 degrees
  ctx.rotate((90 * Math.PI) / 180);
  //ctx.imageSmoothingEnabled = false;
  // Draw the image, offsetting it to account for the rotation
  ctx.drawImage(
    imgElement,
    -imgElement.width / 2 * scaleX,
    -imgElement.height / 2 * scaleY,
    imgElement.width * scaleX,
    imgElement.height * scaleY
  );
  var dataURL = canvas.toDataURL();
  imgElement.src = dataURL;
  canvas.toBlob((blob) => {
    let file = new File([blob], fileName, { type: "image/jpeg" })
    setFile(file);
  }, 'image/jpeg');
  setUploadDisabled(false);
  setProgress(0);

}


function onLoadImage(e) {
  setMessage([]);
  setErrorMessage([]);
  setImage(e.currentTarget);
  setCrop({
    unit: '%', // Percentages ensure it's relative to the parent image size
    x: 0,      // Start at the top-left corner of the image
    y: 0,
    width: 100, // Cover the full width
    height: 100 // Cover the full height
});
}

function setCropImage(event) {
  setUploadDisabled(true);
  setBtnDisabled(false);
  setCrop(event);
}

const cropImageNow = () => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio || 1; 
  const ctx = canvas.getContext('2d');
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';
  const cropCanvasWidth = crop.width * pixelRatio;
  const cropCanvasHeight = crop.height * pixelRatio;
  canvas.width = cropCanvasWidth;
  canvas.height = cropCanvasHeight;

  ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      cropCanvasWidth,
      cropCanvasHeight
  );

    
  // Converting to base64
  const base64Image = canvas.toDataURL('image/jpeg');
  setCrop({unit: '%', // Percentages ensure it's relative to the parent image size
  x: 0,      // Start at the top-left corner of the image
  y: 0,
  width: 100, // Cover the full width
  height: 100 // Cover the full height
  });  
  setBtnDisabled(true);
  setSrc(base64Image);
  
  canvas.toBlob((blob) => {
    let file = new File([blob], fileName, { type: "image/jpeg" })
    setFile(file);
  }, 'image/jpeg');
  //canvas = null;
  setUploadDisabled(false);
};

const resizeImage = (file, callback) => {
  const reader = new FileReader();
  const originalType = file.type;
  reader.readAsDataURL(file);
  reader.onload = (e) => {
    const img = document.createElement('img');
    img.src = e.target.result;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const MAX_WIDTH = img.width/4;
      const MAX_HEIGHT = img.height/4;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        // Create a new file object
        const resizedFile = new File([blob], file.name, {
          type: originalType,
          lastModified: Date.now()
        });

        // Use the callback to return the resized file
        callback(resizedFile);
      }, originalType, 0.85);
    };
  };
};

const selectImage = (file) => {
  //THis file is used only in desktop mode, ignore resize
  if (isIosDevice && file.size > 2*1024*1024) {
    setIsIos(true);
    resizeImage(file, (resizedFile) => {
      // Do something with the resized file
      console.log('Resized file:', resizedFile.size);
      setSrc(URL.createObjectURL(resizedFile));
      setFile(resizedFile);
      setFileName(resizedFile.name);
      // For example, you can set it to the state or upload it to a server
      setUploadDisabled(false)
      setFlipDisabled(false);
      setRotateImageDisabled(false);
      setCrop({
        unit: '%', // Percentages ensure it's relative to the parent image size
        x: 0,      // Start at the top-left corner of the image
        y: 0,
        width: 100, // Cover the full width
        height: 100 // Cover the full height
      });
    });
  } else {
    setIsIos(false);
    setSrc(URL.createObjectURL(file));
    setFile(file);
    setFileName(file.name);
    // For example, you can set it to the state or upload it to a server
    setUploadDisabled(false)
    setFlipDisabled(false);
    setRotateImageDisabled(false);
    setCrop({
      unit: "%",
      x: isMobile ? 5 : 2,
      y: isMobile ? 5 : 2,
      width: 95,
      height: 95
    })

  } 
};

  const uploadImages = () => {
  
    upload(imageId, file);

  };

  const upload = (idx, file) => {
    let fileType = idx
    let imageState = false;
    return UploadService.upload(file, sessionId, fileType, imageState, token, (event) => {
     setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then(() => {
        setMessage(
          "Uploaded the image successfully: " + file.name,
        );
       setDeleteDisabled(false);
      })
      .catch(() => {
        setProgress(0);
        
        setErrorMessage(
          "Could not upload the image: " + file.name + " .Please check your image file size. It has to be less than 5 MB",
        );
      });
  };

  return (
    <Box>
      
        {message !="" && (
        <Box align="center" style={{display:'relative', width:"90%"}}>  
        <Alert severity="success" align="center">
          {message}
        </Alert>
        </Box>
      )}
    {errorMessage !="" && (
      <Box align="center" style={{display:'relative', width:"90%"}}> 
        <Alert severity="error" align="center">
          {errorMessage}
        </Alert>
        </Box>
      )}
      {file != "" && (
         <Box align="center" style={{display:'relative', width:"90%"}}>
            <div className="progress">
              <div
                className="progress-bar progress-bar-info progress-bar-striped"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progress + "%" }}
              >
                {progress}%
              </div>
            </div>
           </Box> 
          )}
      <Box style={{display:'relative', width:"100%"}} >
      <Box style={{display:'relative', width:"100%"}} >
      <input
        type="file"
        accept="image/jpeg,image/png,image/jpg"
        onChange={(e) => {
          selectImage(e.target.files[0]);
        }}
      />
      <Typography variant='body'>&nbsp;<FaQuestionCircle onClick={openHelpContent}/></Typography>
      <br></br><br></br>
            <Button size="small" variant="contained" disabled={btnDisabled} onClick={cropImageNow}><Box 
        sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column': 'row', 
          alignItems: 'center' 
        }}
      >
        <FaCrop/>
        <Typography variant="body">&nbsp;Crop</Typography>
      </Box>
      </Button>&nbsp;
            <Button size="small" variant="contained" disabled={flipDisabled} onClick={flipImage}><Box 
        sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column': 'row', 
          alignItems: 'center' 
        }}
      >
        <FaExchangeAlt/>
        <Typography variant="body">&nbsp;FLIP</Typography>
      </Box></Button>&nbsp;
            <Button size="small" variant="contained" disabled={uploadDisabled} onClick={uploadImages}><Box 
        sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column': 'row', 
          alignItems: 'center' 
        }}
      >
        <FaUpload/>
        <Typography variant="body">&nbsp;UPLOAD</Typography>
      </Box></Button>&nbsp;
            <Button size="small" variant="contained" disabled={rotateImageDisabled} onClick={rotateImage}><Box 
        sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column': 'row', 
          alignItems: 'center' 
        }}
      >
        <FaRedo/>
        <Typography variant="body">&nbsp;Rotate</Typography>
      </Box></Button>&nbsp;
            <Button size="small" variant="contained" disabled={deleteDisabled} onClick={deleteFile}><Box 
        sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column': 'row', 
          alignItems: 'center' 
        }}
      >
        <FaTimes/>
        <Typography variant="body">&nbsp;Delete</Typography>
      </Box></Button>
          </Box>
          
          <Box>
            <Typography>&nbsp;</Typography>
            {src && (
            <Box align="center" width="100%" style={{display:'flex'}} >
            {/* <Card margin={5}> */}
            <ReactCrop crop={crop} onChange={setCropImage}>
              <img src={src} onLoad={onLoadImage} style={{ transform: `scale(${scale})`,
                objectFit: "contain",
                maxHeight: isMobile ? 300: 400
                }} />
            </ReactCrop>  
            {/* </Card> */}
            </Box>
            )}
          </Box>
        
      </Box>
      <Dialog
                  maxWidth={"md"}
                  open={openHelp}
                >
                  <DialogContent>
                        <MarkdownViewerFromFile mdFilePath="./help/floorPlanUploadHelp.md" />
                    </DialogContent>
                  <DialogActions>
                    <Button onClick={handleOpenHelp}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>

  
    </Box>
);
};

export default UploadImage;