import React, { useEffect, useState } from 'react';

const DirectionDropDownMaterial = ({ value, id, options, isMobile }) => {
    const [newValue, setNewValue] = useState(null);
    const [pickValue, setPickValue] = useState(value);
    const [selectedValue, setSelectedValue] = useState(null);
    function handleChange() {
      setSelectedValue(document.getElementById(id).value);
      setPickValue(null);
    }
  

    useEffect(() => {
      if (pickValue == null) {
        setNewValue(selectedValue);
      } else {
        setNewValue(value);
        setPickValue(value);
      }
      
   }, [value, selectedValue]);

      return (

            <select id={id} value={newValue} onChange={handleChange} name={isMobile ? "showFormMobile": "showForm"}>
              <option value="">--Select One--</option>  
              {
                options.map((optionCode) => {
                    return <option key={optionCode.matchingConditionCode} value={optionCode.matchingConditionCode}>{optionCode.matchingConditionDisplayName}</option>
                })
              }
            </select>

      );
    }


  export default DirectionDropDownMaterial