import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import RequestService from '../RequestService';
import validator from 'validator';
import { toast, ToastContainer } from 'react-toastify';
import { FaEye } from 'react-icons/fa';
import usePageView from '../components/usePageView'; // Import the custom hook

export function Reset() {
    usePageView('Reset Password');

    const [registerMessage, setRegisterMessage] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let [callParams, setCallParams] = useState([]);
    const [message, setMessage] = useState([]);
    const navigate = useNavigate();
    const params = [];

    for(let entry of searchParams.entries()) {
      params.push(entry);
    }
    useEffect(() => {
        var callParams = [];
        var messages = [];
        params.map((param, index) => {
            param.map((value, index) => {
                if (index == 1) {
                    callParams.push(value);
                }
             }); 
         });  
         setCallParams(callParams);
    });

    function toggleField (elementId) {

        var x = document.getElementById(elementId);
        if (x) {
            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        }
        
    }

    function resetPassword() {
        var newPassword = document.getElementById("newpassword").value;
        var userName = document.getElementById("userName").value;
        var resetCode = document.getElementById("resetCode").value;
        var messages = [];
        var proceed = true;
        if (validator.isEmpty(newPassword)) {
            messages.push("New password cannot be empty");
            proceed = false;
        }

        if (validator.isEmpty(userName) || validator.isEmpty(resetCode)) {
            messages.push("Please make sure you have clicked on the link provided in the email");
            proceed = false;
        }

        if (proceed) {
            RequestService.resetPasswordWithToken(JSON.stringify({
                resetPasswordCode: resetCode,
                email: userName,
                newPassword: newPassword
             })).then((response) => {
                if (response.status == 200) {
                    toast.success("Password successfully updated");
                    toast.onChange(v => {
                        if(v.status === "removed"){
                         // run your callback
                         navigate('/login');
                        }
                      })
                } else if (response.status == 401) {
                    messages.push("Oops! It seems like there's an issue. The provided code or user is invalid, or the reset code may have expired. Please initiate the forgot password process again and give it another try.");
                    setShowMessage(true);
                    setRegisterMessage(messages);
                } else  {
                    messages.push("Oops! It looks like there's an issue, and we're currently unable to reset the password. Please try again later. If the problem persists, feel free to contact support for assistance.");
                    setShowMessage(true);
                    setRegisterMessage(messages);
                }
              })
              .catch((err) => {
                    messages.push("Oops! It looks like there's an issue, and we're currently unable to reset the password. Please try again later. If the problem persists, feel free to contact support for assistance.");
                    setShowMessage(true);
                    setRegisterMessage(messages);
            });  
        } else {
            setRegisterMessage(messages);
        }
    }

    return (
        <div className='Scrollable-div'>
        <ToastContainer position='top-center' theme='dark' />    
        <fieldset>
        <legend>Reset Password</legend> 
        {registerMessage.length > 0 && (
        <div className="alert alert-secondary mt-2" role="alert">
          <ul>
            {registerMessage.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </div>
      )}   
        <table className='App-table'>
            <tr>
                <td>
                    <label>User Name:</label>
                </td>
                <td>
                    <input type="text" id="userName" name="userName" defaultValue={callParams[0]} readOnly disabled/>
                </td>
            </tr>
            <tr>
                <td>
                    <label>Reset Code:</label>
                </td>
                <td>
                    <input type="password" id="resetCode" name="resetCode" defaultValue={callParams[1]} readOnly disabled/>
                </td>
            </tr>
            <tr>
                <td>
                    <label>New Password:</label>
                </td>
                <td>
                    <input type="password" id="newpassword" name="newpassword"/>
                    <FaEye id="showPassword" onClick={() => toggleField("newpassword")}/>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <button className='App-butt' onClick={resetPassword}>Reset</button>
                </td>
            </tr>
        </table>
         
        </fieldset>
        </div>

    
    )
}

export default Reset;