import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Typography, Paper, Card, CardActionArea, CardMedia, CardContent, CardActions, Button } from '@mui/material';
import plan from '../assets/images/plan.jpg';
import score from '../assets/images/score.png';
import report from '../assets/images/report.png';
import usePageView from '../components/usePageView'; // Import the custom hook

export function Agents() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });

  usePageView('Agents');

    return (

        <Box sx={{ width: '80%', backgroundColor: 'white'}} align='center' p={5}>


        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>What is Vastu?</Typography>
        <Typography variant="body2" paragraph align='justify'>Vastu (also spelled as Vaastu) is an ancient Indian science of architecture and building design that involves the placement and orientation of buildings in harmony with the natural forces and energy fields of the universe. The word "Vaastu" is derived from the Sanskrit word "Vas" which means "to dwell" or "to live".According to Vaastu, every building or structure has a life force or energy field, and it is believed that by designing and constructing buildings in harmony with the natural energy flows, we can create a positive environment that enhances our well-being, health, and prosperity. The principles of Vaastu are based on the five elements of nature - earth, water, fire, air, and space - and aim to balance and harmonize these elements in the built environment.Vaastu also considers the placement and orientation of rooms, doors, windows, and other features of a building in relation to the cardinal directions and the movement of the sun and stars. It is believed that by aligning a building with the natural forces of the universe, we can create a space that is conducive to good health, happiness, and prosperity.
        </Typography>
        <Typography variant="body2" paragraph align='justify'> It's important to note that Vastu Shastra is not universally accepted or scientifically proven. The effectiveness of Vastu principles may vary from person to person, and personal beliefs and cultural backgrounds play a significant role in its adoption. When buying a property, it's advisable to consider factors like location, budget, functionality, and personal preferences alongside any Vastu considerations.
        </Typography>

        <br/>
        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>How does Vastu benefit a Real Estate Agent?</Typography>

        <Typography variant="body2" paragraph align='justify'>
        By incorporating Vastu principles into their designs and recommendations, real estate agents can help their clients to find properties that are more comfortable, harmonious, and beneficial. This can help to build trust and credibility with clients, and can lead to increased sales and referrals over time.Home buyers who are interested in Vastu principles are typically those who come from South Asian or Indian cultural backgrounds where Vastu is traditionally practiced. However, there is also a growing interest in Vastu among non-Indian home buyers who are interested in holistic and sustainable living. These buyers may be attracted to Vastu-compliant properties for their emphasis on natural light, ventilation, and energy flow, as well as their focus on the well-being of the occupants. Additionally, some home buyers may simply be interested in Vastu as a unique selling proposition that sets their property apart from others on the market.
        </Typography>
        <Typography variant="body2" paragraph align='justify'>When it comes to help buying or selling a property, Vastu can help a real estate agent in several ways:
        </Typography>
        <Typography component="div" align='justify' variant="body2">
          <ul>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2">Distinguish Yourself:</Typography>By genuinely caring for your client's well-being and providing Vastu-inspired guidance, you become a trusted guide, setting yourself apart from the competition.</li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Vastu Specialization:</Typography>Focusing on Vastu-compliant properties isn't just specialization; it's a unique edge. This allows you to attract clients seeking balance and harmony in their living spaces.</li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Build Trust:</Typography>As an agent who genuinely cares about Vastu principles, you'll not only strengthen trust with your clients but also enhance your reputation as an expert in your field. </li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Increased Sales and Referrals:</Typography>When you genuinely care about Vastu principles, it not only strengthens trust with your clients but also enhances your reputation, ultimately leading to increased sales and referrals.</li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Increased customer satisfaction:</Typography>Vastu principles ensure that homes are more than just houses; they become comfortable and harmonious spaces. This enhances the overall experience for your clients, making them not just satisfied but truly delighted.</li>
         </ul>
        </Typography>

        <br/>
        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>What are the required inputs from you?</Typography>
        <Typography component="div" align='justify' variant="body2">
          <ul>
            <li>Directional location, shape, and surroundings of the site or plot.</li>
            <li>Optional: Floor plan for determining room placement and orientation.</li>
            <li>Optional: Provide the precise degree of the front door or north point of your house/plot using a compass to enhance accuracy. Instructions will be provided.</li>
          </ul>
        </Typography>

        <br/>
        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>What we offer?</Typography>

        <Typography variant='h6' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>Assisting Buyers:</Typography>

        <Typography component="div" align="justify" variant="body2">
          <ul>
            <li><Typography sx={{ fontWeight: 'bold' }} variant="body2">Vastuscore™ and Rating:</Typography> With our Vastuscore™ and Rating, you can swiftly determine a property's Vastu compliance using our user-friendly scoring system.</li>
            <li><Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Property Attributes:</Typography> We also provide a list of both positive and negative property attributes, ensuring your clients have the information they need for well-informed decisions.</li>
            <li><Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Detailed Vastu Reports:</Typography> In addition to this, we offer your clients detailed Vastu reports for each property, making their decision-making process a breeze.</li>
            <li><Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Property Comparisons:</Typography> Our offering does not stop here; we also streamline property comparisons based on Vastu compliance, simplifying the decision-making journey for your clients.</li>
            <li><Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Guide for Ideal Floor Plans:</Typography> Finally, with our Vastu analysis report, you can guide clients in choosing the ideal floor plan that aligns seamlessly with Vastu principles for their dream home.</li>
            <li><Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Comprehensive Assistance:</Typography> In a nutshell, all these tools and features work together to help you assist your clients and find their dream home quickly.</li>
          </ul>
        </Typography>


        <Typography variant='h6' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>Assisting Sellers:</Typography>

        <Typography component="div" align="justify" variant="body2">
          <ul>
            <li><Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Leverage Vastu as a Selling Tool:</Typography> Our platform provides a Vastuscore™ and Rating, helping you decide whether to promote Vastu compliance as a selling feature or highlight other property attributes.</li>
            <li><Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Command Competitive Prices:</Typography> Helps find out if your property has a higher degree of Vastu compliance. Remember that it not only adds value but also enables you to command competitive prices, making the property even more appealing to potential buyers.</li>
            <li><Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Showcase Positive Attributes:</Typography> With our tools, you can easily highlight the positive attributes of a property, speeding up the sales process and attracting more potential buyers.</li>
            <li><Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Customized Reports:</Typography> It does not stop here. You can tailor and share customized reports with our platform, emphasizing the property's strengths to draw in prospective buyers.</li>
            <li><Typography sx={{ fontWeight: 'bold' }} variant="body2" pt={1}>Inclusive Advertising:</Typography> In addition to these, we also provide you with links to personalized reports that can be included in your ads, giving you a distinct edge in the market.</li>
          </ul>
        </Typography>



        <Typography variant='h4' align='center' sx={{ width: '100%', maxWidth: '600px'}} p='10px' mb={5}>How It Works</Typography>
        <Paper elevation={0} sx={{display: isMobile ? 'relative':'flex', backgroundColor: 'white'}} style={{ width: '100%', justifyContent: 'center' }} p='10px'>
        <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 400, backgroundColor: 'white'}}>
        <CardActionArea>
        <CardMedia
          component="img"
          image={plan}
          height={200}
          width={200}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          alt="Buyer/Seller"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Collect floor plans.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Sign in and upload your floor plan and use our form to enter the directions of the rooms.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{ width: '100%', justifyContent: 'center' }}>
       
      </CardActions>
      </Card>
      <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 400, backgroundColor: 'white' }}>
        <CardActionArea>
        <CardMedia
          component="img"
          image={score}
          height={200}
          width={200}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          alt="Agents"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Get quick score
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Let our engine analyse the various aspects of the home and plot and get a quick feedback on Overall Score.
          </Typography>
        </CardContent>
      </CardActionArea>
        <CardActions style={{ width: '100%', justifyContent: 'center' }}>
        </CardActions>
        </Card>
        <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 400, backgroundColor: 'white' }}>
        <CardActionArea>
        <CardMedia
          component="img"
          image={report}
          height={200}
          width={200}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          alt="Builder"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Share Detailed Report
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Our reporting tool lets you shared the details report and create comparison report for the different homes.
          </Typography>
        </CardContent>
      </CardActionArea>
        <CardActions style={{ width: '100%', justifyContent: 'center' }}>
        
        </CardActions>
        </Card>
        </Paper>
    </Box>


    
    )
}

export default Agents;