import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Typography, List, ListItem, ListItemIcon, Button, Link } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import usePageView from '../components/usePageView'; // Import the custom hook
import { useNavigate } from 'react-router-dom';

// Import your API icon
import ApiIcon from '../assets/images/api-icon.png';

import vastuScoreAPIExample from '../assets/images/api_example_vastu_score.png';
import vastuScoreRatingAPIExample from '../assets/images/api_example_vastu_score_rating.png';


const sampleGetVastuScoreResponse = [
{
    "id": "a8190ae6-87da-11ee-a207-b0390a868506",
    "title": "test 1",
    "detailedReportLink": "https://vastureport.com/getSharedReport?reportId=5461abc8-a1af-46e7-88df-b3ced8a50801",
    "customReportLink": "https://vastureport.com/getSharedReport?reportId=a8ee8fe7-0b66-40a7-8216-60630ed8a853"
}
];


const sampleGetReportsResponse = [
    {
        "id": "a8190ae6-87da-11ee-a207-b0390a868506",
        "title": "test 1"
    },
    {
        "id": "3ba7902a-87db-11ee-a207-b0390a868506",
        "title": "test 2"
    }
];

const sampleGetReportsRequestContent = (
  <Typography variant="body2" paragraph align='justify'>
    GET /v1/reports HTTP/1.1
    <br />
    Host: api.vastureport.com
    <br />
    Authorization: Bearer {'<<YOUR_API_KEY>>'}
    <br />
    X-Token-Type: API_KEY
  </Typography>
);

const sampleGetVastuScoreContent = (
  <Typography variant="body2" paragraph align='justify'>
    GET /v1/reports/&lt;&lt;report id&gt;&gt; HTTP/1.1
    <br />
    Host: api.vastureport.com
    <br />
    Authorization: Bearer {'<<YOUR_API_KEY>>'}
    <br />
    X-Token-Type: API_KEY
  </Typography>
);

// Define constant for API examples
const apiExamples = [
  {
    number: 1,
    apiName:"1. Get Reports API",
    description: 'This API provides a list of meta data of all previously generated Vastuscore™ reports associated with the account.This API returns a JSON output',
    sampleRequest: sampleGetReportsRequestContent,
    sampleResponse: sampleGetReportsResponse,
    dataTypeDescription: [
        { attribute: 'id', type: 'string', description: 'Unique identifier for the report.' },
        { attribute: 'title', type: 'string', description: 'Title of the report.' },
    ],
    curlExample: 'curl --location \'https://api.vastureport.com/v1/reports\' --header "Authorization: Bearer {\'<<YOUR_API_KEY>>\'}" --header "X-Token-Type: API_KEY"',

  },
  {
    number: 2,
    apiName:"2. Get Vastuscore™ evaluation report API",
    description:  'This API provides detailed information about the Vastu Analysis for a specific property. The Vastuscore™ evlauation report offers insights into the property\'s Vastu compliance. Users can access a detailed report through the provided link. If available, a custom report link is included, offering additional tailored information that can be utilized for property sales advertisements. It\'s important to note that the Detailed Report Link and Custom Report Link will be null if the reports are not shared. The response format is JSON.',
    sampleRequest: sampleGetVastuScoreContent,
    sampleResponse: sampleGetVastuScoreResponse,
    dataTypeDescription: [
        { attribute: 'id', type: 'string', description: 'Unique identifier for the report.' },
        { attribute: 'title', type: 'string', description: 'Title of the report.' },
        { attribute: 'detailedReportLink', type: 'string', description: 'Link to the detailed Vastuscore™ Vastu Evaluation Report.' },
        { attribute: 'customReportLink', type: 'string', description: 'Link to the customized Vastuscore™ Vastu Evaluation Report.' },
    ],
    curlExample: 'curl --location \'https://api.vastureport.com/v1/reports/<<report id>>\' --header "Authorization: Bearer {\'<<YOUR_API_KEY>>\'}" --header "X-Token-Type: API_KEY"',
  },
  // Add more examples as needed
];



export function VastuReportAPIs() {
 usePageView('API');
  const navigate = useNavigate();
  const boldCheckStyle = { fontWeight: 'bold', fontSize: '24px', color: 'green' };

  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });

  function contactUs() {
    navigate('/contactus')
  }

  return (
    <Box sx={{ width: '80%', backgroundColor: 'white' }} align='center' p={5}>

      <Box display="flex" alignItems="center">
        <ListItemIcon>
          {/* Your icon component here */}
          <img src={ApiIcon} alt="API Icon" style={{ height: '100px', marginRight: '10px', verticalAlign: 'middle' }} />
        </ListItemIcon>
        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%', lineHeight: '100px' }} mb={1}>
          Vastu Report APIs Overview
        </Typography>
      </Box>

      <br />
      <Typography variant="body2" paragraph align='justify'>
        Developers can leverage Vastu Report APIs to seamlessly integrate and display Vastuscore™ for properties. These APIs empower your application to provide valuable insights into the Vastu compliance of a property, enhancing the user experience with real-time information.
      </Typography>

      <br />

    <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>
        Key Features
      </Typography>
      <Typography component="div" align='justify' variant="body2">
        <List style={{ padding: '0' }}>
          <ListItem style={{ paddingTop: '0', paddingBottom: '5px' }}>
            <ListItemIcon>
              <CheckIcon style={{ ...boldCheckStyle, color: 'green' }} />
            </ListItemIcon>
            <Typography variant="body2" style={{ marginLeft: '10px' }}>
              Add Vastuscore™ Vastu Evaluation Report to your real estate listings.
            </Typography>
          </ListItem>
          <ListItem style={{ paddingTop: '0', paddingBottom: '5px' }}>
            <ListItemIcon>
              <CheckIcon style={{ ...boldCheckStyle, color: 'green' }} />
            </ListItemIcon>
            <Typography variant="body2" style={{ marginLeft: '10px' }}>
              Enable public access on customized reports and provide a direct link to this detailed Vastu Report of a property for users to access.
            </Typography>
          </ListItem>
        </List>
      </Typography>

      <br />
        <br />

    <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>
      Getting Started
    </Typography>
    <Typography variant="body2" paragraph align='justify'>
    Purchasing credits automatically grants you permission to get Vastuscore™ Report for your properties and to make use of our API for those properties. To start using the API, you will need an API key. Request an API Key by contacting us
     <br/>
     <br/>
     <Button variant="contained" color="primary" onClick={contactUs}>
     Get an API Key
     </Button>

    </Typography>


            <br/>
            <br/>

                        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>
                          Available APIs with examples
                        </Typography>

           <br/>
            <br/>


      {/* API Examples */}
      {apiExamples.map((example) => (
        <div key={example.number}>
          <Typography variant='h5' align='left' sx={{ width: '100%', maxWidth: '100%' }} mb={1}>
            {example.apiName}
          </Typography>
          <Typography variant="body2" paragraph align='justify'>
            {example.description}
          </Typography>

        <Box sx={{ width: '100%', backgroundColor: 'white', textAlign: 'left' }} p={0}>
          <pre style={{ backgroundColor: '#f4f4f4', padding: '10px', paddingTop: '15px', paddingLeft: '15px', paddingRight: '50px', borderRadius: '5px', textAlign: 'left', width: 'fit-content' }}>
            {example.sampleRequest}
          </pre>
        </Box>`

{/* Display Data Types */}
<Typography variant='h6' align='left' sx={{ width: '100%', maxWidth: '100%' }} mb={1}>
  Response JSON Data Types
</Typography>

<Box sx={{ width: '100%', backgroundColor: 'white', textAlign: 'left' }} p={0}>

<table style={{ borderCollapse: 'collapse', width: '70%' }}>
  <thead>
    <tr style={{ borderBottom: '1px solid #ddd' }}>
      <th style={{ padding: '8px', textAlign: 'left' }}>Attribute</th>
      <th style={{ padding: '8px', textAlign: 'left' }}>Type</th>
      <th style={{ padding: '8px', textAlign: 'left' }}>Description</th>
    </tr>
  </thead>
  <tbody>
    {example.dataTypeDescription.map((data) => (
      <tr key={data.attribute} style={{ borderBottom: '1px solid #ddd' }}>
        <td style={{ padding: '8px', textAlign: 'left' }}><strong>{data.attribute}</strong></td>
        <td style={{ padding: '8px', textAlign: 'left' }}>{data.type}</td>
        <td style={{ padding: '8px', textAlign: 'left' }}>{data.description}</td>
      </tr>
    ))}
  </tbody>
</table>
</Box>`


<br/>
<br/>

<Typography variant="h6" paragraph align='justify'>
    cURL example:
</Typography>

<Box sx={{ width: '100%', backgroundColor: 'white', textAlign: 'left' }} p={0}>
  <pre style={{ backgroundColor: '#f4f4f4', padding: '10px', paddingTop: '15px', paddingLeft: '15px', paddingRight: '50px', borderRadius: '5px', textAlign: 'left', width: 'fit-content' }}>
    <Typography variant="body2" paragraph align='justify'>
      {example.curlExample}
    </Typography>
  </pre>
</Box>`

        <div key={example.number}>
          <Typography variant="h6" paragraph align='justify'>
            Sample Response:
          </Typography>
          <Box sx={{ width: '100%', backgroundColor: 'white', textAlign: 'left' }} p={0}>
            <pre style={{ backgroundColor: '#f4f4f4', padding: '10px', paddingTop: '15px', paddingLeft: '15px', paddingRight: '50px', borderRadius: '5px', textAlign: 'left', width: 'fit-content' }}>
              <Typography variant="body2" paragraph align='justify'>
                {JSON.stringify(example.sampleResponse, null, 2)}
              </Typography>
            </pre>
          </Box>
        </div>
          <br />
        </div>
      ))}
    </Box>
  );
}

export default VastuReportAPIs;
