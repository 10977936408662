import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Typography, Paper, Card, CardActionArea, CardMedia, CardContent, CardActions, Button } from '@mui/material';
import plan from '../assets/images/plan.jpg';
import score from '../assets/images/score.png';
import report from '../assets/images/report.png';
import usePageView from '../components/usePageView'; // Import the custom hook

export function Agents() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  usePageView('Buyer Seller');

    return (

        <Box sx={{ width: '80%', backgroundColor: 'white'}} align='center' p={5}>

        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>What is Vastu?</Typography>
        <Typography variant="body2" paragraph align='justify'>Vastu (also spelled as Vaastu) is an ancient Indian science of architecture and building design that involves the placement and orientation of buildings in harmony with the natural forces and energy fields of the universe. The word "Vaastu" is derived from the Sanskrit word "Vas" which means "to dwell" or "to live".According to Vaastu, every building or structure has a life force or energy field, and it is believed that by designing and constructing buildings in harmony with the natural energy flows, we can create a positive environment that enhances our well-being, health, and prosperity. The principles of Vaastu are based on the five elements of nature - earth, water, fire, air, and space - and aim to balance and harmonize these elements in the built environment.Vaastu also considers the placement and orientation of rooms, doors, windows, and other features of a building in relation to the cardinal directions and the movement of the sun and stars. It is believed that by aligning a building with the natural forces of the universe, we can create a space that is conducive to good health, happiness, and prosperity.
        </Typography>
        <Typography variant="body2" paragraph align='justify'> It's important to note that Vastu Shastra is not universally accepted or scientifically proven. The effectiveness of Vastu principles may vary from person to person, and personal beliefs and cultural backgrounds play a significant role in its adoption. When buying a property, it's advisable to consider factors like location, budget, functionality, and personal preferences alongside any Vastu considerations.
        </Typography>

        <br/>
        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>How does Vastu help a Buyer?</Typography>

        <Typography variant="body2" paragraph align='justify'>Vastu is a traditional Indian system of architecture and design that is believed to create a harmonious and balanced living environment. According to proponents of Vastu, adhering to its principles can have a positive impact on various aspects of life, including health, wealth, and relationships.
        </Typography>
        <Typography variant="body2" paragraph align='justify'>When it comes to buying a property, Vastu can help a buyer in several ways:
        </Typography>
        <Typography component="div" align='justify' variant="body2">
          <ul>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2">Choosing the right property:</Typography>Vastu principles can be used to evaluate potential properties and identify those that are most aligned with the principles of balance and harmony.</li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Improving the living environment:</Typography>Vastu principles can be applied to the design and layout of the property, including the placement of rooms, furniture, and other elements, to create a more balanced and harmonious living environment.</li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Wealth and Prosperity:</Typography>Vastu Shastra includes principles for the placement of rooms, entrances, and other elements to attract prosperity and abundance. Some buyers believe that aligning their living or working spaces with these principles can enhance financial well-being and success</li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Emotional Balance:</Typography></li>Vastu takes into account the psychological and emotional impact of spatial arrangements. It suggests the ideal placement of rooms and objects to create a sense of balance and tranquility, which can positively affect the occupants' mood and emotional state
          </ul>
        </Typography>

        <br/>
        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>How does Vastu help a Seller?</Typography>
        <Typography component="div" align='justify' variant="body2">
          <ul>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2">Highlighting positive aspects:</Typography>Vastu allows sellers to identify and emphasize the positive aspects of their property. By providing a custom Vastu report that highlights the favorable Vastu features, sellers can present the property's unique selling points, attracting interested buyers.</li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Increased buyer confidence: </Typography>Some potential buyers may value Vastu principles and believe that a Vastu-compliant property promotes positive energy and well-being. By aligning with Vastu, sellers may appeal to buyers who seek properties with Vastu considerations, increasing their confidence in the property's potential.</li>
            <li><Typography sx={{fontWeight: 'bold'}} variant="body2" pt={1}>Marketing advantage:</Typography>Utilizing Vastu principles can provide a unique selling proposition. Sellers can promote their property as Vastu-compliant, distinguishing it from other properties and potentially attracting buyers who value Vastu principles in their decision-making process.</li>
          </ul>
        </Typography>
        <Typography variant="body2" paragraph align='justify'> It is important to note that the effectiveness of Vastu in selling a property may vary depending on individual beliefs and cultural factors. While Vastu can be considered as one aspect of property presentation, sellers should also focus on other conventional aspects, such as pricing, staging, and marketing strategies, to enhance their chances of a successful sale.
        </Typography>

        <br/>
        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>What are the required inputs from you?</Typography>
        <Typography component="div" align='justify' variant="body2">
          <ul>
            <li>Directional location, shape, and surroundings of the site or plot.</li>
            <li>Optional: Floor plan for determining room placement and orientation.</li>
            <li>Optional: Provide the precise degree of the front door or north point of your house/plot using a compass to enhance accuracy. Instructions will be provided.</li>
          </ul>
        </Typography>

        <br/>
        <Typography variant='h4' align='left' sx={{ width: '100%', maxWidth: '100%'}} mb={1}>What we offer?</Typography>
        <Typography component="div" align='justify' variant="body2">
          <ul>
            <li>A Vastuscore™ and rating for your property.</li>
            <li>A detailed report explaining the reasons for the rating given to each room or plot.</li>
            <li>The ability to compare the Vastu of multiple properties.</li>
            <li>Compare different plans on the same site: If you are considering multiple plans for construction on a site and need assistance in selecting the best one, our Vastuscore™ calculator will aid you in making the decision. It will provide you with Vastu scores and ratings for each plan, helping you determine the most favorable option.</li>
            <li>A link to share your complete Vastu report with others.</li>
            <li>When you decide to sell your home, we offer a report that focuses exclusively on the positive features of your property. This report can be utilized in ads to effectively promote your property during the sale, emphasizing its strengths and appealing aspects.</li>
            <li>Helps find out if your property has a higher degree of vastu compliance, which in turn will help boost your home's selling price.</li>
            <li>Are you planning to make any modifications to your existing home? – use our vastu calculator to know how the change could affect your property</li>
          </ul>
        </Typography>

        <Typography variant='h4' align='center' sx={{ width: '100%', maxWidth: '600px'}} p='10px' mb={5}>How It Works</Typography>
        <Paper elevation={0} sx={{display: isMobile ? 'relative':'flex', backgroundColor: 'white'}} style={{ width: '100%', justifyContent: 'center' }} p='10px'>
        <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 400, backgroundColor: 'white'}}>
        <CardActionArea>
        <CardMedia
          component="img"
          image={plan}
          height={200}
          width={200}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          alt="Buyer/Seller"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Collect floor plans.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Sign in and upload your floor plan and use our form to enter the directions of the rooms.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{ width: '100%', justifyContent: 'center' }}>
       
      </CardActions>
      </Card>
      <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 400, backgroundColor: 'white' }}>
        <CardActionArea>
        <CardMedia
          component="img"
          image={score}
          height={200}
          width={200}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          alt="Agents"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Get Vastuscore™ and Rating
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Let our engine analyse the various aspects of the home and plot and get you your property's Vastuscore™.
          </Typography>
        </CardContent>
      </CardActionArea>
        <CardActions style={{ width: '100%', justifyContent: 'center' }}>
        </CardActions>
        </Card>
        <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 400, backgroundColor: 'white' }}>
        <CardActionArea>
        <CardMedia
          component="img"
          image={report}
          height={200}
          width={200}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          alt="Builder"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Detailed Report
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Out reporting tool lets you shared the details report and create comparison report for the different homes.
          </Typography>
        </CardContent>
      </CardActionArea>
        <CardActions style={{ width: '100%', justifyContent: 'center' }}>
        
        </CardActions>
        </Card>
        </Paper>
    </Box>


    
    )
}

export default Agents;