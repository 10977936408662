import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import RequestService from '../RequestService';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import {userLogin, userLogout, selectCurrentUser, selectCurrentToken, userCredits} from '../authSlice'
import config from '../config';
import usePageView from '../components/usePageView'; // Import the custom hook

export function Success() {
    usePageView('Purchase Credit Success');

    let [searchParams, setSearchParams] = useSearchParams();
    const [message, setMessage] = useState([]);
    const params = [];
    const dispatch = useDispatch();
    for(let entry of searchParams.entries()) {
      params.push(entry);
    }
    useEffect(() => {
        var callParams = [];
        var messages = [];
        params.map((param, index) => {
            param.map((value, index) => {
                if (index == 1) {
                    callParams.push(value);
                }
             }); 
         });  
         
         fetch(config[process.env.NODE_ENV].backendUrl + '/verifyToken', {
            method: 'POST',
            body: JSON.stringify({
               tokenIdentifier: callParams[0],
               agreedToTermsAndConditions: 0
            }),
            headers: {
               'Content-type': 'application/json; charset=UTF-8',
            },
         })
            .then((response) => response.json())
            .then((data) => {
               var tokenObject = jwtDecode(callParams[0]);
               var userObject = {};
               userObject.token = callParams[0];
               userObject.name = data.name;
               userObject.emailVerified = data.tokenVerified;
               userObject.email = tokenObject.email;
              
               if (data.tokenVerified == true) {
                 dispatch(userLogin(userObject))
                 RequestService.paymentSuccess(callParams[1], callParams[0]).then((response) => {
                    if (response.status == 200) {
                        if (response.data.status == 'PAID') {
                            var creditsAdded = response.data.totalCreditsAdded;
                            RequestService.getViewCredits(callParams[0]).then((response) => {
                                if (response.status == 200) {
                                   dispatch(userCredits(response.data.creditsRemaining));
                                   setMessage('Payment Succeeded and ' + creditsAdded + ' credit(s) were added to your account. You can check the order details in My Orders');
                                }
                             });
                        }
                        
                    } else {
                        setMessage('Please check back after few minutes.');
                    }                
                  })
                  .catch((err) => {
                    messages.push("Unable to activate the user. The activation token has expired");
                    setMessage(messages);
                });
               }
     
            }).catch((err) => {
               console.log(err.message);
            });
     


    });

    return (

        <p className='App-p'>{message}</p>

    
    )
}

export default Success;