import React, {useRef} from 'react';
import { Box, LinearProgress } from "@mui/material"
import { Document, Page, pdfjs } from 'react-pdf';
import PDFViewerHeader from "./PDFViewerHeader"
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useEffect } from 'react';


const DocumentViewer = ({ loadingReport, pdfUrl, messageText, showPdfViewer, compareCount}) => {

    const printRef = useRef();
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [defaultScale, setDefaultScale] = useState(isMobile ? 0.6 : 1.5);


    useEffect(() => {
      const isMobileAndPortrait = isMobile && window.innerHeight > window.innerWidth;

      if (!isMobile && compareCount > 2) {
        setDefaultScale(1);
      } else if (isMobileAndPortrait && compareCount > 2) {
        setDefaultScale(0.30);
      } else {
        setDefaultScale(isMobile ? 0.6 : 1.5);
      }
    }, [isMobile, compareCount]);


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
      };
    
      const zoomIn = () => {
        setDefaultScale(defaultScale + 0.1);
      };
    
      const zoomOut = () => {
        if (defaultScale > 0.1) setDefaultScale(defaultScale - 0.1);
      };

const handleViewPrint = async () => {
  try {
    const pdfData = await fetch(pdfUrl).then((res) => res.blob());
    const blobUrl = URL.createObjectURL(pdfData);

    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.write(`<iframe width="100%" height="100%" src="${blobUrl}"></iframe>`);
      newWindow.document.title = 'Print Preview';

      // Instruct users to use the browser's print option (e.g., Ctrl+P or Command+P)
      newWindow.document.write('<p>Please use the browser\'s print option (e.g., Ctrl+P or Command+P) to print this document.</p>');
    }
  } catch (error) {
    console.error('Error fetching PDF data:', error);
  }
};

    const downloadPdf = () => {
        // Check if pdfUrl is available and there is a valid queryId
        if (pdfUrl) {
          const link = document.createElement('a');
          link.href = pdfUrl;
          link.download = `vastu_report.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          // Handle the case where pdfUrl is not available or there is no queryId
          console.error("Cannot download PDF. pdfUrl is not available or no queryId.");
        }
      };
    
    return (
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%" }}>
        {loadingReport ? (
          // Render a loading indicator or message instead of the download button
          <Box width={"60%"} display={"flex"} alignContent={"center"} justifyContent={"center"} flexDirection="column" align="center">
          <LinearProgress/>
          <p>Loading Report</p>
          </Box>
        ) : (
          // Render the download button only when the report is loaded
          <Box display={"flex"} alignContent={"center"} justifyContent={"center"} flexDirection="column" align="center" >
          {showPdfViewer ? (
          <div>
            <PDFViewerHeader downloadPdf={downloadPdf} zoomIn={zoomIn} zoomOut={zoomOut} handleViewPrint={handleViewPrint} pageNumber={pageNumber} numPages={numPages}/>
          <div className='pdf-viewer-container' width={isMobile ? "90%": "70vw"}>
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} mobile={true}>
            
              {Array.from(new Array(numPages), (el, index) => (
                <div key={`page_container_${index + 1}`} className='pdf-page'>
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    scale={defaultScale}
                    renderAnnotationLayer={false}
                    className="pdf-page-content"
                  />
                </div>
              ))}
            </Document>
          </div>
          </div>  
        ) : (
          <div className="loading-error-message">
             {messageText}
          </div>
        )}
   
          </Box>
        )}
   
      </Box>
    );
};

export default DocumentViewer;