import { createSlice } from "@reduxjs/toolkit";

export const reportSlice = createSlice({
    name: "report",
    initialState: { queryId: null, reportTitle: null },
    reducers: {
        setQueryId: (state, action) => {
            state.queryId = action.payload;
        },

        setReportTitle: (state, action) => {
            state.reportTitle = action.payload;
        },

        clearQueryId: (state) => {
            state.queryId = null;
            state.reportTitle = null;
        }
    },
});
export const {setQueryId, clearQueryId, setReportTitle} = reportSlice.actions;
export const selectQueryId = (state) => state.report.queryId
export const selectReportTitle = (state) => state.report.reportTitle
export default reportSlice.reducer;

