import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useMediaQuery } from 'react-responsive';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const texts = [
  {
    label: 'Exclusive Offer: Sign Up for a Free Credit and Get 50% Off Your Purchase!'
  },
  {
    label: 'Do It Yourself - Get a Comprehensive Vastu Report instantly for Your Home.'
  },
  {
    label: 'Premium Service : Just provide your floor plan and front direction — we will handle the rest!'
  },
  {
    label: 'Agents and Brokers: Contact Us Today for Special Pricing on Bulk Options!'
  }
];

function TextCarousel() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = texts.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const styles = {
    slide: {
      transitionDuration: '2s', // Adjust the speed here
    },
  };

  const springConfig = {
    duration: '2s', // Increase the duration for a slower transition
  };

  return (
    <Box sx={{ maxWidth: "100vw", flexGrow: 1}}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
      </Paper>
      <AutoPlaySwipeableViews
        style={styles.slide}
        springConfig={springConfig}
        interval={5000}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {texts.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
                <Box class={isMobile ?"offer-section-mobile": "offer-section"} zIndex={99} position={"relative"}>
                    <Typography className="offer-text" variant={isMobile ?'subtitle1': 'h5'}>{step.label}</Typography>
               </Box>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
      />
    </Box>
  );
}

export default TextCarousel;