
import CardImage from './CardImage';
import Dialog from '@mui/material/Dialog';
import {DialogTitle, DialogContent, DialogActions, Button, Typography} from '@mui/material';
import { Box, Alert } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import Slide from '@mui/material/Slide';
import "./Modal.css";
import RequestService from '../RequestService';
import React, { useState, useEffect } from 'react';
import FilterDropDown from './forms/FilterDropDown';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../authSlice';
import { FaQuestionCircle } from 'react-icons/fa';
import MarkdownViewerFromFile from './MarkdownViewerFromFile';
import ScrollTopFab from './ScrollToTopFab';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const MaterialModalImageFilter = ({ displayName, id, options, setOpenModal, setDropDownValue, filterId, selectedValue, optionCode }) => {
    const [value, setValue] = useState(selectedValue);
    const [showImages, setShowImages] = useState(false); 
    const [openHelpPlotShape, setOpenHelpPlotShape] = useState(false); 
    const [openHelpPlotFocus, setOpenHelpPlotFocus] = useState(false); 
    const [filters, setFilters] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [folder, setFolder] = useState(optionCode == 'plot_shape' ? 'plot_shapes' : optionCode);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedPath, setSelectedPath] = useState('');
    const [selectedDisplayName, setSelectedDisplayName] = useState('');
    const isMobile = useMediaQuery({ query: `(max-width: 760px) or (max-height: 600px)` });
    const currentToken = useSelector(selectCurrentToken);

    function handleChange(event) {
      //setValue({event.target.id});
      //document.getElementById(this.state.id).value = event.target.id;
      setValue(event.target.id);
      if (event.target.id != '') {
        setDropDownValue(id, event.target.id);
      } else {
        setDropDownValue(id, selectedValue);
      }
      
      setOpenModal(false);
    }
    function removeSetBlank() {
      //setValue('');
      setDropDownValue(id, '');
      setOpenModal(false);
    }

    function filterImages() {
      var objectArray = [];
      var filterSelected = false;
      filters.map((filter, index) => {
        var object = {}
        object.filterConditionCode = filter.conditionCode;
        object.filterConditionValueCode = document.getElementById(filter.conditionCode).value;
        if (object.filterConditionValueCode != '') {
          filterSelected = true;
          objectArray.push(object);
        }
      });
      if (filterSelected) {
        setErrorMessage('');
        var jsonData = {};
        jsonData.filterConditions = objectArray;
        RequestService.getFilteredMatchConditionCodes(jsonData, currentToken).then((response) => {
          if (response.status == 200) {
            //Parse response to see number of filters.
            var responseData = response.data;
            setFilteredOptions(responseData);
            setShowImages(true);
          }
       });
  
      } else {
          setErrorMessage('Select atleast one filter to load images');
      }
    }
  
    useEffect(() => {
      if (selectedValue) {
        options.map((option, index) => {
          if (option.matchingConditionCode == selectedValue) {
            setSelectedPath(option.imagePath);
            setSelectedDisplayName(option.matchingConditionDisplayName);
          }
        });
      }
      
      //load Filters
      RequestService.getFilterConditions(filterId, currentToken).then((response) => {
        if (response.status == 200) {
          //Parse response to see number of filters.
          var responseData = response.data;
          setFilters(responseData);
        }
     });
    }, []);
    
    function openHelpContent(displayName) {
      if (displayName == "Plot Shape") {
        setOpenHelpPlotShape(true);
      } else {
        setOpenHelpPlotFocus(true);
      }
      

    }

    function handleCloseHelp(displayName) {
      if (displayName == "Plot Shape") {
        setOpenHelpPlotShape(false);
      } else {
        setOpenHelpPlotFocus(false);
      }
    }

      return (
        <Dialog
        fullScreen
        open={true}
        scroll={"paper"}
        onClose={handleChange}
        TransitionComponent={Transition}
        align="center"
      >
        <DialogActions>
          <Button onClick={handleChange}>Close Window X</Button>
        </DialogActions>
        {selectedValue && selectedPath ? <Box align="center" style={{display: 'relative', width:"100%"}}> 
        <Typography>Current Selection</Typography>
        <CardImage id={selectedValue} filePath={selectedPath} displayName={selectedDisplayName}></CardImage><Button onClick={removeSetBlank}>Remove</Button></Box>:''}
        <DialogTitle id="scroll-dialog-title" align='center'>Select image best describes {displayName}
        <Typography variant='subtitle1'>Pick options below to load images</Typography>
        {displayName == 'Plot Shape' ?
        <Typography variant='subtitle1'>&nbsp;<FaQuestionCircle onClick={() => openHelpContent(displayName)}/></Typography>
        : ''}
        
        </DialogTitle>
        <Box align="center" style={{display: 'relative'}} justifyContent={'center'}> 
          <table width={isMobile ? "100%": "35%"}>  
            {
              filters.map((filter, index) => {
                return <tr>
                <td>{filter.conditionDisplayName}</td>  
                <td><FilterDropDown key={filter.conditionCode + '_' + index} id={filter.conditionCode} options={filter.conditionValues}/></td>
                </tr>
              })
            }
          </table>
          <Button size='small' variant="contained" onClick={filterImages}>Load Images</Button>
              {errorMessage != '' ? <Alert style={{width: "500px"}} justify="center" severity="error">{errorMessage}</Alert>: ''}

              {showImages ? <Alert severity="info" style={{width: "500px"}} justify="center">Please select the closest shape matching the below diagrams</Alert>: ''}
              {showImages ?
                filteredOptions.map((optionCode, index) => {
                    return<button className="imageButton" onClick={handleChange} key={optionCode.matchingConditionCode}>
                        <CardImage id={optionCode.matchingConditionCode} filePath={optionCode.imagePath} displayName={optionCode.matchingConditionDisplayName}></CardImage>
                      </button>
                })
                : ''
              }
        </Box>
        <Dialog
                  maxWidth={"md"}
                  open={openHelpPlotFocus}
                >
                  <DialogContent>
                        <MarkdownViewerFromFile mdFilePath="./help/plot_shape_help.md" />
                    </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseHelp(displayName)}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>
        <Dialog
                  maxWidth={"md"}
                  open={openHelpPlotShape}
                >
                  <DialogContent>
                        <MarkdownViewerFromFile mdFilePath="./help/plot_shape_help.md" />
                    </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseHelp(displayName)}>Close</Button>
                  </DialogActions>
                  <ScrollTopFab/>
              </Dialog>
      </Dialog>
      
         
    );
  }

  export default MaterialModalImageFilter