import useImage from './useImage'
import { Tooltip } from 'react-tooltip'

const Image = ({ filePath, id, displayName }) => {
    const { loading, error, image } = useImage(filePath)

    if (error) return <p>Error Loading</p>

    return (
        <>
            {loading ? (
                <p>Loading</p>
            ) : (
                <div>
                <img
                    src={image} id={id} className="plot-image" data-tooltip-content={displayName}
                />
                <Tooltip anchorId={id} variant="info" place="bottom"/>
                </div>
            )}
        </>
    )
}

export default Image