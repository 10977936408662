import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <div className="patent-pending-section"></div>
        <div className="footer-section">
          <div className="line-spacing"></div> {/* Empty div for line spacing */}
          <p>&nbsp;&copy;2023 vastureport.com. All rights reserved. Owned by Swift Vision LLC.</p>
        </div>
        <div className="footer-section">
          <ul className="legal-links">
            <li><Link to="/aboutus">About</Link></li>
            <li><Link to="/privacyPolicy">Privacy Policy</Link></li>
            <li><Link to="/termsOfService">Terms of Service</Link></li>
            <li><Link to="/cookiePolicy">Cookie Policy</Link></li>
            <li><Link to="/acceptableUsePolicy">Acceptable Use Policy</Link></li>
            <li><Link to="/disclaimer">Disclaimer</Link></li>
            <li><Link to="/VastuReportAPIs">APIs</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
            <li></li> {/* Empty li element for spacing */}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
