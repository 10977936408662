import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import RequestService from '../RequestService';
import usePageView from '../components/usePageView'; // Import the custom hook

export function Verify() {
    usePageView('Verify');

    let [searchParams, setSearchParams] = useSearchParams();
    const [message, setMessage] = useState([]);
    const params = [];

    for(let entry of searchParams.entries()) {
      params.push(entry);
    }
    useEffect(() => {
        var callParams = [];
        var messages = [];
        params.map((param, index) => {
            param.map((value, index) => {
                if (index == 1) {
                    callParams.push(value);
                }
             }); 
         });  

         RequestService.activate(callParams[0], callParams[1]).then((response) => {
            if (response.status == 200) {
                setMessage('User Successfully activated.Please login to continue.');
            } else {
                setMessage('User activation failed.');
            }                
          })
          .catch((err) => {
            messages.push("Unable to activate the user. The activation token has expired");
            setMessage(messages);
        });

    });

    return (

        <p className='App-p'>{message}</p>

    
    )
}

export default Verify;